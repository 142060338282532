//Padding SH

.p-12 {
	padding: 12px;
}

//Padding EH
.m-0 {
	margin: 0px;
}

.mb-16 {
	margin-bottom: 16px;
}