.master{
    width: 1280px;
    margin: 0 auto;
}
.flex-between { justify-content: space-between; }
.border-bottom-light {
    border-bottom: 1px solid rgba(#979797, 0.2);
}
.select-wrap {
    width: 23%;
    display: inline-block;
    vertical-align: baseline;
    margin: 6px;
}
.pv-20 {
    padding-top: 20px;
    padding-bottom: 20px;
}
.ht-400 {
    height: 400px;
}
.overflowDiv {
    overflow-x: auto;
    overflow-y: scroll;
}
.compartment .input-text-wrap {
    .text-input {
        width: 100px;
        font-size: 12px;
        padding-bottom: 5px;
    }
}

.reason-modal-popup{
    height: 300px;
    overflow: scroll;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    border-radius: 5px;
    width: 500px;
    background-color: white;
    z-index: 2000;
    display: flex;
    flex-direction: column;
}

.wt-220{
    width: 220px;
}