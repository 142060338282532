.volumetric-wrap{
    .wrapper{
        width: 1280px;
        margin: 0 auto;
        .home-title{
            font-size: 12px;
            color: #86868a;
        }
        .sub-heading-section{
            margin-top: 22px;
            font-family: 'avenir-medium',sans-serif;
            font-weight: 900;
            overflow: auto;
            .sub-heading{
                font-size: 24px;
                color: #282626;
                float: left;
            }
            .add-company-section{
                float: right;
                margin-right: 30px;
            }
        }
        .cta-action-btns {
            display: inline-block;
            .button-classic { 
                height: 30px;
                font-size: 14px;
                padding: 0 20px;
                border-radius: 5px;
                margin-top: 10px;
                margin-right: 10px;
            }
            &.cta-btn-right {
                float: right;
            }
        }
        .search-filter-section{
            clear: both;
            margin-top: 25px;
        }
        .company-list-wrap{
            margin-top: 45px;
            .no-records-section{
                margin-top: 40px;
            }
            .list-section{
                border-bottom: 1px solid rgba(212, 212, 212, 0.5);
                padding-bottom: 25px;
                padding-top: 15px;
                .checkbox-section{
                    display: inline-block;
                    vertical-align: middle;
                }
                .sub-header-inline {
                    display: inline-block;
                    margin-right: 6px;
                    min-width: 70px;
                }
                .table-header {
                    width: 11%;
                    font-size: 12px;
                    display: inline-block;
                    word-break: break-word;

                    &.select-row {
                        width: 4%;
                    }

                    &.rules-tab {
                        width: 18%;
                    }
                }
                .company-name-section{
                    display: inline-block;
                    vertical-align: middle;
                    margin-left: 10px;
                    width: 370px;
                    &.table-header {
                        margin-left: 85px;
                        width: 290px;
                    }
                    .comany-logo ,.compnay-details{
                        display: inline-block;
                        vertical-align: middle;
                        cursor: pointer;
                    }
                    .comany-logo{
                        width: 50px;
                        height: 35px;
                        background-size: contain;
                        background-repeat: no-repeat;
                    }
                    .compnay-details{
                        margin-left: 25px;
                        color: #1a2734;
                        font-size: 15px;
                        font-weight: 900;
                        width: 220px;
                        .date{
                            color: #86868a;
                            font-size: 12px;
                        }

                        .name {
                            word-break: break-word;
                            display: -webkit-box;
                            overflow: hidden;
                            -webkit-line-clamp: 2;
                            -webkit-box-orient: vertical;
                            text-overflow: ellipsis;
                        }
                    }
                }
                .admin-details{
                    display: inline-block;
                    vertical-align: middle;
                    width: 340px;
                    font-size: 15px;
                    color: #1a2734;

                    &.table-header {
                        margin-left: 8px;
                        width: 337px;
                    }
                    .admin-email{
                        font-size: 15px;
                        color: #1a2734;
                        cursor: pointer;
                    }
                    .admin-pan{
                        font-size: 12px;
                        color: #86868a;
                    }
                }
                .account-type{
                    display: inline-block;
                    vertical-align: middle;
                    width: 140px;
                    font-size: 15px;
                    color: #1a2734;
                }
                .status{
                    display: inline-block;
                    vertical-align: middle;
                    width: 100px;
                }
            }
            .list-section{
                &.header{
                    font-weight: 600;
                    padding-top: 0;
                    color: #1a2734;
                    font-size: 14px;
                }
            }
            .list-section{
                &.content:hover{
                    border: 1px solid rgba(12, 12, 12, 0.38);
                    box-shadow: 0px 10px 20px 0px rgba(158, 170, 181, 0.45);
                    
                    .company-name-section .compnay-details .name {
                        display: block;
                    }
                }

            }
        }
        .action-icon {
            background-repeat: no-repeat;
            height: 18px;
            width: 18px;
            background-size: contain;
            // margin: 0 5px;
            cursor: pointer;
            display: inline-block;
            padding: 5px;
            margin: 5px;
        }
    }
}
.divTable{
    margin-top: 20px;
    margin-bottom: 20px;
    display: table;
    width: 100%;
}
.divTableRow {
    display: table-row;
}
.divTableHeading {
    color: white;
    background-color: #EEE;
    display: table-header-group;
}
.divTableCell, .divTableHead {
    /*border: 1px solid #999999;*/
    display: table-cell;
    padding: 10px 10px;
    font-size: 12px;

    &.bordered {
        border-bottom: 1px solid #999999;
    }
}
.divTableHeading {
    background-color: #528aea;
    display: table-header-group;
    font-weight: bold;
}
.divTableFoot {
    background-color: #EEE;
    display: table-footer-group;
    font-weight: bold;
}
.divTableBody {
    display: table-row-group;
    margin-top: 10px;
}

.select{
    display: inline-block;
    width: 150px;
}

.slabRange {
    .input-text-wrap {
        margin-bottom: 0;
    }
    .input-element input {
        width: auto;
        font-size: 12px;
    }
}

.penaltyPercent .input-text-wrap {
    .text-input {
        width: 50px;
        font-size: 12px;
        padding-bottom: 5px;
    }
}
.penaltyBox {
    text-align: center;
}
.download-btn {
    text-decoration: none;
}
.ml-19px{
    margin-left: 19px;
}


.inputBoxes {
    .input-text-wrap {
        margin-bottom: 0;
         .text-input {
            width: 150px;
            font-size: 12px;
        }
    }
    &.lbhBox {
        .input-text-wrap {
             .text-input {
                width: 90px;
            }
        }
    }
}

.mr-10 {
    margin-right: 10px;
}