.fuel-escalation-wrap {
  padding: 20px;
  .select-rfq {
    display: inline-block;
    vertical-align: middle;
    width: 200px;
    height: 26px;
    margin-right: 10px;
  }
  .data-picker {
    display: inline-block;
    vertical-align: sub;
    margin-left: 10px;
    .label {
      font-size: 10px;
      color: #86868a;
    }
    .react-datepicker-manager,
    .react-datepicker-wrapper,
    .react-datepicker__input-container,
    .react-datepicker__input-container input {
      width: 142px;
      height: 38px;
      font-size: 16px;
    }
  }
  .input-section-wrap {
    display: inline-block;
    vertical-align: middle;
    margin-left: 10px;
    .label {
      color: #86868a;
      font-size: 10px;
      font-weight: 500;
    }
    .input-section {
      .input {
        width: 110px;
        height: 38px;
        font-size: 16px;
      }
    }
  }
  .list-wrap-fuel {
    width: 100%;
    margin-top: 30px;
    .list-fuel {
      margin-bottom: 10px;
      &.header {
        font-size: 12px;
        font-weight: 900;
        color: #868e96;
      }
      .rfq-id, .from, .to, .escalation-val, .action{
        display: inline-block;
        width: 20%;
      }
      .action{
        width: 10%;
      }
      .escalation-val{
        width: 30%;
      }
    }
  }
}
