.material-details-section{
    margin-top: 30px;
        .list-section {
            border-bottom: 1px solid rgba(212, 212, 212, 0.5);
            padding-bottom: 25px;
            padding-top: 20px;
            display: flex;
            &.content{
                // margin-top: -20px;
                &:hover {
                border: 1px solid rgba(0, 89, 251, 0.38);
                box-shadow: 0px 10px 20px 0px rgba(129, 172, 212, 0.45);
                }
            }
            &.header{
                font-weight: 600;
                padding-top: 0;
                color: #1a2734;
                font-size: 14px;
            }
        }
    .tabel-header {
        width: 120px;
        display: inline-block;
        vertical-align: middle;
        // padding: 5px;
        white-space: nowrap; /* Prevent line wrapping */
        // overflow: hidden; /* Hide overflowing content */
        // text-overflow: ellipsis; /* Add ellipsis (...) for overflow */
        text-wrap: wrap;
        font-size: 14px;
        padding: 5px;
        &.material {
            width: 300px;
        }
        &.gap1221{
            width: 200px;
        }
    }
}
.date {
    .DateInput{
        width: 125px;
        .DateInput_input{
            font-size: 12px;
            border: 1px solid #d8dcf0;
            padding: 0px 10px 0px;
            line-height: 13px;
            height: 40px;
            border-radius: 5px;
        }
    }
    .wt-80{
        width: 80px;
    }
}
.material-mrp-main{
    .search-text-wrap{
        margin-top: 1rem !important;
    }
    .button-classic{
        height: 38px;
    }
    .cross-img{
        right: 26% !important;
    }
}
.material-modal{
    .modal-popup {
        max-height: 500px;
        width: 596px;
        min-height: 500px;
    }
}
.wt-state{
    width: 50%;
}
.modal-pad{
    padding: 1.5rem;
}
.materialCode{
    word-break: break-all;
}
