.master{
    width: 1280px;
    margin: 0 auto;
}
.success {
    color: #0c8434;
}
.error {
    color: red;
}
.search-text-wrap .text-search{
    width: 190px !important;
}