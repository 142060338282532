@import "../variables.less";

.bank-details-popup{
    .bank-overlay{
        position: fixed;
        z-index: 10;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: rgba(27, 33, 67, 0.9);
    }
    .bank-details-content{
        position: fixed;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
        border-radius: 5px;
        width:90%;
        max-width: 1065px;
        min-height: 100px;
        max-height:85%;
        overflow-y: scroll;
        background-color: #E3E9FF;
        z-index: 11;
        display: flex;
        flex-direction: column;
        padding:10px 10px;

        .bank-heading{
            padding: 20px 0 0 5px;
            font-size: 20px;
            font-weight: 400;
        }


        .bank-details-main {
            background-color: white;
            border: 1px solid black;
            border-radius: 8px;
            margin-top: 10px;
            width: 100%;
            height: auto;
            padding-bottom: 20px;

            .bank-details-header {
                display: flex;
                flex-direction: column;
                width: 100%;
            }

            .bank-details-heading {
                display: flex;
                border-bottom: 1px solid black;
                width: 100%;
                padding: 20px 10px;
                // display: flex;
                flex-direction: column;

                .bank-radio-options{
                    display: flex;
                    width:40%;
                    justify-content: space-between;
                    align-items: center; 
                    margin-top: 10px;                

                    .bank-radio-option{
                        display: flex;
                        height:30px;
                        align-items: center;

                        input[type='radio']{
                            border:1px solid #627FE9;
                            height:20px;
                            width:20px
                        }
                    }
                }
            }

            .bank-details-body {
                display: flex;
                flex-direction: column;
                padding: 10px 10px;
               
                .bank-details-row:last-child{
                    border-bottom: 1px solid #85919E;
                    padding-bottom: 20px;
                }

                .bank-details-row{
                    display: flex;
                    width:100%;
                    justify-content: space-between;
                    
                    .bank-input {
                        min-height: 35px;
                        width: 90%;
                        padding: 10px;
                        margin-top: 5px;
                        border: 1px solid #627FE9;
                    }
    
                    // .bank-new-vendor-dropdown {
                    //     min-height: 35px;
                    //     width: 90%;
                    // }
    
                    .singleDatePicker-bank {
                        .SingleDatePicker{
                            border: 1px solid #627FE9;
                            width: 100%;
                            height: 38px;
                            margin-top: 5px;
    
                            input{
                                height: 35px;
                                border: none;
                                font-size: 14px;
                            }
                        }
                    }
                }
            }

            .button-classic-bank {
                height: 38px;
                padding: 0 15px;
                background-color: white;
                color: #627fe9;
                font-size: 16px;
                font-weight: bold;
                outline: none;
                cursor: pointer;
                border: 1px solid #627fe9;
                width:260px
            }

            .document-upload-bank{
                height: 38px;
                padding: 0 15px;
                background-color: #627fe9;
                color: white;
                font-size: 16px;
                font-weight: bold;
                // border: none;
                outline: none;
                cursor: pointer;
                border: 1px solid #627fe9;
                border-radius: 6px;
                width:150px;
                margin:10px 10px 0 auto
            }
        }

        .cancel-save{
            display: flex;
            width:30%;
            margin:10px 0 0 auto;
            justify-content: space-between;

            .button-classic-bank-1{
                height: 38px;
                padding: 0 15px;
                background-color: white;
                color: #627fe9;
                font-size: 16px;
                font-weight: bold;
                outline: none;
                cursor: pointer;
                border: 1px solid #627fe9;
                border-radius: 6px;
                width:150px
            }

            .button-classic-bank-2{
                height: 38px;
                padding: 0 15px;
                background-color: #627fe9;
                color: white;
                font-size: 16px;
                font-weight: bold;
                // border: none;
                outline: none;
                cursor: pointer;
                border: 1px solid #627fe9;
                border-radius: 6px;
                width:150px
            }
        }
        
    }
}

.custome-checkbox-wrap{
    width: 20px;
    height: 20px;
    border-radius: 5px;
    border: 1px solid #d9d9d9;
    display: inline-block;
    position: relative;
    cursor: pointer;
    &.selected{
        border: 1px solid @tms-admin-color;
    }
    .inner-circle{
        width: 10px;
        height: 10px;
        border-radius: 15%;
        background: @tms-admin-color;
        position: absolute;
        top: 4px;
        left: 4px;
    }
}
.margin-right{
    margin-right: 20px;
}
.no-records{
    padding: 10px;
    font-size: 16px;
    text-align: center;
}
.error{
    color: red;
}
.input-text-align-top .input-text-wrap{
    vertical-align: top;
}

.vAlignCenter {
    vertical-align: middle;
}

.inBlock {
    display: inline-block;
}

.wt-150 {
    width: 150px;
}

.mb-10 {
    margin-bottom: 10px;
}

.mb-20 {
    margin-bottom: 20px;
}

.ml-10 {
    margin-left: 10px;
}

.mr-12 {
    margin-right: 12px;
}

.wt-60 {
    width: 60px;
}

.input-spacing div {
    margin-right: 20px;

    .input-text-wrap {
        vertical-align: middle;
        margin-bottom: 5px;
    }
}

.vendor-select {
    width: 300px;
}

.wt-20p{
    width:20%
}

.wt-30p{
    width:30%
}

.wt-60p{
    width:60%
}

.wt-70p{
    width:70%
}