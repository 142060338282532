.reconciliation {
    .home-navigation-holder {
        min-width: 350px;
        max-width: 400px;
        &.mainTabs {
            max-width: 920px;
        }
    }

    .select-wrap {
        display: inline-block;
        vertical-align: middle;
        width: 33%;
        margin-bottom: 20px;
        .label{
            font-size: 10px;
            color: #868e96;
            //text-transform: uppercase;
            margin-bottom: 10px;
        }
        .select {
            width: 277px;
        }
    }

    .minimum-charge {
        display: inline-block;
        .input-text-wrap {
         width: 100%;
            .text-input  {
                width: 150px;
            }
        }
    }


    .approval-btn {
        text-align:right;
        .button-classic {
            margin-right: 10px;
            margin-top:10px;
        }
    }
    
    .header {
        background: #528aea;
        color: #fff;
        padding: 10px 12px;
        font-family: 'Avenir',sans-serif;
        font-size: 18px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
    } 
    .credit-master {
        .header {
            background: #528aea;
            color: #fff;
            padding: 10px 12px;
            font-family: 'Avenir',sans-serif;
            font-size: 18px;
            font-weight: 500;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
        }
        .fuel-btn {
            margin-top: 10px;
            text-align: center;
        }
        .fuel-details {
            margin-top: 10px;
            margin-left: 35px;
            .fuel-rate {
                display: inline-block;
            }
            .freight-rate {
                display: inline-block;
                width: 25%;
                .input-text-wrap {
                    width: 100%;
                    .text-input {
                        width: 200px;
                    }
                }
            }
            .input-text-wrap {
                width: 10%;
                .text-input {
                    width: 92px;
                }
            }
            .DateRangePickerInput_arrow {
                display: none;
            }
            .select-wrap {
                display: inline-block;
                vertical-align: middle;
                width: 33%;
                margin-bottom: 20px;
                margin-left: 50px;
                .label{
                    font-size: 10px;
                    color: #868e96;
                    //text-transform: uppercase;
                    margin-bottom: 10px;
                }
            }
        }
    }
    .fuel-escalation-table {
        // margin-top: 20px;
        // margin-bottom: 20px;
        .list-section {
            font-weight: 600;
            color: #1a2734;
            font-size: 15px;
            display: flex;
            justify-content:space-between;
            border-bottom: 1px solid rgba(212, 212, 212, 0.5);
            .table-header {
                width: 25%;
                padding: 10px 0px;
                margin-left: 30px;
                &.detention-grace {
                    width: 10%;
                }
                &.product {
                    width: 50%;
                }
                &.weight {
                    width: 75%;
                    &.weight-detention {
                        width: 100%;
                    }
                    .label {
                        text-align: center;
                        border-bottom: 1px solid rgba(212, 212, 212, 0.5);
                        margin-bottom: 7px;
                    }
                    .slab-weight {
                        display: flex;
                        justify-content: space-between;
                        div{
                            width: 25%;
                            text-align: center;
                            &.vehicle-type {
                                width: 50%;
                            }
                        }
                    }
                }
            }
            .table-row {
                width: 25%;
                padding: 10px 0px;
                margin-left: 30px;
                &.detention-grace {
                    width: 10%;
                }
                &.product {
                    width: 50%;
                    .product-tooltip {
                        display: none;
                    }
                    &:hover .product-tooltip {
                        display: block;
                        bottom: 47px;
                        background: #fff;
                        box-shadow: 0 4px 24px 0 rgba(37, 38, 94, 0.1);
                        border-radius: 5px;
                        .list{
                            padding: 5px;
                        }

                    }
                }
                .input-text-wrap {
                    margin-bottom: 0px;
                }
            }
            .event {
                width: 50%;
            }
            .action {
                width: 10%;
                .switch {
                    margin-top: 7px;
                }
                &.delete-icon {
                    margin: 15px 5px;
                }
            }
            .amount {
                width: 20%;
            }
            .uom {
                width: 30%
            }
            .vehicle-type {
                width: 60%;
            }
            .content {
                width: 20%;
            }
        }
        .oda-charges {
            .table-row {
                padding: 0px;
                &.align-center {
                    display: flex;
                    align-items: center;
                }
                .input-text-wrap {
                    margin-bottom: 7px;
                    .text-input {  
                        width: 120px;
                    }
                }
                &.slab-weight {
                    width: 75%;
                    &.slab-weight-detention {
                        width: 100%;
                    }
                    .slab-weight-data {
                        display: flex;
                        justify-content: space-between;
                        &.row-data {
                            margin-bottom: 5px;
                            border-bottom: 1px solid rgba(212, 212, 212, 0.5);
                        }
                        div{
                            width: 25%;
                            &.vehicle-type {
                                width: 50%;
                                .vehicle-type-list {
                                    width: 100%;
                                }
                            }
                        }
                        .slab-action {
                            display: flex;
                            margin-top: 10px;
                        }
                        .input-text-wrap {
                            margin-bottom: 7px;
                            .text-input {  
                                width: 100px;
                            }
                        }
                    }
                }
            }
        }
    }
    .debit-master {
        .late-delivery {
            margin-top: 10px;
            padding: 10px;
            .grace {
                display: inline-block;
                width:33%;
                .input-text-wrap {
                    width: 30%;
                    .text-input {
                        width: 92px;
                    }
                }
            }
            .amount {
                display: inline-block;
                width:23%;
                .input-text-wrap {
                    width: 30%;
                }
                .text-input {
                    width:180px;
                }
            }
            .select-wrap {
                display: inline-block;
                vertical-align: middle;
                width: 33%;
                margin-bottom: 20px;
                .label{
                    font-size: 10px;
                    color: #868e96;
                    //text-transform: uppercase;
                    margin-bottom: 10px;
                }
                .select {
                    width: 277px;
                }
            }
        }
        .non-register-vendor {
            .list-section {
                border: none;
                .table-row {
                    padding: 10px 0px;
                }
            }

        }
    }
    .rfq-input {
        width: 80%;
        height: 3;
        min-height: 33px;
        font-size: 16px;
        border: 1px solid #cccccc;
        border-radius: 6px 5px 5px 5px;
        padding: 7px 9px;
    }
    .type-select {
        margin-top: 10px;
        text-align: center;
        .select {
            width: 277px;
        }
    }
    .fixed-define-process {
        .heading {
            font-size: 16px;
        }
    }
    .drop-section {
        margin-top: 20px;
        margin-bottom: 20px;
    }
    .fixed-define {
        margin: 10px 0px;
    }
}
.DateRangePicker .DateRangePickerInput.DateRangePickerInput_1:after, .DateRangePickerInput.DateRangePickerInput_1:after {
    content: "to";
    position: absolute;
    left: 47%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    margin-left: 1px;
    font-family: 'Proximanova-Bold',sans-serif;
    color: #212224;
    top: 10px;
}

.DateRangePicker input#startDate, .DateRangePicker input#endDate {
    height: 38px;
    border: 1px solid hsl(0,0%,80%);
    border-radius: 5px;
    font-size: 16px;
    color: #212224;
}

.DateRangePicker input#startDate {
    border-right: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    background-image: none;
}

.DateRangePicker input#endDate {
    border-left: none;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    background-image: none;
}

.CalendarDay__selected, .CalendarDay__selected:active, .CalendarDay__selected:hover {
    color: #fff !important;
    background: #3f51b5 !important;
    border: 1px double #3f51b5 !important;
}

.CalendarDay__selected_span, .CalendarDay__hovered_span {
    color: #3f51b5 !important;
    background: rgba(63, 81, 181, 0.1) !important;
    border-color: rgba(63, 81, 181, 0.1) !important;
}

.DateRangePickerInput_arrow_svg {
    display: none;
}

.add-row{
    &.detention {
        .modal-popup {
            width: 1300px;
            padding: 20px 20px 15px 20px;
        } 
        .table-header {
            margin-left: 20px;
            &.table-header-max {
                width: 40%;
                //margin-left: 0px;
            }
            &.table-header-max-vehicle-type {
                width: 65%;
                //margin-left: 0px;
            }
        }
        .select {
            width: 100px;
            &.select-max {
                width: 150px;
            }
            &.select-max-vehcile-type {
                width: 250px;
            }
            &.select-max-vehcile-type-multi {
                width: 350px;
            }
        }
        .deliveryPerformance {
            .table-row {
                margin-left: 20px;
            }
            .input-text-wrap .text-input {
                width: 80px;
            }
        }
    }
    .modal-popup {
        min-height: 284px;
        max-height: 600px;
        overflow: auto;
        width: 1050px;
        .heading {
            font-size: 19px;
            font-weight: 200;
            color: #0c0b0b; 
            margin-bottom: 10px;
        }
        .select-wrap {
            display: inline-block;
            vertical-align: middle;
            width: 33%;
            margin-bottom: 20px;
            .select {
                width: 277px;
            }
            .label{
                font-size: 10px;
                color: #868e96;
                //text-transform: uppercase;
                margin-bottom: 10px;
            }
            &.product-details{
                width: 100%;
            }
        }
    }
}

.increase-icon {
    background-image: url(/images/up-arrow.svg);
    background-repeat: no-repeat;
    height: 15px;
    width: 15px;
    background-size: contain;
    margin: 0 5px;
    cursor: pointer;
    display: inline-block;
}

.bill-number {
    .section-item {
    display: inline-block;
    vertical-align: super;
    padding: 5px;
    color: #535356;
    font-size: 16px;
    white-space: nowrap;
    text-transform: capitalize;
    }
    .title {
        vertical-align: super;
        margin-left: 10px;
    }
    .check-label {
        vertical-align: top;
        // color: #868e96;
        font-weight: 600;
        // margin-bottom: 10px;
        font-size: 10px;
        color: #868e96;
        //text-transform: uppercase;
        margin-bottom: 10px;
    }
    .select {
        width: 250px;
    }
}

.heading {
    color: black;
    font-size: 22px;
}

.zone-rate {
    .text-input {
        width: 240px;
    }
    .button-add {
        float: right;
    }
}

.deliveryPerformance {
   .input-text-wrap {
       width: auto;
       .text-input {
           width: 200px;
       }
   }
}
