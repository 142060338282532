.plants-depots-wrap{
    .search-filter-section{
        .select{
            margin-left: 20px;
        }
        .add-plants-section{
            display: inline-block;
            vertical-align: middle;
            margin-left: 20px;
        }
    }
    .No-data{
        margin-top: 40px;
    }
    .filter-clear-section{
        margin-top: 30px;
    }
    .download-section{
        margin-left: 6px;
        margin-top: 30px;
    }
    .plants-depots-list-section{
        margin-top: 40px;
        .plants-depots-list{
            padding-top: 24px;
            padding-left: 10px;
            &.content{
                border-bottom: 1px solid #ececee;
                padding-bottom: 22px;
            }
            &.content:hover{
                padding-left: 5px;
                border-bottom: 1px solid #ececee;
                padding-bottom: 22px;
                border: 1px solid rgba(0, 89, 251, 0.38);
                box-shadow: 0px 10px 20px 0px rgba(129, 172, 212, 0.45);
                cursor: pointer;
            }
            .branch-name{
                font-weight: 900;
            }
            .branch-name, .branch-code , 
            .sap-branch-code, .type , .pin-code, 
            .zone, .region, .address{
                display: inline-block;
                vertical-align: middle;
                width: 120px; 
                color: #535356;
                font-size: 14px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                &.header{
                    color: #ababae;
                    font-size: 12px;
                }
            }
            .sap-branch-code{
                width: 150px;
            }
            .type {
                width: 100px;
            }
             .pin-code, 
            .zone, .region{
                width: 80px;
            }
            .address{
                width: 220px;
            }
            .fs-12{
                font-size: 12px;
            }
            .theme-color {
                color: #528aea;
            }
            .error-color {
                color: #d9042a;
            }
            .sync-button {
                border: 1px solid;
                border-radius: 5px;
                width: 80px;
                padding: 2px;
            }
        }
    }
}