.grey-box { 
	display: inline-block;
	padding: 5px;
	background-color: #e0e0e0;
	border-radius: 20px; 
	margin-right: 7px;
	font-size: 11px;
	cursor: not-allowed;
	
	

	&.approve {
		background-color: rgba(4, 167, 4, 0.4);
	}

	&.reject {
		background-color: rgba(216, 6, 6, 0.35);
	}
	&.resend {
		background-color: rgba(216, 6, 6, 0.35);
	}

	&.active {
		background-color: blue;
		color: white;
	}
}

.tab-box { 
	display: inline-block;
	padding: 10px;
	background-color: #e0e0e0;
	border-radius: 10px; 
	margin-right: 7px;
	font-size: 14px;
	cursor: pointer;

	&.active {
		background-color: blue;
		color: white;
	}
}

.vendor-email {
	width: 200px !important;
}

.approvals-listing {
	display: inline-block;
    vertical-align: middle;
    margin-left: 15px;
    font-size: 13px;
	width: 300px;
	text-align: center;
}

.comany-logo {
	width: 25px;
    height: 25px;
    float: left;
    margin-right: 10px;
}

.dInline {
	display: inline;
	vertical-align: middle;
}

.form-textarea .text-input-content {
	margin-top: 15px;
	width: auto;
}

.vendors-list-wrap.home-wrap .wrapper .company-list-wrap {
	.list-section .status {
		width: 80px;
	}
	.list-section .account-type {
		margin-right: 10px;
		font-size: 13px;
	}
}

.cancel-btn .button-classic {
	text-align: center;
	background: #528aea;
	color: white;
}

.mt-20 {
	margin-top: 20px;
}

.mb-10 {
	margin-bottom: 10px;
}

.enable-menu {
	cursor: pointer;
	border: 1px solid #528aea;
	background-color: #fff;
}

.list-count-box {
	padding: 8px;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    font-size: 12px;
}

.fs-14{
	font-size: 14px;
}

div.list-count-box {
	-webkit-transition: -webkit-transform 200ms cubic-bezier(0.175, 0.885, 0.320, 1.275);
-moz-transition: -moz-transform 200ms cubic-bezier(0.175, 0.885, 0.320, 1.275);
-o-transition: -o-transform 200ms cubic-bezier(0.175, 0.885, 0.320, 1.275);
transition: transform 200ms cubic-bezier(0.175, 0.885, 0.320, 1.275);
}
.overlay{
	position: absolute;
	z-index: 10;
	width: 100%;
	height: auto;
	// top: 0;
	// left: 0;
	background-color: rgba(27, 33, 67, 0.9); 
	border: 1px solid rgba(212, 212, 212, 0.5);
	border-radius: 5px;
	padding: 5px;
	margin-left: 5px;
}

.white {
	color: white;
}

// vendor


.button-container button {
	border: none;
	background-color: transparent;
	cursor: pointer;
	padding: 10px 20px;
	font-size: 16px;
	margin-right: 10px;
	font-weight: normal; /* Reset font weight */
	// height: 100px;
	// width: 170px;
  }
  
  .button-container button.active {
	border-bottom: 3px solid  #627FE9;;
     font-weight: bold !important; /* Make font bold for active button */
	font-size: 21px;
	// font-family:Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif ;
  }
  
  .content {
	margin-top: 20px;
  }
  .newpop {
  	.msg-section {
  		background-color: #E3E9FF;

  		.approveBtn {
  			background-color: #627FE9;
  			color: #ffff;
  		}
  		.cancel-icon {
  			height: 20px;
  			width: 20px;
  			position: absolute;
  			top: -20px;
  			left: 400px;
  			cursor: pointer;
  			display: inline-block;
  			background-size: contain;
  			background-repeat: no-repeat;
  		}

  	}

  }
