.pageWrapperT121 {
	padding: 24px;
	position: relative;
	background-color: #E3E9FF;
	height: calc(100vh - 80px);
	overflow-y: auto;
}
.br253{
  border:3px solid red
}

.table-container121 {
  margin: 20px;
}

.enlarging-table121 {
  width: 100%;
  border-collapse: collapse;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
// .enlarging-table121 tbody tr:nth-child(even) {
// 	background-color: #eff1fd;
// }

.blue-header121 {
  background-color: #44569e;
  color: white;
  padding: 15px;
  text-align: left;
}

// .enlarged-row121 {
//   transform: scale(1.05); /* Enlarge the row slightly on hover */
//   transition: transform 0.2s ease-in-out;
// }

.enlarging-table121 tbody tr {
  background-color: #fff;
}

.enlarging-table121 th{
	padding: 15px;
  text-align: left;
	// border:#44569e solid 1px;
}
.enlarging-table121 td {
  padding: 15px;
  text-align: left;
	// border:white solid 1px;
}

.enlarging-table121 tbody tr:hover {
  background-color: #f0f0f0;
	cursor:default;
}
.action-icon121 {
	background-repeat: no-repeat;
	height: 18px;
	width: 18px;
	background-size: contain;
	// margin: 0 5px;
	cursor: pointer;
	display: inline-block;
	padding: 5px;
	margin: 5px;
}


//POPUP
.popup-container121 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6); // Semi-transparent black background for the blur effect
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(5px); // Adjust the blur intensity as needed
  z-index: 999;
}

.popup121 {
  background: #fff; // Set your desired background color
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  width: 500px; // Adjust the width as needed
  max-height: 90%;
  max-width: 90%;
  position: relative;
  overflow: auto; 
}
.popup121::-webkit-scrollbar {
  width: 05px; /* Adjust the width as needed */
}

.popup121::-webkit-scrollbar-thumb {
  background-color: white; /* Adjust the color as needed */
}

.popup121::-webkit-scrollbar-track {
  background-color: transparent;
}

.header121 {
  padding: 10px;
  text-align: center;
  background: white; // Set your desired background color for the header
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.header121 h2 {
  margin: 0;
}

.content121 {
  display: flex;
  flex-direction:column;
  padding: 20px;
  overflow: hidden;
}
.form-group121 {
  margin-bottom: 20px;
}

.label121 {
  display: block;
  margin-bottom: 5px;
}

.textarea121 {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize:none;
}
.date-picker121 {
  display: inline-block;
  margin-top: 10px;
}

.footer121 {
  padding: 10px;
  text-align: right;
  background: white; // Set your desired background color for the footer
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.footer121 button {
  margin-left: 10px;
}

.checkbox-group121 {
  display: flex;
  flex-wrap: wrap;
}

.checkbox-group121 > div {
  margin-right: 20px; /* Adjust margin between checkboxes */
}

.checkbox-group121 input[type="checkbox"] {
  margin-right: 5px;/* Adjust margin between checkbox and label */
}
.checkbox-group121 label{
  margin-bottom: 5px;/* Adjust margin between checkbox and label */
}
.banner {
  background-color: orange;
  padding: 5px;
  // line-height: 18px;
}

