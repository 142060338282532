
.loader{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0, 0.5);
  z-index: 15;
  .loader-img{
    background-image: url("../../../../assets/images/loading.gif");
    width: 120px;
    height: 120px;
    background-size: contain;
    display: inline-block;
    background-repeat: no-repeat;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
