.add-provider-root{
    .modal-popup{
        min-height: 500px;
        .form-section{
            margin-bottom: 15px;
        }
        .active-status{
            margin: 15px 0px;
            display : flex;
            .label{
                margin-right: 20px;
            }
        }
        .dropdown-holder{
            margin: 10px 0px;
            .users-holder{
                margin: 5px 0px;
                .select {
                    width: 400px;
                    &:first-child {
                        margin-right: 20px;
                    }
                }
            }
            .plus{
                margin: 5px 0px;
            }
        }
    }
}