@import "../../variables.less";

.home-navigation-panel-root{
    font-size: 15px;
    .home-navigation-holder{
        overflow-x: scroll;
        overflow-y: hidden;
    }
    .home-navigation-holder::-webkit-scrollbar {
        height: 6px;
      }
      
      .home-navigation-holder::-webkit-scrollbar-track {
        background: #f1f1f1;
      }
      
      .home-navigation-holder::-webkit-scrollbar-thumb {
        background: #528aea;
        border-radius: 4px; 
      }
      
      .home-navigation-holder::-webkit-scrollbar-thumb:hover {
        background: #555;
      }
    .company-name{
        font-size: 24px;
        color:  #282626;
        font-family: 'avenir-medium',sans-serif;
        margin-top: 20px;
        margin-right: 20px;
    }
    .company-details{
        .options{
            display: block;
            margin-right: 5%;
            margin-bottom: 5px;
            .pan-heading{
                color: #1a2734;
                font-size: 14px;
                display: inline-block;
                vertical-align: middle;
            }
            .pan-no{
                color: #86868a;
                margin-left: 5px;
                font-size: 14px;
                display: inline-block;
                vertical-align: middle;
                text-transform: capitalize;
            }
            .comp-website {
                text-transform: lowercase;
            }
            .img{
                display: inline-block;
                vertical-align: middle;
                width: 15px;
                height: 15px;
                background-size: contain;
                background-repeat: no-repeat;
            }
        }
    }
    .other-details{
        margin-top: 10px;
    }
    .current-screen {
        text-decoration: underline;
    }
}
body{
    -webkit-box-sizing: border-box;
}
.more-details-wrap{
    display: inline-block;
    vertical-align: middle;
    padding: 2px 7px 0px 7px;
    text-align: center;
    border-radius: 13px;
    background: #f9f9f9;
    color: #86868a;
    font-family: 'avenir-light',sans-serif;
    margin-right: 30px;
    height: 26px;
    line-height: 26px;
    min-width: 45px;
    &.imp{
        background: @tms-admin-color;
        color: #fff;
        font-weight: 500;
    }
}
.card-view {
    width: 40%;
    display: inline-block;
    border-radius: 10px;
    // border: 1px solid #f0f0;
    padding: 12px 12px;
    vertical-align: bottom;
    margin-top: -50px;
    box-shadow: 0px 10px 20px 0px rgba(129, 172, 212, 0.45);
    border: 1px solid rgba(0, 89, 251, 0.38);
    &.card-company-admin {
        margin-top: -30px;
    }
}
.title {
    width: 55%;
    display: inline-block;
    vertical-align: top;
}
.depot-users-list-firs{float:left;width:50%;}
.depot-users-list-secon{float:left;width:50%;}
.depot-users-list-firs .first,.depot-users-list-secon .first{float:left;}
.depot-users-list-firs .second,.depot-users-list-secon .second{float:left;}
.heading .first,.heading .second{font-weight: 900;}

.heading{
    display: flex;
    font-size: 12px;
    font-weight: 900;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.2px;
    color: #ababae;
}
.depot-users-list{
    display: flex;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.2px;
    color: #535356;
    margin : 4px 0px;
    padding-bottom: 10px;
    border-bottom: 1px solid #ececee;
    .edit-delete{
        display: flex;
        justify-content: space-between;
        max-height: 23px;
        width: 99px!important;
        .add-button{
            padding: 4px 6px;
            margin: 0px 4px 0px 0px;
        }
    }
}

.heading, .depot-users-list{
    .head{
        white-space: nowrap;
    }
    .usn{
        width: 135px
    }
}
.depot-users-list{
    .usn{
        font-weight: 900;
    }
}

.add-action-wrap-popup{
    .modal-popup{
        max-height: 500px!important;
        overflow: visible!important;
        width: 600px!important;
        min-height: 400px!important;
    }
}
.details-header{
    font-size: 10px;
    color: #868e96;
    margin-bottom: 10px;
    display: inline-block;
    width: 200px;
}
.field-details{
    display: flex;
    font-size: 14px;
    font-weight: 900;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.2px;
    color: #535356;
    border-bottom: 1px solid #ececee;
    .name{
        width: 350px;
        margin: 10px 15px 10px 5px;
        // text-align: center;
    }
    .fields{
        width: 200px;
        margin: 10px 15px 10px 5px;
        text-align: center;
    }
    .code{
        width: 150px;
        margin: 10px 15px 10px 5px;
        text-align: center;
    }
}
.multifield{
    display: flex;
    font-size: 12px;
    font-weight: 900;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.2px;
    color: #535356;
    .name{
        width: 350px;
        margin: 10px 15px 10px 5px;
        // text-align: center;
    }
    .fields{
        width: 200px;
        margin: 10px 15px 10px 5px;
        text-align: center;
    }
    .code{
        width: 150px;
        margin: 10px 15px 10px 5px;
        text-align: center;
    }
}

.wt-25p{
    width: 25%;
}

.TabContainer121{
width: 30%;
display: flex;
justify-content:center;
}
.TabOptions1221{
  width: 300px;
  background-color: white;
  color: black;
  border: none;
  padding: 5px;
}
.selectedTab1221{
    width: 300px;
    color: @tms-admin-color;
    border:none;
    border-bottom: 2px solid @tms-admin-color;
    padding: 5px; 
}

