// Button SH

.btnT1,.textBtnT1 ,.outlineBtnT1,.iconBtnT1{
    all:unset;
    color: #627FE9;
    font-family: 'Poppins',sans-serif;
    font-style: normal;
    padding: 5px 10px;
    border-radius: 4px;
    
    &:hover{
        cursor: pointer;
    }   
    
    &:active{
        background-color: #cfd9ff;
    }
    
    }
    
    .textBtnT1{
    
    }
    
    .outlineBtnT1{
     border-radius: 2px;
     border: 1px solid #627FE9;
    }
    

    .iconBtnT1{
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        border: 1px solid #627FE9;
        padding: 4px;
        width: 18px;
        height: 18px;
    }
  // Button EH