@import "../../../../variables.less";

.add-user-wrap{
    .overlay{
        position: fixed;
        z-index: 10;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: rgba(27, 33, 67, 0.9);
    }
    .modal-popup{
        max-height: 600px;
        overflow: scroll;
        position: fixed;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
        border-radius: 5px;
        width: 1000px;
        min-height: 500px;
        background-color: white;
        z-index: 11;
        .pop-heading{
            background-color: #f4f4f4;
            padding: 18px;
            font-family: 'avenir',sans-serif;
            font-size: 16px;
            font-weight: 600;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            color: @tms-admin-color;
            text-align: center;
            margin-bottom: 30px;
        }
        .content{
            padding: 20px 0px 20px 30px;
            .select-container{
                display: flex;
                margin: 20px 0px;
                .select-holder{
                    width: 277px;
                    position: relative;
                    .sub-head{
                        font-size: 10px;
                        font-weight: 500;
                        font-style: normal;
                        font-stretch: normal;
                        line-height: normal;
                        letter-spacing: normal;
                        color: #868e96;
                        margin-bottom: 7px;
                    }
                }
                .action-holder{
                    display: flex;
                    flex-wrap: wrap;
                    width: 330px;
                    margin: 15px;
                    position: relative;
                    .actions{
                        margin: 5px;
                        .add-button{
                            margin: 0;
                            height: 35px;
                            padding: 0 15px;
                            font-size: 20px;
                            margin-left: 10px;
                        }
                        .select{
                            width: 235px;
                        }
                    }
                    .add-button{
                        margin: 5px 5px;    
                        padding: 10px 20px;
                    }
                    .sub-head{
                        position: absolute;
                        font-size: 10px;
                        font-weight: 500;
                        font-style: normal;
                        font-stretch: normal;
                        line-height: normal;
                        letter-spacing: normal;
                        color: #868e96;
                        top: -10px;
                        left: 7px;
                    }
                }
            }
            .usn-container{
                margin: 17px 0px;
                display: flex;
                .add-button{
                    margin: 20px;
                    border-radius: 5px;
                }
                .btn-disabled{
                    background-color: #cac2c2;
                    border: 1px solid #cac2c2;
                    pointer-events: none;
                    color: white;
                }
            }
            .submit-section{
                display: flex;
                justify-content: flex-end;
                .add-button{
                    border-radius: 5px;
                    width: 145px;
                }
                .discard{
                    background: white;
                    color: @tms-admin-color;
                    border: 1px solid @tms-admin-color;
                }
            }
        }
    }
    &.add-indents-warp {
        .apply-settings {
            display: flex;
            justify-content: center;
            .label {
              font-family: 'Avenir',sans-serif;
              font-size: 16px;
              font-weight: normal;
              font-style: normal;
              font-stretch: normal;
              line-height: normal;
              letter-spacing: 0.2px;
              color: #535356;
              margin-left: 15px;
            }
          }
    }
}

.form-section .input-text-wrap {
    vertical-align: top;
}
.small-font{
    font-size: 12px;
    font-weight: normal;
}

.add-edit-user {
    margin-top: -4%;
}

.padding-left-0 {
    padding-left: 0px !important;
}

.arrow-icon {
    background-image: url(/images/drop-up-arrow.svg);
    background-repeat: no-repeat;
    height: 23px;
    width: 23px;
    background-size: contain;
    margin: 0 5px;
    cursor: pointer;
    float: right;
    transition-delay: 0.2s;
    transition-duration: 0.2s;
    -webkit-transition-duration: 0.5s;
    -webkit-transition-delay: 0.2s;
  }
  
  .rotate {
    transform: rotate(180deg);
  }
  
  .submoves {
      background-color: #5289ea;
      height: 30px;
      font-size: 16px;
      margin-top: 10px;
      margin-bottom: 10px;
      padding: 5px;
      color: white;
  }