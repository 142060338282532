.account-master {
    .header{
        background: #528aea;
        color: #fff;
        padding: 10px 12px;
        font-family: 'Avenir',sans-serif;
        font-size: 18px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        margin: 10px 0px;
    }

    .list-section {
        font-weight: 600;
        color: #1a2734;
        font-size: 15px;
        display: flex;
        justify-content:space-between;
        border-bottom: 1px solid rgba(212, 212, 212, 0.5);
        .table-header {
            width: 50%;
            padding: 10px 0px;
            margin-left: 30px;
        }
        .table-row {
            width: 50%;
            margin-left: 30px;
            padding: 10px 0px;
        }
        .event-id {
            width: 20%;
        }
        .event {
            width: 60%;
        }
        .action {
            width: 20%;
        }
        .rfq-input {
            width: 100%;
            height: 3;
            min-height: 33px;
            font-size: 16px;
            border: 1px solid #cccccc;
            border-radius: 6px 5px 5px 5px;
            padding: 7px 9px;
        }
        .delete-icon {
            margin-top: 10px;
        }
    }
    .approval-btn {
        text-align:right;
        margin-right: 50px;
        margin-top: 20px;
        .button-classic {
            margin-right: 10px;
        }
    }

    .event-overlay {
        z-Index: 1050;
    }
}