#table-title {
  text-align: center;
  font-family: inherit;
  width: 90%;
}

#broadcast-table {
  text-align: center;
  border-collapse: collapse;
  border: 3px solid #ddd;
  width: 90%;
}

#broadcast-table td,
#broadcast-table th {
  border: 1px solid #ddd;
  padding: 8px;
}

#broadcast-table tr:nth-child(even) {
  background-color: #f2f2f2;
}

#broadcast-table tr:hover {
  background-color: rgba(112, 178, 253, 0.1);
}

#broadcast-table th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  background-color: #528aea;
  color: white;
}

.generate-button {
  //display: inline;
  cursor: pointer;
  // border-radius: 2px;
  border: 1px solid #528aea;
  background: #528aea;
  margin: 0px 20px;
  color: white;
  padding: 10px;
  font-size: 14px;
  border-radius: 20px;
  text-align: center;
  width: 100px;
}

