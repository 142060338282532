.wholeNetWorkMaster {
    margin-left: 70px;
    background-color: #E3E9FF;
    display: flex;
    flex-direction: column;
    font-family: 'Poppins',sans-serif;
    font-style: normal;

    .heading {
        display: flex;
        flex-direction: column;
        column-gap: 7;

        .mainHeading {

            font-family: 'Poppins',sans-serif;
            font-style: normal;
            font-weight: 600;
            font-size: 30px;
            line-height: 32px;
            color: #000000;

        }

        .col2 {
            display: flex;
            flex-direction: row !important;
            row-gap: 3;
            margin-top: 20px;
            .boxsearch{
                background-color:white;
                border-radius: 4px;
                width: 350px;
                display: flex;
                box-shadow: 0px 0px 8px rgba(82, 138, 234, 0.45);
                border: 3px solid #7fabdb;

            }

            .newsearch {
              

                width: 290px;
                height: 40px;
                background: #FFFFFF;
                border: none;
                
                // border-radius: 4px;
                text-align: center;
                background-image: url('../../../assets/images/Search.svg');
                background-repeat: no-repeat;
                background-position-y: 50%;
                background-position-x: 10px;

            }

            .newsearch:focus {
                border: none;
                outline-offset: 0px;
                outline: none !important;
                

            }

            #xinsearch {
                margin-top: 2px;
                z-index: 10;

                &#xinsearch:hover {
                    cursor: pointer;

                }

            }

            .uploadTemplate {
                width: 180px;
                height: 40px;

                text-align: left;
                position: relative;

                .download-icon {
                    position: absolute;
                    width: 18px;
                    top: 5px;

                }

            }

            .templatebutton {
                width: 208px;
                height: 40px;
                text-align: left;
                margin-left: 10px;

                position: relative;

                .download-icon {
                    position: absolute;
                    top: 2px;
                }
            }

            .downloadData {
                width: 140px;
                height: 40px;

            }

            .addButton {
                box-sizing: border-box;
                background: #627FE9;
                color: #FFFFFF;
                width: 163px;
                height: 36px;
                border: 1px solid #627FE9;
                border-radius: 5px;

                &:hover {
                    cursor: pointer;
                }
            }

        }

    }

    .bodytable {
        
        margin-top: 10px;
        min-width: 1020px;
        height: 450px;
        overflow: scroll;
        font-family: "Poppins",sans-serif;
        font-size: 16px;
        font-weight: 500;
        line-height: 18px;
        letter-spacing: 0em;




        .alternaterow:nth-child(odd) {
            background: #EFF2FD;

        }

        .alternaterow:nth-child(even) {
            background: #FFFFFF;

        }

        .completeTable {
            border-spacing: 0;
            border-collapse: collapse;
            border: none;



            .tableheader {
                background: linear-gradient(0deg, rgba(0, 0, 0, 0.32), rgba(0, 0, 0, 0.32)), #627FE9;
                color: #FFFFFF;
                border: none;

            }

            .tableCell {
                text-align: center;
                border: none;

                border-left: 1px solid white;
                min-width: 150px;
                padding: 10px;
            }

            .divTableBody {
                min-width: 100px;
                border: none;



                .divTableCell {
                    border: none;
                    text-align: center;
                    padding: 10px;
                    border-right: 1px solid #528AEAB2;


                }

            }

            .divTableRow {
                border: none;

            }
        }

        .action-icon {
            background-repeat: no-repeat;
            height: 18px;
            width: 18px;
            border: none;
            background-size: contain;
            // margin: 0 5px;
            cursor: pointer;
            display: inline-block;
            padding: 5px;
            margin: 5px;
        }
        
}
.popupBody {
    width: 1332px;
height: 396px;
background-color: #E3E9FF;
overflow: hidden;

.popupHeading {
width: 284px;
height: 32px;
margin: 15px 20px 0px 10px;
font-family: 'Poppins',sans-serif;
font-style: normal;
font-weight: 500;
font-size: 28px;
line-height: 32px;
color: #0A0D17;
}
.alldropdown {
box-sizing: border-box;
position: absolute;
width: 1000px;
height: 255px;
left: 40px;
top: 77px;
display: flex;
flex-wrap: wrap;
margin-bottom: 5px;
padding: 15px;
background: #FFFFFF;
border: 1px solid #85919E;
border-radius: 5px;
}
input {
border: 1px solid #ccc;
padding: 8px;
color: #333;
border-radius: 4px;
}
}
input:focus {
border: 3px solid #7fabdb;
outline-offset: 0px;
outline: none !important;

}
.buttonstab {
position: absolute;
top: 330px;
left: 650px;
}
.cta-action-btns {
width: 210px;
}
.element {
display: inline;
}
}