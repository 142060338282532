@import "../../variables.less";

.new-vendor.add-company-wrap{
    .overlay{
        position: fixed;
        z-index: 10;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: rgba(27, 33, 67, 0.9);
    }
    .modal-popup{
        position: fixed;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
        border-radius: 5px;
        width: 1065px;
        min-height: 100px;
        height: 500px;
        overflow: scroll;
        background-color: white;
        z-index: 11;
        padding: 40px 47px 15px 47px;
        .heading{
            color: #fff;
            padding: 20px;
            font-size: 20px;
            font-weight: 400;
            margin-bottom: 20px;
            background-color: lightgray;
        }
        .form-section{
            margin-bottom: 42px;
        }
        .select-relevant-category-section{
            margin-bottom: 50px;
            display: inline-grid;
            width: 30%;
            &.sub-category{
                .section-item{
                    white-space: nowrap;
                    .title{
                        white-space: normal;
                    }
                }
            }
            &.sub-services{
                .section-item{
                    white-space: nowrap;
                    .title{
                        white-space: normal;
                    }
                }
            }
            .header{
                display: inline-block;
                vertical-align: top;
                color: @tms-admin-color;
                font-weight: 600;
                border-bottom: 1px solid;
                padding-bottom: 15px;
                margin-bottom: 20px;
            }
            .section-item{
                display: inline-block;
                vertical-align: super;
                padding: 5px;
                color: #535356;
                font-size: 16px;
                text-transform: capitalize;
                .title{
                    display: inline-block;
                    vertical-align: top;
                    margin: 3px 14px;
                }
            }
        }
        .submit-section{
            float: right;
            .button-section{
                margin-left: 20px;
                float: right;
            }
        }
    }
}

.custome-checkbox-wrap{
    width: 20px;
    height: 20px;
    border-radius: 5px;
    border: 1px solid #d9d9d9;
    display: inline-block;
    position: relative;
    cursor: pointer;
    &.selected{
        border: 1px solid @tms-admin-color;
    }
    .inner-circle{
        width: 10px;
        height: 10px;
        border-radius: 15%;
        background: @tms-admin-color;
        position: absolute;
        top: 4px;
        left: 4px;
    }
}
.margin-right{
    margin-right: 20px;
}
.no-records{
    padding: 10px;
    font-size: 16px;
    text-align: center;
}
.error{
    color: red;
}
.input-text-align-top .input-text-wrap{
    vertical-align: top;
}

.vAlignCenter {
    vertical-align: middle;
}

.inBlock {
    display: inline-block;
}

.wt-150 {
    width: 150px;
}

.mb-10 {
    margin-bottom: 10px;
}

.mb-20 {
    margin-bottom: 20px;
}

.ml-10 {
    margin-left: 10px;
}

.mr-12 {
    margin-right: 12px;
}

.wt-60 {
    width: 60px;
}

.input-spacing div {
    margin-right: 20px;

    .input-text-wrap {
        vertical-align: middle;
        margin-bottom: 5px;
    }
}

.vendor-select {
    width: 300px;
}