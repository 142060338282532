.add-user-wrap{
    .overlay{
        position: fixed;
        z-index: 10;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: rgba(27, 33, 67, 0.9);
    }
    .modal-popup{
        max-height: 300px;
        overflow: scroll;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
        border-radius: 5px;
        width: 1000px;
        min-height: 500px;
        background-color: white;
        z-index: 11;
        .pop-heading{
            background-color: #f4f4f4;
            padding: 18px;
            font-family: 'avenir',sans-serif;
            font-size: 16px;
            font-weight: 600;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #528aea;
            text-align: center;
            margin-bottom: 30px;
        }
        .content{
            padding: 20px 0px 20px 20px;
            padding-top: 0px;
            .select-container{
                display: flex;
                margin: 20px 0px;
                .select-holder{
                    width: 277px;
                    position: relative;
                    .sub-head{
                        font-size: 10px;
                        font-weight: 500;
                        font-style: normal;
                        font-stretch: normal;
                        line-height: normal;
                        letter-spacing: normal;
                        color: #868e96;
                        margin-bottom: 7px;
                    }
                }
                .action-holder{
                    display: flex;
                    flex-wrap: wrap;
                    width: 330px;
                    margin: 15px;
                    position: relative;
                    .actions{
                        margin: 5px;
                        .add-button{
                            margin: 0;
                            height: 35px;
                            padding: 0 15px;
                            font-size: 20px;
                            margin-left: 10px;
                        }
                        .select{
                            width: 235px;
                        }
                    }
                    .add-button{
                        margin: 5px 5px;    
                        padding: 10px 20px;
                    }
                    .sub-head{
                        position: absolute;
                        font-size: 10px;
                        font-weight: 500;
                        font-style: normal;
                        font-stretch: normal;
                        line-height: normal;
                        letter-spacing: normal;
                        color: #868e96;
                        top: -10px;
                        left: 7px;
                    }
                }
            }
            .usn-container{
                margin: 17px 0px;
                display: flex;
                .add-button{
                    margin: 20px;
                    border-radius: 5px;
                }
                .btn-disabled{
                    background-color: #cac2c2;
                    border: 1px solid #cac2c2;
                    pointer-events: none;
                    color: white;
                }
            }
            .submit-section{
                display: flex;
                justify-content: flex-end;
                .add-button{
                    border-radius: 5px;
                    width: 145px;
                }
                .discard{
                    background: white;
                    color: #528aea;
                    border: 1px solid #528aea;
                }

                &.business-button button.add-button {
                    margin: 0;
                    color: black;
                    padding: 10px 20px;
                    background: #fd8989;
                    border-radius: 20px;
                    border-color: #ff000052;

                    &.discard {
                        background: #fff;    
                    }
                }
            }
        }
    }
    .subheading {
        margin: 5px;
        font-weight: bold;
        font-size: 14px;
        padding-left: 20px;
        padding-right: 30px;
    }
    .heading {
        margin: 20px;
        font-weight: bold;
        font-size: 24px;
    }
    .close-button {
        margin-right: 0;
        cursor: pointer;
        height: 30px;
        font-size: 14px;
        border-radius: 2px;
        color: #fff;
        background: #528aea;
        padding: 6px 12px;
        display: inline-block;
        border: 1px solid #528aea;
    }
}

.pl-20{
    padding-left: 20px;
}

.wt-100p{
    width: 100%;
}
.fs-10{
    font-size: 10px;
    font-weight: 500;
}
.tCenter{
    text-align: center;
}
.company-logo {
	width: 150px;
    height: 100px;
    float: left;
    
}
.hsd-checkbox{
    .divTableCell{
        input{
            width: 15px;
            height: 15px;
            border: none;
            outline: none;
            border-bottom: 1px solid lightgray;

        }
    }
}
.action-icon {
    background-repeat: no-repeat;
    height: 18px;
    width: 18px;
    background-size: contain;
    // margin: 0 5px;
    cursor: pointer;
    display: inline-block;
    padding: 5px;
    margin: 5px;
}
