.main-LR {
  width: 100%;

  .lr-management {
    display: flex;
    margin-bottom: 20px;
    cursor: pointer;

    .LrSelected-option {
      background-color: #528aea;
      color: white;
    }

    .options {
      border: 1px solid #b7cdf5;
      padding: 8px 15px;
    }
  }
  .home-navigation-holder {
    min-width: 350px;
    max-width: 400px;
    &.mainTabs {
        max-width: 920px;
    }
}
}