@import "../../../variables.less";

.add-chain {
    .overlay{
        position: fixed;
        z-index: 10;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: rgba(27, 33, 67, 0.9);
    }
    .modal-popup{
        max-height: 610px;
        overflow: scroll;
        position: fixed;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
        border-radius: 5px;
        width: 1120px;
        min-height: 500px;
        background-color: white;
        z-index: 11;
        .pop-heading{
            background-color: #f4f4f4;
            padding: 18px;
            font-family: 'avenir',sans-serif;
            font-size: 16px;
            font-weight: 600;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            color: @tms-admin-color;
            text-align: center;
        }
        .content{
            // padding: 0px 0px 20px 30px;
            width: 1065px;
            margin: 0px 0px 20px 30px;
            padding: 0px 0px 20px 30px;
            border-radius: 6px;
            box-shadow: 0 0 27px 4px #dbdbe4;
            .heading-section{
                margin-top: 15px;
                font-size: 20px;
                color: #1a2734;
                font-family: 'avenir-medium',sans-serif;
                &.network-consignee {
                    padding-top: 20px;
                }
            }
            .details {
                margin-top: 20px;
            }
        }
        .data-picker {
            display: inline-block;
            width: 33%;
            .label {
                font-size: 10px;
                color: #86868a;
                //text-transform: uppercase;
                margin-bottom: 10px;
            }
            .react-datepicker-manager,
            .react-datepicker-wrapper,
            .react-datepicker__input-container,
            .react-datepicker__input-container input {
              width: 277px;
              height: 38px;
              font-size: 16px;
            }
            .rc-time-picker-input{
                height: 38px;
                font-size: 15px;
                color: #252424;
            }    
        }
        .details-section {
            .details{
                margin-top: 20px;
                .select-wrap{
                    width: 33%;
                    display: inline-block;
                    vertical-align: baseline;
                    .label{
                        font-size: 10px;
                        color: #868e96;
                        //text-transform: uppercase;
                        margin-bottom: 10px;
                    }
                    .select {
                        width: 277px;
                    }
                }
            }
        }
    }
}

.point-of-contact {
    .heading-section {
        padding: 18px 0px;
    }
    .list-section {
        border-bottom: 1px solid rgba(212, 212, 212, 0.5);
        padding-bottom: 25px;
        padding-top: 15px;
        &header{
            font-weight: 600;
            padding-top: 0;
            color: #1a2734;
            font-size: 14px;
        }
    }
    .tabel-header{
        width: 122px;
        display: inline-block;
        vertical-align: middle;
        margin-left: 20px;
        &.name {
            width: 207px;
        }
        &.email {
            width: 350px;
        }
        &.code {
            margin-left: 20px;
        }
    }
    .contact-btn {
        margin-right: 10px;
        margin-top: 10px;
    }
    .tabel-header-input {
        width: 122px;
        display: inline-block;
        vertical-align: middle;
        font-size: 16px;
        border: none;
        margin-left: 20px;
        &.name {
            width: 207px;
        }
        &.email {
            width: 350px;
        }
        &.code-value {
            padding: 7px 0px;
            border: 1px solid lightgray;
            border-radius: 5px;
        }
    }
}
.loading-section {
    display: inline-block;
    width: 320px;
    .branch {
        display: inline-block;
        margin-left: 5px;
        vertical-align: middle;
        height: 28px;
    }
}
.Add-logistics-btn {
    margin: 10px;
    float: right;
}
.borderRed {
    border: solid 1px red;
}