.vendor_mapping_detail-popUp {
    background-color: rgba(27, 33, 67, 0.9);
    .overlay-popUp{
        position: fixed;
        z-index: 10;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: rgba(27, 33, 67, 0.9);
    }
    .modal-popup-popUp .pop-heading-popUp {
        display: flex;
        padding: 18px;
        font-family: 'avenir',sans-serif;
        font-size: 16px;
        font-weight: 800;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        align-items: center;
        margin-left: 35px;
    }
    .modal-popup-popUp {
        position: fixed;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        border-radius: 5px;
        width: 800px;
        height: 500px;
        background-color: white;
        z-index: 11;
    }
  
  
    .vendor-detail-popUp {
      padding: 0px 50px;
      overflow-y: scroll;
      
      height: 400px;
        .table {
          width: 100%;
          border-radius: 8px;
          display: flex;
      
          th {
            border: 1px solid white;
          }
      
          td {
            border: 1px solid white;
            border-right: 1px solid #c4d4f0;
          }
      
          .table-tr {
            background-color: #3261b1;
            color: white;
            font-size: 13px;
      
            .tCenter {
              padding: 5px 10px;
            }
            
          }
          .tr-2 {
            background-color: #d6e0ef;
          }
    
          .table-view2 {
            margin-left: 20px;
          }
        }
  
        .button_route {
          display: flex;
          width: 800px;
          justify-content: center;
          gap: 30px;
          padding-bottom: 30px;
          background-color: white;
          position: fixed;
          bottom: 0;
          left: 0;
      
          .save-button {
            border: 1px solid #528aea;
          padding: 8px 25px;
          font-size: 13px;
          background-color: #528aea;
          color: white;
          border-radius: 3px;
          cursor: pointer;
          margin-top: 50px;
          }
      
          .cancel-button{
            border: 1px solid #528aea;
            padding: 8px 25px;
            font-size: 13px;
            background-color: white;
            color: #528aea;
            border-radius: 3px;
            cursor: pointer;
            margin-top: 50px;
          }
      }
        
    }
  
  
    
    .scheduler-detail {
        margin: 10px;
    }
    
    .error-message {
        margin-right: 10px;
        float: right;
        font-size: 12px;
        color: red;
    }
    .select-wrap-depot{
        width: 33%;
        display: inline-block;
        vertical-align: baseline;
        .label{
            font-size: 10px;
            color: #868e96;
            margin-bottom: 10px;
        }
    }
  }
  
  .popup{
    padding-top: 180px;
    padding-left: 200px;
  }