.dept-roles-root .home-navigation-holder {
    min-width: 50px;
    max-width: 200px;
    margin: 20px 0px;
}

.delete-icon {
    background-image: url(/images/delete.svg);
    background-repeat: no-repeat;
    height: 23px;
    width: 23px;
    background-size: contain;
    margin: 0 5px;
    cursor: pointer;
    display: inline-block;
}

.button-classic {
    height: 30px;
    font-size: 14px;
    padding: 0 20px;
    border-radius: 5px;
}
.roles-class{
    margin-left: 3rem !important;
}
.add-role-modal{
    width: 390px !important;
    max-height: 361px !important;
    min-height: 361px !important
}
