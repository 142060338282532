.module-config {
    margin-left: 30px;
    .select-relevant-category-section{
        margin-bottom: 50px;
        display: inline-grid;
        width: 30%;
        .section-item{
            // display: inline-block;
            vertical-align: super;
            padding: 5px;
            color: #535356;
            font-size: 16px;
            text-transform: capitalize;
            &.sub-item {
                margin-left: 30px;
            }
            .title{
                width: auto;
                display: inline-block;
                vertical-align: top;
                margin: 3px 14px;
            }
        }
    }
}