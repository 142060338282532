.companyUserInfo {
    .search-input {
        padding: 4px 8px;
        border: 1px solid rgb(190, 179, 179);
        outline: 0px;
        min-width: 200px;

        &:hover {
            box-shadow: 0px 0px 2px rgb(190, 179, 179);
        }
    }

    .pagination-wrapper {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 10px;
        margin-top: 15px;
        margin-right: 10px;

        button {
            padding: 10px 20px;
            border-radius: 50px;
            color: #fff;
            background-color: #42569E;
            outline: none;
            border: 0px;

            &:not(:disabled):hover {
                box-shadow: 0px 0px 2px #42569E;
                cursor: pointer;
            }

            &:disabled {
                cursor: not-allowed;
                opacity: 0.6;
            }

            &:not(:disabled):active {
                outline: 1px solid #42569E;
            }
        }

      
    }

    .view-more-less-btn{
        padding: 2px 10px;
        border-radius: 30px;
        color: #42569E;
        background-color: transparent;
        outline: none;
        border: 0px;

        display: flex;
        align-items: center;
        gap:8px;

        &:not(:disabled):hover {
            background-color: #42569e25;
            cursor: pointer;
        }

        &:disabled {
            cursor: not-allowed;
            opacity: 0.6;
        }

        &:active {
            color: #375ad7;
        }
     
    }
}