.hsdMaster{
    .wrapper{
        width: 1280px;
        margin: 0 auto;
        .search{
            width: 170px;
            font-size: 10px;
            border: 1px Solid #cccccc;
            border-radius: 5px;
            height: 38px;
            margin-top: 10px;
            .input-text-wrap{
                width: 100%;
                input{
                    width: 150px;
                    border-bottom: none;
                    padding-bottom: 5px;
                    padding-left: 5px;
                    font-size: 14px;
                }
            }
        }
        .close-button {
            margin-right: 0;
            cursor: pointer;
            height: 30px;
            font-size: 14px;
            border-radius: 2px;
            color: #fff;
            background: #528aea;
            padding: 6px 12px;
            display: inline-block;
            border: 1px solid #528aea;
            
        }
        .disable-btn {
            pointer-events: none;
            background: #efeded;
            border: none;
        }
        .hsdTable{
            width: 100%;
            border: 1px Solid #cccccc;
            .hsdHeader{
                border: 1px Solid #cccccc;
                background-color: #528aea;
                color: white;
                font-size: 12px;
                th{
                    border: 1px Solid #cccccc;
                    text-align: center;
                }
                
            }
            tr{
                .hsdDetails{
                    border: none;
                    text-align: center;
                    font-size: 14px;
                    .lanes-link {
                        color: #5289ea;
                        cursor: pointer;
                    }
                    &.approve{
                        color: green;
                    }
                    
                }
            }
            .action-icon {
                background-repeat: no-repeat;
                height: 18px;
                width: 18px;
                background-size: contain;
                // margin: 0 5px;
                cursor: pointer;
                display: inline-block;
                padding: 5px;
                margin: 5px;
            }
            
        }
        .zoneTable{
            width: 100%;
            border: 3px Solid #cccccc;
            .zoneHeader{
                border: 1px Solid  white;
                background-color: white;
                color: #528aea;
                font-size: 12px;
                th{
                    border: 1px Solid white;
                    text-align: center;
                    color: black;
                }
                
            }
            tr{
                .zoneDetails{
                    border: none;
                    text-align: center;
                    font-size: 14px;
                    color: black;
                }
            }
        }
    }
}
.error-font{
    color: red
}