@import "../../variables.less";
.update-plant-depots-wrap{
    .wrapper{
        width: 1280px;
        margin: 0 auto;
    }
    .plant-header-section{
        margin-top: 21px;
        font-size: 24px;
    }
    .plant-other-details{
        // margin-top: 26px;
        .content{
            display: block;
            margin-right: 20px;
            font-size: 14px;
            font-family: 'avenir-light',sans-serif;
            color: #86868a;
            margin-bottom: 7px;
            .bold{
                font-family: 'avenir-medium',sans-serif;
            }
        }
    }
    .go-back-prev-screen {
        width: 20px;
        height: 20px;
        background-repeat: no-repeat;
        background-size: contain;
        margin-bottom: 20px;
        cursor: pointer;
    }
    .current-screen {
        text-decoration: underline;
    }
    .plant-edit-title {
        width: 75%;
        display: inline-block;
    }
    .card-width {
        width: 20%;
    }
    .heading-section {
        margin-top: 15px;
        font-size: 20px;
        color: #1a2734;
        font-family: 'avenir-medium',sans-serif;
    }
    .refresh {
        display: inline-block;
        width: 33%;
        .long {
            margin: 0px 65px;
        }
    }
    .refresh-icon {
        background-image: url(/images/refresh-button.svg);
        background-repeat: no-repeat;
        height: 15px;
        width: 15px;
        background-size: contain;
        margin: 0px 58px;
        cursor: pointer;
        display: inline-block;
        position: absolute;
    }
    .data-picker {
        display: inline-block;
        width: 33%;
        .label {
            font-size: 10px;
            color: #86868a;
            //text-transform: uppercase;
            margin-bottom: 10px;
        }
        .react-datepicker-manager,
        .react-datepicker-wrapper,
        .react-datepicker__input-container,
        .react-datepicker__input-container input {
          width: 277px;
          height: 38px;
          font-size: 16px;
        }
        .rc-time-picker-input{
            height: 38px;
            font-size: 15px;
            color: #252424;
        }    
    }

    .point-of-contact {
        .heading-section {
            padding: 18px 0px;
        }
        .list-section {
            border-bottom: 1px solid rgba(212, 212, 212, 0.5);
            padding-bottom: 25px;
            padding-top: 15px;
            &header{
                font-weight: 600;
                padding-top: 0;
                color: #1a2734;
                font-size: 14px;
            }
        }
        .tabel-header{
            width: 122px;
            display: inline-block;
            vertical-align: middle;
            margin-left: 20px;
            &.name {
                width: 207px;
            }
            &.email {
                width: 306px;
            }
            &.code {
                margin-left: 20px;
            }
        }
        .contact-btn {
            margin-right: 10px;
            margin-top: 10px;
        }
        .tabel-header-input {
            width: 122px;
            display: inline-block;
            vertical-align: middle;
            font-size: 16px;
            border: none;
            margin-left: 20px;
            &.name {
                width: 207px;
            }
            &.email {
                width: 306px;
            }
            &.code-value {
                padding: 7px 0px;
                border: 1px solid lightgray;
                border-radius: 5px;
            }
        }
    }
    .button-add {
        margin-bottom: 27px;
    }
}

.select-container {
    clear: both;
    margin-bottom: 20px;
    float: left;
    width: 100%;
    .select-holder {
        width: 30%;
        margin-left: 5%;
        float: left;
        &:first-child {
            margin-left: 0;
        }
    }
}

.select-container-moves{
    clear: both;
    margin-bottom: 20px;
    .moves-select-container{
        clear: both;
        margin-bottom: 20px;
        float: left;
        width: 100%;
        .select-box{
            width: 33.33%; 
            // float: left;
            display: inline-block;
            padding-left: 15px;
            &:first-child {
                padding-left: 0;
            }
        }
    }
    .moves{
        float: left;
        width: 100%;
        position: relative;
        border-bottom: solid 1px #ccc;
        margin-bottom: 20px;
        &:last-child{
            border-bottom: none;
        }
    }
    .submoves-bucket{
        float: left;
        width: 100%;
        position: relative;
        border: solid 1px #ccc;
        margin-bottom: 20px;
        background-color: #f0f3f7;
        padding: 10px;
        &:last-child{
            border-bottom: none;
        }
    }
    .remove-button {
        position: absolute;
        top: 15px;
        right: 0px;
        color: #fff;
        font-size: 14px;
        line-height: 1px;
        //background: #528aea;
        margin: 0px 0px;
        padding: 12px 14px;
        display: inline-block;
        // border: 1px solid #528aea;
        cursor: pointer;
        border-radius: 10px;
    }
}
.add-more-moves{
    float: right;
    margin-right: 0px;
}

.submit-section {
    clear: both;
}
.mt-10{
    margin-top: 10px;
}
.m-10{
    margin: 10px;
}
.mb-3p{
    margin-bottom: 3%;
}
.mt-1p{
    margin-top: 1%;
}
.ml-23p{
    margin-left: 23%;
}
.add-indents-warp{
    .select-holder, .select-container-moves{
        font-size: 14px;
    }
}
.flow-head{
    margin: 10px 15px 10px 5px;
    font-size: 14px;
    width: 25%!important;
}
.border-bottom{
    border-bottom: 1px solid #ececee;
}

.disable-btn {
    pointer-events: none;
    background: #efeded;
    border: none;
}

.no_data_found {
    margin: 10px 15px 10px 5px;
    font-size: 14px;
    text-align: center;
}

.golden-box {
    &.golden-box-sub {
    //   margin: 10px 5px;
      border: 1px solid @tms-admin-color;
      margin: 30px 0px;
    }
    &.warehouse_particulars {
        .home-navigation-holder {
          min-width: 200px;
          max-width: 300px;
        }
        .delete-icon {
            height: 17px;
            width: 20px;
            padding: 0px;
            margin: 0px;
        }
    }
    .heading {
      border-bottom: 1px solid @tms-admin-color;
    }
    .golden-box-content {
      display: flex;
      justify-content: space-between;
      // overflow: scroll;
      .input-holder {
        position: relative;
        margin: 50px 20px;
        .input {
          border: none;
          border-bottom: 1px solid #ededef;
          width: 200px;
          padding: 7px;
        }
        .labels {
          position: absolute;
          top: -20px;
          left: 0;
          font-family: 'Avenir',sans-serif;
          font-size: 12px;
          font-weight: 900;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #868e96;
        }
      }
      .add-role-section {
        padding: 30px 20px 20px 15px;
        .add-items {
          display: inline-block;
          vertical-align: middle;
          margin-right: 30px;
        }
      }
    }
    .golden-box-display {
      display: flex;
      justify-content: space-around;
      border: none;
      border-bottom: 1px solid #ededef;
      padding: 12px;
      .headers {
        margin: 0px 20px;
        font-family: 'Avenir',sans-serif;
        font-size: 12px;
        font-weight: 900;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #868e96;
        width: 150px;
      }
      .details {
        width: 150px;
        margin: 0px 20px;
        .labels {
          margin-top: 6px;
        }
        .add-button {
          margin: 0px;
        }
      }
    }
    .add-button {
      padding: 10px 15px;
    }
    .button-holder {
      padding: 20px;
      text-align: right;
      &.save {
        text-align: center;
        .add-button {
            padding: 13px 30px;
        }
      }
      .add-button {
        font-size: 13px;
      }
    }
    .heading {
        background: @tms-admin-color;
        color: #fff;
        padding: 20px 12px;
        font-family: 'Avenir',sans-serif;
        font-size: 20px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        display: block;
        &.sub-heading {
        //   padding: 2px 16px;
          font-size: 18px;
          margin-left: 0px;
          margin-top: 0px;
        }
      }
      .apply-settings {
        display: flex;
        .label {
          font-family: 'Avenir',sans-serif;
          font-size: 16px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: 0.2px;
          color: #535356;
          margin-left: 15px;
        }
      }
    .add-company-wrap {
        .modal-popup{
            &.small-modal  {
                width: 420px;
            }
        }
    }
  }

  .level-header {
    margin: 5px 0px;
    border-bottom: 1px solid grey;
    padding: 5px;
    color: #528aea;
    font-size: 16px;
    font-weight: 900;
    &.particulars-header {
        margin: 5px 5px;
        color: #212020;
        background-color: #a2e1f6;
    }
  }

  .cancel-icon {
    display: inline-block;
    // color: orangered;
    pointer-events: cursor;
    cursor: pointer;
    font-size: larger;
  }
  .wt-200{
    width: 200px;
  }
  .VAM{
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  .VAMT1{
    padding: 10px 15px;
    .rowWrapper{
        display: grid;
        grid-template-columns: repeat(3,1fr);
        gap:40px;
        justify-content: space-around;
    }
    .p-10{
        padding: 10px;
    }
    .p-5{
        padding: 10px;
    }
  }

  

  

