.demurrages.discount-wrap{
    .wrapper{
        width: 1280px;
        margin: 0 auto;
        .home-title{
            font-size: 12px;
            color: #86868a;
        }
        .filterSelect{
            margin-right: 2rem;
        }
        .sub-heading-section{
            margin-top: 22px;
            font-family: 'avenir-medium',sans-serif;
            font-weight: 900;
            overflow: auto;
            .sub-heading{
                font-size: 24px;
                color: #282626;
                float: left;
            }
            .add-company-section{
                float: right;
                margin-right: 30px;
            }
        }
        .cta-action-btns {
            display: inline-block;
            .button-classic { 
                height: 30px;
                font-size: 14px;
                padding: 0 20px;
                border-radius: 5px;
                margin-top: 10px;
                margin-right: 10px;
            }
            &.cta-btn-right {
                float: right;
            }
        }
        .search-filter-section{
            clear: both;
            margin-top: 25px;
        }
        .company-list-wrap{
            margin-top: 45px;
            .no-records-section{
                margin-top: 40px;
            }
            .list-section{
                border-bottom: 1px solid rgba(212, 212, 212, 0.5);
                padding-bottom: 25px;
                padding-top: 15px;
                .checkbox-section{
                    display: inline-block;
                    vertical-align: middle;
                }
                .sub-header-inline {
                    width: 100%;
                    margin-top: 5px;
                    margin-right: 6px;
                    text-align: center;
                    padding-bottom: 5px;
                    border-bottom: 1px solid #e0e0e0;
                }
                .table-header {
                    width: 11%;
                    font-size: 12px;
                    display: inline-block;
                    word-break: break-word;

                    &.select-row {
                        width: 4%;
                    }

                    &.rules-tab {
                        width: 18%;
                    }
                }
                .company-name-section{
                    display: inline-block;
                    vertical-align: middle;
                    margin-left: 10px;
                    width: 370px;
                    &.table-header {
                        margin-left: 85px;
                        width: 290px;
                    }
                    .comany-logo ,.compnay-details{
                        display: inline-block;
                        vertical-align: middle;
                        cursor: pointer;
                    }
                    .comany-logo{
                        width: 50px;
                        height: 35px;
                        background-size: contain;
                        background-repeat: no-repeat;
                    }
                    .compnay-details{
                        margin-left: 25px;
                        color: #1a2734;
                        font-size: 15px;
                        font-weight: 900;
                        width: 220px;
                        .date{
                            color: #86868a;
                            font-size: 12px;
                        }

                        .name {
                            word-break: break-word;
                            display: -webkit-box;
                            overflow: hidden;
                            -webkit-line-clamp: 2;
                            -webkit-box-orient: vertical;
                            text-overflow: ellipsis;
                        }
                    }
                }
                .admin-details{
                    display: inline-block;
                    vertical-align: middle;
                    width: 340px;
                    font-size: 15px;
                    color: #1a2734;

                    &.table-header {
                        margin-left: 8px;
                        width: 337px;
                    }
                    .admin-email{
                        font-size: 15px;
                        color: #1a2734;
                        cursor: pointer;
                    }
                    .admin-pan{
                        font-size: 12px;
                        color: #86868a;
                    }
                }
                .account-type{
                    display: inline-block;
                    vertical-align: middle;
                    width: 140px;
                    font-size: 15px;
                    color: #1a2734;
                }
                .status{
                    display: inline-block;
                    vertical-align: middle;
                    width: 100px;
                }
            }
            .list-section{
                &.header{
                    font-weight: 600;
                    padding-top: 0;
                    color: #1a2734;
                    font-size: 14px;
                }
            }
            .list-section{
                &.content:hover{
                    border: 1px solid rgba(12, 12, 12, 0.38);
                    box-shadow: 0px 10px 20px 0px rgba(158, 170, 181, 0.45);
                    
                    .company-name-section .compnay-details .name {
                        display: block;
                    }
                }

            }
            .action-icon {
                background-repeat: no-repeat;
                height: 18px;
                width: 18px;
                background-size: contain;
                // margin: 0 5px;
                cursor: pointer;
                display: inline-block;
                padding: 5px;
                margin: 5px;
            }
        }
    }
}
.divTable{
    margin-top: 20px;
    margin-bottom: 20px;
    display: table;
    width: 100%;
}
.divTableRow {
    display: table-row;
}
.divTableHeading {
    color: white;
    background-color: #EEE;
    display: table-header-group;
}
.divTableCell, .divTableHead {
    /*border: 1px solid #999999;*/
    display: table-cell;
    padding: 10px 10px;
    font-size: 12px;

    &.bordered {
        border-bottom: 1px solid #999999;
    }
}
.divTableHeading {
    background-color: #528aea;
    display: table-header-group;
    font-weight: bold;
}
.divTableFoot {
    background-color: #EEE;
    display: table-footer-group;
    font-weight: bold;
}
.divTableBody {
    display: table-row-group;
    margin-top: 10px;
}

.select{
    display: inline-block;
    width: 150px;
}

.slabRange {
    .input-text-wrap {
        margin-bottom: 0;
    }
    .input-element input {
        width: auto;
        font-size: 12px;
    }
}

.penaltyPercent .input-text-wrap {
    .text-input {
        width: 50px;
        font-size: 12px;
        padding-bottom: 5px;
    }
}
.penaltyBox {
    text-align: center;
}
.download-btn {
    text-decoration: none;
}

.add-user-wrap{
    .overlay{
        position: fixed;
        z-index: 10;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: rgba(27, 33, 67, 0.9);
    }
    .modal-popup{
        max-height: 600px;
        overflow: scroll;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
        border-radius: 5px;
        width: 1000px;
        min-height: 500px;
        background-color: white;
        z-index: 11;
        .pop-heading{
            background-color: #f4f4f4;
            padding: 18px;
            font-family: 'avenir',sans-serif;
            font-size: 16px;
            font-weight: 600;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #528aea;
            text-align: center;
            margin-bottom: 30px;
        }
        .content{
            padding: 20px 0px 20px 30px;
            .select-container{
                display: flex;
                margin: 20px 0px;
                .select-holder{
                    width: 277px;
                    position: relative;
                    .sub-head{
                        font-size: 10px;
                        font-weight: 500;
                        font-style: normal;
                        font-stretch: normal;
                        line-height: normal;
                        letter-spacing: normal;
                        color: #868e96;
                        margin-bottom: 7px;
                    }
                }
                .action-holder{
                    display: flex;
                    flex-wrap: wrap;
                    width: 330px;
                    margin: 15px;
                    position: relative;
                    .actions{
                        margin: 5px;
                        .add-button{
                            margin: 0;
                            height: 35px;
                            padding: 0 15px;
                            font-size: 20px;
                            margin-left: 10px;
                        }
                        .select{
                            width: 235px;
                        }
                    }
                    .add-button{
                        margin: 5px 5px;    
                        padding: 10px 20px;
                    }
                    .sub-head{
                        position: absolute;
                        font-size: 10px;
                        font-weight: 500;
                        font-style: normal;
                        font-stretch: normal;
                        line-height: normal;
                        letter-spacing: normal;
                        color: #868e96;
                        top: -10px;
                        left: 7px;
                    }
                }
            }
            .usn-container{
                margin: 17px 0px;
                display: flex;
                .add-button{
                    margin: 20px;
                    border-radius: 5px;
                }
                .btn-disabled{
                    background-color: #cac2c2;
                    border: 1px solid #cac2c2;
                    pointer-events: none;
                    color: white;
                }
            }
            .submit-section{
                display: flex;
                justify-content: flex-end;
                .add-button{
                    border-radius: 5px;
                    width: 145px;
                }
                .discard{
                    background: white;
                    color: #528aea;
                    border: 1px solid #528aea;
                }

                &.business-button button.add-button {
                    margin: 0;
                    color: black;
                    padding: 10px 20px;
                    background: #fd8989;
                    border-radius: 20px;
                    border-color: #ff000052;

                    &.discard {
                        background: #fff;    
                    }
                }
            }
        }
    }
}
.divTableCell.rules-tab {
    width: 350px !important;
}
.penalty-slab {
    color: grey;
}
.penalty-value {
    color: black;
}
/*.table-header {
    .divTableCell {
        font-size: 15px !important;
    }
}*/

.mb-5 {
    margin-bottom: 5px;
}

.fs-11 {
    font-size: 11px;
}

.header-color {
    color: #86868a;
}

.levy-logic-container {
    max-height: 400px;
    overflow: scroll;
    padding-bottom: 10px;
    margin-bottom: 15px;
}

.delete-icon {
    background-image: url(/images/delete.svg);
    background-repeat: no-repeat;
    height: 23px;
    width: 23px;
    background-size: contain;
    // margin: 0 5px;
    cursor: pointer;
    display: inline-block;
    padding: 5px;
    margin: 5px;
    &.levyDelete {
        height: 18px;
        width: 18px;
    }
}