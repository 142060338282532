// typography SH

.text-lg {
    font-size: 1.125rem;
    /* 18px */
    line-height: 1.75rem;
    /* 28px */
}

.typography,
.typographyT1 {
    color: #000;
    font-family: 'Poppins',sans-serif;
    font-style: normal;
}

.typographyT1 {
    font-size: 24px;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: 0.24px;
}

.typographyT2 {
    color: #FFF;
    font-family: 'Poppins',sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
}

.typographyT3 {
    color: #191919;
    font-family: 'Poppins',sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
}
.typographyT4 {
    color: #627FE9;
    font-family: 'Poppins',sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 114.286% */
    letter-spacing: 0.42px;
}

// typography EH