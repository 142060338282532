@import "../../../../../variables.less";

.add-company-wrap{
    .modal-popup {
    &.small-modal{
        width: 330px;
        overflow: scroll;
        max-height: 600px;
        min-height: 150px;
        &.add-department {
            width: 520px;
        }
        &.edit-department{
            width: 520px !important;
        }
        .form-section{
            .roles-holder{
                margin: 10px 0px;
                display: flex;
                .delete-icon{
                    margin: 0px 10px;
                    padding : 5px 7px;
                    cursor: pointer;
                }
                .select {
                    display: inline-block;
                    width: 280px;
                }
            }
        }
        .submit-area{
            display: flex;
            justify-content: space-between;
            flex-direction: row-reverse;
            padding: 20px 0px!important;
            float: none;
            .button-section{
                margin-left: 0px!important;
                .add-button{
                    margin: 0px!important;
                }
            }
        }
    }
    }
    
    .smallest{
        max-height: 300px!important;
        min-height: 300px!important;
    }
}
.addMore{
    cursor: pointer;
    text-transform: uppercase;
    border-radius: 5px;
    border: 1px solid @tms-admin-color;
    background: @tms-admin-color;
    color: #fff;
    padding: 10px;
    display: inline-block;
}
.sub-label{
    font-size: 10px;
    color: #868e96;
    text-transform: uppercase;
}