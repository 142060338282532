@import "../../../variables.less";

.yes-no-root{
    display: inline-block;
    margin-right: 50px;
    .yes-no-holder{
        display: flex;
        width: 90px;
        border: 1px solid #d4d4d4;
        border-right: none;
        .option{
            padding: 4px 12px;
            border-right: 1px solid #d4d4d4;
            text-align: center;
            font-family: 'Avenir' ,sans-serif;
            font-size: 14px;
            font-weight: 500;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #d4d4d4;
            cursor: pointer;
        }
        .selected-option{
            background: @tms-admin-color;
            color: white;
        }
    }
}