@import "../../variables.less";

.company-category {

    .button-default {
        cursor: pointer;
        padding: 0 15px;
        border-radius: 2px;
        border: 1px solid #528aea;
        background: #528aea;
        margin: 0px 20px;
        color: #fff;
        min-width: 76px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        display: inline-block;
        vertical-align: bottom;
        margin-bottom: 10px;
    }
    .button-white {
        cursor: pointer;
        padding: 0 15px;
        border-radius: 2px;
        border: 1px solid #528aea;
        background: white;
        margin: 0px 20px;
        color: #528aea;
        min-width: 76px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        display: inline-block;
        vertical-align: bottom;
        margin-bottom: 10px;
    }
    .f-r {
        float: right;
    }
    .sub-title-section-wrap {
        margin-top: 10px;
    }
    .w-p-3 {
        width: 3% !important;
    }
    .w-p-5 {
        width: 5% !important;
    }
    .w-p-6 {
        width: 6% !important;
    }
    .w-p-8 {
        width: 8% !important;
    }
    .w-p-12 {
        width: 12% !important;
    }
    .w-p-16 {
        width: 16% !important;
    }
    .w-p-22 {
        width: 22% !important;
    }
    .w-p-25 {
        width: 25% !important;
    }
    .w-80 {
        width: 80px !important;
    }
    .w-100 {
        width: 100px !important;
    }
    .w-150 {
        width: 150px !important;
    }
    .w-225 {
        width: 225px !important;
    }
    .w-p-24 {
        width: 24% !important;
    }
    .w-p-10 {
        width: 10% !important;
    }
    .button-delete {
        cursor: pointer;
        padding: 0 15px;
        border-radius: 2px;
        border: 1px solid #528aea;
        background: #528aea;
        color: #fff;
        min-width: 76px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        display: inline-block;
        vertical-align: bottom;
        margin-bottom: 5px;
        margin-right: 5px;
    }
    .input-text {
        padding: 5px;
        border: 1px solid #cccccc;
        height: 38px;
        border-radius: 5px;
        font-size: 16px;
        width: 100%;
    }
    .m-r-8 {
        margin-right: 8px;
    }
    .m-l-5 {
        margin-left: 5px;
    }
    .align-middle {
        vertical-align: middle !important;
    }
    .align-top {
        vertical-align: top !important;
    }
    .flex {
        display: flex !important;
    }
}

.top-margin-20 {
    margin-top: 20px;
}

.mr-10 {
    margin-right: 10px;
}

.p-5 {
    padding: 5px;
}

.m-5 {
    margin: 5px;
}

.html2canvas-container { height: 3000px !important; }

.preview-header {
    background-color: #e0e0e0;
    border: 1px solide #f0f0f0;
    text-align: center;
    padding: 10px;
}

.dateTenture {
    display: inline-block;
    margin-right: 20px;
}

.tCenter {
    text-align: center;
}


.mb-5 {
    margin-bottom: 5px;
}

.fs-11 {
    font-size: 11px;
}
.fs-14 {
    font-size: 14px;
}
.header-color {
    color: #86868a;
}



.basic-details-wrap{
    .heading-section{
        margin-top: 33px;
        font-size: 20px;
        color: #1a2734;
        font-family: 'avenir-medium',sans-serif;
    }
    .details{
        margin-top: 20px;
        .fiscal-section{
            display: inline-block;
            width: 33%;
            .rupee-icon{
                vertical-align: baseline;
            }
            .input-text-wrap{
                width: 50%;
            }
            .input-text-wrap .text-input{
                width: 185px;
            }
            .select-wrap{
                .select{
                    width: 125px;
                    margin-left: 30px;
                    margin-top: 16px;
                }
                
            }
        }
        .select-wrap{
            width: 33%;
            display: inline-block;
            vertical-align: baseline;
            .label{
                font-size: 10px;
                color: #868e96;
               //  text-transform: uppercase;
                margin-bottom: 10px;
            }
            .label-mb-0{
                font-size: 10px;
                color: #868e96;
                // text-transform: uppercase;
                
            }
        }
        .select-wrap-w-25{
            width: 25%;
            display: inline-block;
            vertical-align: baseline;
            .label{
                font-size: 10px;
                color: #868e96;
               //  text-transform: uppercase;
                margin-bottom: 10px;
            }
            .label-mb-0{
                font-size: 10px;
                color: #868e96;
                // text-transform: uppercase;
                
            }
        }
        .select{
            width: 180px;
        }
        .select-branch{
            width: 220px;
        }
    }
    .company-details{
        margin-top: 50px;
        .title{
            font-size: 20px;
            color: #1a2734;
            font-family: 'avenir-medium',sans-serif;
        }
        .file-upload-section{
            .file-lable-section{
                .company-icon{
                    width: 80px;
                    height: 80px;
                    border-radius: 5px;
                    background-color: #f9f9f9;
                    display: inline-block;
                    vertical-align: middle;
                    &.img{
                        background-size: cover;
                        background-repeat: no-repeat;
                    }
                }
                .file-upload-details{
                    display: inline-block;
                    margin-left: 20px;
                    vertical-align: middle;
                }
                .title{
                    font-size: 10px;
                    color: #868e96;
                }
                .desc{
                    font-size: 16px;
                    color: #1a2734;
                    margin-top: 5px;
                    margin-bottom: 15px;
                }
            }
            .file-holder{
                width: 300px;
                height: 26px;
                border-radius: 2px;
                background-color: #f9f9f9;
                border: 1px solid #cccccc;
                cursor: pointer;
                position: relative;
                input{
                    width: 300px;
                    height: 26px;
                    opacity: 0;
                    cursor: pointer;
                }
                .file-choose{
                    position: absolute;
                    right: 9px;
                    top: 4px;
                    font-family: 'avenir',sans-serif;
                    font-size: 12px;
                    letter-spacing: 0.3px;
                    cursor: pointer;
                    color: @tms-admin-color;
                }
                .file-name{
                    font-family: 'avenir',sans-serif;
                    font-size: 12px;
                    position: absolute;
                    top: 3px;
                    left: 12px;
                    color: #86868a;
                }
            }
            .generate-button{
                cursor: pointer;
                padding: 0 15px;
                border-radius: 23px;
                border: 1px solid @tms-admin-color;
                background: @tms-admin-color;
                margin: 0px 20px;
                color: #fff;
                min-width: 76px;
                height: 30px;
                line-height: 30px;
                text-align: center;
                display: inline-block;
                vertical-align: bottom;
            }
        }
    }
}

.sub-title-section-wrap{
    margin-top: 35px;
    .circle, .text, .underline{
        display: inline-block;
        vertical-align: baseline;
    }
    .circle{
        width: 10px;
        height: 10px;
        border-radius: 5px;
        background: @tms-admin-color;
    }
    .text{
        margin-left: 10px;
        font-size: 16px;
        color: #1a2734;
        font-family: 'avenir-medium',sans-serif;
    }
    .underline{
        width: 20px;
        height: 3px;
        background: @tms-admin-color;
        margin-left: 5px;
    }
}

.details .input-text-wrap {
    vertical-align: middle;
    margin-bottom: 15px;
}

.rupee-icon {
    background-image: url(/images/rupee.svg);
    background-repeat: no-repeat;
    height: 17px;
    width: 17px;
    background-size: contain;
    margin: 0 5px;
    display: inline-block;
    vertical-align: middle;
}

.button-add {
    cursor: pointer;
    padding: 0 15px;
    border-radius: 23px;
    border: 1px solid #528aea;
    background: #528aea;
    margin: 0px 20px;
    color: #fff;
    min-width: 76px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    display: inline-block;
    vertical-align: bottom;
    margin-bottom: 10px;
}


.documents-upload-section {
    min-height: 500px;
    // overflow-y: scroll;
    padding: 10px;
    margin-bottom: 40px;
    margin-top: 40px;
    border-radius: 5px;
    box-shadow: 0 0 10px 4px rgba(69, 68, 68, 0.07);
    .file-lable-section{
        margin: 10px;
        .company-icon{
            width: 50px;
            height: 50px;
            border-radius: 5px;
            background-color: #f9f9f9;
            display: inline-block;
            vertical-align: middle;
            &.img{
                background-size: cover;
                background-repeat: no-repeat;
            }
        }
        .file-upload-details{
            display: inline-block;
            vertical-align: middle;
        }
        .title{
            font-size: 12px;
            color: #868e96;
        }
        .desc{
            font-size: 16px;
            color: #1a2734;
            margin-top: 5px;
            margin-bottom: 15px;
        }
        .attachment-container-row {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin: 5px;
            border-top: 1px solid hsla(0, 0%, 59.2%, 0.2);
            .attachment-file-icon {
                background-image: url(/images/attachment.svg);
                background-repeat: no-repeat;
                height: 30px;
                width: 30px;
                background-size: contain;
                padding: 5px;
                margin: 5px;
            }
            .attachment-name {
                padding-top: 10px;
                font-size: 14px;
            }
            .delete-icon {
                background-image: url(/images/delete.svg);
                background-repeat: no-repeat;
                height: 23px;
                width: 23px;
                background-size: contain;
                // margin: 0 5px;
                cursor: pointer;
                display: inline-block;
                padding: 5px;
                margin: 5px;
            }
        }
        input[type="text"] {
            width: 200px;
            height: 26px;
            border-radius: 2px;
            border: 1px solid #cccccc;
        }
        input[type="text"]:focus {
            border: 1px solid #cccccc;
            outline-offset: 0px;
            outline: none !important;
            padding: 5px;
        }
        .single-date-input {
            .DateInput_input {
                font-size: 14px;
            }
        }   
       
    }
    .file-holder{
        width: 300px;
        height: 26px;
        border-radius: 2px;
        background-color: #f9f9f9;
        border: 1px solid #cccccc;
        cursor: pointer;
        position: relative;
        input{
            width: 300px;
            height: 26px;
            opacity: 0;
            cursor: pointer;
        }
        .file-choose{
            position: absolute;
            right: 9px;
            top: 4px;
            font-family: 'avenir',sans-serif;
            font-size: 12px;
            letter-spacing: 0.3px;
            cursor: pointer;
            color: @tms-admin-color;
        }
        .file-name{
            font-family: 'avenir',sans-serif;
            font-size: 12px;
            position: absolute;
            top: 3px;
            left: 12px;
            color: #86868a;
        }
    }
    .generate-button{
        cursor: pointer;
        padding: 0 15px;
        border-radius: 23px;
        border: 1px solid @tms-admin-color;
        background: @tms-admin-color;
        margin: 0px 20px;
        color: #fff;
        min-width: 76px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        display: inline-block;
        vertical-align: bottom;
    }
}

.tolerance{
    background-repeat: no-repeat;
    height: 17px;
    width: 17px;
    background-size: contain;
    margin: 0 5px;
    display: inline-block;
    vertical-align: middle;
}

.flex{
    display: flex;
}

.space-between {
    justify-content: space-between
}

.hl{ 
    border: 2px solid red;
}

.label-inline {
    font-size: 10px;
    color: #868e96;
    margin-bottom: 10px;
}

.font-1rem{
    font-size: 1rem;
}

.mt-1rem{
    margin-top: 1rem;
}

.pb-13px{
    padding-bottom:13px !important;
}

.pl-0 {
    padding-left: 0!important ;
}

.mr-20 {
    margin-right: 20px;
}

.datepicker {
    font-size: 10px;
}