.dept-root, .role-root{
    max-width: 1029px;
    margin: 0 auto;
    .company-list-wrap{
        margin-top: 30px;
        .list-section{
            border-bottom: 1px solid rgba(212, 212, 212, 0.5);
            padding-bottom: 25px;
            padding-top: 15px;
            .checkbox-section{
                display: inline-block;
                vertical-align: middle;
            }
            .company-name-section{
                display: inline-block;
                vertical-align: middle;
                margin-left: 10px;
                width: 320px;
                .comany-logo ,.compnay-details{
                    display: inline-block;
                    vertical-align: middle;
                    cursor: pointer;
                }
                .comany-logo{
                    width: 50px;
                    height: 35px;
                    background-size: contain;
                    background-repeat: no-repeat;
                }
                .compnay-details{
                    color: #1a2734;
                    font-size: 15px;
                    font-weight: 900;
                    word-break: break-word;
                    .date{
                        color: #86868a;
                        font-size: 12px;
                    }
                }
            }
            .admin-details{
                display: inline-block;
                vertical-align: middle;
                width: 320px;
                font-size: 15px;
                color: #1a2734;
                .admin-email{
                    font-size: 15px;
                    color: #1a2734;
                    cursor: pointer;
                    .role{
                        margin-right: 10px;
                    }
                }
                .admin-pan{
                    font-size: 12px;
                    color: #86868a;
                }
            }
            .account-type{
                display: inline-block;
                vertical-align: middle;
                width: 170px;
                font-size: 15px;
                color: #1a2734;
            }
            .status{
                display: inline-block;
                vertical-align: middle;
                width: 140px;
                .action-icons{
                    margin-right:10px;
                    padding : 5px 7px;
                    cursor: pointer;
                }
            }
            .center{
                text-align: center;
            }
            &.roles {
                width: 49%;
                display: inline-block;
            }
        }
        .list-section{
            &.header{
                font-weight: 600;
                padding-top: 0;
                color: #1a2734;
                font-size: 15px;
            }
        }
        .list-section{
            &.content:hover{
                border: 1px solid rgba(0, 89, 251, 0.38);
                box-shadow: 0px 10px 20px 0px rgba(129, 172, 212, 0.45);
            }
        }
    }
}
.submit-section{
    text-align: center;
    padding: 20px;
}

.dept_role_download{
    margin-top: 50px;
    margin-left: 30px;
}
.dept_role_upload{
    margin-top: 50px;
    margin-left: 12px;
}