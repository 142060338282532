.rate-masters{
    .rates-table {
        width: 975px;
        overflow: scroll;
    }
    .wrapper{
        width: 1280px;
        margin: 0 auto;
        .home-title{
            font-size: 12px;
            color: #86868a;
        }
        .sub-heading-section{
            margin-top: 5px;
            font-family: avenir-medium;
            font-weight: 900;
            overflow: auto;
            .sub-heading{
                font-size: 18px;
                color: #282626;
                float: left;
                width: 100%;
            }
            .add-company-section{
                float: right;
                margin-right: 30px;
            }
        }
        .cta-action-btns {
            display: flex;
            .button-classic { 
                height: 30px;
                font-size: 14px;
                padding: 0 20px;
                border-radius: 5px;
                margin-top: 10px;
                margin-right: 10px;
            }
            &.cta-btn-right {
                float: right;
            }
            .secondary { 
                background: #5289ea;
                color: white;
            }
        }
        .company-list-wrap{
            margin-top: 10px;
            .no-records-section{
                margin-top: 40px;
            }
            .list-section{
                border-bottom: 1px solid rgba(212, 212, 212, 0.5);
                padding-bottom: 25px;
                padding-top: 15px;
                .checkbox-section{
                    display: inline-block;
                    vertical-align: middle;
                }
                .sub-header-inline {
                    display: inline-block;
                    margin-right: 6px;
                    width: 75px;
                }
                .table-header {
                    margin: 10px;
                    display: inline-block;
                    word-break: break-word;
                    text-transform: uppercase;
                    font-size: 14px;
                    text-align: center;
                    &.select-row {
                        width: 4%;
                    }

                    &.rules-tab {
                        width: 18%;
                    }
                }
                .company-name-section{
                    display: inline-block;
                    vertical-align: middle;
                    margin-left: 10px;
                    width: 370px;
                    &.table-header {
                        margin-left: 85px;
                        width: 290px;
                    }
                    .comany-logo ,.compnay-details{
                        display: inline-block;
                        vertical-align: middle;
                        cursor: pointer;
                    }
                    .comany-logo{
                        width: 50px;
                        height: 35px;
                        background-size: contain;
                        background-repeat: no-repeat;
                    }
                    .compnay-details{
                        margin-left: 25px;
                        color: #1a2734;
                        font-size: 15px;
                        font-weight: 900;
                        width: 220px;
                        .date{
                            color: #86868a;
                            font-size: 12px;
                        }

                        .name {
                            word-break: break-word;
                            display: -webkit-box;
                            overflow: hidden;
                            -webkit-line-clamp: 2;
                            -webkit-box-orient: vertical;
                            text-overflow: ellipsis;
                        }
                    }
                }
            }
            .list-section{
                &.header{
                    font-weight: 600;
                    padding-top: 0;
                    color: #1a2734;
                    font-size: 14px;
                }
            }
            .list-section{
                &.content:hover{
                    border: 1px solid rgba(12, 12, 12, 0.38);
                    box-shadow: 0px 10px 20px 0px rgba(158, 170, 181, 0.45);
                }

            }
            .indicate-icon {
                background-repeat: no-repeat;
                height: 15px;
                width: 15px;
                background-size: contain;
                margin: 0 5px;
                cursor: pointer;
                display: inline-block;
            }
            .divTable{
                margin-top: 20px;
                margin-bottom: 20px;
                display: table;
                width: 100%;
            }
            .divTableRow {
                display: table-row;
            }
            .divTableCell, .divTableHead {
                /*border: 1px solid #999999;*/
                display: table-cell;
                padding: 10px 10px;
                font-size: 12px;
            
                &.bordered {
                    border-bottom: 1px solid #999999;
                }
            }
            .divTableHeading {
                background-color: #528aea;
                display: table-header-group;
                font-weight: bold;
            }
            .divTableFoot {
                background-color: #EEE;
                display: table-footer-group;
                font-weight: bold;
            }
            .divTableBody {
                display: table-row-group;
                margin-top: 10px;
            }
        }
        .action-row {
            display: inline-flex;
            width: 100%;
        }
        .details-block {
            border: 1px solid #b9b9b9;
            border-radius: 2px;
            padding: 10px;
            padding-top: 8px;
            padding-bottom: 4px;
            margin-right: 0;
            margin-left: auto;
            /* width: 415px; */
        }
        .zone-wise {
            margin-top: 5px;
            margin-bottom: 5px;
        }
        .zone-wise-label {
            //border: 1px solid #5289ea;
            border-radius: 2px;
            padding: 5px;
            margin-right: 10px;
            font-size: 14px;
        }
        .zone-wise-value {
            //border: 1px solid #5289ea;
            border-radius: 2px;
            padding: 5px;
            font-size: 18px;
        }
        .pan-average-label {
            //border: 1px solid #5289ea;
            border-radius: 2px;
            padding-left: 5px;
            padding-right: 5px;
            padding-top: 2px;
            padding-bottom: 2px;
            font-size: 14px;
        }
        .pan-average-value {
            //border: 1px solid #5289ea;
            border-radius: 2px;
            padding-left: 5px;
            padding-right: 5px;
            padding-top: 2px;
            padding-bottom: 2px;
            font-size: 18px;
        }
        .zone-average {
            margin-left: 5px;
            font-size: 18px;
            color: #5289ea;
        }
        .m-b-5 {
            margin-bottom: 5px;
        }
        .value-highlight {
            background: #5289ea;
            color: white;
        }
    }
    .input-text {
        padding: 5px;
        border: 1px solid #cccccc;
        height: 35px;
        border-radius: 4px;
        font-size: 14px;
        width: 100%;
    }
    .m-t-5 {
        margin-top: 5px;
    }
    .m-l-5 {
        margin-left: 5px;
    }
    .action-icon {
        background-repeat: no-repeat;
        height: 18px;
        width: 18px;
        background-size: contain;
        margin: auto !important;
        cursor: pointer;
        display: inline-block;
        padding: 5px;
    }
    .lanes-link {
        color: #5289ea;
        cursor: pointer;
    }
}
.error {
    color: red;
}
.m-t-10 {
    margin-top: 10px;
}