@import "../variables.less";

.main-container {
    width: 100%;
    background-color: #E3E9FF;
    min-height: 100vh;

    .boarding-form {
        padding: 29px 20px;
        width: 100%;
        display: flex;
        flex-direction: column;

        .header {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }

        .heading {
            display: flex;
            justify-content: space-between;
            margin-top: 70px;

            .header-title {
                color: Black;
                font-size: 25px;
                margin: 30px 0;
                font-weight: bolder;
            }

            .form-status {
                width: 24%;

                .progress-status-bar {

                    .progressbar-container {
                        width: 400px;
                    }

                    .progressbar {
                        counter-reset: step;
                    }

                    .progressbar li {
                        list-style-type: none;
                        width: 30%;
                        display: flex;
                        float: left;
                        font-size: 12px;
                        position: relative;
                        text-align: center;
                        //text-transform: uppercase;
                        color: blue;
                        flex-direction: column;
                    }

                    .progressbar li:before {
                        width: 30px;
                        height: 30px;
                        content: counter(step);
                        counter-increment: step;
                        line-height: 30px;
                        border: 2px solid blue;
                        display: block;
                        text-align: center;
                        margin: 0 auto 10px auto;
                        border-radius: 50%;
                        background-color: white;
                        //  display: flex;
                        //  flex-direction: column;
                    }

                    .progressbar li:after {
                        width: 100%;
                        height: 2px;
                        content: '';
                        position: absolute;
                        background-color: #7d7d7d;
                        top: 15px;
                        left: -50%;
                        z-index: -1;
                        //  display: flex;
                        //  flex-direction: column;
                    }

                    .progressbar li:first-child:after {
                        content: none;
                        //  display: flex;
                        //  flex-direction: column;
                    }

                    .progressbar li.active {
                        color: blue;
                        display: flex;
                        flex-direction: column;
                    }

                    .progressbar li.active:before {
                        border-color: blue;
                        background-color: blue;
                        color: white;
                        //  display: flex;
                        //  flex-direction: column;
                    }

                    .progressbar li.active+li:after {
                        background-color: blue;
                        //  display: flex;
                        //  flex-direction: column;
                    }

                }
            }
        }

        .form-page-1 {
            display: flex;
            flex-direction: column;
            width: 100%;

            .contact-table {
                background-color: white;
                border: 1px solid black;
                border-radius: 8px;
                margin-top: 80px;

            }

            .Table-header {
                display: flex;
                flex-direction: column;
                width: 100%;

                .declaration-content {
                    display: flex;
                    flex-direction: column;
                    padding-top: 20px;
                    width: 100%;
                }

                .declaration-inside-content {
                    display: flex;
                    padding-left: 20px;
                    width: 100%;
                    align-items: center;
                    padding-top: 5px;
                    margin-bottom: 10px;

                    .declaration-inside-content-name {
                        width: 20%;
                    }

                    .declaration-button {
                        height: 38px;
                        background-color: white;
                        color: #627fe9;
                        font-size: 14px;
                        font-weight: bold;
                        // border: none;
                        outline: none;
                        cursor: pointer;
                        margin-left: 20px;
                        border: 1px solid #627fe9;
                        align-items: center;
                        display: flex;
                        padding: 0 15px;
                        text-decoration: none;
                    }
                }

                .table-heading {
                    display: flex;
                    border-bottom: 1px solid black;
                    width: 100%;
                    padding: 20px 10px;
                }

                .first-table-body {
                    display: flex;
                    width: 100%;
                    justify-content: space-between;
                    padding: 10px 10px;

                    .new-vendor-input {
                        height: 35px;
                        width: 40%;
                        padding: 10px;
                        border: 1px solid #627FE9;
                    }

                    .upload-img {
                        //vertical-align: top;
                        text-align: right;
                        //display: inline-block;
                        width: 30%;
                    }

                }

                .second-table-body-verify {
                    display: flex;
                    padding: 10px 10px;
                    justify-content: space-between;
                    width: 100%;

                    .contact-details-vendor-input {
                        height: 35px;
                        width: 80%;
                        padding: 10px;
                        border: 1px solid #627FE9;
                    }
                }

            }

            .files-link {
                color: #627fe9;
                text-decoration: none;
                margin-left: 15px;
            }

            .ml-25 {
                margin-left: 25px;
            }

            // .company-details-table {
            //     background-color: white;
            //     border: 1px solid black;
            //     border-radius: 8px;
            //     margin-top: 10px;

            //     .Table-header {
            //         display: flex;
            //         flex-direction: column;
            //         width: 100%;

            //         .table-heading {
            //             display: flex;
            //             border-bottom: 1px solid black;
            //             width: 100%;
            //             padding: 20px 10px;
            //         }

            //         .company-details-body {
            //             display: flex;
            //             flex-direction: column;
            //             padding: 10px 10px;
            //             width: 100%;

            //             .company-details-row {
            //                 display: flex;
            //                 justify-content: space-between;
            //                 width: 100%;
            //                 margin-top: 15px;

            //                 .company-details-vendor-input {
            //                     height: 35px;
            //                     width: 90%;
            //                     padding: 10px;
            //                     border: 1px solid #627FE9;
            //                 }

            //                 .company-details-vendor-select {
            //                     height: 35px;
            //                     width: 90%;
            //                 }
            //             }

            //             .turn-over-details {
            //                 display: flex;
            //                 flex-direction: column;
            //                 width: 100%;

            //                 .turn-over-details-row {
            //                     width: 40%;
            //                     display: flex;
            //                     justify-content: space-between;
            //                     margin-top: 10px;

            //                     .turn-vendor-input {
            //                         height: 35px;
            //                         width: 90%;
            //                         padding: 10px;
            //                         border: 1px solid #627FE9;
            //                     }

            //                     .turn-vendor-select {
            //                         height: 35px;
            //                         width: 90%;
            //                     }
            //                 }
            //             }
            //         }

            //     }
            // }

            .gst-details-table {
                width: 100%;
                display: flex;
                flex-direction: column;
                background-color: white;
                border: 1px solid black;
                border-radius: 10px;
                margin-top: 15px;

                .gst-table-header {
                    display: flex;
                    flex-direction: column;
                    width: 100%;

                    .gst-table-heading {
                        display: flex;
                        width: 20%;
                        padding: 20px 10px;
                    }
                }
            }

            .regional-office-details {
                background-color: white;
                border: 1px solid black;
                border-radius: 8px;
                margin-top: 10px;

                .regional-table-header {
                    display: flex;
                    flex-direction: column;
                    width: 100%;

                    .regional-table-heading {
                        display: flex;
                        border-bottom: 1px solid black;
                        width: 100%;
                        padding: 20px 10px;
                    }

                    .regional-table-body {
                        display: flex;
                        width: 100%;
                        justify-content: space-between;
                        padding: 10px 10px;

                        .regional-left-row {
                            width: 85%;
                            display: flex;
                            justify-content: space-between;

                            .regional-new-vendor-input {
                                height: 35px;
                                width: 90%;
                                padding: 10px;
                                border: 1px solid #627FE9;
                            }

                            .regional-details-vendor-select {
                                height: 35px;
                                width: 90%;
                            }
                        }
                    }
                }
            }

            .bank-details {
                background-color: white;
                border: 1px solid black;
                border-radius: 8px;
                margin-top: 10px;
                width: 100%;

                .bank-details-table-header {
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                }

                .bank-details-table-heading {
                    display: flex;
                    border-bottom: 1px solid black;
                    width: 100%;
                    padding: 20px 10px;
                }

                .bank-table-body {
                    display: flex;
                    width: 100%;
                    justify-content: space-between;
                    padding: 10px 10px;

                    .bank-new-vendor-input {
                        height: 35px;
                        width: 90%;
                        padding: 10px;
                        border: 1px solid #627FE9;
                    }
                }
            }

            // .user-details {
            //     background-color: white;
            //     border: 1px solid black;
            //     border-radius: 8px;
            //     margin-top: 10px;

            //     .user-table-header {
            //         display: flex;
            //         flex-direction: column;
            //         width: 100%;

            //         .user-table-heading {
            //             display: flex;
            //             border-bottom: 1px solid black;
            //             width: 100%;
            //             padding: 20px 10px;
            //         }

            //         .user-table-body {
            //             display: flex;
            //             width: 100%;
            //             justify-content: space-between;
            //             padding: 10px 10px;

            //             .user-left-row {
            //                 width: 85%;
            //                 display: flex;
            //                 justify-content: space-between;

            //                 .user-new-vendor-input {
            //                     height: 35px;
            //                     width: 90%;
            //                     padding: 10px;
            //                     border: 1px solid #627FE9;
            //                 }

            //                 .user-details-vendor-select {
            //                     height: 35px;
            //                     width: 90%;
            //                 }
            //             }
            //         }
            //     }
            // }

            .customer-details-table {
                background-color: white;
                border: 1px solid black;
                border-radius: 8px;
                margin-top: 10px;

                .customer-table-header {
                    display: flex;
                    flex-direction: column;
                    width: 100%;

                    .customer-table-heading {
                        display: flex;
                        border-bottom: 1px solid black;
                        width: 100%;
                        padding: 20px 10px;
                    }

                    .customer-details-body {
                        display: flex;
                        flex-direction: column;
                        padding: 10px 10px;
                        width: 100%;

                        .customer-details-row {
                            display: flex;
                            justify-content: space-between;
                            width: 100%;
                            margin-top: 15px;

                            .customer-details-vendor-input {
                                height: 35px;
                                width: 90%;
                                padding: 10px;
                                border: 1px solid #627FE9;
                            }

                            .customer-details-vendor-select {
                                height: 35px;
                                width: 90%;
                            }
                        }

                        .customer-details-row2 {
                            display: flex;
                            justify-content: space-between;
                            width: 100%;
                            margin-top: 15px;

                            .customer-details-left {
                                display: flex;
                                width: 70%;

                                .customer-details-vendor-input2 {
                                    height: 35px;
                                    width: 80%;
                                    padding: 10px;
                                    border: 1px solid #627FE9;
                                }

                                .customer-details-vendor-select2 {
                                    height: 35px;
                                    width: 90%;
                                }
                            }
                        }
                    }

                }
            }

            .vendor-type-table {
                background-color: white;
                border: 1px solid black;
                border-radius: 8px;
                margin-top: 10px;

                .vendor-type-header {
                    display: flex;
                    flex-direction: column;
                    width: 100%;

                    .vendor-type-heading {
                        display: flex;
                        border-bottom: 1px solid black;
                        width: 100%;
                        padding: 20px 10px;
                    }

                    .vendor-table-body {
                        width: 100%;
                        padding: 10px 10px;
                        display: flex;
                        flex-wrap: wrap;

                        .vendor-type-select {
                            height: 35px;
                            width: 40%;
                            padding: 10px;
                            border: 1px solid #627FE9;
                        }
                    }
                }
            }
        }

    }



    .grey {
        color: #86868a;
    }

    .theme {
        color: #5289ea;
    }

    .black {
        color: #1a2734;
    }

    .headerColor {
        color: black;
        border-bottom: 1px solid #85919e;
        padding: 12px 12px;
        font-size: 20px;

        span {
            margin-left: 5px;
        }
    }

    .dInBlock {
        display: inline-block;
    }

    .VAlign {
        vertical-align: top;
    }

    .mt-5 {
        margin-top: 5px;
    }

    .mt-10 {
        margin-top: 10px;
    }

    .mt-15 {
        margin-top: 15px;
    }

    .mt-20 {
        margin-bottom: 20px;
    }

    .mt-25 {
        margin-top: 25px;
    }

    .mt-40 {
        margin-top: 40px;
    }

    .mt-60 {
        margin-top: 60px;
    }

    .mb-5 {
        margin-bottom: 5px;
    }

    .mb-10 {
        margin-bottom: 10px;
    }

    .mb-15 {
        margin-bottom: 15px;
    }

    .mb-20 {
        margin-bottom: 20px;
    }

    .mb-25 {
        margin-bottom: 25px;
    }

    .mr-5 {
        margin-right: 5px;
    }

    .mr-10 {
        margin-right: 10px;
    }

    .mr-15 {
        margin-right: 15px;
    }

    .mr-20 {
        margin-right: 20px;
    }

    .mt-18 {
        margin-top: 18px
    }

    .mr-25 {
        margin-right: 25px;
    }

    .mwt-80 {
        min-width: 80px;
    }

    .mwt-120 {
        min-width: 120px;
    }

    .pt-10 {
        padding-top: 10px;
    }

    .wt-100 {
        width: 100px;
    }

    .wt-120 {
        width: 120px;
    }

    .wt-150 {
        width: 150px;
    }

    .wt-160 {
        width: 160px;
    }

    .ht-150 {
        height: 150px;
    }

    .ht-50 {
        height: 50px;
    }

    .ht-65 {
        height: 65px;
    }

    .ht-90 {
        height: 90px;
    }

    .wt-200 {
        width: 200px;
    }

    .wt-250 {
        width: 250px;
    }

    .wt-10p {
        width: 10%;
    }

    .wt-12p {
        width: 15%;
    }

    .wt-18p {
        width: 18%;
    }

    .wt-20p {
        width: 20%;
    }

    .wt-25p {
        width: 25%;
    }

    .wt-50p {
        width: 50%;
    }

    .wt-60p {
        width: 60%;
    }

    .wt-75p {
        width: 75%;
    }

    .wt-100p {
        width: 100%;
    }

    .mwt-170 {
        width: 170px;
    }

    .fRight {
        float: right;
    }

    .tCenter {
        text-align: center;
    }

    .tLeft {
        text-align: left;
    }

    .pointer {
        cursor: pointer;
    }

    .fs-13 {
        font-size: 13px;
    }

    .fs-15 {
        font-size: 15px;
    }

    .marginAuto {
        margin: auto;
    }

    .noBorder {
        border: none;
    }

    .fTop {
        vertical-align: top;
    }

    .checkbox-flex {
        display: flex;
        align-items: center;
    }

    .fMiddle {
        vertical-align: middle;
    }

    .area-name {
        font-size: 17px;
        color: #7d7d7d;
    }

    .borderLight {
        border: solid 1px #dadada;
    }

    .bg-light {
        background-color: #d5e2f9;
    }
}

.ml-20 {
    margin-left: 20px;
}