/* SAPVendorForm.less */
input {
  height: 35px;
  border: 1.5px solid #627fe9;
  border-radius: 5px;
}
.heading {
  margin-top: 40px;
  font-size: 24px;
  font-weight: 500;
  margin-left: 40px;
}
.button {
  padding: 30px;
  display: flex;
  justify-content: flex-end;
}
.button-classic-doc {
  height: 38px;
  padding: 0 15px;
  background-color: white;
  color: #627fe9;
  font-size: 16px;
  font-weight: bold;
  outline: none;
  cursor: pointer;
  border: 1px solid #627fe9;
}
#btn {
  height: 28px;
  padding: 8 15px;
  background-color: #627fe9;
  width: 110px;
  color: #FFFF;
  font-size: 16px;
  font-weight: bold;
  outline: none;
  cursor: pointer;
  border: 1px solid #627fe9;
}
#btn:disabled {
  background-color: gainsboro !important;
}
#btn1 {
  width: 110px;
}
#btn1:disabled {
  background-color: gainsboro !important;
}
#btn2 {
  width: 110px;
}
#btn2:disabled {
  background-color: gainsboro !important;
}
.form-container {
  background-color: #e3e9ff;
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  padding-right: 38px;
  /* Add any other styles you want for the form container */
}
.form-container .container {
  background-color: white;
  border: 1px solid grey;
  border-radius: 10px;
  height: fit-content;
  width: 100%;
  margin-top: 30px;
  margin-left: 15px;
}
.form-container .container .container-heading {
  font-size: 20px;
  font-weight: 500;
  line-height: 22px;
  margin-left: 20px;
  margin-top: 15px;
  margin-bottom: 15px;
}
.form-container .container .form-section {
  display: flex;
  margin-bottom: 20px;
  /* Add some spacing between form sections */
}
.form-container .container .form-section .form-item {
  display: grid;
  margin-bottom: 5px;
  margin-left: 20px;
  /* Add some spacing between form items */
}
.form-container .container .form-section .form-item .form-item label {
  width: 150px;
  /* Set the width of the labels (adjust as needed) */
  margin-right: 10px;
  /* Add some spacing between labels and inputs */
}
.form-container .GST-div {
  background-color: white;
  border: 1px solid grey;
  border-radius: 10px;
  width: 100%;
  height: fit-content;
  padding-bottom: 13px;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 30px;
  margin-left: 13px;
}
.form-container .GST-div .GST-div-hr {
  margin-top: 20px;
}
.form-container .GST-div .GST-div-top {
  display: flex;
}
.form-container .GST-div .GST-div-top .GST-div-heading {
  font-size: 20px;
  font-weight: 500;
  line-height: 22px;
  margin-left: 20px;
  margin-top: 20px;
  margin-bottom: 15px;
  margin-right: 20px;
}
.form-container .GST-div .GST-div-top .GST-div-inp {
  margin-top: 15px;
}
.form-container .GST-div .GST-div-bot .GST-table {
  width: 1148px;
}
.form-container .GST-div .GST-div-bot .GST-table th {
  background-color: #e3e9ff;
}
.form-container .GST-div .GST-div-bot .GST-table td,
.form-container .GST-div .GST-div-bot .GST-table th {
  text-align: center;
}
.form-container .SAPcontainer {
  background-color: white;
  border: 1px solid grey;
  border-radius: 10px;
  height: 230px;
  width: 100%;
  margin-top: 30px;
  margin-left: 13px;
}
.form-container .SAPcontainer .container-heading {
  font-size: 20px;
  font-weight: 500;
  line-height: 22px;
  margin-left: 20px;
  margin-top: 15px;
  margin-bottom: 15px;
}
.form-container .SAPcontainer .form-section {
  display: flex;
  margin-bottom: 20px;
  /* Add some spacing between form sections */
  margin-left: 20px;
}
.form-container .SAPcontainer .form-section .form-item {
  display: grid;
  margin-bottom: 5px;
  margin-left: 30px;
  /* Add some spacing between form items */
}
.form-container .SAPcontainer .form-section .form-item .form-item label {
  width: 150px;
  /* Set the width of the labels (adjust as needed) */
  margin-right: 10px;
  /* Add some spacing between labels and inputs */
}
#company_name {
  width: 490px;
}
#email {
  width: 320px;
}
#contact_number {
  width: 130px;
}
#pan_number {
  width: 120px;
}
#address {
  width: 360px;
}
#state {
  width: 188px;
}
#city {
  width: 188px;
}
#pincode {
  width: 109px;
}
#gst_number {
  width: 433px;
}
#bank_name {
  width: 330px;
}
#bank_address {
  width: 440px;
}
#account_number {
  width: 157px;
}
#ifsc_code {
  width: 144px;
}
#account_group {
  width: 170px;
}
#central_blocking {
  width: 142px;
}
#company_code {
  width: 129px;
}
#recon_account {
  width: 254px;
}
#payment_method {
  width: 254px;
}
#purchase_org {
  width: 215px;
}
#order_currency {
  width: 254px;
}
#payment_terms {
  width: 254px;
}
#inco_terms {
  width: 254px;
}
.labelname {
  padding: 4px;
}
.common {
  padding: 10px;
}
#Acctlabel {
  margin-bottom: 15px;
}
.Acct {
  margin-top: 0px;
}
.regional-details-vendor-select {
  height: 35px;
  width: 200px;
  margin-top: 2px;
}
.dropdownT1 {
  margin-left: 20px;
}
.GST-table th:nth-child(2) {
  width: 35%;
}
.GST-table th:nth-child(3) {
  width: 14%;
}
.GST-table th:nth-child(4) {
  width: 10%;
}
#ContactheadOffice {
  margin-left: 30px;
}
#addressheadOffice {
  margin-left: 40px;
  margin-bottom: 10px;
}
#CityheadOffice {
  margin-left: 40px;
}
/* Add any other styles you want for form items and labels */
