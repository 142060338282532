.tCenter {
	text-align: center;
	}

.mh-30 {
	margin-left: 30px;
	margin-right: 30px;
	}
.page {
	/*width: 700px;*/
	height: 850px;
	
	padding: 50px;
}
p {
	text-align: justify;
	}

ol,li {
	margin-top: 20px;
	text-align: justify;
	}

table, th, td {
  border: 1px solid black;
  border-collapse: collapse;
	/*margin-right: 40px;*/
	text-align: justify;
	padding:5px;
	margin-top: 10px;
}

h3 {
margin-top: 60px;
}

table {
	justify-content: center;
	margin: auto;
	 margin-top: 20px;
}