.switch{
		position: relative;
		display: inline-block;
		vertical-align: top;
}
.switch label{
		width: 40px;
		height: 18px;
		position: absolute;;
		background-color: #f2f2f2;
		top:0;
		left: 0;
		border-radius: 50px;
		border: solid 1.5px rgba(0, 0, 0, 0.1);
		cursor: pointer;
}
.switch input[type="checkbox"]{
		visibility: hidden;
}
.switch label:after{
		content: '';
		width: 16px;
		height: 16px;
		background-color: #d4d4d4;
		border-radius: 50px;
		top:-2px;
		left: 0px;
		position: absolute;
		transition:all 0.3s;
		border: solid 0.5px rgba(0, 0, 0, 0.1);
		box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.05), 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 1px 0 rgba(0, 0, 0, 0.05);
}
.switch input[type="checkbox"]:checked + label:before {
	  color: white;
	  content: '' !important;
	  text-align: center;
}
.switch input[type="checkbox"]:checked + label:after{
		left: 20px;
		background-color: #00c251;
}
.switch input[type="checkbox"]:checked + label{
		background-color: #e9f9f0;;
}
