@import "../../../variables.less";

.route-import-root{
    max-width: 900px;;
    .container-1{
        margin: 20px 0px;
        .file-dropdown-holder{
            display: flex;
            .file-upload{
                margin-left: 20px;
            }
        }
        .banner{
            background: @tms-admin-color;
            color: #fff;
            font-family: 'Avenir',sans-serif;
            font-size: 20px;
            font-weight: 500;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            padding: 13px 24px;
        }
        .header{
            color: #1a2734;
            font-family: 'Avenir',sans-serif;
            font-size: 12px;
            font-weight: 900;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            margin: 25px 0px;
        }
        .sub-container{
            display: flex;
            .file-holder{
                width: 300px;
                height: 26px;
                border-radius: 2px;
                background-color: #f9f9f9;
                border: 1px solid #cccccc;
                cursor: pointer;
                position: relative;
                input{
                    width: 300px;
                    height: 26px;
                    opacity: 0;
                    cursor: pointer;
                }
                .file-choose{
                    position: absolute;
                    right: 9px;
                    top: 4px;
                    font-family: 'SFProDisplay',sans-serif;
                    font-size: 12px;
                    font-weight: 500;
                    font-style: normal;
                    font-stretch: normal;
                    line-height: normal;
                    letter-spacing: 0.3px;
                    cursor: pointer;
                    color: @tms-admin-color;
                }
                .file-name{
                    font-family: 'Avenir-Roman',sans-serif;
                    font-size: 12px;
                    position: absolute;
                    top: 3px;
                    left: 12px;
                    color: #86868a;
                }

            }
        }
        .generate-button{
            cursor: pointer;
            // border-radius: 2px;
            border: 1px solid @tms-admin-color;
            background: @tms-admin-color;
            margin: 0px 20px;
            color: #fff;
            padding: 10px;
            font-size: 14px;
            border-radius: 20px;
        }
    }
}

.error{
    color: red;
}