@import "../../../variables.less";

.plants-basic-details-wrap{
    .details{
        margin-top: 40px;
        .address ,.pin-code{
            display: inline-block;
            vertical-align: top;
        }
        .vehicle_placement {
            display: inline-block;
            .select {
                width: 120px;
            }
        }
        .select-wrap-depot{
            width: 33%;
            display: inline-block;
            vertical-align: baseline;
            .label{
                font-size: 10px;
                color: #868e96;
               //  text-transform: uppercase;
                margin-bottom: 10px;
            }
            &.auto_move {
                .custome-checkbox-wrap {
                    margin-right: 10px;
                }
            }
        }
        .select{
            width: 277px;
        }
        .address{
            width: 600px;
            .input-text-wrap{
                width: 100%;
            }
            .input-text-wrap .text-input{
                width: 100%;
            }
        }
        .pin-code{
            margin-left:50px;
        }
    }
    .submit-section-depot{
        margin-top: 30px;

    }
}

.address-details{ 
    .input-text-wrap {
    vertical-align: top;
    }
    span {
        display: flex;
        width: 277px;
    }
}


.delete-company-wrap{
    .overlay{
        position: fixed;
        z-index: 10;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: rgba(27, 33, 67, 0.9);
    }
    .modal-popup1{
        position: fixed;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
        border-radius: 5px;
        width: 500px;
        min-height: 200px;
        background-color: white;
        z-index: 11;
        padding: 40px 80px 15px 72px;
        text-align: center;
        margin-top: 20px;
        
        .question{
           margin-top: 20px;
           font-size: 20px;
        }
   
        .submit-section{
           float: right;
            .button-section{
                margin-left: 10px;
                float: right;
            }
            &.delete-confirmation {
                padding: 20px 0px;
            }
        }
    }
}

.error-sec{
    font-size: 14px;
    font-weight: 600;
    color: rgb(124, 31, 31);
    background-color: rgb(230, 189, 189);
    padding: 10px;
    width: 90%;
    margin: 5px;
}

.warning-sec{
    font-size: 14px;
    font-weight: 600;
    color: #d7ab04;
    background-color: #fbf3d5;
    padding: 10px;
    width: 90%;
    margin: 5px;
}

.dedicated-vehicle{
    margin-top:20px;
    display: flex;
    flex-direction: column;

    .first-header{
        display: flex;
    }

    .dedicated-content{
        display: flex;
        margin-top: 10px;
        width:90%;
        justify-content: space-between;
    }
}

.recheck-approval{
    margin-top:20px;
    display: flex;
    flex-direction: column;

    .first-header{
        display: flex;
    }

    .label{
        font-size: 16px;
    }

    .mt-25{
        margin-top: 25px;
    }

    .input-container{
        display: flex;
        align-items: center;
        .label{
            width: 250px;
        }
        span{
            font-size: 12px;
            margin-top: 30px;
            align-self: center;
            margin-left: -12px;
        }
        .select-wrap-depot{
            width: 180px;
        }
    }

    .approval-content{
        display: flex;
        margin-top: 10px;
        width:90%;
        gap: 20px;
        input{
            padding: 5px 0px 5px 10px;            
            width: 180px;
            height: 35px;
            border: 1px solid lightgray;
            border-radius: 5px;
        }
    }
}

