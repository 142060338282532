@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap");

.loginFormMain {
  padding: 0;
  margin: 0 auto;
  box-sizing: border-box;
  height: 100vh;
  // background-image: linear-gradient(to right, #597ef7, #c4befe);
  background: linear-gradient(87deg, #597EF7 15.86%, #C4BEFE 124.08%);
  position: relative
}
.forceLogin{
  display: flex;
  justify-content: center;
  bottom: 200px;
  background-image: linear-gradient(to right, #597ef7, #c4befe);
}
.forceLoginContent{
  width: 1000px;
  color: #FFFFFF;
  // background-color: #FFFFFF;
}