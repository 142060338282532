.item-table .item-header .item-label {
  width: 100px;
}
.add-item-details .label {
  font-size: 10px;
  color: #868e96;
  margin-bottom: 10px;
  margin-top: 25px;
}

.error-color{
  color: red;
}
