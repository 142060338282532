
.msg-pop-up{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0, 0.5);
  z-index: 13;
  .msg-section{
    width: 400px;
    min-height: 200px;
    position: absolute;
    background: white;
    border-radius: 5px;
    text-align: center;
    padding: 40px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    .msg{
      margin-bottom: 35px;
      margin-top: 35px;
      &.error{
        color: #D8000C;
      }
    }
  }
}
