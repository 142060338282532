@font-face {
  font-family: 'avenir-black';
  src: url('../../../assets/fonts/avenir-otf/AvenirLTStd-Black.otf') format("otf"), url("../../../assets/fonts/avenir-woff/AvenirLTStd-Black.woff") format("woff");
  font-style: normal;
  font-weight: normal;
}
@font-face {
  font-family: 'avenir-medium';
  src: url('../../../assets/fonts/avenir-otf/AvenirLTStd-Medium.otf') format("otf"), url("../../../assets/fonts/avenir-woff/AvenirLTStd-Medium.woff") format("woff");
  font-style: normal;
  font-weight: normal;
}
@font-face {
  font-family: 'avenir-light';
  src: url('../../../assets/fonts/avenir-otf/AvenirLTStd-Light.otf') format("otf"), url("../../../assets/fonts/avenir-woff/AvenirLTStd-Light.woff") format("woff");
  font-style: normal;
  font-weight: normal;
}
@font-face {
  font-family: 'icomoon';
  src: url('../../../assets/icomoon/icomoon.eot?ofw34t');
  src: url('../../../assets/icomoon/icomoon.eot?ofw34t#iefix') format('embedded-opentype'), url('../../../assets/icomoon/icomoon.ttf?ofw34t') format('truetype'), url('../../../assets/icomoon/icomoon.woff?ofw34t') format('woff'), url('../../../assets/icomoon/icomoon.svg?ofw34t#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}
[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-bills:before {
  content: "\e900";
}
.icon-broadcast:before {
  content: "\e901";
}
.icon-group-2 .path1:before {
  content: "\e902";
}
.icon-group-2 .path2:before {
  content: "\e903";
  margin-left: -2.41210938em;
}
.icon-group-2 .path3:before {
  content: "\e904";
  margin-left: -2.41210938em;
}
.icon-home:before {
  content: "\e905";
}
.icon-master:before {
  content: "\e906";
}
.icon-reports:before {
  content: "\e907";
}
.icon-vehicle-modification:before {
  content: "\e908";
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "avenir-medium", 'Poppins';
}
abbr {
  text-decoration: none;
}
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px white inset;
}
.page-title {
  font-size: 12px;
  color: #1a2734;
}
.page-title .home {
  cursor: pointer;
}
.home-navigation-holder-sub {
  min-width: 500px!important;
  max-width: 650px!important;
  margin: 30px auto!important;
}
.home-navigation-holder {
  display: flex;
  justify-content: space-between;
  border-bottom: 4px solid #f0f0f0;
  min-width: 885px;
  max-width: 920px;
  margin: 30px 0px;
  color: #1a2734;
  font-weight: 600;
  font-family: 'avenir-light';
}
.home-navigation-holder.provider {
  display: block;
}
.home-navigation-holder.provider .options {
  display: inline-block;
  vertical-align: middle;
}
.home-navigation-holder .options {
  width: 200px;
  border-bottom: 4px solid #f0f0f0;
  padding: 5px 6px;
  cursor: pointer;
  margin-bottom: -4px;
  font-family: 'avenir-light';
}
.home-navigation-holder .selected-option {
  width: 200px;
  color: #528aea;
  border-bottom: 4px solid;
}
.error-section {
  float: left;
  color: #D8000C;
}
.submit-section-pagination {
  margin-top: 30px;
}
.submit-section-pagination .next {
  float: right;
  margin-right: 30px;
}
.submit-section-pagination .pre {
  float: left;
}
.mode-navigation-holder {
  display: flex;
  border-bottom: 4px solid #f0f0f0;
  width: 180px;
  margin: 30px 0px;
  color: #1a2734;
  font-weight: 600;
  font-family: 'avenir-light';
}
.mode-navigation-holder.provider {
  display: block;
}
.mode-navigation-holder.provider .options {
  display: inline-block;
  vertical-align: middle;
}
.mode-navigation-holder .options {
  border-bottom: 4px solid #f0f0f0;
  padding: 5px 6px;
  cursor: pointer;
  margin-bottom: -4px;
  font-family: 'avenir-light';
}
.mode-navigation-holder .selected-option {
  color: #528aea;
  border-bottom: 4px solid;
}
.main-wrap {
  width: 100%;
}
.main-wrap .main-content {
  padding: 40px;
  padding-right: 0px;
  width: 1020px;
  margin-left: 20%;
  display: block;
  margin-bottom: 100px;
}
.panel-body-contents {
  padding-top: 80px;
}
