@import "../../../../variables.less";

.add-user-wrap{
    .overlay{
        position: fixed;
        z-index: 10;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: rgba(27, 33, 67, 0.9);
    }
    .modal-popup{
        max-height: 600px;
        overflow: scroll;
        position: fixed !important;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
        border-radius: 5px;
        max-width:80%;
        min-height: 500px;
        background-color: white;
        z-index: 11;
        .pop-heading{
            background-color: #f4f4f4;
            padding: 18px;
            font-family: 'avenir',sans-serif;
            font-size: 16px;
            font-weight: 600;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            color: @tms-admin-color;
            text-align: center;
            margin-bottom: 30px;
        }
        .content{
            padding: 20px 0px 20px 30px;
            .select-container{
                display: flex;
                margin: 20px 0px;
                &.action-check {
                    margin: 0px;
                    .delete-icon {
                        height: 30px;
                        width: 30px;
                    }
                }
                .select-holder{
                    width: 277px;
                    position: relative;
                    .sub-head{
                        font-size: 10px;
                        font-weight: 500;
                        font-style: normal;
                        font-stretch: normal;
                        line-height: normal;
                        letter-spacing: normal;
                        color: #868e96;
                        margin-bottom: 7px;
                    }
                }
                .action-holder{
                    display: flex;
                    flex-wrap: wrap;
                    width: 330px;
                    margin: 15px;
                    position: relative;
                    &.small-actions {
                        width: 250px;
                    }
                    .actions{
                        margin: 5px;
                        .add-button{
                            margin: 0;
                            height: 35px;
                            padding: 0 15px;
                            font-size: 20px;
                            margin-left: 10px;
                        }
                        .select{
                            width: 235px;
                        }
                    }
                    .add-button{
                        margin: 5px 5px;    
                        padding: 10px 20px;
                    }
                    .sub-head{
                        position: absolute;
                        font-size: 10px;
                        font-weight: 500;
                        font-style: normal;
                        font-stretch: normal;
                        line-height: normal;
                        letter-spacing: normal;
                        color: #868e96;
                        top: -10px;
                        left: 7px;
                    }
                }
            }
            .usn-container{
                margin: 17px 0px;
                display: flex;
                .add-button{
                    margin: 20px;
                    border-radius: 5px;
                }
                .btn-disabled{
                    background-color: #cac2c2;
                    border: 1px solid #cac2c2;
                    pointer-events: none;
                    color: white;
                }
            }
            .submit-section{
                display: flex;
                justify-content: flex-end;
                .add-button{
                    border-radius: 5px;
                    width: 145px;
                }
                .discard{
                    background: white;
                    color: @tms-admin-color;
                    border: 1px solid @tms-admin-color;
                }
            }
        }
    }

    .modal-popupUserConfirmation{
        max-height: fit-content;
        position: fixed !important;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
        padding: 30px;
        border-radius: 5px;
        max-width:80%;
        background-color: white;
        z-index: 11;
        .content{
            font-size: 16;
            // padding: 20px 0px 20px 30px;
        }
    }
}

.form-section .input-text-wrap {
    vertical-align: top;
}

.tk11-dashboard {
    .switch {
        margin-top: 10px;
    }
    .custome-checkbox-wrap {
        margin-top: 9px;
    }
    .title {
        margin-top: 12px;
        margin-left: 12px;
        width: 80%;
        font-size: 14px;
        &.small-title {
            width: 75%;
        }
    }
}
.ph-10 {
    padding: 10px 0px;
}
.wt-270 {
    width: 270px;
}
.mt-30{
    margin-top: 30px;
}
