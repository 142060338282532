.plants-depots-main-wrap{
    max-width: 944px;
    font-family: 'Avenir',sans-serif;
    .search-holder{
        display: flex;
        justify-content: space-between;
        margin: 35px 4px;
        .select {
            width: 250px;
        }
        .new{
            border-radius: 5px;
            border: none;
        }
    }
    .heading{
        display: flex;
        font-size: 12px;
        font-weight: 900;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: 0.2px;
        color: #ababae;
    }
    .depot-users-list{
        display: flex;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: 0.2px;
        color: #535356;
        margin : 4px 0px;
        padding-bottom: 10px;
        border-bottom: 1px solid #ececee;
        .edit-delete{
            display: flex;
            justify-content: space-between;
            max-height: 23px;
            width: 99px!important;
            .add-button{
                padding: 4px 6px;
                margin: 0px 4px 0px 0px;
            }
        }
    }
    .heading, .depot-users-list{
        .head{
            margin: 10px 15px 10px 5px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        .usn{
            width: 135px
        }
        .email{
            width: 150px;
        }
        .phone{
            width: 100px!important;
        }
        .branch{
            width: 120px!important;
        }
        .role, .actions{
            width: 70px;
        }
        .active{
            width: 100px;
        }
        .subheading{
            width: 250px;
        }
        .time{
            margin: none !important;
            width: 50px !important;
        }
    }
    .depot-users-list{
        .usn{
            font-weight: 900;
        }
    }
    .tooltip {
        position: absolute;
        display: inline-block;
      }
      
      .tooltip .tooltiptext {
        visibility: hidden;
        background-color: #b9b6b6;
        color: black;
        text-align: left;
        border-radius: 4px;
        padding: 5px;
        position: absolute;
        z-index: 1;
        bottom: 125%;
        right: 80%;
        // opacity: 0;
        transition: opacity 0.3s;
        font-size: 14px;
      }
      
      .tooltip:hover .tooltiptext {
        visibility: visible;
        opacity: 1;
      }
}

.plants-depots-main-wrap {
    .search-text-wrap .text-search{
        width: 190px;
    }
    .search-text-wrap .cross-img {
        right: 60px;
    }
}

.advanced-button {
    margin-bottom: 20px;
    margin-left: 0;
    float: left;
}
.select-container-advanced {
    .select-holder {
        width: 25%;
        padding-left: 20px;
        margin-left: 0;
        &:first-child {
            margin-left: 0;
            padding-left: 0;
        }
    }
}
.depot-users-list-first{float:left;width:50%;}
.depot-users-list-second{float:right;width:50%;}
.depot-users-list-first .first,.depot-users-list-second .first{float:left;width:50%;}
.depot-users-list-first .second,.depot-users-list-second .second{float:right;width:50%;}
.depot-users-list-complete{width: 100%;}
.heading .first,.heading .second{font-weight: 900;}
.float-right{float:right;}
.float-left{float:left;}
.view-flow-box{min-width: 885px;
    max-width: 920px;height:30px;}

