.scheduler{
    border-color: #aecdf7;
    .button-classic {
        margin-left: 10px;
    }
    .scheduler_detail_section{
        overflow: scroll;
        border-color: #aecdf7;
    }
    .scheduler_detail {
        margin-bottom: 150px;
        width: 260%;
        text-align: center;
        border-color: #aecdf7;
        .header{
            background-color: #528aea;
            color: white;
            border-color: #aecdf7;
            .subheader{
                background-color: #aecdf7;
                border-color: #aecdf7;
            }
        }
        .action-header{
            text-align: center;
            border-color: #aecdf7;
        }
        .main-header{
            width: 30%;
            text-align: center;
            border-color: #aecdf7;
            .subtabs{
                width: 10%;
                text-align: center;
                border: none;
                
            }
        }
        .code-header{
            text-align: center;
            border-color: #aecdf7;
            .date-filter{
                .DateRangePicker{
                    width: 275px;
                }
            }
            .select-option{
                z-index: 999 !important;
            }
            .input-text-wrap {
                margin-right: 35px;
                padding-top: 10px;
                .input-element{
                    width: 20px;
                    .text-input {
                        width: 70px;
                        font-size: 12px;
                    }
                }
                
            }
        }
        .delete-icon{
            height: 20px;
            margin: 0px;
        }
    }
}


.scheduler_code_detail {
    .overlay{
        position: fixed;
        z-index: 10;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: rgba(27, 33, 67, 0.9);
    }
    .modal-popup .pop-heading {
        background-color: #f4f4f4;
        padding: 18px;
        font-family: 'avenir',sans-serif;
        font-size: 16px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #528aea;
        text-align: center;
    }
    .modal-popup {
        max-height: 670px;
        overflow: scroll;
        position: fixed;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        border-radius: 5px;
        width: 1000px;
        min-height: 400px;
        background-color: white;
        z-index: 11;
    }
    .scheduler-detail {
        margin: 10px;
        .code-header{
            display: flex;
            text-align: center;
            border: none;
            .select{
                width: 230px;
                height: 35px;
                margin: 10px;
            }  
            .date-filter{
                margin: 10px;
                height: 35px;
                
            }
            .table{
                width: 150%;
                .subtabs{
                    width: 10%;
                    text-align: center;
                    border: none;
                    
                }
            } 
            .input-text-wrap {
                width: 15%;
                margin: 10px;
                .input-element{
                    width: 30px;
                    .text-input {
                        width: 150px;
                        font-size: 12px;
                        padding-bottom: 5px;
                    }
                }
                
            }
            
        }
    }
    .button_route {
        margin-right: 10px;
        float: right;
    }
    .error-message {
        margin-right: 10px;
        float: right;
        font-size: 12px;
        color: red;
    }
    .select-wrap-depot{
        width: 33%;
        display: inline-block;
        vertical-align: baseline;
        .label{
            font-size: 10px;
            color: #868e96;
           //  text-transform: uppercase;
            margin-bottom: 10px;
        }
    }
}
.download-icon {
    background-repeat: no-repeat;
    height: 30px;
    width: 30px;
    background-size: contain;
    // margin: 0 5px;
    cursor: pointer;
    display: inline-block;
    padding: 5px;
    margin: 5px;
}
 
.ml-10{
    margin-left: 10px;
}
.mr-10{
    margin-right: 10px;
}
.wt-230{
    width: 230px;
}
.mt-30{
    margin-top: 30px;
}
.wt-100p{
    width: 100%;
}
.wt-80p{
    width: 80%;
}
.wt-90p{
    width: 90%;
}
.pl-30{
    padding-left: 30px;
}
.danger-color{
    color: #d80027;
}
.fs-12{
    font-size: 12px;
}