@import "../variables.less";


.main-container {
    width: 100%;
    background-color: #E3E9FF;
    min-height: 100vh;  
    .pop{
        .msg-section {
                background-color: #E3E9FF;
        
                .approveBtn {
                    background-color: #627FE9;
                    color: #ffff;
                }
        
                .cancel-icon {
                    height: 20px;
                    width: 20px;
                    position: absolute;
                    top: -20px;
                    left: 400px;
                    cursor: pointer;
                    display: inline-block;
                    background-size: contain;
                    background-repeat: no-repeat;
                }
        
            }
    }
    

    .boarding-form {
        // margin: 20px 0;
        padding: 29px 20px;
        width: 100%;
        display: flex;
        flex-direction: column;

        // background-color: #E3E9FF;
        // background-color:#E3E9FF;

        // div {
        //     margin-bottom: 10px;
        //     &.dropdown{
        //         margin-bottom: none !important;
        //     }
        // }

        .header {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }

        .heading {
            display: flex;
            justify-content: space-between;
            margin-top: 70px;
            width: 100%;

            .header-title {
                color: Black;
                font-size: 25px;
                margin: 30px 0;
                font-weight: bolder;
            }

            .form-status {
                width: 24%;

                .progress-status-bar {

                    .progressbar-container {
                        width: 400px;
                        display: flex;
                        margin-left: 40px;
                        
                    }

                    .progressbar {
                        counter-reset: step;
                       
                        
                        
                    }
                    .dlpdf{
                        margin-top: 60px;

                    }

                    .progressbar li {
                        list-style-type: none;
                        width: 30%;
                        display: flex;
                        float: left;
                        font-size: 12px;
                        position: relative;
                        text-align: center;
                        //text-transform: uppercase;
                        color: blue;
                        flex-direction: column;
                    }

                    .progressbar li:before {
                        width: 30px;
                        height: 30px;
                        content: counter(step);
                        counter-increment: step;
                        line-height: 30px;
                        border: 2px solid blue;
                        display: block;
                        text-align: center;
                        margin: 0 auto 10px auto;
                        border-radius: 50%;
                        background-color: white;
                        //  display: flex;
                        //  flex-direction: column;
                    }

                    .progressbar li:after {
                        width: 100%;
                        height: 2px;
                        content: '';
                        position: absolute;
                        background-color: #7d7d7d;
                        top: 15px;
                        left: -50%;
                        z-index: -1;
                        //  display: flex;
                        //  flex-direction: column;
                    }

                    .progressbar li:first-child:after {
                        content: none;
                        //  display: flex;
                        //  flex-direction: column;
                    }

                    .progressbar li.active {
                        color: blue;
                        display: flex;
                        flex-direction: column;
                    }

                    .progressbar li.active:before {
                        border-color: blue;
                        background-color: blue;
                        color: white;
                        //  display: flex;
                        //  flex-direction: column;
                    }

                    .progressbar li.active+li:after {
                        background-color: blue;
                        //  display: flex;
                        //  flex-direction: column;
                    }

                }
            }
        }

        .form-page-1 {
            display: flex;
            flex-direction: column;
            width: 100%;

            .first-table-company {
                background-color: white;
                border: 1px solid black;
                border-radius: 8px;
                margin-top: 10px;

            }

            .Table-header {
                display: flex;
                flex-direction: column;
                width: 100%;

                .declaration-content {
                    display: flex;
                    flex-direction: column;
                    padding-top: 20px;
                    width: 100%;
                }

                .declaration-inside-content {
                    display: flex;
                    padding-left: 20px;
                    width: 100%;
                    align-items: center;
                    padding-top: 5px;
                    margin-bottom: 10px;

                    .declaration-inside-content-name {
                        width: 20%;
                    }

                    .declaration-button {
                        height: 38px;
                        background-color: white;
                        color: #627fe9;
                        font-size: 14px;
                        font-weight: bold;
                        // border: none;
                        outline: none;
                        cursor: pointer;
                        margin-left: 20px;
                        border: 1px solid #627fe9;
                        align-items: center;
                        display: flex;
                        padding: 0 15px;
                        text-decoration: none;
                    }
                }

                .table-heading {
                    display: flex;
                    border-bottom: 1px solid black;
                    width: 100%;
                    padding: 20px 10px;
                }

                .first-table-body-company {
                    display: flex;
                    width: 100%;
                    justify-content: space-between;
                    padding: 10px 10px;

                    .new-vendor-input {
                        height: 35px;
                        width: 40%;
                        padding: 10px;
                        border: 1px solid #627FE9;
                    }

                    .upload-img {
                        //vertical-align: top;
                        text-align: right;
                        //display: inline-block;
                        width: 30%;
                    }

                }

                .second-table-body-company {
                    display: flex;
                    padding: 10px 10px;
                    justify-content: space-between;
                    width: 100%;

                    .contact-details-vendor-input {
                        height: 35px;
                        width: 80%;
                        padding: 10px;
                        border: 1px solid #627FE9;
                    }
                }

            }

            .files-link {
                color: #627fe9;
                text-decoration: none;
                margin-left: 15px;
            }

            .ml-250 {
                margin-left: 250px;
            }

            .company-details-table {
                background-color: white;
                border: 1px solid black;
                border-radius: 8px;
                margin-top: 10px;

                .Table-header {
                    display: flex;
                    flex-direction: column;
                    width: 100%;

                    .table-heading {
                        display: flex;
                        border-bottom: 1px solid black;
                        width: 100%;
                        padding: 20px 10px;
                    }

                    .company-details-body {
                        display: flex;
                        flex-direction: column;
                        padding: 10px 10px;
                        width: 100%;

                        .company-details-row {
                            display: flex;
                            justify-content: space-between;
                            width: 100%;
                            margin-top: 15px;

                            .company-details-vendor-input {
                                min-height: 35px;
                                width: 90%;
                                padding: 10px;
                                border: 1px solid #627FE9;
                            }

                            .company-details-vendor-select {
                                height: 35px;
                                width: 90%;
                            }

                            .singleDatePickerVendor {
                                .SingleDatePicker{
                                    border: 1px solid #627FE9;
                                    width: 90%;
                                    height: 35px;
            
                                    input{
                                        height: 30px;
                                        border: none;
                                        font-size: 14px;
                                    }
                                }
                            }
                        }

                        .turn-over-details {
                            display: flex;
                            flex-direction: column;
                            width: 100%;

                            .turn-over-details-row {
                                width: 40%;
                                display: flex;
                                justify-content: space-between;
                                margin-top: 10px;

                                .turn-vendor-input {
                                    height: 35px;
                                    width: 90%;
                                    padding: 10px;
                                    border: 1px solid #627FE9;
                                }

                                .turn-vendor-select {
                                    height: 35px;
                                    width: 90%;
                                }
                            }
                        }
                    }

                }
            }

            .gst-details-table {
                width: 100%;
                display: flex;
                flex-direction: column;
                background-color: white;
                border: 1px solid black;
                border-radius: 10px;
                margin-top: 15px;

                .gst-table-header {
                    display: flex;
                    flex-direction: column;
                    width: 100%;

                    .gst-table-heading {
                        display: flex;
                        width: 20%;
                        padding: 20px 10px;
                    }

                    .gst-details-vendor-input {
                        min-height: 30px;
                        width: 90%;
                        padding: 10px;
                        border: 1px solid #627FE9;
                    }

                    .gst-details-vendor-dropdown {
                        min-height: 35px;
                        width: 90%;
                    }
                }
            }

            .regional-office-details {
                background-color: white;
                border: 1px solid black;
                border-radius: 8px;
                margin-top: 10px;

                .regional-table-header {
                    display: flex;
                    flex-direction: column;
                    width: 100%;

                    .regional-table-heading {
                        display: flex;
                        border-bottom: 1px solid black;
                        width: 100%;
                        padding: 20px 10px;
                    }

                    .regional-table-body {
                        display: flex;
                        width: 100%;
                        justify-content: space-between;
                        padding: 10px 10px;

                        .regional-left-row {
                            width: 85%;
                            display: flex;
                            justify-content: space-between;

                            .regional-new-vendor-input {
                                height: 35px;
                                width: 90%;
                                padding: 10px;
                                border: 1px solid #627FE9;
                            }

                            .regional-details-vendor-select {
                                height: 35px;
                                width: 90%;
                            }
                        }
                    }
                }
            }

            .bank-details {
                background-color: white;
                border: 1px solid black;
                border-radius: 8px;
                margin-top: 10px;
                width: 100%;

                .bank-details-table-header {
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                }

                .bank-details-table-heading {
                    display: flex;
                    border-bottom: 1px solid black;
                    width: 100%;
                    padding: 20px 10px;
                }

                .bank-table-body {
                    display: flex;
                    width: 100%;
                    justify-content: space-between;
                    padding: 10px 10px;

                    .bank-new-vendor-input {
                        min-height: 35px;
                        width: 90%;
                        padding: 10px;
                        margin-top: 5px;
                        border: 1px solid #627FE9;
                    }


                    .bank-new-vendor-dropdown {
                        min-height: 35px;
                        width: 90%;
                    }
                }
            }


            .company-code-data {
                background-color: white;
                border: 1px solid black;
                border-radius: 8px;
                margin-top: 10px;
                width: 100%;

                .company-code-table-header {
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                }

                .company-code-table-heading {
                    display: flex;
                    border-bottom: 1px solid black;
                    width: 100%;
                    padding: 20px 10px;
                }

                .company-code-table-body {
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    justify-content: space-between;
                    padding: 10px 10px;

                    .company-code-new-vendor-input {
                        min-height: 35px;
                        width: 90%;
                        padding: 10px;
                        border: 1px solid #627FE9;
                    }

                    .company-code-new-vendor-dropdown {
                        min-height: 35px;
                        width: 90%;
                        // padding: 10px;
                    }
                }
            }



            .user-details {
                background-color: white;
                border: 1px solid black;
                border-radius: 8px;
                margin-top: 10px;

                .user-table-header {
                    display: flex;
                    flex-direction: column;
                    width: 100%;

                    .user-table-heading {
                        display: flex;
                        border-bottom: 1px solid black;
                        width: 100%;
                        padding: 20px 10px;
                    }

                    .user-table-body {
                        display: flex;
                        width: 100%;
                        justify-content: space-between;
                        padding: 10px 10px;

                        .user-left-row {
                            width: 85%;
                            display: flex;
                            justify-content: space-between;

                            .user-new-vendor-input {
                                height: 35px;
                                //height: max-content;
                                min-width: 90%;
                                padding: 10px;
                                border: 1px solid #627FE9;
                            }

                            .user-details-vendor-select {
                                height: 35px;
                                width: 90%;
                            }
                        }
                    }
                }
            }

            .customer-details-table {
                background-color: white;
                border: 1px solid black;
                border-radius: 8px;
                margin-top: 10px;

                .customer-table-header {
                    display: flex;
                    flex-direction: column;
                    width: 100%;

                    .customer-table-heading {
                        display: flex;
                        border-bottom: 1px solid black;
                        width: 100%;
                        padding: 20px 10px;
                    }

                    .customer-details-body {
                        display: flex;
                        flex-direction: column;
                        padding: 10px 10px;
                        width: 100%;

                        .customer-details-row {
                            display: flex;
                            justify-content: space-between;
                            width: 100%;
                            margin-top: 15px;

                            .customer-details-vendor-input {
                                height: 35px;
                                width: 90%;
                                padding: 10px;
                                border: 1px solid #627FE9;
                            }

                            .customer-details-vendor-select {
                                height: 35px;
                                width: 90%;
                            }
                        }

                        .customer-details-row2 {
                            display: flex;
                            justify-content: space-between;
                            width: 100%;
                            margin-top: 15px;

                            .customer-details-left {
                                display: flex;
                                width: 70%;

                                .customer-details-vendor-input2 {
                                    height: 35px;
                                    width: 80%;
                                    padding: 10px;
                                    border: 1px solid #627FE9;
                                }

                                .customer-details-vendor-select2 {
                                    height: 35px;
                                    width: 90%;
                                }
                            }
                        }
                    }

                }
            }

            .vendor-type-table {
                background-color: white;
                border: 1px solid black;
                border-radius: 8px;
                margin-top: 10px;

                .vendor-type-header {
                    display: flex;
                    flex-direction: column;
                    width: 100%;

                    .vendor-type-heading {
                        display: flex;
                        border-bottom: 1px solid black;
                        width: 100%;
                        padding: 20px 10px;
                    }

                    .vendor-table-body {
                        width: 100%;
                        padding: 10px 10px;
                        display: flex;
                        flex-wrap: wrap;

                        .vendor-type-select {
                            height: 35px;
                            width: 40%;
                            padding: 10px;
                            border: 1px solid #627FE9;
                        }
                    }
                }
            }
        }

        .vendor-basic-details .mb-20 {
            margin-bottom: 25px;
        }



        .multiSelect {
            .input-text-wrap {
                width: 100%;

                .select {
                    width: 100%;
                    margin-bottom: none;
                }
            }

        }

        .input-text-wrapper {
            width: 100%;

            .select {
                width: 100%;
                margin-bottom: none;
            }

            .vehicle-details {
                display: flex;
                width: 100%;
                flex-direction: column;

                .vehicle-details-row {
                    display: flex;
                    width: 70%;
                    justify-content: space-between;
                }

                .vehicle-details-input {
                    width: 60%;
                    border: 1px solid blue;
                    height: 35px;
                    padding: 10px
                }

                .vehicle-details-select {
                    width: 60%;
                    height: 50px
                }
            }
        }

        .product-handling-capability {
            // margin: 10px 10px 20px 10px;
            display: flex;
            flex-wrap: wrap;
            margin-left: 15px;
            margin-bottom: 10px
        }

        .input-text-wrap {
            height: 40px;
        }

        .vendor-input {
            height: 35px;
            width: 150px;
            padding: 10px;
            border: none;
            border-bottom: 1px solid #86868a;
        }

        .select .css-vj8t7z {
            font-size: 12px;
            height: 40px;
        }

        .upload-docs .button-classic {
            width: 300px;
        }

        .disabled {
            height: 40px;
            padding: 0 30px;
            border-radius: 25px;
            background-color: white;
            color: #b6ccf1;
            font-size: 16px;
            font-weight: bold;
            // border: none;
            outline: none;
            cursor: none;
            border: 1px solid #b6ccf1;
        }

        .react-datepicker__input-container input {
            width: 200px;
            border: none;
            font-size: 14px;
            border-bottom: 1px solid grey;
            padding: 6px 5px;
        }

        .custome-checkbox-wrap {
            margin-right: 8px;
            width: 18px;
            height: 18px;
            border-radius: 2px;
            border: 1px solid black;
            display: inline-block;
            position: relative;
            cursor: pointer;

            &.selected {
                border: 1px solid @tms-admin-color;
            }

            .inner-circle {
                width: 10px;
                height: 10px;
                border-radius: 15%;
                background: @tms-admin-color;
                position: absolute;
                top: 3px;
                left: 3px;
            }
        }

        .divTable {
            width: 90%;
            display: table;
            margin: 15px auto;
            border: 1px solid #528aea;

            .mb-20 {
                margin-bottom: 20px;
            }
        }

        .divTableRow {
            display: table-row;
        }

        .divTableHeading {
            color: white;
            font-weight: 800;
            background-color: #528aea;
            display: table-header-group;
        }

        .divTableCell,
        .divTableHead {
            border-bottom: 1px solid #e0e0e0;
            display: table-cell;
            padding: 5px 10px;
            font-size: 12px;
        }

        .divTableFoot {
            background-color: #EEE;
            display: table-footer-group;
            font-weight: bold;
        }

        .divTableBody {
            display: table-row-group;
            margin-top: 10px;
        }

        .app-icon {
            background-repeat: no-repeat;
            height: 20px;
            width: 20px;
            background-size: contain;
            cursor: pointer;
            margin-bottom: 0;
            vertical-align: top;
            display: inline-block;
        }

        .rej-icon {
            height: 24px;
            width: 24px;
            margin-top: -2px;
            background-repeat: no-repeat;
            background-size: contain;
            cursor: pointer;
            margin-bottom: 0;
            vertical-align: top;
            display: inline-block;
        }
    }


    .logo {
        display: inline-block;
        width: 70%;
        vertical-align: top;
    }

    .company-logo {
        width: 200px;
    }

    .vendor-basic-details {
        display: inline-block;
        width: 50%;
    }

    .grey {
        color: #86868a;
    }

    .theme {
        color: #5289ea;
    }

    .black {
        color: #1a2734;
    }

    .headerColor {
        color: black;
        border-bottom: 1px solid #85919e;
        padding: 12px 12px;
        font-size: 20px;

        span {
            margin-left: 5px;
        }

        // background-color: #d5e2f9;;
    }

    .onboarding-container {
        background: #fff;
        border: 1px solid #85919e;
        border-radius: 6px;

        .operational_area {
            display: flex;
            width: 100%;
            flex-wrap: wrap;
            margin-left: 15px;
        }

        .service-offered-container {
            display: flex;
            flex-direction: column;
            width: 20%;
            margin-top: 10px;
        }

    }

    .dInBlock {
        display: inline-block;
    }

    .VAlign {
        vertical-align: top;
    }

    .mt-5 {
        margin-top: 5px;
    }

    .mt-10 {
        margin-top: 10px;
    }

    .mt-15 {
        margin-top: 15px;
    }

    .mt-20 {
        margin-bottom: 20px;
    }

    .mt-25 {
        margin-top: 25px;
    }
    .mt-30 {
        margin-top: 30px;
    }

    .mt-40 {
        margin-top: 40px;
    }

    .mt-60 {
        margin-top: 60px;
    }

    .mb-5 {
        margin-bottom: 5px;
    }

    .mb-10 {
        margin-bottom: 10px;
    }

    .mb-15 {
        margin-bottom: 15px;
    }

    .mb-20 {
        margin-bottom: 20px;
    }

    .mb-25 {
        margin-bottom: 25px;
    }

    .mr-5 {
        margin-right: 5px;
    }

    .mr-10 {
        margin-right: 10px;
    }

    .mr-15 {
        margin-right: 15px;
    }

    .mr-20 {
        margin-right: 20px;
    }

    .mt-18 {
        margin-top: 18px
    }

    .mr-25 {
        margin-right: 25px;
    }

    .mwt-80 {
        min-width: 80px;
    }

    .mwt-120 {
        min-width: 120px;
    }

    .pt-10 {
        padding-top: 10px;
    }

    .wt-100 {
        width: 100px;
    }

    .wt-120 {
        width: 120px;
    }

    .wt-150 {
        width: 150px;
    }

    .wt-160 {
        width: 160px;
    }

    .ht-150 {
        height: 150px;
    }

    .ht-50 {
        height: 50px;
    }

    .ht-65 {
        height: 65px;
    }

    .ht-90 {
        height: 90px;
    }

    .wt-200 {
        width: 200px;
    }

    .wt-250 {
        width: 250px;
    }

    .wt-10p {
        width: 10%;
    }

    .wt-12p {
        width: 15%;
    }

    .wt-18p {
        width: 18%;
    }

    .wt-20p {
        width: 20%;
    }

    .wt-25p {
        width: 25%;
    }

    .wt-50p {
        width: 50%;
    }

    .wt-60p {
        width: 60%;
    }

    .wt-75p {
        width: 75%;
    }

    .wt-100p {
        width: 100%;
    }

    .mwt-170 {
        width: 170px;
    }

    .fRight {
        float: right;
    }

    .tCenter {
        text-align: center;
    }

    .tLeft {
        text-align: left;
    }

    .pointer {
        cursor: pointer;
    }

    .fs-13 {
        font-size: 13px;
    }

    .fs-15 {
        font-size: 15px;
    }

    .marginAuto {
        margin: auto;
    }

    .noBorder {
        border: none;
    }

    .fTop {
        vertical-align: top;
    }

    .checkbox-flex {
        display: flex;
        align-items: center;
    }

    .fMiddle {
        vertical-align: middle;
    }

    .area-name {
        font-size: 17px;
        color: #7d7d7d;
    }

    .borderLight {
        border: solid 1px #dadada;
    }

    .bg-light {

        background-color: #d5e2f9;
    }

    .table-container {
        display: flex;

        &:first-child {
            margin-left: 20px;
        }
    }

    .operational-area-text {
        color: #000;
        font-weight: 800;
        font-size: 16px;
    }

    .vendor-form-input {
        width: 200px;
        padding: 4px 10px;
        border: none;
        border-bottom: 1px solid grey;
        font-size: 14px;
        margin-bottom: 10px;
    }

    .counter-ip {
        padding: 3px;
        margin-right: 5px;
    }

    .success {
        color: #0c8434;
    }

    .error {
        color: red;
    }

    .vendor-name {
        color: gray;
        font-size: 10px;
        margin-bottom: 5px;
    }

    .history-icon {
        height: 15px;
        width: 15px;
        margin: 0 8px;
        cursor: pointer;
        display: inline-block;
        background-size: contain;
        background-repeat: no-repeat;
    }

    .pr {
        position: relative;
    }

    .history-block {
        width: 350px;
        max-height: 300px;
        overflow: scroll;
        position: absolute;
        padding: 15px;
        top: 16px;
        z-index: 2;
        background: #efeded;
        box-shadow: 0px 10px 20px 0px rgba(158, 170, 181, 0.45);
    }

    .scroll-table {
        height: 500px;
        overflow: scroll;
    }

    .table-border-none {
        border: none;
    }

    .qualification-table {
        border: none;
        border-bottom: 1px solid #868e96;
    }

    .tr-sanity {
        padding-left: 25px;

    }

    .tr-sanity-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 10px;
        margin: 10px;
        border-bottom: 1px solid #868e96;

    }

    .upload-button {
        float: right;
    }

    .disabled-upload {
        height: 40px;
        padding: 0 30px;
        border-radius: 5px;
        background-color: rgb(128, 137, 169);
        color: #fff;
        font-size: 16px;
        font-weight: bold;
        // border: none;
        outline: none;
        cursor: none;
        border: 1px solid #fff;
        pointer-events: none;
    }


    .button-classic-doc {
        height: 38px;
        padding: 0 15px;
        background-color: white;

        color: #627fe9;
        font-size: 16px;
        font-weight: bold;
        // border: none;
        outline: none;
        cursor: pointer;
        border: 1px solid #627fe9;
    }

    .vehicle-owned {
        color: black;
        border-bottom: 1px solid #85919e;
        padding: 12px 12px;
        font-size: 20px;

        span {
            margin-left: 5px;
        }

        // background-color: #d5e2f9;;
    }

}

.ml-20 {
    margin-left: 20px;
}
.ml-auto {
    margin-left:auto;
}

.vendor-comment-input {
    width: 1000px;
    padding: 4px 10px;
    border: none;
    // border-bottom: 1px solid grey;
    font-size: 14px;
    outline: none;
}

.flex {
    display: flex;
}

.head-popup{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0, 0.5);
    z-index: 20000;
    .head-popup-section{
      width: 400px;
      min-height: 200px;
      position: absolute;
      background: white;
      border-radius: 5px;
      text-align: center;
      padding: 20px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
 .approverflex{
    display: flex;
    justify-content:space-between;
  
 }

  .gst-table-body {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 10px 10px;

    .gst-table-left-row {
        width: 85%;
        display: flex;
        justify-content: space-between;

        .gst-table-new-vendor-input {
            height: 35px;
            width: 90%;
            padding: 10px;
            border: 1px solid #627FE9;
        }

        .gst-table-details-vendor-select {
            height: 35px;
            width: 90%;
        }
    }
}
.companyy-details-vendor-input {
    min-height: 30px;
    width: 30%;
    padding: 10px;
    border: 1px solid #627FE9;
}
.fs-15{
    font-size: 15px;
}
.DocsTab{
display: flex;
justify-content: space-between;
    
}
.exmptnbox{
    display: flex;
    
}
.VendorFormSAPcontainer{
      
    background-color: white;
    border: 1px solid grey;
    border-radius: 10px;
    height: 230px;
    width: 98%;
    margin-top: 30px;
    margin-left: 13px;

    .container-heading{
        font-size: 20px;
        font-weight: 500;
        line-height: 22px;
        margin-left: 20px;
        margin-top: 15px;
        margin-bottom: 15px;
    }

    .form-section {
        display: flex;
        margin-bottom: 20px; /* Add some spacing between form sections */
        margin-left: 20px;
        

        .form-item {
            display:grid;
            // align-items: center; /* Align items vertically in the center */
            margin-bottom: 5px;
            margin-left: 30px; /* Add some spacing between form items */
            .form-item label {
                width: 150px; /* Set the width of the labels (adjust as needed) */
                margin-right: 10px; /* Add some spacing between labels and inputs */
  }
  
}

}

}
.incotermsOptions{
    width: 200px;
}
.ml-200 {
    margin-left: 200px;
}
.ala-017 {
    min-height: 40px;
    width: 90%;
    padding: 10px;
    border: 1px solid #000000;
    box-sizing: border-box;
    font-family: "avenir-medium",sans-serif;
  }
  .modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    p{
        font-size: 20px;
    }
  }
  
  .modal-content {
    background-color: white;
    padding: 24px 60px 24px 60px;
    border-radius: 5px;
    font-weight: 14px;
    text-align: center;
  }
  
  .modal-content button {
    margin-top: 34px;
    padding: 8px 23px 8px 23px;
    border-radius: 6px;
    background-color: #0b0b50ad;
    color: white;
    border-color: #0b0b50ad;
  }