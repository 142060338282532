.rectangleTopL {
  // border: 1px solid red;
  // width: 200px;
  top: 0;
  left: 0;
  transform: translate(550px, 130px);
  z-index: 1;
  position: absolute;
  // height: 200px;
  width: 167px;
  height: 171px;
  flex-shrink: 0;
  border-radius: 10px;
  background: #7a98ff;
  opacity: 0.5;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  // box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset,
  //   rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
}
.rectangleTopS {
  // border: 1px solid red;
  // width: 200px;
  top: 0;
  left: 0;
  transform: translate(650px, 110px);
  z-index: 1;
  position: absolute;
  // height: 200px;
  width: 130px;
  height: 109px;
  flex-shrink: 0;
  // border-radius: 10px;
  // background: #7A98FF;
  border-radius: 15px;
  border: 1px dashed #6083ff;
  // box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.12);
}
.rectangleBottomL {
  // border: 1px solid red;
  // width: 200px;
  bottom: 0;
  right: 0;
  transform: translate(-570px, -120px);
  z-index: 1;
  position: absolute;
  // height: 200px;
  width: 154px;
  height: 154px;
  flex-shrink: 0;
  border-radius: 10px;
  border: 1px dashed #6083ff;
  // border-style: dashed;
  // background: #7A98FF;
  // opacity:0.5 ;
  // box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.12);
}
.rectangleBottomS {
  // border: 1px solid red;
  // width: 200px;
  bottom: 0;
  right: 0;
  transform: translate(-590px, -140px);
  z-index: 1;
  position: absolute;
  // height: 200px;
  width: 116px;
  height: 116px;
  flex-shrink: 0;
  // border-radius: 10px;
  background: #7a98ff;
  // box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset,
  //   rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
  border-radius: 10px;
  opacity: 0.5;
  // border: 1px solid #6083FF;
  box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.12);
}
.otpRectangleTopL {
  // border: 1px solid red;
  // width: 200px;
  top: 230px;
  left: 560px;
  z-index: 1;
  position: absolute;
  // height: 200px;
  width: 167px;
  height: 171px;
  flex-shrink: 0;
  border-radius: 10px;
  background: #7a98ff;
  opacity: 0.5;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  // box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset,
  //   rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
}
.otpRectangleTopS {
  // border: 1px solid red;
  // width: 200px;
  top: 210px;
  left: 650px;
  z-index: 1;
  position: absolute;
  // height: 200px;
  width: 130px;
  height: 109px;
  flex-shrink: 0;
  // border-radius: 10px;
  // background: #7A98FF;
  border-radius: 15px;
  border: 1px dashed #6083ff;
  // box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.12);
}
.otpRectangleBottomL {
  // border: 1px solid red;
  // width: 200px;
  bottom: 230px;
  right: 570px;
  z-index: 1;
  position: absolute;
  // height: 200px;
  width: 154px;
  height: 154px;
  flex-shrink: 0;
  border-radius: 10px;
  border: 1px dashed #6083ff;
  // border-style: dashed;
  // background: #7A98FF;
  // opacity:0.5 ;
  // box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.12);
}
.otpRectangleBottomS {
  // border: 1px solid red;
  // width: 200px;
  bottom: 250px;
  right: 590px;
  z-index: 1;
  position: absolute;
  // height: 200px;
  width: 116px;
  height: 116px;
  flex-shrink: 0;
  // border-radius: 10px;
  background: #7a98ff;
  // box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset,
  //   rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
  border-radius: 10px;
  opacity: 0.5;
  // border: 1px solid #6083FF;
  box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.12);
}


.changePasswordRectangleTopL {
  // border: 1px solid red;
  // width: 200px;
  top: 190px;
  left: 560px;
  z-index: 1;
  position: absolute;
  // height: 200px;
  width: 167px;
  height: 171px;
  flex-shrink: 0;
  border-radius: 10px;
  background: #7a98ff;
  opacity: 0.5;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  // box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset,
  //   rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
}
.changePasswordRectangleTopS {
  // border: 1px solid red;
  // width: 200px;
  top: 160px;
  left: 650px;
  z-index: 1;
  position: absolute;
  // height: 200px;
  width: 130px;
  height: 109px;
  flex-shrink: 0;
  // border-radius: 10px;
  // background: #7A98FF;
  border-radius: 15px;
  border: 1px dashed #6083ff;
  // box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.12);
}
.changePasswordRectangleBottomL {
  // border: 1px solid red;
  // width: 200px;
  bottom: 150px;
  right: 570px;
  z-index: 1;
  position: absolute;
  // height: 200px;
  width: 154px;
  height: 154px;
  flex-shrink: 0;
  border-radius: 10px;
  border: 1px dashed #6083ff;
  // border-style: dashed;
  // background: #7A98FF;
  // opacity:0.5 ;
  // box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.12);
}
.changePasswordRectangleBottomS {
  // border: 1px solid red;
  // width: 200px;
  bottom: 170px;
  right: 590px;
  z-index: 1;
  position: absolute;
  // height: 200px;
  width: 116px;
  height: 116px;
  flex-shrink: 0;
  // border-radius: 10px;
  background: #7a98ff;
  // box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset,
  //   rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
  border-radius: 10px;
  opacity: 0.5;
  // border: 1px solid #6083FF;
  box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.12);
}