// @import "../../../variables.less";
@import "../../../../variables.less";

.sub-navigation-panel-root{
    .sub-navigation-holder{
        display: flex;
        justify-content: space-between;
        border-bottom: 4px solid #f9f9f9;
        max-width: 370px;
        margin: 40px auto;
        font-size: 16px;
        .options{
            border-bottom: 4px solid #f9f9f9;
            padding: 5px 10px;
            cursor: pointer;
            margin-bottom: -4px;
        }
        .selected-option{
            color: @tms-admin-color;
            border-bottom: 4px solid;
        }
    }
}