.input-text-wrap{
    display: inline-block;
    vertical-align: middle;
    width: 33%;
    margin-bottom: 20px;
    .label{
        font-size: 10px;
        color: #868e96;
      //   text-transform: uppercase;
        margin-bottom: 10px;
    }
    .text-input{
        border: none;
        border-bottom: 1px solid #d8dbf0;
        padding-bottom: 13px;
        font-size: 16px;
        color: #212224;
        outline: none;
        width: 277px;
    }
    .pwd-input {
        display: inline-block;
    }
    .gen-btn {
        background-repeat: no-repeat;
        height: 20px;
        background-size: contain;
        cursor: pointer;
        width: 20px;
        display: inline-block;
        vertical-align: top;
    }
}
.error-font {
    font-size: 12px;
}

.input-text-wrap {
    input:read-only {
        border: none;
    }
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
}