    .item-table{
        margin-top: 60px;
        overflow: auto;
        text-align: center;
        width: 100%;
        .list-section {
            border-bottom: 1px solid rgba(212, 212, 212, 0.5);
            padding-bottom: 15px;
            padding-top: 15px;
        }
        .item-header {
                font-weight: 600;
                color: #363b40;
                font-size: 12px;
                display: inline-flex;
                width: 100%;
            &.column {
                color: #040506;
                font-weight: 900;
                font-size: 13px;
            }
            .item-label {
                width: 80px;
            }
            .product-label {
                width: 180px;
            }
            .chain {
                margin-left: 10px;
            }
            .volumetric {
                width: 90px;
            }

        }
    }

    .add-item-details {
        .item-select {
            display: inline-block;
            width: 277px;
        }
        .select-wrap {
            width: 33%;
            display: inline-block;
            vertical-align: baseline;
        }
        .label {
            font-size: 10px;
            color: #868e96;
            //text-transform: uppercase;
            margin-bottom: 10px;
        }
    }
    
    .submit-button {
        text-align: center;
        padding: 20px;
        float: right;
        .button-section {
            margin-left: 20px;
            float: right;
        }
    }
    .header {
        .button-classic {
            margin-left: 20px;
        }
    }

    .bacardi-item {
        .item-table {
            .item-header{
                .item-label {
                    width: 130px;
                }
                .product-label {
                    width: 210px;
                }
            }
        }
    }

    .Price {
        .rupee-icon {
            vertical-align: baseline;
        }
        .input-text-wrap{
            .text-input {
                width: 230px;
            }
        }
        
    }

    .Pfizer-Item {
        .delete-icon {
            height: 17px;
            width: 21px;
            margin: 0px;
        }
        .header {
            .button-classic {
                margin-left: 10px;
            }
        }
    } 

    .rateChart {
        .label {
            font-size: 24px;
            font-weight: 500;
            text-align: center;
            margin-bottom: 10px;
        }
        .cross-img {
            right: 16%;
            width: 15px;
            height: 15px;
            opacity: 0.4;
            cursor: pointer;
            background-size: contain;
            background-repeat: no-repeat;
        }
        .add-img {
            width: 24px;
            height: 22px;
            cursor: pointer;
            background-size: contain;
            background-repeat: no-repeat;
        }
        .action-save {
            border-radius: 5px;
            border: 1px solid #627FE9;
            background-color: #627FE9;
            color: white;
            font-size: 16px;
            font-weight: 500;
            width: 84px;
            text-align: center;
            padding: 8px 0px;
            cursor: pointer;
        }
        table {
            border: none;
            th, td {
                border: none;
            }
            th {
                font-size: 16px;
                font-weight: 500;
            }
            td {
                .DateInput_input {
                    border: 1px solid #627FE9;
                    width: 200px;
                    font-size: 14px;
                }
            }
        }
        .sku-table {
            th {
                border: 1px solid rgb(227, 233, 255);
                background-color: #627FE9;
                color: white;
                padding: 10px;
            }
            td {
                border: 1px solid rgb(227, 233, 255);
                padding: 5px;
                background-color: white;
                .DateInput_input {
                    width: 175px;
                }
            }
        }
    }