.main-container {
    width:100%;
    background-color: #E3E9FF;
    min-height:100vh;

    .boarding-form {
        padding: 29px 20px; 
        width:100%;
        display: flex;
        flex-direction: column;
        height:100%;
    
        .header{
            width:100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }
    
        .heading {
            display: flex;
            justify-content: space-between;
            margin-top: 70px;
            width:100%;
            .header-title {
                color: Black;
                font-size: 25px;
                margin: 30px 0;
                font-weight: bolder;
            }
        }
    
       .form-page-1 {
        display: flex;
        flex-direction: column;
        width:100%;

        .first-table{
            background-color: white;
            border: 1px solid black;
            border-radius:8px;
            margin-top:10px;
        }
        .Table-header{
            display: flex;
            flex-direction: column;
            width: 100%;

            .declaration-content{
                display: flex;
                flex-direction: column;
                padding-top: 20px;
                width: 100%;
            }
            .declaration-inside-content{
                display: flex;
                padding-left: 20px;
                width: 100%;
                align-items: center;
                padding-top: 5px;
                margin-bottom: 10px;

                .declaration-inside-content-name{
                    width: 20%;
                }

                .declaration-button{
                    height: 38px;
                    background-color: white;
                    color: #627fe9;
                    font-size: 14px;
                    font-weight: bold;
                    // border: none;
                    outline: none;
                    cursor: pointer;
                    margin-left: 20px;
                    border:1px solid #627fe9;
                    align-items: center;
                    display: flex;
                    padding: 0 15px;
                    text-decoration: none;
                }
            }

            .table-heading{
                display: flex;
                border-bottom: 1px solid black;
                width: 100%;
                padding: 20px 10px;
            }

            .first-table-body{
                display: flex;
                width: 100%;
                //justify-content: space-between;
                padding: 10px 10px;
                flex-wrap: wrap;

                .new-vendor-input{
                    height: 35px;
                    width: 100%;
                    padding: 10px;
                    border: 1px solid #627FE9;
                }


                .new-vendor-select{
                    height: 35px;
                    width: 100%;
                }

                .singleDatePicker {
                    .SingleDatePicker{
                        border: 1px solid #627FE9;
                        width: 100%;
                        height: 35px;

                        input{
                            height: 30px;
                            border: none;
                            font-size: 14px;
                        }
                    }
                }

            }

        }   
        .files-link{
            color: #627fe9;
            text-decoration: none;
            margin-left: 15px;
          }
          .ml-25{
            margin-left: 25px;
          }
     }

        
    }
   
    
    .grey {
        color: #86868a;
    }
    
    .theme {
        color: #5289ea;
    }
    
    .black {
        color: #1a2734;
    }
    
    .headerColor {
        color:  black;
        border-bottom: 1px solid #85919e;
        padding: 12px 12px;
      font-size: 20px;
        
      span{
        margin-left: 5px;
      }
        // background-color: #d5e2f9;;
    }
    .onboarding-container{
        background: #fff;
        border: 1px solid #85919e;
        border-radius: 6px;

        .operational_area{
            display: flex;
            width: 100%;
            flex-wrap: wrap;
            margin-left:15px ;
        }

        .service-offered-container{
            display: flex; 
           flex-direction: column;
            width:20%;
            margin-top: 10px;
        }

    }
    
    .dInBlock { 
        display: inline-block;
    }
    
    .VAlign {
        vertical-align: top;
    }
    
    .mt-5 {
        margin-top: 5px;
    }
    
    .mt-10 {
        margin-top: 10px;
    }
    
    .mt-15 {
        margin-top: 15px;
    }
    
    .mt-20 {
        margin-bottom: 20px;
    }
    
    .mt-25 {
        margin-top: 25px;
    }
    
    .mt-40 {
        margin-top: 40px;
    }
    .mt-60 {
        margin-top: 60px;
    }
    .mb-5 {
        margin-bottom: 5px;
    }
    
    .mb-10 {
        margin-bottom: 10px;
    }
    
    .mb-15 {
        margin-bottom: 15px;
    }
    
    .mb-20 {
        margin-bottom: 20px;
    }
    
    .mb-25 {
        margin-bottom: 25px;
    }
    
    .mr-5 {
        margin-right: 5px;
    }
    
    .mr-10 {
        margin-right: 10px;
    }
    
    .mr-15 {
        margin-right: 15px;
    }
    
    .mr-20 {
        margin-right: 20px;
    }
    .mt-18{
        margin-top:18px
    }
    
    .mr-25 {
        margin-right: 25px;
    }
    
    .mwt-80 {
        min-width: 80px;
    }
    
    .mwt-120 {
        min-width: 120px;
    }
    .pt-10 {
        padding-top: 10px;
    }
    .wt-100 {
        width: 100px;
    }
    .wt-120 {
        width: 120px;
    }
    
    .wt-150 {
        width: 150px;
    }
    
    .wt-160 {
        width: 160px;
    }
    
    .ht-150 {
        height: 150px;
    }
    .ht-50 {
        height: 50px;
    }
    .ht-65 {
        height: 65px;
    }
    .ht-90 {
        height: 90px;
    }
    .wt-200 {
        width: 200px;
    }
    
    .wt-250 {
        width: 250px;
    }
    .wt-10p{
        width: 10%;
    }
    .wt-12p{
        width: 15%;
    }
    .wt-18p{
        width: 18%;
    }
    .wt-20p{
        width: 20%;
    }
    .wt-25p{
        width: 25%;
    }
    .wt-50p{
        width: 50%;
    }
    .wt-60p{
        width: 60%;
    }
    .wt-75p{
        width: 75%;
    }
    .wt-100p{
        width: 100%;
    }
    .mwt-170{
        width: 170px;
    }
    .fRight {
        float: right;
    }
    
    .tCenter {
        text-align: center;
    }
    
    .tLeft {
        text-align: left;
    }
    
    .pointer {
        cursor: pointer;
    }
    
    .fs-13 {
        font-size: 13px;
    }
    .fs-15 {
        font-size: 15px;
    }
    
    .marginAuto {
        margin: auto;
    }
    .noBorder {
        border: none;
    }
    .fTop {
        vertical-align: top;
    }
    .checkbox-flex{
        display: flex;
        align-items: center;
    }
    .fMiddle {
        vertical-align: middle;
    }
    .area-name{
        font-size: 17px;
        color: #7d7d7d;
    }
    .borderLight {
        border: solid 1px #dadada;
    }
    .bg-light {
    
    background-color: #d5e2f9;
    }
    .table-container{
        display: flex;

        &:first-child{
            margin-left: 20px;
        }
    }
    .operational-area-text{
           color: #000;
       font-weight: 800;
       font-size: 16px;
    }
    .vendor-form-input {
        width: 200px;
        padding: 4px 10px;
        border: none;
        border-bottom: 1px solid grey;
        font-size: 14px;
        margin-bottom: 10px;
    }
    
    .counter-ip {
        padding: 3px;
        margin-right: 5px;
    }
    .success {
        color: #0c8434;
    }
    .error {
        color: red;
    }
    
    .vendor-name {
        color: gray;
        font-size: 10px;
        margin-bottom: 5px;
    }
    
    .history-icon {
        height: 15px;
        width: 15px;
        margin: 0 8px;
        cursor: pointer;
        display: inline-block;
        background-size: contain;
        background-repeat: no-repeat;
    }
    
    .pr {
        position: relative;
    }
    
    .history-block {
        width: 350px;
        max-height: 300px;
        overflow: scroll;
        position: absolute;
        padding: 15px;
        top: 16px;
        z-index: 2;
        background: #efeded;
        box-shadow: 0px 10px 20px 0px rgba(158, 170, 181, 0.45);
    }
    
    .scroll-table {
        height: 500px;
        overflow: scroll;
    }

    .table-border-none {
        border: none;
    }
    
    .qualification-table {
        border: none;
        border-bottom: 1px solid #868e96;
    }

    .tr-sanity {
        padding-left: 25px;
        
    }
    .tr-sanity-container{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 10px ;
        margin: 10px;
        border-bottom: 1px solid #868e96;

    }

    .upload-button{
        float: right;
    }
    .disabled-upload{
        height: 40px;
        padding: 0 30px;
        border-radius: 5px;
        background-color: rgb(128,137,169);
        color: #fff;
        font-size: 16px;
        font-weight: bold;
        // border: none;
        outline: none;
        cursor: none;
        border: 1px solid #fff;
    }


    .button-classic-doc{
        height: 38px;
        padding: 0 15px;
        background-color: white;
        
        color: #627fe9;
        font-size: 16px;
        font-weight: bold;
        // border: none;
        outline: none;
        cursor: pointer;
        border: 1px solid #627fe9;
    }
}

.ml-20{
    margin-left: 20px;
}

.vendor-comment-input {
    width: 1000px;
    padding: 4px 10px;
    border: none;
    // border-bottom: 1px solid grey;
    font-size: 14px;
    outline: none;
}

.wt-30p{
    width:30%
}

.wt-25p{
    width:25%
}

.wt-45p{
    width:45%
}

.disabled-download {
    height: 30px;
    //padding: 0 30px;
    background-color: white;
    color: #b6ccf1;
    font-size: 14px;
    font-weight: bold;
    // border: none;
    outline: none;
    cursor: none;
    border: 1px solid #b6ccf1;
}