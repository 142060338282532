.discount-wrap{
    .wrapper{
        width: 1280px;
        margin: 0 auto;
        .home-title{
            font-size: 12px;
            color: #86868a;
        }
        .sub-heading-section{
            margin-top: 22px;
            font-family: 'avenir-medium',sans-serif;
            font-weight: 900;
            overflow: auto;
            .sub-heading{
                font-size: 24px;
                color: #282626;
                float: left;
            }
            .add-company-section{
                float: right;
                margin-right: 30px;
            }
        }
        .search-filter-section{
            clear: both;
            margin-top: 25px;
        }
        .company-list-wrap{
            margin-top: 60px;
            .no-records-section{
                margin-top: 40px;
            }
            .list-section{
                border-bottom: 1px solid rgba(212, 212, 212, 0.5);
                padding-bottom: 25px;
                padding-top: 15px;
                .checkbox-section{
                    display: inline-block;
                    vertical-align: middle;
                }
                .company-name-section{
                    display: inline-block;
                    vertical-align: middle;
                    margin-left: 10px;
                    width: 370px;
                    &.table-header {
                        margin-left: 85px;
                        width: 290px;
                    }
                    .comany-logo ,.compnay-details{
                        display: inline-block;
                        vertical-align: middle;
                        cursor: pointer;
                    }
                    .comany-logo{
                        width: 50px;
                        height: 35px;
                        background-size: contain;
                        background-repeat: no-repeat;
                    }
                    .compnay-details{
                        margin-left: 25px;
                        color: #1a2734;
                        font-size: 15px;
                        font-weight: 900;
                        width: 220px;
                        .date{
                            color: #86868a;
                            font-size: 12px;
                        }

                        .name {
                            word-break: break-word;
                            display: -webkit-box;
                            overflow: hidden;
                            -webkit-line-clamp: 2;
                            -webkit-box-orient: vertical;
                            text-overflow: ellipsis;
                        }
                    }
                }
                .admin-details{
                    display: inline-block;
                    vertical-align: middle;
                    width: 340px;
                    font-size: 15px;
                    color: #1a2734;

                    &.table-header {
                        margin-left: 8px;
                        width: 337px;
                    }
                    .admin-email{
                        font-size: 15px;
                        color: #1a2734;
                        cursor: pointer;
                    }
                    .admin-pan{
                        font-size: 12px;
                        color: #86868a;
                    }
                }
                .account-type{
                    display: inline-block;
                    vertical-align: middle;
                    width: 140px;
                    font-size: 15px;
                    color: #1a2734;
                }
                .status{
                    display: inline-block;
                    vertical-align: middle;
                    width: 100px;
                }
            }
            .list-section{
                &.header{
                    font-weight: 600;
                    padding-top: 0;
                    color: #1a2734;
                    font-size: 14px;
                }
            }
            .list-section{
                &.content:hover{
                    border: 1px solid rgba(0, 89, 251, 0.38);
                    box-shadow: 0px 10px 20px 0px rgba(129, 172, 212, 0.45);
                    div, div .date {
                        /*color: #528aea;*/
                        color: black
                    }
                    .company-name-section .compnay-details .name {
                        display: block;
                    }
                }

            }
        }
    }
}
.select{
    display: inline-block;
    width: 150px;
}