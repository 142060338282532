.advance-filter {
    width: 12%;
    margin: 10px;
    padding: 10px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 8px 0px, rgba(0, 0, 0, 0.17) 0px 7px 20px 0px;
    position: absolute;
    background: white;
    left: 82%;
    z-index: 10000;
}
.tag-elements {
    border: 1px solid grey;
    margin-right: 5px;
    margin-top: 5px;
    padding: 4px 10px 2px;
    border-radius: 15px;
    font-size: 14px;
    display: inline-block;
    cursor: pointer;
}
.tag-elements-active {
    border: 1px solid grey;
    margin-right: 5px;
    margin-top: 5px;
    padding: 4px 10px 2px;
    border-radius: 15px;
    display: inline-block;
    background: black;
    color: white;
    font-size: 14px;
    cursor: pointer;
}
.advance-filter-title {
    border-bottom: 2px solid #dfdfdf;
    padding-left: 5px;
}
.m-5 {
    margin: 5px;
}
.m-t-0 {
    margin-top: 0px;
}
.m-t-10 {
    margin-top: 10px;
}
.h-l {
    border: 1px solid #dfdfdf;
}
.apply-filter-button {
    cursor: pointer;
    height: 30px;
    padding: 0 20px;
    color: #fff;
    background: #528aea;
    margin: 0px 20px;
    display: inline-block;
    border: 1px solid #528aea;
    font-size: 16px;
}
.align-center {
    text-align: center;
}