.pod-cycle {
    .home-title{
        font-size: 12px;
        color: #86868a;
    }
    .sub-heading-section{
        margin-top: 22px;
        font-family: 'avenir-medium',sans-serif;
        font-weight: 900;
        overflow: auto;
        .sub-heading{
            font-size: 24px;
            color: #282626;
            float: left;
        }
        .add-company-section{
            float: right;
            margin-right: 30px;
        }
    }
    .search-filter-section{
        clear: both;
        margin-top: 25px;
    }
    .company-list-wrap{
        margin-top: 60px;
        .no-records-section{
            margin-top: 40px;
        }
        .list-section{
            border-bottom: 1px solid rgba(212, 212, 212, 0.5);
            .checkbox-section{
                display: inline-block;
                vertical-align: middle;
            }
            .company-name-section{
                display: inline-block;
                vertical-align: middle;
                margin-left: 10px;
                width: 370px;
                &.table-header {
                    margin-left: 85px;
                    width: 290px;
                }
                .comany-logo ,.compnay-details{
                    display: inline-block;
                    vertical-align: middle;
                    cursor: pointer;
                }
                .comany-logo{
                    width: 50px;
                    height: 35px;
                    background-size: contain;
                    background-repeat: no-repeat;
                }
                .compnay-details{
                    margin-left: 25px;
                    color: #1a2734;
                    font-size: 15px;
                    font-weight: 900;
                    width: 220px;
                    .date{
                        color: #86868a;
                        font-size: 12px;
                    }

                    .name {
                        word-break: break-word;
                        display: -webkit-box;
                        overflow: hidden;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                        text-overflow: ellipsis;
                    }
                }
            }
            .admin-details{
                display: inline-block;
                vertical-align: middle;
                width: 340px;
                font-size: 15px;
                color: #1a2734;

                &.table-header {
                    margin-left: 8px;
                    width: 337px;
                }
                .admin-email{
                    font-size: 15px;
                    color: #1a2734;
                    cursor: pointer;
                }
                .admin-pan{
                    font-size: 12px;
                    color: #86868a;
                }
            }
            .account-type{
                display: inline-block;
                vertical-align: middle;
                width: 140px;
                font-size: 15px;
                color: #1a2734;
            }
            .status{
                display: inline-block;
                vertical-align: middle;
                width: 100px;
            }
        }
        .list-section{
            &.header{
                font-weight: 600;
                padding-top: 0;
                color: #1a2734;
                font-size: 14px;
            }
        }
        .list-section{
            &.content:hover{
                border: 1px solid rgba(0, 89, 251, 0.38);
                box-shadow: 0px 10px 20px 0px rgba(129, 172, 212, 0.45);
                div, div .date {
                    /*color: #528aea;*/
                    color: black;
                }
                .company-name-section .compnay-details .name {
                    display: block;
                }
            }

        }
    }
    .action-icon {
        background-repeat: no-repeat;
        height: 18px;
        width: 18px;
        background-size: contain;
        margin: auto !important;
        cursor: pointer;
        display: inline-block;
        padding: 5px;
    }
    .m-b-10 {
        margin-bottom: 10px;
    }
    .pod-cell {
        border: 1px solid #dedede;
        border-radius: 5px;
        padding: 5px;
    }
    .w-p-100 {
        width: 100%;
    }
    .pod-status-cell {
        width: 60%;
    }
    .info {
        margin-left: 5px;
        border: 1px solid;
        padding-top: 2px;
        padding-right: 8px;
        background: #80808085;
        border-radius: 10px;
        padding-left: 8px;
        color: white;
        cursor: pointer;
        margin-top: -3px;
    }
    .divTableCell {
        vertical-align: middle;
    }
    .tooltip {
        position: absolute;
        display: inline-block;
      }
      
      .tooltip .tooltiptext {
        visibility: hidden;
        width: 600px;
        background-color: #b9b6b6;
        color: black;
        text-align: left;
        border-radius: 4px;
        padding: 10px;
        position: absolute;
        z-index: 1;
        bottom: 125%;
        right: 80%;
        opacity: 0;
        transition: opacity 0.3s;
        font-size: 14px;
      }
      
      .tooltip:hover .tooltiptext {
        visibility: visible;
        opacity: 1;
      }
      .w-p-5 {
        width: 5% !important;
        }
      .w-p-10 {
          width: 10% !important;
      }
      .w-p-20 {
        width: 20% !important;
      }
      .w-p-50 {
          width: 100% !important;
      }
    .select{
    display: inline-block;
    width: 150px;
    }
}