.cookie-card-wrapper {
    position: fixed;
    bottom: 40px;
    left: 20px;
    width: 400px;
    color: #333;
    background-color: white;
    padding: 16px;
    text-align: center;
    font-family: Arial, sans-serif;
    z-index: 20;
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;

    .cookie-card-info-box {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .cookie-icon {
            font-size: 30px;
            margin-bottom: 15px;
        }

        .cookie-description {
            margin: 6px 0;
            font-size: 14px;
            max-width: 400px;
            margin-bottom: 16px;
            text-align: left;
            line-height: 17px;
            text-size-adjust: 2;
            // letter-spacing:1px
            .fontBold {
                font-weight: 700 !important;
                color: black;
            }

        }

        .cookie-policy-link {
            margin-left: 6px;
            color: #597ef7;
            text-decoration: none;
        }

        .cookie-action-wrapper {
            display: flex;
            justify-content: flex-end;
            gap: 10px;
            width: 100%;
        }

        .btn {
            padding: 8px 18px;
            font-size: 14px;
            border: none;
            cursor: pointer;
            transition: background-color 0.3s ease;
   

            &.accept-btn {
                background-color: #597ef7;
                color: white;
                border-radius: 5px;
            }

            &.Preferences-btn {
                color: #597ef7;
                background-color: transparent;
                border-radius: 5px;
                border: 1.5px solid #597ef7;
                
            }

            &.customize-btn {
                background-color: #555;
                color: white;
                border-radius: 5px;
                margin-top: 10px;
            }

            &:hover {
                opacity: 0.8;
            }
        }
    }
}