.days-hours-min-root{
    display: flex;
    .days-hours-holder{
        position: relative;
        margin-right: 14px;
        .dhm-input{
            width: 60px;
            padding: 7px;
        }
        .dhm-label{
            position: absolute;
            top: -16px;
            font-family: 'Avenir', sans-serif;
            font-size: 10px;
            font-weight: 500;
            color : #86868a;
        }
    }
}