@import url(https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
.home-wrap .wrapper {
  width: 1280px;
  margin: 0 auto;
}
.home-wrap .wrapper .home-title {
  font-size: 12px;
  color: #86868a;
}
.home-wrap .wrapper .sub-heading-section {
  margin-top: 22px;
  font-family: 'avenir-medium', sans-serif;
  font-weight: 900;
  overflow: auto;
}
.home-wrap .wrapper .sub-heading-section .sub-heading {
  font-size: 24px;
  color: #282626;
  float: left;
}
.home-wrap .wrapper .sub-heading-section .add-company-section {
  float: right;
  margin-right: 30px;
}
.home-wrap .wrapper .sub-heading-section .button-classic {
  margin-left: 5px;
}
.home-wrap .wrapper .search-filter-section {
  clear: both;
  margin-top: 25px;
}
.home-wrap .wrapper .company-list-wrap {
  margin-top: 60px;
}
.home-wrap .wrapper .company-list-wrap .no-records-section {
  margin-top: 40px;
}
.home-wrap .wrapper .company-list-wrap .list-section {
  border-bottom: 1px solid rgba(212, 212, 212, 0.5);
  padding-bottom: 25px;
  padding-top: 15px;
}
.home-wrap .wrapper .company-list-wrap .list-section .checkbox-section {
  display: inline-block;
  vertical-align: middle;
}
.home-wrap .wrapper .company-list-wrap .list-section .company-name-section {
  display: inline-block;
  vertical-align: middle;
  margin-left: 10px;
  width: 370px;
}
.home-wrap .wrapper .company-list-wrap .list-section .company-name-section.table-header {
  margin-left: 85px;
  width: 290px;
}
.home-wrap .wrapper .company-list-wrap .list-section .company-name-section .comany-logo,
.home-wrap .wrapper .company-list-wrap .list-section .company-name-section .compnay-details {
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
}
.home-wrap .wrapper .company-list-wrap .list-section .company-name-section .comany-logo {
  width: 50px;
  height: 35px;
  background-size: contain;
  background-repeat: no-repeat;
}
.home-wrap .wrapper .company-list-wrap .list-section .company-name-section .compnay-details {
  margin-left: 25px;
  color: #1a2734;
  font-size: 15px;
  font-weight: 900;
  width: 220px;
}
.home-wrap .wrapper .company-list-wrap .list-section .company-name-section .compnay-details .date {
  color: #86868a;
  font-size: 12px;
}
.home-wrap .wrapper .company-list-wrap .list-section .company-name-section .compnay-details .name {
  word-break: break-word;
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}
.home-wrap .wrapper .company-list-wrap .list-section .admin-details {
  display: inline-block;
  vertical-align: middle;
  width: 340px;
  font-size: 15px;
  color: #1a2734;
}
.home-wrap .wrapper .company-list-wrap .list-section .admin-details.table-header {
  margin-left: 8px;
  width: 337px;
}
.home-wrap .wrapper .company-list-wrap .list-section .admin-details .admin-email {
  font-size: 15px;
  color: #1a2734;
  cursor: pointer;
}
.home-wrap .wrapper .company-list-wrap .list-section .admin-details .admin-pan {
  font-size: 12px;
  color: #86868a;
}
.home-wrap .wrapper .company-list-wrap .list-section .account-type {
  display: inline-block;
  vertical-align: middle;
  width: 140px;
  font-size: 15px;
  color: #1a2734;
}
.home-wrap .wrapper .company-list-wrap .list-section .status {
  display: inline-block;
  vertical-align: middle;
  width: 100px;
}
.home-wrap .wrapper .company-list-wrap .list-section.header {
  font-weight: 600;
  padding-top: 0;
  color: #1a2734;
  font-size: 14px;
}
.home-wrap .wrapper .company-list-wrap .list-section.content:hover {
  border: 1px solid rgba(0, 89, 251, 0.38);
  box-shadow: 0px 10px 20px 0px rgba(129, 172, 212, 0.45);
}
.home-wrap .wrapper .company-list-wrap .list-section.content:hover div,
.home-wrap .wrapper .company-list-wrap .list-section.content:hover div .date {
  color: #528aea;
}
.home-wrap .wrapper .company-list-wrap .list-section.content:hover .company-name-section .compnay-details .name {
  display: block;
}
.select {
  display: inline-block;
  width: 150px;
}
.flex {
  display: flex;
}
.contract-tenure input {
  text-align: center;
}
.contract-tenure .input-text-wrap {
  width: 50%;
}
.contract-tenure .input-text-wrap .text-input {
  width: 100%;
}
.approver-matrix {
  display: flex;
  justify-content: center;
}

@font-face {
  font-family: 'avenir-black';
  src: url(/teg/media/AvenirLTStd-Black.b1abb878.otf) format("otf"), url(/teg/media/AvenirLTStd-Black.d74d87dd.woff) format("woff");
  font-style: normal;
  font-weight: normal;
}
@font-face {
  font-family: 'avenir-medium';
  src: url(/teg/media/AvenirLTStd-Medium.4f995fa4.otf) format("otf"), url(/teg/media/AvenirLTStd-Medium.5ae9e0cb.woff) format("woff");
  font-style: normal;
  font-weight: normal;
}
@font-face {
  font-family: 'avenir-light';
  src: url(/teg/media/AvenirLTStd-Light.0b78ec9d.otf) format("otf"), url(/teg/media/AvenirLTStd-Light.4a3e0c40.woff) format("woff");
  font-style: normal;
  font-weight: normal;
}
@font-face {
  font-family: 'icomoon';
  src: url(/teg/media/icomoon.3e3bce86.eot);
  src: url(/teg/media/icomoon.3e3bce86.eot#iefix) format('embedded-opentype'), url(/teg/media/icomoon.6b3a8502.ttf) format('truetype'), url(/teg/media/icomoon.1aa45d56.woff) format('woff'), url(/teg/media/icomoon.ba086aff.svg#icomoon) format('svg');
  font-weight: normal;
  font-style: normal;
}
[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-bills:before {
  content: "\E900";
}
.icon-broadcast:before {
  content: "\E901";
}
.icon-group-2 .path1:before {
  content: "\E902";
}
.icon-group-2 .path2:before {
  content: "\E903";
  margin-left: -2.41210938em;
}
.icon-group-2 .path3:before {
  content: "\E904";
  margin-left: -2.41210938em;
}
.icon-home:before {
  content: "\E905";
}
.icon-master:before {
  content: "\E906";
}
.icon-reports:before {
  content: "\E907";
}
.icon-vehicle-modification:before {
  content: "\E908";
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "avenir-medium", 'Poppins';
}
abbr {
  text-decoration: none;
}
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px white inset;
}
.page-title {
  font-size: 12px;
  color: #1a2734;
}
.page-title .home {
  cursor: pointer;
}
.home-navigation-holder-sub {
  min-width: 500px!important;
  max-width: 650px!important;
  margin: 30px auto!important;
}
.home-navigation-holder {
  display: flex;
  justify-content: space-between;
  border-bottom: 4px solid #f0f0f0;
  min-width: 885px;
  max-width: 920px;
  margin: 30px 0px;
  color: #1a2734;
  font-weight: 600;
  font-family: 'avenir-light';
}
.home-navigation-holder.provider {
  display: block;
}
.home-navigation-holder.provider .options {
  display: inline-block;
  vertical-align: middle;
}
.home-navigation-holder .options {
  width: 200px;
  border-bottom: 4px solid #f0f0f0;
  padding: 5px 6px;
  cursor: pointer;
  margin-bottom: -4px;
  font-family: 'avenir-light';
}
.home-navigation-holder .selected-option {
  width: 200px;
  color: #528aea;
  border-bottom: 4px solid;
}
.error-section {
  float: left;
  color: #D8000C;
}
.submit-section-pagination {
  margin-top: 30px;
}
.submit-section-pagination .next {
  float: right;
  margin-right: 30px;
}
.submit-section-pagination .pre {
  float: left;
}
.mode-navigation-holder {
  display: flex;
  border-bottom: 4px solid #f0f0f0;
  width: 180px;
  margin: 30px 0px;
  color: #1a2734;
  font-weight: 600;
  font-family: 'avenir-light';
}
.mode-navigation-holder.provider {
  display: block;
}
.mode-navigation-holder.provider .options {
  display: inline-block;
  vertical-align: middle;
}
.mode-navigation-holder .options {
  border-bottom: 4px solid #f0f0f0;
  padding: 5px 6px;
  cursor: pointer;
  margin-bottom: -4px;
  font-family: 'avenir-light';
}
.mode-navigation-holder .selected-option {
  color: #528aea;
  border-bottom: 4px solid;
}
.side-panel-wrap {
  width: 250px;
  display: inline-block;
  height: 100vh;
  position: fixed;
  background: white;
  vertical-align: top;
  font-family: "avenir-medium", sans-serif;
  z-index: 1;
  overflow: scroll;
}
.side-panel-wrap .header-section {
  text-align: center;
  cursor: pointer;
  margin-top: 23px;
  height: 100px;
}
.side-panel-wrap .header-section .img {
  height: 34px;
  width: 82px;
  background-size: contain;
  background-repeat: no-repeat;
  display: inline-block;
  color: red;
}
.side-panel-wrap .header-section .header-content {
  font-size: 12px;
  color: #86868a;
  margin-top: 5px;
}
.side-panel-wrap .panel-option-section {
  background: #f0f3f7;
  min-height: 800px;
  padding-top: 30px;
}
.side-panel-wrap .panel-option-section .options-section .masterDataIcon {
  cursor: pointer;
}
.side-panel-wrap .panel-option-section .options-section .showMenus {
  padding-left: 40px;
}
.side-panel-wrap .panel-option-section .options-section .showMenus .option {
  margin-bottom: 0;
}
.side-panel-wrap .panel-option-section .options-section .option {
  color: #86868a;
  text-decoration: none;
  display: block;
  height: 30px;
  line-height: 35px;
  padding-left: 20px;
  margin-bottom: 10px;
}
.side-panel-wrap .panel-option-section .options-section .active {
  color: #528aea;
  text-decoration: none;
  background: #ffffff;
  border-right: 4px solid #528aea;
}
.side-panel-wrap .panel-option-section .options-section .icon-section {
  display: inline-block;
  vertical-align: middle;
  font-size: 15px;
  width: 22px;
}
.side-panel-wrap .panel-option-section .options-section .icon-name {
  display: inline-block;
  vertical-align: middle;
  margin-left: 10px;
  font-size: 14px;
  font-family: "avenir-medium", sans-serif !important;
}
.side-panel-wrap .user-details {
  padding: 10px 20px 10px 35px;
}
.side-panel-wrap .user-details .user-icon {
  background: #d4d4d4;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  display: inline-block;
  vertical-align: middle;
}
.side-panel-wrap .user-details .user-name {
  display: inline-block;
  vertical-align: text-bottom;
  margin-left: 10px;
  font-size: 14px;
  color: #86868a;
}
.side-panel-wrap .user-details .logout {
  display: inline-block;
  vertical-align: text-bottom;
  font-size: 14px;
  margin-left: 50px;
  cursor: pointer;
}
.pointer-event {
  pointer-events: none;
}

@font-face {
  font-family: 'avenir-black';
  src: url(/teg/media/AvenirLTStd-Black.b1abb878.otf) format("otf"), url(/teg/media/AvenirLTStd-Black.d74d87dd.woff) format("woff");
  font-style: normal;
  font-weight: normal;
}
@font-face {
  font-family: 'avenir-medium';
  src: url(/teg/media/AvenirLTStd-Medium.4f995fa4.otf) format("otf"), url(/teg/media/AvenirLTStd-Medium.5ae9e0cb.woff) format("woff");
  font-style: normal;
  font-weight: normal;
}
@font-face {
  font-family: 'avenir-light';
  src: url(/teg/media/AvenirLTStd-Light.0b78ec9d.otf) format("otf"), url(/teg/media/AvenirLTStd-Light.4a3e0c40.woff) format("woff");
  font-style: normal;
  font-weight: normal;
}
@font-face {
  font-family: 'icomoon';
  src: url(/teg/media/icomoon.3e3bce86.eot);
  src: url(/teg/media/icomoon.3e3bce86.eot#iefix) format('embedded-opentype'), url(/teg/media/icomoon.6b3a8502.ttf) format('truetype'), url(/teg/media/icomoon.1aa45d56.woff) format('woff'), url(/teg/media/icomoon.ba086aff.svg#icomoon) format('svg');
  font-weight: normal;
  font-style: normal;
}
[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-bills:before {
  content: "\E900";
}
.icon-broadcast:before {
  content: "\E901";
}
.icon-group-2 .path1:before {
  content: "\E902";
}
.icon-group-2 .path2:before {
  content: "\E903";
  margin-left: -2.41210938em;
}
.icon-group-2 .path3:before {
  content: "\E904";
  margin-left: -2.41210938em;
}
.icon-home:before {
  content: "\E905";
}
.icon-master:before {
  content: "\E906";
}
.icon-reports:before {
  content: "\E907";
}
.icon-vehicle-modification:before {
  content: "\E908";
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "avenir-medium", 'Poppins';
}
abbr {
  text-decoration: none;
}
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px white inset;
}
.page-title {
  font-size: 12px;
  color: #1a2734;
}
.page-title .home {
  cursor: pointer;
}
.home-navigation-holder-sub {
  min-width: 500px!important;
  max-width: 650px!important;
  margin: 30px auto!important;
}
.home-navigation-holder {
  display: flex;
  justify-content: space-between;
  border-bottom: 4px solid #f0f0f0;
  min-width: 885px;
  max-width: 920px;
  margin: 30px 0px;
  color: #1a2734;
  font-weight: 600;
  font-family: 'avenir-light';
}
.home-navigation-holder.provider {
  display: block;
}
.home-navigation-holder.provider .options {
  display: inline-block;
  vertical-align: middle;
}
.home-navigation-holder .options {
  width: 200px;
  border-bottom: 4px solid #f0f0f0;
  padding: 5px 6px;
  cursor: pointer;
  margin-bottom: -4px;
  font-family: 'avenir-light';
}
.home-navigation-holder .selected-option {
  width: 200px;
  color: #528aea;
  border-bottom: 4px solid;
}
.error-section {
  float: left;
  color: #D8000C;
}
.submit-section-pagination {
  margin-top: 30px;
}
.submit-section-pagination .next {
  float: right;
  margin-right: 30px;
}
.submit-section-pagination .pre {
  float: left;
}
.mode-navigation-holder {
  display: flex;
  border-bottom: 4px solid #f0f0f0;
  width: 180px;
  margin: 30px 0px;
  color: #1a2734;
  font-weight: 600;
  font-family: 'avenir-light';
}
.mode-navigation-holder.provider {
  display: block;
}
.mode-navigation-holder.provider .options {
  display: inline-block;
  vertical-align: middle;
}
.mode-navigation-holder .options {
  border-bottom: 4px solid #f0f0f0;
  padding: 5px 6px;
  cursor: pointer;
  margin-bottom: -4px;
  font-family: 'avenir-light';
}
.mode-navigation-holder .selected-option {
  color: #528aea;
  border-bottom: 4px solid;
}
.header-panel {
  width: 1280px;
  margin: 0 auto;
  height: 80px;
  display: block;
  position: fixed;
  background: white;
  vertical-align: top;
  border-bottom: 2px solid #528aea;
  box-shadow: 0 0 27px 4px rgba(69, 68, 68, 0.07);
  font-family: "avenir-medium", sans-serif;
  z-index: 1;
}
.header-panel .logo-section {
  display: inline-block;
  width: 20%;
  text-align: left;
}
.header-panel .user-detail-section {
  display: inline-block;
  width: 80%;
  text-align: right;
}
.header-panel .logo-section {
  text-align: center;
  cursor: pointer;
  height: 100%;
  padding: 10px;
}
.header-panel .logo-section .img {
  height: 50px;
  width: 175px;
  background-size: contain;
  background-repeat: no-repeat;
  display: inline-block;
  color: red;
}
.header-panel .logo-section .header-content {
  font-size: 12px;
  color: #86868a;
  margin-top: 5px;
}
.header-panel .user-detail-section {
  padding: 23px 18px;
  vertical-align: top;
  height: 100%;
}
.header-panel .user-detail-section .user-icon {
  height: 28px;
  width: 28px;
  border-radius: 50%;
  display: inline-block;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-size: contain;
}
.header-panel .user-detail-section .user-name {
  display: inline-block;
  vertical-align: middle;
  margin-left: 10px;
  font-size: 14px;
}
.header-panel .user-detail-section .logout {
  display: inline-block;
  font-size: 14px;
  margin-left: 30px;
  cursor: pointer;
}
.header-panel .user-detail-section .logout:hover {
  color: #528aea;
}
.header-panel .user-detail-section .logout-icon {
  width: 22px;
  height: 22px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
  background-repeat: no-repeat;
}

.search-text-wrap {
  display: inline-block;
  margin-right: 10px;
  position: relative;
}
.search-text-wrap .text-search {
  width: 300px;
  height: 40px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  background-color: #ffffff;
  outline: none;
  text-decoration: none;
  border: none;
  border: 1px solid #d4d4d4;
  padding-left: 12px;
  font-size: 14px;
}
.search-text-wrap .text-search::-moz-placeholder {
  color: #d4d4d4;
}
.search-text-wrap .text-search:-ms-input-placeholder {
  color: #d4d4d4;
}
.search-text-wrap .text-search::-webkit-input-placeholder {
  color: #d4d4d4;
}
.search-text-wrap .button-search {
  width: 50px;
  height: 40px;
  background: #528aea;
  color: white;
  font-size: 16px;
  border: none;
  outline: none;
  cursor: pointer;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.search-text-wrap .cross-img {
  position: absolute;
  right: 25%;
  top: 30%;
  width: 15px;
  height: 15px;
  opacity: 0.4;
  cursor: pointer;
  background-size: contain;
  background-repeat: no-repeat;
}

.button-classic {
  height: 40px;
  padding: 0 30px;
  border-radius: 25px;
  background-color: white;
  color: #528aea;
  font-size: 16px;
  font-weight: bold;
  outline: none;
  cursor: pointer;
  border: 1px solid #528aea;
}
.button-classic.incomplete {
  color: white;
}
.button-section .button-classic {
  margin: 0 10px;
}
input.button-classic[disabled] {
  background: #f0f0f0;
  cursor: not-allowed;
  opacity: 0.5;
}

.button-default {
  cursor: pointer;
  padding: 0 15px;
  border-radius: 20px;
  border: 1px solid #528aea;
  background: #528aea;
  color: #fff;
  min-width: 76px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  display: inline-block;
  vertical-align: bottom;
  margin-bottom: 10px;
}
.wt-33p {
  width: 33%;
}
.m-10 {
  margin: 10px;
}
.p-10 {
  padding: 10px;
}
.tCenter {
  text-align: center;
}

@font-face {
  font-family: 'avenir-black';
  src: url(/teg/media/AvenirLTStd-Black.b1abb878.otf) format("otf"), url(/teg/media/AvenirLTStd-Black.d74d87dd.woff) format("woff");
  font-style: normal;
  font-weight: normal;
}
@font-face {
  font-family: 'avenir-medium';
  src: url(/teg/media/AvenirLTStd-Medium.4f995fa4.otf) format("otf"), url(/teg/media/AvenirLTStd-Medium.5ae9e0cb.woff) format("woff");
  font-style: normal;
  font-weight: normal;
}
@font-face {
  font-family: 'avenir-light';
  src: url(/teg/media/AvenirLTStd-Light.0b78ec9d.otf) format("otf"), url(/teg/media/AvenirLTStd-Light.4a3e0c40.woff) format("woff");
  font-style: normal;
  font-weight: normal;
}
@font-face {
  font-family: 'icomoon';
  src: url(/teg/media/icomoon.3e3bce86.eot);
  src: url(/teg/media/icomoon.3e3bce86.eot#iefix) format('embedded-opentype'), url(/teg/media/icomoon.6b3a8502.ttf) format('truetype'), url(/teg/media/icomoon.1aa45d56.woff) format('woff'), url(/teg/media/icomoon.ba086aff.svg#icomoon) format('svg');
  font-weight: normal;
  font-style: normal;
}
[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-bills:before {
  content: "\E900";
}
.icon-broadcast:before {
  content: "\E901";
}
.icon-group-2 .path1:before {
  content: "\E902";
}
.icon-group-2 .path2:before {
  content: "\E903";
  margin-left: -2.41210938em;
}
.icon-group-2 .path3:before {
  content: "\E904";
  margin-left: -2.41210938em;
}
.icon-home:before {
  content: "\E905";
}
.icon-master:before {
  content: "\E906";
}
.icon-reports:before {
  content: "\E907";
}
.icon-vehicle-modification:before {
  content: "\E908";
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "avenir-medium", 'Poppins';
}
abbr {
  text-decoration: none;
}
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px white inset;
}
.page-title {
  font-size: 12px;
  color: #1a2734;
}
.page-title .home {
  cursor: pointer;
}
.home-navigation-holder-sub {
  min-width: 500px!important;
  max-width: 650px!important;
  margin: 30px auto!important;
}
.home-navigation-holder {
  display: flex;
  justify-content: space-between;
  border-bottom: 4px solid #f0f0f0;
  min-width: 885px;
  max-width: 920px;
  margin: 30px 0px;
  color: #1a2734;
  font-weight: 600;
  font-family: 'avenir-light';
}
.home-navigation-holder.provider {
  display: block;
}
.home-navigation-holder.provider .options {
  display: inline-block;
  vertical-align: middle;
}
.home-navigation-holder .options {
  width: 200px;
  border-bottom: 4px solid #f0f0f0;
  padding: 5px 6px;
  cursor: pointer;
  margin-bottom: -4px;
  font-family: 'avenir-light';
}
.home-navigation-holder .selected-option {
  width: 200px;
  color: #528aea;
  border-bottom: 4px solid;
}
.error-section {
  float: left;
  color: #D8000C;
}
.submit-section-pagination {
  margin-top: 30px;
}
.submit-section-pagination .next {
  float: right;
  margin-right: 30px;
}
.submit-section-pagination .pre {
  float: left;
}
.mode-navigation-holder {
  display: flex;
  border-bottom: 4px solid #f0f0f0;
  width: 180px;
  margin: 30px 0px;
  color: #1a2734;
  font-weight: 600;
  font-family: 'avenir-light';
}
.mode-navigation-holder.provider {
  display: block;
}
.mode-navigation-holder.provider .options {
  display: inline-block;
  vertical-align: middle;
}
.mode-navigation-holder .options {
  border-bottom: 4px solid #f0f0f0;
  padding: 5px 6px;
  cursor: pointer;
  margin-bottom: -4px;
  font-family: 'avenir-light';
}
.mode-navigation-holder .selected-option {
  color: #528aea;
  border-bottom: 4px solid;
}
.main-wrap {
  width: 100%;
}
.main-wrap .main-content {
  padding: 40px;
  padding-right: 0px;
  width: 1020px;
  margin-left: 20%;
  display: block;
  margin-bottom: 100px;
}
.panel-body-contents {
  padding-top: 80px;
}

.switch {
  position: relative;
  display: inline-block;
  vertical-align: top;
}
.switch label {
  width: 40px;
  height: 18px;
  position: absolute;
  background-color: #f2f2f2;
  top: 0;
  left: 0;
  border-radius: 50px;
  border: solid 1.5px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}
.switch input[type="checkbox"] {
  visibility: hidden;
}
.switch label:after {
  content: '';
  width: 16px;
  height: 16px;
  background-color: #d4d4d4;
  border-radius: 50px;
  top: -2px;
  left: 0px;
  position: absolute;
  transition: all 0.3s;
  border: solid 0.5px rgba(0, 0, 0, 0.1);
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.05), 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 1px 0 rgba(0, 0, 0, 0.05);
}
.switch input[type="checkbox"]:checked + label:before {
  color: white;
  content: '' !important;
  text-align: center;
}
.switch input[type="checkbox"]:checked + label:after {
  left: 20px;
  background-color: #00c251;
}
.switch input[type="checkbox"]:checked + label {
  background-color: #e9f9f0;
}

.add-company-wrap .overlay {
  position: fixed;
  z-index: 10;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(27, 33, 67, 0.9);
}
.add-company-wrap .modal-popup {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 5px;
  width: 1065px;
  min-height: 600px;
  max-height: 600px;
  background-color: white;
  z-index: 11;
  overflow: scroll;
  padding: 40px 47px 15px 47px;
}
.add-company-wrap .modal-popup .heading {
  font-size: 24px;
  font-weight: 400;
  margin-bottom: 30px;
}
.add-company-wrap .modal-popup .form-section {
  margin-bottom: 42px;
}
.add-company-wrap .modal-popup .select-relevant-category-section {
  margin-bottom: 50px;
  display: inline-grid;
  width: 30%;
}
.add-company-wrap .modal-popup .select-relevant-category-section.sub-category .section-item {
  white-space: nowrap;
}
.add-company-wrap .modal-popup .select-relevant-category-section.sub-category .section-item .title {
  white-space: normal;
}
.add-company-wrap .modal-popup .select-relevant-category-section.sub-services .section-item {
  white-space: nowrap;
}
.add-company-wrap .modal-popup .select-relevant-category-section.sub-services .section-item .title {
  white-space: normal;
}
.add-company-wrap .modal-popup .select-relevant-category-section .header {
  display: inline-block;
  vertical-align: top;
  color: #528aea;
  font-weight: 600;
  border-bottom: 1px solid;
  padding-bottom: 15px;
  margin-bottom: 20px;
}
.add-company-wrap .modal-popup .select-relevant-category-section .section-item-company {
  display: inline-block;
  vertical-align: super;
  color: #535356;
  font-size: 16px;
  text-transform: capitalize;
}
.add-company-wrap .modal-popup .select-relevant-category-section .section-item-company .title {
  display: inline-block;
  vertical-align: top;
  margin: 3px 14px;
}
.add-company-wrap .modal-popup .select-relevant-category-section .section-item {
  display: inline-block;
  vertical-align: super;
  padding: 5px;
  color: #535356;
  font-size: 16px;
  text-transform: capitalize;
}
.add-company-wrap .modal-popup .select-relevant-category-section .section-item .title {
  display: inline-block;
  vertical-align: top;
  margin: 3px 14px;
}
.add-company-wrap .modal-popup .submit-section {
  float: right;
}
.add-company-wrap .modal-popup .submit-section .button-section {
  margin-left: 20px;
  float: right;
}
.custome-checkbox-wrap {
  width: 20px;
  height: 20px;
  border-radius: 5px;
  border: 1px solid #d9d9d9;
  display: inline-block;
  position: relative;
  cursor: pointer;
}
.custome-checkbox-wrap.selected {
  border: 1px solid #528aea;
}
.custome-checkbox-wrap .inner-circle {
  width: 10px;
  height: 10px;
  border-radius: 15%;
  background: #528aea;
  position: absolute;
  top: 4px;
  left: 4px;
}
.margin-right {
  margin-right: 20px;
}
.no-records {
  padding: 10px;
  font-size: 16px;
  text-align: center;
}
.error {
  color: red;
}
.input-text-align-top .input-text-wrap {
  vertical-align: top;
}
.delete-company-wrap .overlay {
  position: fixed;
  z-index: 10;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(27, 33, 67, 0.9);
}
.delete-company-wrap .modal-popup1 {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 5px;
  width: 500px;
  min-height: 200px;
  background-color: white;
  z-index: 11;
  padding: 40px 80px 15px 72px;
  text-align: center;
  margin-top: 20px;
}
.delete-company-wrap .modal-popup1 .question {
  margin-top: 20px;
  font-size: 20px;
}
.delete-company-wrap .modal-popup1 .submit-section {
  float: right;
}
.delete-company-wrap .modal-popup1 .submit-section .button-section {
  margin-left: 10px;
  float: right;
}

.input-text-wrap {
  display: inline-block;
  vertical-align: middle;
  width: 33%;
  margin-bottom: 20px;
}
.input-text-wrap .label {
  font-size: 10px;
  color: #868e96;
  margin-bottom: 10px;
}
.input-text-wrap .text-input {
  border: none;
  border-bottom: 1px solid #d8dbf0;
  padding-bottom: 13px;
  font-size: 16px;
  color: #212224;
  outline: none;
  width: 277px;
}
.input-text-wrap .pwd-input {
  display: inline-block;
}
.input-text-wrap .gen-btn {
  background-repeat: no-repeat;
  height: 20px;
  background-size: contain;
  cursor: pointer;
  width: 20px;
  display: inline-block;
  vertical-align: top;
}
.error-font {
  font-size: 12px;
}
.input-text-wrap input:read-only {
  border: none;
}
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}

.loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 15;
}
.loader .loader-img {
  background-image: url(/teg/media/loading.032bf6d9.gif);
  width: 120px;
  height: 120px;
  background-size: contain;
  display: inline-block;
  background-repeat: no-repeat;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.msg-pop-up {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 13;
}
.msg-pop-up .msg-section {
  width: 400px;
  min-height: 200px;
  position: absolute;
  background: white;
  border-radius: 5px;
  text-align: center;
  padding: 40px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.msg-pop-up .msg-section .msg {
  margin-bottom: 35px;
  margin-top: 35px;
}
.msg-pop-up .msg-section .msg.error {
  color: #D8000C;
}

.navigation-panel-root {
  font-size: 16px;
}
.navigation-panel-root .navigation-holder {
  display: flex;
  justify-content: space-between;
  border-bottom: 4px solid #f9f9f9;
  max-width: 885px;
  margin: 30px 0px;
}
.navigation-panel-root .navigation-holder .options {
  border-bottom: 4px solid #f9f9f9;
  padding: 5px 10px;
  cursor: pointer;
  margin-bottom: -4px;
}
.navigation-panel-root .navigation-holder .selected-option {
  color: #528aea;
  border-bottom: 4px solid;
}
body {
  -webkit-box-sizing: border-box;
}

.sub-navigation-panel-root .sub-navigation-holder {
  display: flex;
  justify-content: space-between;
  border-bottom: 4px solid #f9f9f9;
  max-width: 370px;
  margin: 40px auto;
  font-size: 16px;
}
.sub-navigation-panel-root .sub-navigation-holder .options {
  border-bottom: 4px solid #f9f9f9;
  padding: 5px 10px;
  cursor: pointer;
  margin-bottom: -4px;
}
.sub-navigation-panel-root .sub-navigation-holder .selected-option {
  color: #528aea;
  border-bottom: 4px solid;
}

.master {
  width: 1280px;
  margin: 0 auto;
}
.success {
  color: #0c8434;
}
.error {
  color: red;
}
.search-text-wrap .text-search {
  width: 190px !important;
}

.route-import-root {
  max-width: 900px;
}
.route-import-root .container-1 {
  margin: 20px 0px;
}
.route-import-root .container-1 .file-dropdown-holder {
  display: flex;
}
.route-import-root .container-1 .file-dropdown-holder .file-upload {
  margin-left: 20px;
}
.route-import-root .container-1 .banner {
  background: #528aea;
  color: #fff;
  font-family: 'Avenir', sans-serif;
  font-size: 20px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  padding: 13px 24px;
}
.route-import-root .container-1 .header {
  color: #1a2734;
  font-family: 'Avenir', sans-serif;
  font-size: 12px;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  margin: 25px 0px;
}
.route-import-root .container-1 .sub-container {
  display: flex;
}
.route-import-root .container-1 .sub-container .file-holder {
  width: 300px;
  height: 26px;
  border-radius: 2px;
  background-color: #f9f9f9;
  border: 1px solid #cccccc;
  cursor: pointer;
  position: relative;
}
.route-import-root .container-1 .sub-container .file-holder input {
  width: 300px;
  height: 26px;
  opacity: 0;
  cursor: pointer;
}
.route-import-root .container-1 .sub-container .file-holder .file-choose {
  position: absolute;
  right: 9px;
  top: 4px;
  font-family: 'SFProDisplay', sans-serif;
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.3px;
  cursor: pointer;
  color: #528aea;
}
.route-import-root .container-1 .sub-container .file-holder .file-name {
  font-family: 'Avenir-Roman', sans-serif;
  font-size: 12px;
  position: absolute;
  top: 3px;
  left: 12px;
  color: #86868a;
}
.route-import-root .container-1 .generate-button {
  cursor: pointer;
  border: 1px solid #528aea;
  background: #528aea;
  margin: 0px 20px;
  color: #fff;
  padding: 10px;
  font-size: 14px;
  border-radius: 20px;
}
.error {
  color: red;
}

.shipment-root {
  max-width: 920px;
}
.shipment-root .banner {
  background: #528aea;
  color: #fff;
  font-family: 'Avenir', sans-serif;
  font-size: 20px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  padding: 13px 24px;
}
.shipment-root .header {
  color: #1a2734;
  font-family: 'Avenir', sans-serif;
  font-size: 12px;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  margin: 25px 0px;
}

.item-table {
  margin-top: 60px;
  overflow: auto;
  text-align: center;
  width: 100%;
}
.item-table .list-section {
  border-bottom: 1px solid rgba(212, 212, 212, 0.5);
  padding-bottom: 15px;
  padding-top: 15px;
}
.item-table .item-header {
  font-weight: 600;
  color: #363b40;
  font-size: 12px;
  display: inline-flex;
  width: 100%;
}
.item-table .item-header.column {
  color: #040506;
  font-weight: 900;
  font-size: 13px;
}
.item-table .item-header .item-label {
  width: 80px;
}
.item-table .item-header .product-label {
  width: 180px;
}
.item-table .item-header .chain {
  margin-left: 10px;
}
.item-table .item-header .volumetric {
  width: 90px;
}
.add-item-details .item-select {
  display: inline-block;
  width: 277px;
}
.add-item-details .select-wrap {
  width: 33%;
  display: inline-block;
  vertical-align: baseline;
}
.add-item-details .label {
  font-size: 10px;
  color: #868e96;
  margin-bottom: 10px;
}
.submit-button {
  text-align: center;
  padding: 20px;
  float: right;
}
.submit-button .button-section {
  margin-left: 20px;
  float: right;
}
.header .button-classic {
  margin-left: 20px;
}
.bacardi-item .item-table .item-header .item-label {
  width: 130px;
}
.bacardi-item .item-table .item-header .product-label {
  width: 210px;
}
.Price .rupee-icon {
  vertical-align: baseline;
}
.Price .input-text-wrap .text-input {
  width: 230px;
}
.Pfizer-Item .delete-icon {
  height: 17px;
  width: 21px;
  margin: 0px;
}
.Pfizer-Item .header .button-classic {
  margin-left: 10px;
}
.rateChart .label {
  font-size: 24px;
  font-weight: 500;
  text-align: center;
  margin-bottom: 10px;
}
.rateChart .cross-img {
  right: 16%;
  width: 15px;
  height: 15px;
  opacity: 0.4;
  cursor: pointer;
  background-size: contain;
  background-repeat: no-repeat;
}
.rateChart .add-img {
  width: 24px;
  height: 22px;
  cursor: pointer;
  background-size: contain;
  background-repeat: no-repeat;
}
.rateChart .action-save {
  border-radius: 5px;
  border: 1px solid #627FE9;
  background-color: #627FE9;
  color: white;
  font-size: 16px;
  font-weight: 500;
  width: 84px;
  text-align: center;
  padding: 8px 0px;
  cursor: pointer;
}
.rateChart table {
  border: none;
}
.rateChart table th,
.rateChart table td {
  border: none;
}
.rateChart table th {
  font-size: 16px;
  font-weight: 500;
}
.rateChart table td .DateInput_input {
  border: 1px solid #627FE9;
  width: 200px;
  font-size: 14px;
}
.rateChart .sku-table th {
  border: 1px solid #e3e9ff;
  background-color: #627FE9;
  color: white;
  padding: 10px;
}
.rateChart .sku-table td {
  border: 1px solid #e3e9ff;
  padding: 5px;
  background-color: white;
}
.rateChart .sku-table td .DateInput_input {
  width: 175px;
}

.account-master .header {
  background: #528aea;
  color: #fff;
  padding: 10px 12px;
  font-family: 'Avenir', sans-serif;
  font-size: 18px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  margin: 10px 0px;
}
.account-master .list-section {
  font-weight: 600;
  color: #1a2734;
  font-size: 15px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgba(212, 212, 212, 0.5);
}
.account-master .list-section .table-header {
  width: 50%;
  padding: 10px 0px;
  margin-left: 30px;
}
.account-master .list-section .table-row {
  width: 50%;
  margin-left: 30px;
  padding: 10px 0px;
}
.account-master .list-section .event-id {
  width: 20%;
}
.account-master .list-section .event {
  width: 60%;
}
.account-master .list-section .action {
  width: 20%;
}
.account-master .list-section .rfq-input {
  width: 100%;
  height: 3;
  min-height: 33px;
  font-size: 16px;
  border: 1px solid #cccccc;
  border-radius: 6px 5px 5px 5px;
  padding: 7px 9px;
}
.account-master .list-section .delete-icon {
  margin-top: 10px;
}
.account-master .approval-btn {
  text-align: right;
  margin-right: 50px;
  margin-top: 20px;
}
.account-master .approval-btn .button-classic {
  margin-right: 10px;
}
.account-master .event-overlay {
  z-Index: 1050;
}

.transporter-list {
  width: 100%;
  display: flex;
}
.transporter-list .search-text-wrap .text-search {
  margin-bottom: 10px;
  margin-left: 10px;
}
.transporter-list .col-2 {
  width: 48%;
  box-shadow: 0 0 27px 4px rgba(69, 68, 68, 0.07);
  max-height: 720px;
  overflow: scroll;
}
.transporter-list .header {
  padding: 10px;
  text-align: center;
  display: flex;
  color: #528aea;
  justify-content: space-between;
  height: 40px;
}
.transporter-list .header .add-button {
  padding: 2px 18px;
  height: 20px;
}
.transporter-list .lists {
  padding: 5px 10px;
  display: flex;
  border-top: 1px solid hsla(0, 0%, 59.2%, 0.2);
}
.transporter-list .ml-10 {
  margin-left: 10px;
}
.transporter-list .mr-10 {
  margin-right: 10px;
}
.transporter-list .space-btw {
  justify-content: space-between;
}
.transporter-mapping {
  text-align: center;
}
.transporter-mapping .section-item-company {
  display: inline-block;
  vertical-align: super;
  color: #535356;
  font-size: 16px;
  text-transform: capitalize;
  width: 20%;
}
.transporter-mapping .section-item-company.ftl {
  width: 11%;
}
.transporter-mapping .section-item-company .title {
  display: inline-block;
  vertical-align: top;
  margin: 3px 10px;
}
.transporter-mapping .custome-checkbox-wrap {
  width: 20px;
  height: 20px;
  border-radius: 5px;
  border: 1px solid #d9d9d9;
  display: inline-block;
  position: relative;
  cursor: pointer;
}
.transporter-mapping .custome-checkbox-wrap.selected {
  border: 1px solid #528aea;
}
.transporter-mapping .custome-checkbox-wrap .inner-circle {
  width: 10px;
  height: 10px;
  border-radius: 15%;
  background: #528aea;
  position: absolute;
  top: 4px;
  left: 4px;
}

.add-item-details .item-select {
  display: inline-block;
  width: 277px;
}
.add-item-details .select-wrap {
  width: 33%;
  display: inline-block;
  vertical-align: baseline;
}
.add-item-details .label {
  font-size: 10px;
  color: #868e96;
  margin-bottom: 10px;
}
.add-item-details .label1 {
  font-size: 10px;
  color: #868e96;
  margin-bottom: 10px;
  margin-top: 30px;
}
.item-table .item-header .item-label {
  width: 98px;
}
.add-user-wrap .modal-popup .pop-heading {
  background-color: #f4f4f4;
  padding: 18px;
  font-family: 'avenir', sans-serif;
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #528aea;
  text-align: center;
  margin-bottom: 30px;
  margin-top: 30px;
}
.add-user-wrap .modal-popup .pop-heading.select-wrap.data-picke.label {
  margin-top: 30px;
}
.text-orange {
  color: orange;
}
.text-red {
  color: red;
}
.theme-color {
  color: #528aea;
}

.item-table .item-header .item-label {
  width: 100px;
}
.add-item-details .label {
  font-size: 10px;
  color: #868e96;
  margin-bottom: 10px;
  margin-top: 25px;
}
.error-color {
  color: red;
}

.reconciliation .list-section {
  font-weight: 600;
  color: #1a2734;
  font-size: 15px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgba(212, 212, 212, 0.5);
}
.reconciliation .list-section .table-header {
  width: 50%;
  padding: 10px 0px;
  margin-left: 30px;
}
.reconciliation .list-section .table-row {
  width: 50%;
  margin-left: 30px;
  padding: 10px 0px;
}
.reconciliation .list-section .event-id {
  width: 20%;
}
.reconciliation .list-section .dropdownInput {
  width: 20%;
  height: 40px;
  border: 1px solid lightgray;
  margin-top: 2px;
  outline: none;
  border-radius: 4px;
  padding: 5px;
}
.reconciliation .list-section .event {
  width: 60%;
}
.reconciliation .list-section .action {
  width: 20%;
  cursor: pointer;
}
.reconciliation .list-section .rfq-input {
  width: 100%;
  height: 3;
  min-height: 33px;
  font-size: 16px;
  border: 1px solid #cccccc;
  border-radius: 6px 5px 5px 5px;
  padding: 7px 9px;
}
.reconciliation .list-section .delete-icon {
  margin-top: 10px;
}
.reconciliation .approval-btn {
  text-align: right;
  margin-right: 50px;
  margin-bottom: 20px;
}
.reconciliation .approval-btn .button-classic {
  margin-right: 10px;
}
.reconciliation .debit-master .late-delivery {
  margin-top: 10px;
  padding: 10px;
}
.reconciliation .debit-master .late-delivery .grace {
  display: inline-block;
  width: 33%;
}
.reconciliation .debit-master .late-delivery .grace .input-text-wrap {
  width: 30%;
}
.reconciliation .debit-master .late-delivery .grace .input-text-wrap .text-input {
  width: 92px;
}
.reconciliation .debit-master .late-delivery .amount {
  display: inline-block;
  width: 23%;
}
.reconciliation .debit-master .late-delivery .amount .input-text-wrap {
  width: 30%;
}
.reconciliation .debit-master .late-delivery .amount .text-input {
  width: 180px;
}
.reconciliation .debit-master .late-delivery .select-wrap {
  display: inline-block;
  vertical-align: middle;
  width: 33%;
  margin-bottom: 20px;
}
.reconciliation .debit-master .late-delivery .select-wrap .label {
  font-size: 10px;
  color: #868e96;
  margin-bottom: 10px;
}
.reconciliation .debit-master .late-delivery .select-wrap .select {
  width: 277px;
}
.reconciliation .debit-master .non-register-vendor .list-section {
  border: none;
}
.reconciliation .debit-master .non-register-vendor .list-section .table-row {
  padding: 10px 0px;
}

.navigation-panel-root {
  font-size: 16px;
}
.navigation-panel-root .navigation-holder {
  display: flex;
  justify-content: left;
  border-bottom: 4px solid #f9f9f9;
  max-width: 885px;
  margin: 30px 0px;
}
.navigation-panel-root .navigation-holder .options {
  border-bottom: 4px solid #f9f9f9;
  padding: 5px 10px;
  cursor: pointer;
  margin-bottom: -4px;
}
.navigation-panel-root .navigation-holder .selected-option {
  color: #528aea;
  border-bottom: 4px solid;
}
body {
  -webkit-box-sizing: border-box;
}

.generate-button {
  display: inline;
  cursor: pointer;
  border: 1px solid #528aea;
  background: #528aea;
  margin: 0px 23px;
  color: white;
  padding: 10px;
  font-size: 14px;
  border-radius: 20px;
  text-align: center;
  max-width: 250px;
}
.generate-button-default {
  display: inline;
  cursor: pointer;
  border: 1px solid #528aea;
  background: #528aea;
  margin: 0px 23px;
  color: white;
  padding: 10px;
  font-size: 14px;
  border-radius: 20px;
  text-align: center;
  max-width: 250px;
}
.generate-button-default-clicked {
  display: inline;
  cursor: not-allowed;
  border: 1px solid lightcoral;
  background: lightcoral;
  margin: 0px 23px;
  color: white;
  padding: 10px;
  font-size: 14px;
  border-radius: 20px;
  text-align: center;
  max-width: 250px;
}
.main {
  padding: 10px;
  padding-right: 0px;
  margin-left: 20%;
  display: block;
}
.btn-cls {
  padding: 5px 0;
  padding-left: 9px;
  background: none;
  border: none;
  cursor: pointer;
  margin-left: 20px;
  font-size: 16px;
  border-left: 1px solid lightgray;
}
.element {
  margin-bottom: 15px;
  margin-top: 15px;
}
.text-input {
  border: none;
  padding-bottom: 13px;
  font-size: 16px;
  outline: none;
  width: 277px;
  border-bottom: 1px solid lightgray;
}
.text-input-content {
  border: 1px solid #d8dbf0;
  font-size: 16px;
  color: #212224;
  outline: black;
  width: 350px;
  padding: 10px;
}
.list {
  background-color: white;
  font-size: 14px;
  border-radius: 5px;
  height: 30px;
  padding: 10px 10px;
  display: inline-flex;
  align-items: center;
  margin: 0 0.3rem 0.3rem 0;
  border: 1px solid lightgray;
}
.list-div {
  background-color: white;
  width: 370px;
  padding: 0.5rem;
  border-radius: 0.3rem;
  border: 1px solid lightgray;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  scroll-behavior: initial;
  height: 192px;
  scrollbar-arrow-color: #212224;
}
.count-box {
  font-size: 12px;
  font-family: inherit;
  max-width: 45px;
  display: inline-block;
}
.inline-button {
  display: inline-block;
}
div[data-placeholder]:not(:focus):not([data-div-placeholder-content]):before {
  content: attr(data-placeholder);
  float: left;
  margin-left: 18%;
  margin-right: 2px;
  color: inherit;
  margin-top: 9%;
}
#total {
  margin-bottom: 15px;
  margin-left: 40%;
  margin-top: 2px;
}
.to {
  float: initial;
}
.type-label {
  margin: 2px 33px 0px 5px;
}
.flex {
  display: flex;
}
.element-attach {
  margin-top: 34px;
  margin-bottom: 35px;
}

#table-title {
  text-align: center;
  font-family: inherit;
  width: 90%;
}
#broadcast-table {
  text-align: center;
  border-collapse: collapse;
  border: 3px solid #ddd;
  width: 90%;
}
#broadcast-table td,
#broadcast-table th {
  border: 1px solid #ddd;
  padding: 8px;
}
#broadcast-table tr:nth-child(even) {
  background-color: #f2f2f2;
}
#broadcast-table tr:hover {
  background-color: rgba(112, 178, 253, 0.1);
}
#broadcast-table th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  background-color: #528aea;
  color: white;
}
.generate-button {
  cursor: pointer;
  border: 1px solid #528aea;
  background: #528aea;
  margin: 0px 20px;
  color: white;
  padding: 10px;
  font-size: 14px;
  border-radius: 20px;
  text-align: center;
  width: 100px;
}

.main-container {
  width: 100%;
  background-color: #E3E9FF;
  min-height: 100vh;
}
.main-container .pop .msg-section {
  background-color: #E3E9FF;
}
.main-container .pop .msg-section .approveBtn {
  background-color: #627FE9;
  color: #ffff;
}
.main-container .pop .msg-section .cancel-icon {
  height: 20px;
  width: 20px;
  position: absolute;
  top: -20px;
  left: 400px;
  cursor: pointer;
  display: inline-block;
  background-size: contain;
  background-repeat: no-repeat;
}
.main-container .boarding-form {
  padding: 29px 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.main-container .boarding-form .header {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.main-container .boarding-form .heading {
  display: flex;
  justify-content: space-between;
  margin-top: 70px;
  width: 100%;
}
.main-container .boarding-form .heading .header-title {
  color: Black;
  font-size: 25px;
  margin: 30px 0;
  font-weight: bolder;
}
.main-container .boarding-form .heading .form-status {
  width: 24%;
}
.main-container .boarding-form .heading .form-status .progress-status-bar .progressbar-container {
  width: 400px;
  display: flex;
  margin-left: 40px;
}
.main-container .boarding-form .heading .form-status .progress-status-bar .progressbar {
  counter-reset: step;
}
.main-container .boarding-form .heading .form-status .progress-status-bar .dlpdf {
  margin-top: 60px;
}
.main-container .boarding-form .heading .form-status .progress-status-bar .progressbar li {
  list-style-type: none;
  width: 30%;
  display: flex;
  float: left;
  font-size: 12px;
  position: relative;
  text-align: center;
  color: blue;
  flex-direction: column;
}
.main-container .boarding-form .heading .form-status .progress-status-bar .progressbar li:before {
  width: 30px;
  height: 30px;
  content: counter(step);
  counter-increment: step;
  line-height: 30px;
  border: 2px solid blue;
  display: block;
  text-align: center;
  margin: 0 auto 10px auto;
  border-radius: 50%;
  background-color: white;
}
.main-container .boarding-form .heading .form-status .progress-status-bar .progressbar li:after {
  width: 100%;
  height: 2px;
  content: '';
  position: absolute;
  background-color: #7d7d7d;
  top: 15px;
  left: -50%;
  z-index: -1;
}
.main-container .boarding-form .heading .form-status .progress-status-bar .progressbar li:first-child:after {
  content: none;
}
.main-container .boarding-form .heading .form-status .progress-status-bar .progressbar li.active {
  color: blue;
  display: flex;
  flex-direction: column;
}
.main-container .boarding-form .heading .form-status .progress-status-bar .progressbar li.active:before {
  border-color: blue;
  background-color: blue;
  color: white;
}
.main-container .boarding-form .heading .form-status .progress-status-bar .progressbar li.active + li:after {
  background-color: blue;
}
.main-container .boarding-form .form-page-1 {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.main-container .boarding-form .form-page-1 .first-table-company {
  background-color: white;
  border: 1px solid black;
  border-radius: 8px;
  margin-top: 10px;
}
.main-container .boarding-form .form-page-1 .Table-header {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.main-container .boarding-form .form-page-1 .Table-header .declaration-content {
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  width: 100%;
}
.main-container .boarding-form .form-page-1 .Table-header .declaration-inside-content {
  display: flex;
  padding-left: 20px;
  width: 100%;
  align-items: center;
  padding-top: 5px;
  margin-bottom: 10px;
}
.main-container .boarding-form .form-page-1 .Table-header .declaration-inside-content .declaration-inside-content-name {
  width: 20%;
}
.main-container .boarding-form .form-page-1 .Table-header .declaration-inside-content .declaration-button {
  height: 38px;
  background-color: white;
  color: #627fe9;
  font-size: 14px;
  font-weight: bold;
  outline: none;
  cursor: pointer;
  margin-left: 20px;
  border: 1px solid #627fe9;
  align-items: center;
  display: flex;
  padding: 0 15px;
  text-decoration: none;
}
.main-container .boarding-form .form-page-1 .Table-header .table-heading {
  display: flex;
  border-bottom: 1px solid black;
  width: 100%;
  padding: 20px 10px;
}
.main-container .boarding-form .form-page-1 .Table-header .first-table-body-company {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 10px 10px;
}
.main-container .boarding-form .form-page-1 .Table-header .first-table-body-company .new-vendor-input {
  height: 35px;
  width: 40%;
  padding: 10px;
  border: 1px solid #627FE9;
}
.main-container .boarding-form .form-page-1 .Table-header .first-table-body-company .upload-img {
  text-align: right;
  width: 30%;
}
.main-container .boarding-form .form-page-1 .Table-header .second-table-body-company {
  display: flex;
  padding: 10px 10px;
  justify-content: space-between;
  width: 100%;
}
.main-container .boarding-form .form-page-1 .Table-header .second-table-body-company .contact-details-vendor-input {
  height: 35px;
  width: 80%;
  padding: 10px;
  border: 1px solid #627FE9;
}
.main-container .boarding-form .form-page-1 .files-link {
  color: #627fe9;
  text-decoration: none;
  margin-left: 15px;
}
.main-container .boarding-form .form-page-1 .ml-250 {
  margin-left: 250px;
}
.main-container .boarding-form .form-page-1 .company-details-table {
  background-color: white;
  border: 1px solid black;
  border-radius: 8px;
  margin-top: 10px;
}
.main-container .boarding-form .form-page-1 .company-details-table .Table-header {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.main-container .boarding-form .form-page-1 .company-details-table .Table-header .table-heading {
  display: flex;
  border-bottom: 1px solid black;
  width: 100%;
  padding: 20px 10px;
}
.main-container .boarding-form .form-page-1 .company-details-table .Table-header .company-details-body {
  display: flex;
  flex-direction: column;
  padding: 10px 10px;
  width: 100%;
}
.main-container .boarding-form .form-page-1 .company-details-table .Table-header .company-details-body .company-details-row {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 15px;
}
.main-container .boarding-form .form-page-1 .company-details-table .Table-header .company-details-body .company-details-row .company-details-vendor-input {
  min-height: 35px;
  width: 90%;
  padding: 10px;
  border: 1px solid #627FE9;
}
.main-container .boarding-form .form-page-1 .company-details-table .Table-header .company-details-body .company-details-row .company-details-vendor-select {
  height: 35px;
  width: 90%;
}
.main-container .boarding-form .form-page-1 .company-details-table .Table-header .company-details-body .company-details-row .singleDatePickerVendor .SingleDatePicker {
  border: 1px solid #627FE9;
  width: 90%;
  height: 35px;
}
.main-container .boarding-form .form-page-1 .company-details-table .Table-header .company-details-body .company-details-row .singleDatePickerVendor .SingleDatePicker input {
  height: 30px;
  border: none;
  font-size: 14px;
}
.main-container .boarding-form .form-page-1 .company-details-table .Table-header .company-details-body .turn-over-details {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.main-container .boarding-form .form-page-1 .company-details-table .Table-header .company-details-body .turn-over-details .turn-over-details-row {
  width: 40%;
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}
.main-container .boarding-form .form-page-1 .company-details-table .Table-header .company-details-body .turn-over-details .turn-over-details-row .turn-vendor-input {
  height: 35px;
  width: 90%;
  padding: 10px;
  border: 1px solid #627FE9;
}
.main-container .boarding-form .form-page-1 .company-details-table .Table-header .company-details-body .turn-over-details .turn-over-details-row .turn-vendor-select {
  height: 35px;
  width: 90%;
}
.main-container .boarding-form .form-page-1 .gst-details-table {
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: white;
  border: 1px solid black;
  border-radius: 10px;
  margin-top: 15px;
}
.main-container .boarding-form .form-page-1 .gst-details-table .gst-table-header {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.main-container .boarding-form .form-page-1 .gst-details-table .gst-table-header .gst-table-heading {
  display: flex;
  width: 20%;
  padding: 20px 10px;
}
.main-container .boarding-form .form-page-1 .gst-details-table .gst-table-header .gst-details-vendor-input {
  min-height: 30px;
  width: 90%;
  padding: 10px;
  border: 1px solid #627FE9;
}
.main-container .boarding-form .form-page-1 .gst-details-table .gst-table-header .gst-details-vendor-dropdown {
  min-height: 35px;
  width: 90%;
}
.main-container .boarding-form .form-page-1 .regional-office-details {
  background-color: white;
  border: 1px solid black;
  border-radius: 8px;
  margin-top: 10px;
}
.main-container .boarding-form .form-page-1 .regional-office-details .regional-table-header {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.main-container .boarding-form .form-page-1 .regional-office-details .regional-table-header .regional-table-heading {
  display: flex;
  border-bottom: 1px solid black;
  width: 100%;
  padding: 20px 10px;
}
.main-container .boarding-form .form-page-1 .regional-office-details .regional-table-header .regional-table-body {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 10px 10px;
}
.main-container .boarding-form .form-page-1 .regional-office-details .regional-table-header .regional-table-body .regional-left-row {
  width: 85%;
  display: flex;
  justify-content: space-between;
}
.main-container .boarding-form .form-page-1 .regional-office-details .regional-table-header .regional-table-body .regional-left-row .regional-new-vendor-input {
  height: 35px;
  width: 90%;
  padding: 10px;
  border: 1px solid #627FE9;
}
.main-container .boarding-form .form-page-1 .regional-office-details .regional-table-header .regional-table-body .regional-left-row .regional-details-vendor-select {
  height: 35px;
  width: 90%;
}
.main-container .boarding-form .form-page-1 .bank-details {
  background-color: white;
  border: 1px solid black;
  border-radius: 8px;
  margin-top: 10px;
  width: 100%;
}
.main-container .boarding-form .form-page-1 .bank-details .bank-details-table-header {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.main-container .boarding-form .form-page-1 .bank-details .bank-details-table-heading {
  display: flex;
  border-bottom: 1px solid black;
  width: 100%;
  padding: 20px 10px;
}
.main-container .boarding-form .form-page-1 .bank-details .bank-table-body {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 10px 10px;
}
.main-container .boarding-form .form-page-1 .bank-details .bank-table-body .bank-new-vendor-input {
  min-height: 35px;
  width: 90%;
  padding: 10px;
  margin-top: 5px;
  border: 1px solid #627FE9;
}
.main-container .boarding-form .form-page-1 .bank-details .bank-table-body .bank-new-vendor-dropdown {
  min-height: 35px;
  width: 90%;
}
.main-container .boarding-form .form-page-1 .company-code-data {
  background-color: white;
  border: 1px solid black;
  border-radius: 8px;
  margin-top: 10px;
  width: 100%;
}
.main-container .boarding-form .form-page-1 .company-code-data .company-code-table-header {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.main-container .boarding-form .form-page-1 .company-code-data .company-code-table-heading {
  display: flex;
  border-bottom: 1px solid black;
  width: 100%;
  padding: 20px 10px;
}
.main-container .boarding-form .form-page-1 .company-code-data .company-code-table-body {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  padding: 10px 10px;
}
.main-container .boarding-form .form-page-1 .company-code-data .company-code-table-body .company-code-new-vendor-input {
  min-height: 35px;
  width: 90%;
  padding: 10px;
  border: 1px solid #627FE9;
}
.main-container .boarding-form .form-page-1 .company-code-data .company-code-table-body .company-code-new-vendor-dropdown {
  min-height: 35px;
  width: 90%;
}
.main-container .boarding-form .form-page-1 .user-details {
  background-color: white;
  border: 1px solid black;
  border-radius: 8px;
  margin-top: 10px;
}
.main-container .boarding-form .form-page-1 .user-details .user-table-header {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.main-container .boarding-form .form-page-1 .user-details .user-table-header .user-table-heading {
  display: flex;
  border-bottom: 1px solid black;
  width: 100%;
  padding: 20px 10px;
}
.main-container .boarding-form .form-page-1 .user-details .user-table-header .user-table-body {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 10px 10px;
}
.main-container .boarding-form .form-page-1 .user-details .user-table-header .user-table-body .user-left-row {
  width: 85%;
  display: flex;
  justify-content: space-between;
}
.main-container .boarding-form .form-page-1 .user-details .user-table-header .user-table-body .user-left-row .user-new-vendor-input {
  height: 35px;
  min-width: 90%;
  padding: 10px;
  border: 1px solid #627FE9;
}
.main-container .boarding-form .form-page-1 .user-details .user-table-header .user-table-body .user-left-row .user-details-vendor-select {
  height: 35px;
  width: 90%;
}
.main-container .boarding-form .form-page-1 .customer-details-table {
  background-color: white;
  border: 1px solid black;
  border-radius: 8px;
  margin-top: 10px;
}
.main-container .boarding-form .form-page-1 .customer-details-table .customer-table-header {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.main-container .boarding-form .form-page-1 .customer-details-table .customer-table-header .customer-table-heading {
  display: flex;
  border-bottom: 1px solid black;
  width: 100%;
  padding: 20px 10px;
}
.main-container .boarding-form .form-page-1 .customer-details-table .customer-table-header .customer-details-body {
  display: flex;
  flex-direction: column;
  padding: 10px 10px;
  width: 100%;
}
.main-container .boarding-form .form-page-1 .customer-details-table .customer-table-header .customer-details-body .customer-details-row {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 15px;
}
.main-container .boarding-form .form-page-1 .customer-details-table .customer-table-header .customer-details-body .customer-details-row .customer-details-vendor-input {
  height: 35px;
  width: 90%;
  padding: 10px;
  border: 1px solid #627FE9;
}
.main-container .boarding-form .form-page-1 .customer-details-table .customer-table-header .customer-details-body .customer-details-row .customer-details-vendor-select {
  height: 35px;
  width: 90%;
}
.main-container .boarding-form .form-page-1 .customer-details-table .customer-table-header .customer-details-body .customer-details-row2 {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 15px;
}
.main-container .boarding-form .form-page-1 .customer-details-table .customer-table-header .customer-details-body .customer-details-row2 .customer-details-left {
  display: flex;
  width: 70%;
}
.main-container .boarding-form .form-page-1 .customer-details-table .customer-table-header .customer-details-body .customer-details-row2 .customer-details-left .customer-details-vendor-input2 {
  height: 35px;
  width: 80%;
  padding: 10px;
  border: 1px solid #627FE9;
}
.main-container .boarding-form .form-page-1 .customer-details-table .customer-table-header .customer-details-body .customer-details-row2 .customer-details-left .customer-details-vendor-select2 {
  height: 35px;
  width: 90%;
}
.main-container .boarding-form .form-page-1 .vendor-type-table {
  background-color: white;
  border: 1px solid black;
  border-radius: 8px;
  margin-top: 10px;
}
.main-container .boarding-form .form-page-1 .vendor-type-table .vendor-type-header {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.main-container .boarding-form .form-page-1 .vendor-type-table .vendor-type-header .vendor-type-heading {
  display: flex;
  border-bottom: 1px solid black;
  width: 100%;
  padding: 20px 10px;
}
.main-container .boarding-form .form-page-1 .vendor-type-table .vendor-type-header .vendor-table-body {
  width: 100%;
  padding: 10px 10px;
  display: flex;
  flex-wrap: wrap;
}
.main-container .boarding-form .form-page-1 .vendor-type-table .vendor-type-header .vendor-table-body .vendor-type-select {
  height: 35px;
  width: 40%;
  padding: 10px;
  border: 1px solid #627FE9;
}
.main-container .boarding-form .vendor-basic-details .mb-20 {
  margin-bottom: 25px;
}
.main-container .boarding-form .multiSelect .input-text-wrap {
  width: 100%;
}
.main-container .boarding-form .multiSelect .input-text-wrap .select {
  width: 100%;
  margin-bottom: none;
}
.main-container .boarding-form .input-text-wrapper {
  width: 100%;
}
.main-container .boarding-form .input-text-wrapper .select {
  width: 100%;
  margin-bottom: none;
}
.main-container .boarding-form .input-text-wrapper .vehicle-details {
  display: flex;
  width: 100%;
  flex-direction: column;
}
.main-container .boarding-form .input-text-wrapper .vehicle-details .vehicle-details-row {
  display: flex;
  width: 70%;
  justify-content: space-between;
}
.main-container .boarding-form .input-text-wrapper .vehicle-details .vehicle-details-input {
  width: 60%;
  border: 1px solid blue;
  height: 35px;
  padding: 10px;
}
.main-container .boarding-form .input-text-wrapper .vehicle-details .vehicle-details-select {
  width: 60%;
  height: 50px;
}
.main-container .boarding-form .product-handling-capability {
  display: flex;
  flex-wrap: wrap;
  margin-left: 15px;
  margin-bottom: 10px;
}
.main-container .boarding-form .input-text-wrap {
  height: 40px;
}
.main-container .boarding-form .vendor-input {
  height: 35px;
  width: 150px;
  padding: 10px;
  border: none;
  border-bottom: 1px solid #86868a;
}
.main-container .boarding-form .select .css-vj8t7z {
  font-size: 12px;
  height: 40px;
}
.main-container .boarding-form .upload-docs .button-classic {
  width: 300px;
}
.main-container .boarding-form .disabled {
  height: 40px;
  padding: 0 30px;
  border-radius: 25px;
  background-color: white;
  color: #b6ccf1;
  font-size: 16px;
  font-weight: bold;
  outline: none;
  cursor: none;
  border: 1px solid #b6ccf1;
}
.main-container .boarding-form .react-datepicker__input-container input {
  width: 200px;
  border: none;
  font-size: 14px;
  border-bottom: 1px solid grey;
  padding: 6px 5px;
}
.main-container .boarding-form .custome-checkbox-wrap {
  margin-right: 8px;
  width: 18px;
  height: 18px;
  border-radius: 2px;
  border: 1px solid black;
  display: inline-block;
  position: relative;
  cursor: pointer;
}
.main-container .boarding-form .custome-checkbox-wrap.selected {
  border: 1px solid #528aea;
}
.main-container .boarding-form .custome-checkbox-wrap .inner-circle {
  width: 10px;
  height: 10px;
  border-radius: 15%;
  background: #528aea;
  position: absolute;
  top: 3px;
  left: 3px;
}
.main-container .boarding-form .divTable {
  width: 90%;
  display: table;
  margin: 15px auto;
  border: 1px solid #528aea;
}
.main-container .boarding-form .divTable .mb-20 {
  margin-bottom: 20px;
}
.main-container .boarding-form .divTableRow {
  display: table-row;
}
.main-container .boarding-form .divTableHeading {
  color: white;
  font-weight: 800;
  background-color: #528aea;
  display: table-header-group;
}
.main-container .boarding-form .divTableCell,
.main-container .boarding-form .divTableHead {
  border-bottom: 1px solid #e0e0e0;
  display: table-cell;
  padding: 5px 10px;
  font-size: 12px;
}
.main-container .boarding-form .divTableFoot {
  background-color: #EEE;
  display: table-footer-group;
  font-weight: bold;
}
.main-container .boarding-form .divTableBody {
  display: table-row-group;
  margin-top: 10px;
}
.main-container .boarding-form .app-icon {
  background-repeat: no-repeat;
  height: 20px;
  width: 20px;
  background-size: contain;
  cursor: pointer;
  margin-bottom: 0;
  vertical-align: top;
  display: inline-block;
}
.main-container .boarding-form .rej-icon {
  height: 24px;
  width: 24px;
  margin-top: -2px;
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;
  margin-bottom: 0;
  vertical-align: top;
  display: inline-block;
}
.main-container .logo {
  display: inline-block;
  width: 70%;
  vertical-align: top;
}
.main-container .company-logo {
  width: 200px;
}
.main-container .vendor-basic-details {
  display: inline-block;
  width: 50%;
}
.main-container .grey {
  color: #86868a;
}
.main-container .theme {
  color: #5289ea;
}
.main-container .black {
  color: #1a2734;
}
.main-container .headerColor {
  color: black;
  border-bottom: 1px solid #85919e;
  padding: 12px 12px;
  font-size: 20px;
}
.main-container .headerColor span {
  margin-left: 5px;
}
.main-container .onboarding-container {
  background: #fff;
  border: 1px solid #85919e;
  border-radius: 6px;
}
.main-container .onboarding-container .operational_area {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  margin-left: 15px;
}
.main-container .onboarding-container .service-offered-container {
  display: flex;
  flex-direction: column;
  width: 20%;
  margin-top: 10px;
}
.main-container .dInBlock {
  display: inline-block;
}
.main-container .VAlign {
  vertical-align: top;
}
.main-container .mt-5 {
  margin-top: 5px;
}
.main-container .mt-10 {
  margin-top: 10px;
}
.main-container .mt-15 {
  margin-top: 15px;
}
.main-container .mt-20 {
  margin-bottom: 20px;
}
.main-container .mt-25 {
  margin-top: 25px;
}
.main-container .mt-30 {
  margin-top: 30px;
}
.main-container .mt-40 {
  margin-top: 40px;
}
.main-container .mt-60 {
  margin-top: 60px;
}
.main-container .mb-5 {
  margin-bottom: 5px;
}
.main-container .mb-10 {
  margin-bottom: 10px;
}
.main-container .mb-15 {
  margin-bottom: 15px;
}
.main-container .mb-20 {
  margin-bottom: 20px;
}
.main-container .mb-25 {
  margin-bottom: 25px;
}
.main-container .mr-5 {
  margin-right: 5px;
}
.main-container .mr-10 {
  margin-right: 10px;
}
.main-container .mr-15 {
  margin-right: 15px;
}
.main-container .mr-20 {
  margin-right: 20px;
}
.main-container .mt-18 {
  margin-top: 18px;
}
.main-container .mr-25 {
  margin-right: 25px;
}
.main-container .mwt-80 {
  min-width: 80px;
}
.main-container .mwt-120 {
  min-width: 120px;
}
.main-container .pt-10 {
  padding-top: 10px;
}
.main-container .wt-100 {
  width: 100px;
}
.main-container .wt-120 {
  width: 120px;
}
.main-container .wt-150 {
  width: 150px;
}
.main-container .wt-160 {
  width: 160px;
}
.main-container .ht-150 {
  height: 150px;
}
.main-container .ht-50 {
  height: 50px;
}
.main-container .ht-65 {
  height: 65px;
}
.main-container .ht-90 {
  height: 90px;
}
.main-container .wt-200 {
  width: 200px;
}
.main-container .wt-250 {
  width: 250px;
}
.main-container .wt-10p {
  width: 10%;
}
.main-container .wt-12p {
  width: 15%;
}
.main-container .wt-18p {
  width: 18%;
}
.main-container .wt-20p {
  width: 20%;
}
.main-container .wt-25p {
  width: 25%;
}
.main-container .wt-50p {
  width: 50%;
}
.main-container .wt-60p {
  width: 60%;
}
.main-container .wt-75p {
  width: 75%;
}
.main-container .wt-100p {
  width: 100%;
}
.main-container .mwt-170 {
  width: 170px;
}
.main-container .fRight {
  float: right;
}
.main-container .tCenter {
  text-align: center;
}
.main-container .tLeft {
  text-align: left;
}
.main-container .pointer {
  cursor: pointer;
}
.main-container .fs-13 {
  font-size: 13px;
}
.main-container .fs-15 {
  font-size: 15px;
}
.main-container .marginAuto {
  margin: auto;
}
.main-container .noBorder {
  border: none;
}
.main-container .fTop {
  vertical-align: top;
}
.main-container .checkbox-flex {
  display: flex;
  align-items: center;
}
.main-container .fMiddle {
  vertical-align: middle;
}
.main-container .area-name {
  font-size: 17px;
  color: #7d7d7d;
}
.main-container .borderLight {
  border: solid 1px #dadada;
}
.main-container .bg-light {
  background-color: #d5e2f9;
}
.main-container .table-container {
  display: flex;
}
.main-container .table-container:first-child {
  margin-left: 20px;
}
.main-container .operational-area-text {
  color: #000;
  font-weight: 800;
  font-size: 16px;
}
.main-container .vendor-form-input {
  width: 200px;
  padding: 4px 10px;
  border: none;
  border-bottom: 1px solid grey;
  font-size: 14px;
  margin-bottom: 10px;
}
.main-container .counter-ip {
  padding: 3px;
  margin-right: 5px;
}
.main-container .success {
  color: #0c8434;
}
.main-container .error {
  color: red;
}
.main-container .vendor-name {
  color: gray;
  font-size: 10px;
  margin-bottom: 5px;
}
.main-container .history-icon {
  height: 15px;
  width: 15px;
  margin: 0 8px;
  cursor: pointer;
  display: inline-block;
  background-size: contain;
  background-repeat: no-repeat;
}
.main-container .pr {
  position: relative;
}
.main-container .history-block {
  width: 350px;
  max-height: 300px;
  overflow: scroll;
  position: absolute;
  padding: 15px;
  top: 16px;
  z-index: 2;
  background: #efeded;
  box-shadow: 0px 10px 20px 0px rgba(158, 170, 181, 0.45);
}
.main-container .scroll-table {
  height: 500px;
  overflow: scroll;
}
.main-container .table-border-none {
  border: none;
}
.main-container .qualification-table {
  border: none;
  border-bottom: 1px solid #868e96;
}
.main-container .tr-sanity {
  padding-left: 25px;
}
.main-container .tr-sanity-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 10px;
  margin: 10px;
  border-bottom: 1px solid #868e96;
}
.main-container .upload-button {
  float: right;
}
.main-container .disabled-upload {
  height: 40px;
  padding: 0 30px;
  border-radius: 5px;
  background-color: #8089a9;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  outline: none;
  cursor: none;
  border: 1px solid #fff;
  pointer-events: none;
}
.main-container .button-classic-doc {
  height: 38px;
  padding: 0 15px;
  background-color: white;
  color: #627fe9;
  font-size: 16px;
  font-weight: bold;
  outline: none;
  cursor: pointer;
  border: 1px solid #627fe9;
}
.main-container .vehicle-owned {
  color: black;
  border-bottom: 1px solid #85919e;
  padding: 12px 12px;
  font-size: 20px;
}
.main-container .vehicle-owned span {
  margin-left: 5px;
}
.ml-20 {
  margin-left: 20px;
}
.ml-auto {
  margin-left: auto;
}
.vendor-comment-input {
  width: 1000px;
  padding: 4px 10px;
  border: none;
  font-size: 14px;
  outline: none;
}
.flex {
  display: flex;
}
.head-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 20000;
}
.head-popup .head-popup-section {
  width: 400px;
  min-height: 200px;
  position: absolute;
  background: white;
  border-radius: 5px;
  text-align: center;
  padding: 20px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
}
.approverflex {
  display: flex;
  justify-content: space-between;
}
.gst-table-body {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 10px 10px;
}
.gst-table-body .gst-table-left-row {
  width: 85%;
  display: flex;
  justify-content: space-between;
}
.gst-table-body .gst-table-left-row .gst-table-new-vendor-input {
  height: 35px;
  width: 90%;
  padding: 10px;
  border: 1px solid #627FE9;
}
.gst-table-body .gst-table-left-row .gst-table-details-vendor-select {
  height: 35px;
  width: 90%;
}
.companyy-details-vendor-input {
  min-height: 30px;
  width: 30%;
  padding: 10px;
  border: 1px solid #627FE9;
}
.fs-15 {
  font-size: 15px;
}
.DocsTab {
  display: flex;
  justify-content: space-between;
}
.exmptnbox {
  display: flex;
}
.VendorFormSAPcontainer {
  background-color: white;
  border: 1px solid grey;
  border-radius: 10px;
  height: 230px;
  width: 98%;
  margin-top: 30px;
  margin-left: 13px;
}
.VendorFormSAPcontainer .container-heading {
  font-size: 20px;
  font-weight: 500;
  line-height: 22px;
  margin-left: 20px;
  margin-top: 15px;
  margin-bottom: 15px;
}
.VendorFormSAPcontainer .form-section {
  display: flex;
  margin-bottom: 20px;
  /* Add some spacing between form sections */
  margin-left: 20px;
}
.VendorFormSAPcontainer .form-section .form-item {
  display: grid;
  margin-bottom: 5px;
  margin-left: 30px;
  /* Add some spacing between form items */
}
.VendorFormSAPcontainer .form-section .form-item .form-item label {
  width: 150px;
  /* Set the width of the labels (adjust as needed) */
  margin-right: 10px;
  /* Add some spacing between labels and inputs */
}
.incotermsOptions {
  width: 200px;
}
.ml-200 {
  margin-left: 200px;
}
.ala-017 {
  min-height: 40px;
  width: 90%;
  padding: 10px;
  border: 1px solid #000000;
  box-sizing: border-box;
  font-family: "avenir-medium", sans-serif;
}
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal-backdrop p {
  font-size: 20px;
}
.modal-content {
  background-color: white;
  padding: 24px 60px 24px 60px;
  border-radius: 5px;
  font-weight: 14px;
  text-align: center;
}
.modal-content button {
  margin-top: 34px;
  padding: 8px 23px 8px 23px;
  border-radius: 6px;
  background-color: #0b0b50ad;
  color: white;
  border-color: #0b0b50ad;
}

.bank-details-popup .bank-overlay {
  position: fixed;
  z-index: 10;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(27, 33, 67, 0.9);
}
.bank-details-popup .bank-details-content {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 5px;
  width: 90%;
  max-width: 1065px;
  min-height: 100px;
  max-height: 85%;
  overflow-y: scroll;
  background-color: #E3E9FF;
  z-index: 11;
  display: flex;
  flex-direction: column;
  padding: 10px 10px;
}
.bank-details-popup .bank-details-content .bank-heading {
  padding: 20px 0 0 5px;
  font-size: 20px;
  font-weight: 400;
}
.bank-details-popup .bank-details-content .bank-details-main {
  background-color: white;
  border: 1px solid black;
  border-radius: 8px;
  margin-top: 10px;
  width: 100%;
  height: auto;
  padding-bottom: 20px;
}
.bank-details-popup .bank-details-content .bank-details-main .bank-details-header {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.bank-details-popup .bank-details-content .bank-details-main .bank-details-heading {
  display: flex;
  border-bottom: 1px solid black;
  width: 100%;
  padding: 20px 10px;
  flex-direction: column;
}
.bank-details-popup .bank-details-content .bank-details-main .bank-details-heading .bank-radio-options {
  display: flex;
  width: 40%;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}
.bank-details-popup .bank-details-content .bank-details-main .bank-details-heading .bank-radio-options .bank-radio-option {
  display: flex;
  height: 30px;
  align-items: center;
}
.bank-details-popup .bank-details-content .bank-details-main .bank-details-heading .bank-radio-options .bank-radio-option input[type='radio'] {
  border: 1px solid #627FE9;
  height: 20px;
  width: 20px;
}
.bank-details-popup .bank-details-content .bank-details-main .bank-details-body {
  display: flex;
  flex-direction: column;
  padding: 10px 10px;
}
.bank-details-popup .bank-details-content .bank-details-main .bank-details-body .bank-details-row:last-child {
  border-bottom: 1px solid #85919E;
  padding-bottom: 20px;
}
.bank-details-popup .bank-details-content .bank-details-main .bank-details-body .bank-details-row {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.bank-details-popup .bank-details-content .bank-details-main .bank-details-body .bank-details-row .bank-input {
  min-height: 35px;
  width: 90%;
  padding: 10px;
  margin-top: 5px;
  border: 1px solid #627FE9;
}
.bank-details-popup .bank-details-content .bank-details-main .bank-details-body .bank-details-row .singleDatePicker-bank .SingleDatePicker {
  border: 1px solid #627FE9;
  width: 100%;
  height: 38px;
  margin-top: 5px;
}
.bank-details-popup .bank-details-content .bank-details-main .bank-details-body .bank-details-row .singleDatePicker-bank .SingleDatePicker input {
  height: 35px;
  border: none;
  font-size: 14px;
}
.bank-details-popup .bank-details-content .bank-details-main .button-classic-bank {
  height: 38px;
  padding: 0 15px;
  background-color: white;
  color: #627fe9;
  font-size: 16px;
  font-weight: bold;
  outline: none;
  cursor: pointer;
  border: 1px solid #627fe9;
  width: 260px;
}
.bank-details-popup .bank-details-content .bank-details-main .document-upload-bank {
  height: 38px;
  padding: 0 15px;
  background-color: #627fe9;
  color: white;
  font-size: 16px;
  font-weight: bold;
  outline: none;
  cursor: pointer;
  border: 1px solid #627fe9;
  border-radius: 6px;
  width: 150px;
  margin: 10px 10px 0 auto;
}
.bank-details-popup .bank-details-content .cancel-save {
  display: flex;
  width: 30%;
  margin: 10px 0 0 auto;
  justify-content: space-between;
}
.bank-details-popup .bank-details-content .cancel-save .button-classic-bank-1 {
  height: 38px;
  padding: 0 15px;
  background-color: white;
  color: #627fe9;
  font-size: 16px;
  font-weight: bold;
  outline: none;
  cursor: pointer;
  border: 1px solid #627fe9;
  border-radius: 6px;
  width: 150px;
}
.bank-details-popup .bank-details-content .cancel-save .button-classic-bank-2 {
  height: 38px;
  padding: 0 15px;
  background-color: #627fe9;
  color: white;
  font-size: 16px;
  font-weight: bold;
  outline: none;
  cursor: pointer;
  border: 1px solid #627fe9;
  border-radius: 6px;
  width: 150px;
}
.custome-checkbox-wrap {
  width: 20px;
  height: 20px;
  border-radius: 5px;
  border: 1px solid #d9d9d9;
  display: inline-block;
  position: relative;
  cursor: pointer;
}
.custome-checkbox-wrap.selected {
  border: 1px solid #528aea;
}
.custome-checkbox-wrap .inner-circle {
  width: 10px;
  height: 10px;
  border-radius: 15%;
  background: #528aea;
  position: absolute;
  top: 4px;
  left: 4px;
}
.margin-right {
  margin-right: 20px;
}
.no-records {
  padding: 10px;
  font-size: 16px;
  text-align: center;
}
.error {
  color: red;
}
.input-text-align-top .input-text-wrap {
  vertical-align: top;
}
.vAlignCenter {
  vertical-align: middle;
}
.inBlock {
  display: inline-block;
}
.wt-150 {
  width: 150px;
}
.mb-10 {
  margin-bottom: 10px;
}
.mb-20 {
  margin-bottom: 20px;
}
.ml-10 {
  margin-left: 10px;
}
.mr-12 {
  margin-right: 12px;
}
.wt-60 {
  width: 60px;
}
.input-spacing div {
  margin-right: 20px;
}
.input-spacing div .input-text-wrap {
  vertical-align: middle;
  margin-bottom: 5px;
}
.vendor-select {
  width: 300px;
}
.wt-20p {
  width: 20%;
}
.wt-30p {
  width: 30%;
}
.wt-60p {
  width: 60%;
}
.wt-70p {
  width: 70%;
}

.tax-code-details {
  background-color: white;
  border: 1px solid grey;
  border-radius: 10px;
  width: 98%;
  margin-top: 30px;
  margin-left: 13px;
}
.tax-code-details label {
  margin-bottom: 1rem;
}
.tax-code-details h3 {
  margin-left: 13px;
  font-size: 20px;
  font-weight: 500;
  margin-top: 15px;
}
.tax-code-details .planning-component {
  padding: 20px;
  margin: 0 auto;
}
.tax-code-details .row-container {
  margin-bottom: 20px;
}
.tax-code-details .row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  width: 80%;
}
.tax-code-details .column {
  flex: 1 1;
  margin: 0 5px;
}
.tax-code-details .transhColumn {
  flex: 1 1;
  margin: 0 5px;
  margin-top: 1rem;
}
.tax-code-details input {
  width: 100%;
  padding: 8px;
  border-radius: 4px;
  height: 29px;
  border: 1px solid #ccc;
  font-size: 16px;
}
.tax-code-details .row-input {
  height: 43px;
}
.tax-code-details .add-btn,
.tax-code-details .delete-btn {
  background-color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  font-size: 16px;
  border-radius: 4px;
}
.tax-code-details .add-btn {
  display: flex;
  align-items: center;
  margin-top: 20px;
  background-color: white;
  border: 1px solid hsl(0, 0%, 80%);
  color: rgba(0, 0, 0, 0.7215686275);
}
.tax-code-details .delete-btn {
  background-color: white;
  color: rgba(255, 0, 0, 0.831372549);
  padding-left: 14px;
  padding-top: 11px;
  padding-bottom: 7px;
}
.tax-code-details .add-btn:hover {
  opacity: 0.8;
  color: white;
  background-color: cornflowerblue;
}
.tax-code-details .delete-btn:hover {
  opacity: 0.8;
  color: white;
  background-color: rgba(228, 19, 19, 0.831372549);
}
.tax-code-details .add-btn svg,
.tax-code-details .delete-btn svg {
  margin-right: 5px;
}
.tax-code-details .tax-code-btns {
  display: flex;
}
.tax-code-details .tax-code-btns button {
  margin-right: 1rem;
}
.company-details-wrap .wrapper {
  width: 1280px;
  margin: 0 auto;
}
.company-details-wrap .go-back-prev-screen {
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-size: contain;
  margin-bottom: 20px;
  cursor: pointer;
}

.home-navigation-panel-root {
  font-size: 15px;
}
.home-navigation-panel-root .home-navigation-holder {
  overflow-x: scroll;
  overflow-y: hidden;
}
.home-navigation-panel-root .home-navigation-holder::-webkit-scrollbar {
  height: 6px;
}
.home-navigation-panel-root .home-navigation-holder::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.home-navigation-panel-root .home-navigation-holder::-webkit-scrollbar-thumb {
  background: #528aea;
  border-radius: 4px;
}
.home-navigation-panel-root .home-navigation-holder::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.home-navigation-panel-root .company-name {
  font-size: 24px;
  color: #282626;
  font-family: 'avenir-medium', sans-serif;
  margin-top: 20px;
  margin-right: 20px;
}
.home-navigation-panel-root .company-details .options {
  display: block;
  margin-right: 5%;
  margin-bottom: 5px;
}
.home-navigation-panel-root .company-details .options .pan-heading {
  color: #1a2734;
  font-size: 14px;
  display: inline-block;
  vertical-align: middle;
}
.home-navigation-panel-root .company-details .options .pan-no {
  color: #86868a;
  margin-left: 5px;
  font-size: 14px;
  display: inline-block;
  vertical-align: middle;
  text-transform: capitalize;
}
.home-navigation-panel-root .company-details .options .comp-website {
  text-transform: lowercase;
}
.home-navigation-panel-root .company-details .options .img {
  display: inline-block;
  vertical-align: middle;
  width: 15px;
  height: 15px;
  background-size: contain;
  background-repeat: no-repeat;
}
.home-navigation-panel-root .other-details {
  margin-top: 10px;
}
.home-navigation-panel-root .current-screen {
  text-decoration: underline;
}
body {
  -webkit-box-sizing: border-box;
}
.more-details-wrap {
  display: inline-block;
  vertical-align: middle;
  padding: 2px 7px 0px 7px;
  text-align: center;
  border-radius: 13px;
  background: #f9f9f9;
  color: #86868a;
  font-family: 'avenir-light', sans-serif;
  margin-right: 30px;
  height: 26px;
  line-height: 26px;
  min-width: 45px;
}
.more-details-wrap.imp {
  background: #528aea;
  color: #fff;
  font-weight: 500;
}
.card-view {
  width: 40%;
  display: inline-block;
  border-radius: 10px;
  padding: 12px 12px;
  vertical-align: bottom;
  margin-top: -50px;
  box-shadow: 0px 10px 20px 0px rgba(129, 172, 212, 0.45);
  border: 1px solid rgba(0, 89, 251, 0.38);
}
.card-view.card-company-admin {
  margin-top: -30px;
}
.title {
  width: 55%;
  display: inline-block;
  vertical-align: top;
}
.depot-users-list-firs {
  float: left;
  width: 50%;
}
.depot-users-list-secon {
  float: left;
  width: 50%;
}
.depot-users-list-firs .first,
.depot-users-list-secon .first {
  float: left;
}
.depot-users-list-firs .second,
.depot-users-list-secon .second {
  float: left;
}
.heading .first,
.heading .second {
  font-weight: 900;
}
.heading {
  display: flex;
  font-size: 12px;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.2px;
  color: #ababae;
}
.depot-users-list {
  display: flex;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.2px;
  color: #535356;
  margin: 4px 0px;
  padding-bottom: 10px;
  border-bottom: 1px solid #ececee;
}
.depot-users-list .edit-delete {
  display: flex;
  justify-content: space-between;
  max-height: 23px;
  width: 99px!important;
}
.depot-users-list .edit-delete .add-button {
  padding: 4px 6px;
  margin: 0px 4px 0px 0px;
}
.heading .head,
.depot-users-list .head {
  white-space: nowrap;
}
.heading .usn,
.depot-users-list .usn {
  width: 135px;
}
.depot-users-list .usn {
  font-weight: 900;
}
.add-action-wrap-popup .modal-popup {
  max-height: 500px!important;
  overflow: visible!important;
  width: 600px!important;
  min-height: 400px!important;
}
.details-header {
  font-size: 10px;
  color: #868e96;
  margin-bottom: 10px;
  display: inline-block;
  width: 200px;
}
.field-details {
  display: flex;
  font-size: 14px;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.2px;
  color: #535356;
  border-bottom: 1px solid #ececee;
}
.field-details .name {
  width: 350px;
  margin: 10px 15px 10px 5px;
}
.field-details .fields {
  width: 200px;
  margin: 10px 15px 10px 5px;
  text-align: center;
}
.field-details .code {
  width: 150px;
  margin: 10px 15px 10px 5px;
  text-align: center;
}
.multifield {
  display: flex;
  font-size: 12px;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.2px;
  color: #535356;
}
.multifield .name {
  width: 350px;
  margin: 10px 15px 10px 5px;
}
.multifield .fields {
  width: 200px;
  margin: 10px 15px 10px 5px;
  text-align: center;
}
.multifield .code {
  width: 150px;
  margin: 10px 15px 10px 5px;
  text-align: center;
}
.wt-25p {
  width: 25%;
}
.TabContainer121 {
  width: 30%;
  display: flex;
  justify-content: center;
}
.TabOptions1221 {
  width: 300px;
  background-color: white;
  color: black;
  border: none;
  padding: 5px;
}
.selectedTab1221 {
  width: 300px;
  color: #528aea;
  border: none;
  border-bottom: 2px solid #528aea;
  padding: 5px;
}

.basic-details-wrap .heading-section {
  margin-top: 33px;
  font-size: 20px;
  color: #1a2734;
  font-family: 'avenir-medium', sans-serif;
}
.basic-details-wrap .details {
  margin-top: 20px;
}
.basic-details-wrap .details .fiscal-section {
  display: inline-block;
  width: 33%;
}
.basic-details-wrap .details .fiscal-section .rupee-icon {
  vertical-align: baseline;
}
.basic-details-wrap .details .fiscal-section .input-text-wrap {
  width: 50%;
}
.basic-details-wrap .details .fiscal-section .input-text-wrap .text-input {
  width: 185px;
}
.basic-details-wrap .details .fiscal-section .select-wrap .select {
  width: 125px;
  margin-left: 30px;
  margin-top: 16px;
}
.basic-details-wrap .details .select-wrap {
  width: 33%;
  display: inline-block;
  vertical-align: baseline;
}
.basic-details-wrap .details .select-wrap .label {
  font-size: 10px;
  color: #868e96;
  margin-bottom: 10px;
}
.basic-details-wrap .details .select-wrap .label-mb-0 {
  font-size: 10px;
  color: #868e96;
}
.basic-details-wrap .details .select-wrap-w-25 {
  width: 25%;
  display: inline-block;
  vertical-align: baseline;
}
.basic-details-wrap .details .select-wrap-w-25 .label {
  font-size: 10px;
  color: #868e96;
  margin-bottom: 10px;
}
.basic-details-wrap .details .select-wrap-w-25 .label-mb-0 {
  font-size: 10px;
  color: #868e96;
}
.basic-details-wrap .details .select {
  width: 180px;
}
.basic-details-wrap .details .select-branch {
  width: 220px;
}
.basic-details-wrap .company-details {
  margin-top: 50px;
}
.basic-details-wrap .company-details .title {
  font-size: 20px;
  color: #1a2734;
  font-family: 'avenir-medium', sans-serif;
}
.basic-details-wrap .company-details .file-upload-section .file-lable-section .company-icon {
  width: 80px;
  height: 80px;
  border-radius: 5px;
  background-color: #f9f9f9;
  display: inline-block;
  vertical-align: middle;
}
.basic-details-wrap .company-details .file-upload-section .file-lable-section .company-icon.img {
  background-size: cover;
  background-repeat: no-repeat;
}
.basic-details-wrap .company-details .file-upload-section .file-lable-section .file-upload-details {
  display: inline-block;
  margin-left: 20px;
  vertical-align: middle;
}
.basic-details-wrap .company-details .file-upload-section .file-lable-section .title {
  font-size: 10px;
  color: #868e96;
}
.basic-details-wrap .company-details .file-upload-section .file-lable-section .desc {
  font-size: 16px;
  color: #1a2734;
  margin-top: 5px;
  margin-bottom: 15px;
}
.basic-details-wrap .company-details .file-upload-section .file-holder {
  width: 300px;
  height: 26px;
  border-radius: 2px;
  background-color: #f9f9f9;
  border: 1px solid #cccccc;
  cursor: pointer;
  position: relative;
}
.basic-details-wrap .company-details .file-upload-section .file-holder input {
  width: 300px;
  height: 26px;
  opacity: 0;
  cursor: pointer;
}
.basic-details-wrap .company-details .file-upload-section .file-holder .file-choose {
  position: absolute;
  right: 9px;
  top: 4px;
  font-family: 'avenir', sans-serif;
  font-size: 12px;
  letter-spacing: 0.3px;
  cursor: pointer;
  color: #528aea;
}
.basic-details-wrap .company-details .file-upload-section .file-holder .file-name {
  font-family: 'avenir', sans-serif;
  font-size: 12px;
  position: absolute;
  top: 3px;
  left: 12px;
  color: #86868a;
}
.basic-details-wrap .company-details .file-upload-section .generate-button {
  cursor: pointer;
  padding: 0 15px;
  border-radius: 23px;
  border: 1px solid #528aea;
  background: #528aea;
  margin: 0px 20px;
  color: #fff;
  min-width: 76px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  display: inline-block;
  vertical-align: bottom;
}
.sub-title-section-wrap {
  margin-top: 35px;
}
.sub-title-section-wrap .circle,
.sub-title-section-wrap .text,
.sub-title-section-wrap .underline {
  display: inline-block;
  vertical-align: baseline;
}
.sub-title-section-wrap .circle {
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background: #528aea;
}
.sub-title-section-wrap .text {
  margin-left: 10px;
  font-size: 16px;
  color: #1a2734;
  font-family: 'avenir-medium', sans-serif;
}
.sub-title-section-wrap .underline {
  width: 20px;
  height: 3px;
  background: #528aea;
  margin-left: 5px;
}
.details .input-text-wrap {
  vertical-align: middle;
  margin-bottom: 15px;
}
.rupee-icon {
  background-image: url(/images/rupee.svg);
  background-repeat: no-repeat;
  height: 17px;
  width: 17px;
  background-size: contain;
  margin: 0 5px;
  display: inline-block;
  vertical-align: middle;
}
.button-add {
  cursor: pointer;
  padding: 0 15px;
  border-radius: 23px;
  border: 1px solid #528aea;
  background: #528aea;
  margin: 0px 20px;
  color: #fff;
  min-width: 76px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  display: inline-block;
  vertical-align: bottom;
  margin-bottom: 10px;
}
.documents-upload-section {
  min-height: 500px;
  padding: 10px;
  margin-bottom: 40px;
  margin-top: 40px;
  border-radius: 5px;
  box-shadow: 0 0 10px 4px rgba(69, 68, 68, 0.07);
}
.documents-upload-section .file-lable-section {
  margin: 10px;
}
.documents-upload-section .file-lable-section .company-icon {
  width: 50px;
  height: 50px;
  border-radius: 5px;
  background-color: #f9f9f9;
  display: inline-block;
  vertical-align: middle;
}
.documents-upload-section .file-lable-section .company-icon.img {
  background-size: cover;
  background-repeat: no-repeat;
}
.documents-upload-section .file-lable-section .file-upload-details {
  display: inline-block;
  vertical-align: middle;
}
.documents-upload-section .file-lable-section .title {
  font-size: 12px;
  color: #868e96;
}
.documents-upload-section .file-lable-section .desc {
  font-size: 16px;
  color: #1a2734;
  margin-top: 5px;
  margin-bottom: 15px;
}
.documents-upload-section .file-lable-section .attachment-container-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 5px;
  border-top: 1px solid hsla(0, 0%, 59.2%, 0.2);
}
.documents-upload-section .file-lable-section .attachment-container-row .attachment-file-icon {
  background-image: url(/images/attachment.svg);
  background-repeat: no-repeat;
  height: 30px;
  width: 30px;
  background-size: contain;
  padding: 5px;
  margin: 5px;
}
.documents-upload-section .file-lable-section .attachment-container-row .attachment-name {
  padding-top: 10px;
  font-size: 14px;
}
.documents-upload-section .file-lable-section .attachment-container-row .delete-icon {
  background-image: url(/images/delete.svg);
  background-repeat: no-repeat;
  height: 23px;
  width: 23px;
  background-size: contain;
  cursor: pointer;
  display: inline-block;
  padding: 5px;
  margin: 5px;
}
.documents-upload-section .file-lable-section input[type="text"] {
  width: 200px;
  height: 26px;
  border-radius: 2px;
  border: 1px solid #cccccc;
}
.documents-upload-section .file-lable-section input[type="text"]:focus {
  border: 1px solid #cccccc;
  outline-offset: 0px;
  outline: none !important;
  padding: 5px;
}
.documents-upload-section .file-lable-section .single-date-input .DateInput_input {
  font-size: 14px;
}
.documents-upload-section .file-holder {
  width: 300px;
  height: 26px;
  border-radius: 2px;
  background-color: #f9f9f9;
  border: 1px solid #cccccc;
  cursor: pointer;
  position: relative;
}
.documents-upload-section .file-holder input {
  width: 300px;
  height: 26px;
  opacity: 0;
  cursor: pointer;
}
.documents-upload-section .file-holder .file-choose {
  position: absolute;
  right: 9px;
  top: 4px;
  font-family: 'avenir', sans-serif;
  font-size: 12px;
  letter-spacing: 0.3px;
  cursor: pointer;
  color: #528aea;
}
.documents-upload-section .file-holder .file-name {
  font-family: 'avenir', sans-serif;
  font-size: 12px;
  position: absolute;
  top: 3px;
  left: 12px;
  color: #86868a;
}
.documents-upload-section .generate-button {
  cursor: pointer;
  padding: 0 15px;
  border-radius: 23px;
  border: 1px solid #528aea;
  background: #528aea;
  margin: 0px 20px;
  color: #fff;
  min-width: 76px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  display: inline-block;
  vertical-align: bottom;
}
.tolerance {
  background-repeat: no-repeat;
  height: 17px;
  width: 17px;
  background-size: contain;
  margin: 0 5px;
  display: inline-block;
  vertical-align: middle;
}
.flex {
  display: flex;
}
.space-between {
  justify-content: space-between;
}
.hl {
  border: 2px solid red;
}
.label-inline {
  font-size: 10px;
  color: #868e96;
  margin-bottom: 10px;
}
.font-1rem {
  font-size: 1rem;
}
.mt-1rem {
  margin-top: 1rem;
}
.pb-13px {
  padding-bottom: 13px !important;
}
.pl-0 {
  padding-left: 0!important ;
}
.mr-20 {
  margin-right: 20px;
}
.groups-div {
  display: flex;
}
.group-tab {
  display: inline-block;
}
.selectgrp {
  width: 200px;
}
.sapdiv-parent {
  display: flex;
}
.sapdiv-parent .sapvendor-div {
  display: flex;
  grid-template-columns: auto auto auto;
}
.sapdiv-parent .close {
  margin-top: 60px;
  margin-left: 30px;
}
.sapdiv-parentT1 {
  display: flex;
  align-items: center;
}
.sapdiv-parentT1 .sapvendor-div {
  display: grid;
  grid-template-columns: auto auto;
}
.sapdiv-parentT1 .close {
  margin-top: 60px;
  margin-left: 30px;
}
.select-wrapT2 {
  width: -moz-fit-content;
  width: fit-content;
  display: inline-block;
  vertical-align: baseline;
  margin: 5px;
}
.select-wrapT2 .select {
  min-width: 400px;
}
.select-wrapT2 .label {
  font-size: 10px;
  color: #868e96;
  margin-bottom: 10px;
}
.select-wrapT2 .label-mb-0 {
  font-size: 10px;
  color: #868e96;
}

.user-provider-root {
  margin: 60px 0px;
}
.user-provider-root .details-head {
  font-family: 'Avenir', sans-serif;
  font-size: 24px;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #1a2734;
  margin: 15px 5px;
}
.user-provider-root .button-holder .add-button {
  border: 1px solid #528aea;
  margin: 10px 0px;
  text-align: center;
  background-color: #fff;
}
.user-provider-root .select_service_type {
  width: 270px;
  margin: 10px;
}

.add-provider-root .modal-popup {
  min-height: 500px;
}
.add-provider-root .modal-popup .form-section {
  margin-bottom: 15px;
}
.add-provider-root .modal-popup .active-status {
  margin: 15px 0px;
  display: flex;
}
.add-provider-root .modal-popup .active-status .label {
  margin-right: 20px;
}
.add-provider-root .modal-popup .dropdown-holder {
  margin: 10px 0px;
}
.add-provider-root .modal-popup .dropdown-holder .users-holder {
  margin: 5px 0px;
}
.add-provider-root .modal-popup .dropdown-holder .users-holder .select {
  width: 400px;
}
.add-provider-root .modal-popup .dropdown-holder .users-holder .select:first-child {
  margin-right: 20px;
}
.add-provider-root .modal-popup .dropdown-holder .plus {
  margin: 5px 0px;
}

.add-user-wrap .overlay {
  position: fixed;
  z-index: 10;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(27, 33, 67, 0.9);
}
.add-user-wrap .modal-popup {
  max-height: 600px;
  overflow: scroll;
  position: fixed !important;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 5px;
  max-width: 80%;
  min-height: 500px;
  background-color: white;
  z-index: 11;
}
.add-user-wrap .modal-popup .pop-heading {
  background-color: #f4f4f4;
  padding: 18px;
  font-family: 'avenir', sans-serif;
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #528aea;
  text-align: center;
  margin-bottom: 30px;
}
.add-user-wrap .modal-popup .content {
  padding: 20px 0px 20px 30px;
}
.add-user-wrap .modal-popup .content .select-container {
  display: flex;
  margin: 20px 0px;
}
.add-user-wrap .modal-popup .content .select-container.action-check {
  margin: 0px;
}
.add-user-wrap .modal-popup .content .select-container.action-check .delete-icon {
  height: 30px;
  width: 30px;
}
.add-user-wrap .modal-popup .content .select-container .select-holder {
  width: 277px;
  position: relative;
}
.add-user-wrap .modal-popup .content .select-container .select-holder .sub-head {
  font-size: 10px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #868e96;
  margin-bottom: 7px;
}
.add-user-wrap .modal-popup .content .select-container .action-holder {
  display: flex;
  flex-wrap: wrap;
  width: 330px;
  margin: 15px;
  position: relative;
}
.add-user-wrap .modal-popup .content .select-container .action-holder.small-actions {
  width: 250px;
}
.add-user-wrap .modal-popup .content .select-container .action-holder .actions {
  margin: 5px;
}
.add-user-wrap .modal-popup .content .select-container .action-holder .actions .add-button {
  margin: 0;
  height: 35px;
  padding: 0 15px;
  font-size: 20px;
  margin-left: 10px;
}
.add-user-wrap .modal-popup .content .select-container .action-holder .actions .select {
  width: 235px;
}
.add-user-wrap .modal-popup .content .select-container .action-holder .add-button {
  margin: 5px 5px;
  padding: 10px 20px;
}
.add-user-wrap .modal-popup .content .select-container .action-holder .sub-head {
  position: absolute;
  font-size: 10px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #868e96;
  top: -10px;
  left: 7px;
}
.add-user-wrap .modal-popup .content .usn-container {
  margin: 17px 0px;
  display: flex;
}
.add-user-wrap .modal-popup .content .usn-container .add-button {
  margin: 20px;
  border-radius: 5px;
}
.add-user-wrap .modal-popup .content .usn-container .btn-disabled {
  background-color: #cac2c2;
  border: 1px solid #cac2c2;
  pointer-events: none;
  color: white;
}
.add-user-wrap .modal-popup .content .submit-section {
  display: flex;
  justify-content: flex-end;
}
.add-user-wrap .modal-popup .content .submit-section .add-button {
  border-radius: 5px;
  width: 145px;
}
.add-user-wrap .modal-popup .content .submit-section .discard {
  background: white;
  color: #528aea;
  border: 1px solid #528aea;
}
.add-user-wrap .modal-popupUserConfirmation {
  max-height: -moz-fit-content;
  max-height: fit-content;
  position: fixed !important;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 30px;
  border-radius: 5px;
  max-width: 80%;
  background-color: white;
  z-index: 11;
}
.add-user-wrap .modal-popupUserConfirmation .content {
  font-size: 16;
}
.form-section .input-text-wrap {
  vertical-align: top;
}
.tk11-dashboard .switch {
  margin-top: 10px;
}
.tk11-dashboard .custome-checkbox-wrap {
  margin-top: 9px;
}
.tk11-dashboard .title {
  margin-top: 12px;
  margin-left: 12px;
  width: 80%;
  font-size: 14px;
}
.tk11-dashboard .title.small-title {
  width: 75%;
}
.ph-10 {
  padding: 10px 0px;
}
.wt-270 {
  width: 270px;
}
.mt-30 {
  margin-top: 30px;
}

.add-company-wrap .modal-popup.small-modal {
  width: 330px;
  overflow: scroll;
  max-height: 600px;
  min-height: 150px;
}
.add-company-wrap .modal-popup.small-modal.add-department {
  width: 520px;
}
.add-company-wrap .modal-popup.small-modal.edit-department {
  width: 520px !important;
}
.add-company-wrap .modal-popup.small-modal .form-section .roles-holder {
  margin: 10px 0px;
  display: flex;
}
.add-company-wrap .modal-popup.small-modal .form-section .roles-holder .delete-icon {
  margin: 0px 10px;
  padding: 5px 7px;
  cursor: pointer;
}
.add-company-wrap .modal-popup.small-modal .form-section .roles-holder .select {
  display: inline-block;
  width: 280px;
}
.add-company-wrap .modal-popup.small-modal .submit-area {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  padding: 20px 0px!important;
  float: none;
}
.add-company-wrap .modal-popup.small-modal .submit-area .button-section {
  margin-left: 0px!important;
}
.add-company-wrap .modal-popup.small-modal .submit-area .button-section .add-button {
  margin: 0px!important;
}
.add-company-wrap .smallest {
  max-height: 300px!important;
  min-height: 300px!important;
}
.addMore {
  cursor: pointer;
  text-transform: uppercase;
  border-radius: 5px;
  border: 1px solid #528aea;
  background: #528aea;
  color: #fff;
  padding: 10px;
  display: inline-block;
}
.sub-label {
  font-size: 10px;
  color: #868e96;
  text-transform: uppercase;
}

.plants-depots-wrap .search-filter-section .select {
  margin-left: 20px;
}
.plants-depots-wrap .search-filter-section .add-plants-section {
  display: inline-block;
  vertical-align: middle;
  margin-left: 20px;
}
.plants-depots-wrap .No-data {
  margin-top: 40px;
}
.plants-depots-wrap .filter-clear-section {
  margin-top: 30px;
}
.plants-depots-wrap .download-section {
  margin-left: 6px;
  margin-top: 30px;
}
.plants-depots-wrap .plants-depots-list-section {
  margin-top: 40px;
}
.plants-depots-wrap .plants-depots-list-section .plants-depots-list {
  padding-top: 24px;
  padding-left: 10px;
}
.plants-depots-wrap .plants-depots-list-section .plants-depots-list.content {
  border-bottom: 1px solid #ececee;
  padding-bottom: 22px;
}
.plants-depots-wrap .plants-depots-list-section .plants-depots-list.content:hover {
  padding-left: 5px;
  border-bottom: 1px solid #ececee;
  padding-bottom: 22px;
  border: 1px solid rgba(0, 89, 251, 0.38);
  box-shadow: 0px 10px 20px 0px rgba(129, 172, 212, 0.45);
  cursor: pointer;
}
.plants-depots-wrap .plants-depots-list-section .plants-depots-list .branch-name {
  font-weight: 900;
}
.plants-depots-wrap .plants-depots-list-section .plants-depots-list .branch-name,
.plants-depots-wrap .plants-depots-list-section .plants-depots-list .branch-code,
.plants-depots-wrap .plants-depots-list-section .plants-depots-list .sap-branch-code,
.plants-depots-wrap .plants-depots-list-section .plants-depots-list .type,
.plants-depots-wrap .plants-depots-list-section .plants-depots-list .pin-code,
.plants-depots-wrap .plants-depots-list-section .plants-depots-list .zone,
.plants-depots-wrap .plants-depots-list-section .plants-depots-list .region,
.plants-depots-wrap .plants-depots-list-section .plants-depots-list .address {
  display: inline-block;
  vertical-align: middle;
  width: 120px;
  color: #535356;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.plants-depots-wrap .plants-depots-list-section .plants-depots-list .branch-name.header,
.plants-depots-wrap .plants-depots-list-section .plants-depots-list .branch-code.header,
.plants-depots-wrap .plants-depots-list-section .plants-depots-list .sap-branch-code.header,
.plants-depots-wrap .plants-depots-list-section .plants-depots-list .type.header,
.plants-depots-wrap .plants-depots-list-section .plants-depots-list .pin-code.header,
.plants-depots-wrap .plants-depots-list-section .plants-depots-list .zone.header,
.plants-depots-wrap .plants-depots-list-section .plants-depots-list .region.header,
.plants-depots-wrap .plants-depots-list-section .plants-depots-list .address.header {
  color: #ababae;
  font-size: 12px;
}
.plants-depots-wrap .plants-depots-list-section .plants-depots-list .sap-branch-code {
  width: 150px;
}
.plants-depots-wrap .plants-depots-list-section .plants-depots-list .type {
  width: 100px;
}
.plants-depots-wrap .plants-depots-list-section .plants-depots-list .pin-code,
.plants-depots-wrap .plants-depots-list-section .plants-depots-list .zone,
.plants-depots-wrap .plants-depots-list-section .plants-depots-list .region {
  width: 80px;
}
.plants-depots-wrap .plants-depots-list-section .plants-depots-list .address {
  width: 220px;
}
.plants-depots-wrap .plants-depots-list-section .plants-depots-list .fs-12 {
  font-size: 12px;
}
.plants-depots-wrap .plants-depots-list-section .plants-depots-list .theme-color {
  color: #528aea;
}
.plants-depots-wrap .plants-depots-list-section .plants-depots-list .error-color {
  color: #d9042a;
}
.plants-depots-wrap .plants-depots-list-section .plants-depots-list .sync-button {
  border: 1px solid;
  border-radius: 5px;
  width: 80px;
  padding: 2px;
}

.dept-root,
.role-root {
  max-width: 1029px;
  margin: 0 auto;
}
.dept-root .company-list-wrap,
.role-root .company-list-wrap {
  margin-top: 30px;
}
.dept-root .company-list-wrap .list-section,
.role-root .company-list-wrap .list-section {
  border-bottom: 1px solid rgba(212, 212, 212, 0.5);
  padding-bottom: 25px;
  padding-top: 15px;
}
.dept-root .company-list-wrap .list-section .checkbox-section,
.role-root .company-list-wrap .list-section .checkbox-section {
  display: inline-block;
  vertical-align: middle;
}
.dept-root .company-list-wrap .list-section .company-name-section,
.role-root .company-list-wrap .list-section .company-name-section {
  display: inline-block;
  vertical-align: middle;
  margin-left: 10px;
  width: 320px;
}
.dept-root .company-list-wrap .list-section .company-name-section .comany-logo,
.role-root .company-list-wrap .list-section .company-name-section .comany-logo,
.dept-root .company-list-wrap .list-section .company-name-section .compnay-details,
.role-root .company-list-wrap .list-section .company-name-section .compnay-details {
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
}
.dept-root .company-list-wrap .list-section .company-name-section .comany-logo,
.role-root .company-list-wrap .list-section .company-name-section .comany-logo {
  width: 50px;
  height: 35px;
  background-size: contain;
  background-repeat: no-repeat;
}
.dept-root .company-list-wrap .list-section .company-name-section .compnay-details,
.role-root .company-list-wrap .list-section .company-name-section .compnay-details {
  color: #1a2734;
  font-size: 15px;
  font-weight: 900;
  word-break: break-word;
}
.dept-root .company-list-wrap .list-section .company-name-section .compnay-details .date,
.role-root .company-list-wrap .list-section .company-name-section .compnay-details .date {
  color: #86868a;
  font-size: 12px;
}
.dept-root .company-list-wrap .list-section .admin-details,
.role-root .company-list-wrap .list-section .admin-details {
  display: inline-block;
  vertical-align: middle;
  width: 320px;
  font-size: 15px;
  color: #1a2734;
}
.dept-root .company-list-wrap .list-section .admin-details .admin-email,
.role-root .company-list-wrap .list-section .admin-details .admin-email {
  font-size: 15px;
  color: #1a2734;
  cursor: pointer;
}
.dept-root .company-list-wrap .list-section .admin-details .admin-email .role,
.role-root .company-list-wrap .list-section .admin-details .admin-email .role {
  margin-right: 10px;
}
.dept-root .company-list-wrap .list-section .admin-details .admin-pan,
.role-root .company-list-wrap .list-section .admin-details .admin-pan {
  font-size: 12px;
  color: #86868a;
}
.dept-root .company-list-wrap .list-section .account-type,
.role-root .company-list-wrap .list-section .account-type {
  display: inline-block;
  vertical-align: middle;
  width: 170px;
  font-size: 15px;
  color: #1a2734;
}
.dept-root .company-list-wrap .list-section .status,
.role-root .company-list-wrap .list-section .status {
  display: inline-block;
  vertical-align: middle;
  width: 140px;
}
.dept-root .company-list-wrap .list-section .status .action-icons,
.role-root .company-list-wrap .list-section .status .action-icons {
  margin-right: 10px;
  padding: 5px 7px;
  cursor: pointer;
}
.dept-root .company-list-wrap .list-section .center,
.role-root .company-list-wrap .list-section .center {
  text-align: center;
}
.dept-root .company-list-wrap .list-section.roles,
.role-root .company-list-wrap .list-section.roles {
  width: 49%;
  display: inline-block;
}
.dept-root .company-list-wrap .list-section.header,
.role-root .company-list-wrap .list-section.header {
  font-weight: 600;
  padding-top: 0;
  color: #1a2734;
  font-size: 15px;
}
.dept-root .company-list-wrap .list-section.content:hover,
.role-root .company-list-wrap .list-section.content:hover {
  border: 1px solid rgba(0, 89, 251, 0.38);
  box-shadow: 0px 10px 20px 0px rgba(129, 172, 212, 0.45);
}
.submit-section {
  text-align: center;
  padding: 20px;
}
.dept_role_download {
  margin-top: 50px;
  margin-left: 30px;
}
.dept_role_upload {
  margin-top: 50px;
  margin-left: 12px;
}

.dept-roles-root .home-navigation-holder {
  min-width: 50px;
  max-width: 200px;
  margin: 20px 0px;
}
.delete-icon {
  background-image: url(/images/delete.svg);
  background-repeat: no-repeat;
  height: 23px;
  width: 23px;
  background-size: contain;
  margin: 0 5px;
  cursor: pointer;
  display: inline-block;
}
.button-classic {
  height: 30px;
  font-size: 14px;
  padding: 0 20px;
  border-radius: 5px;
}
.roles-class {
  margin-left: 3rem !important;
}
.add-role-modal {
  width: 390px !important;
  max-height: 361px !important;
  min-height: 361px !important;
}

.contracts-management-wrap {
  max-width: 900px;
  margin: 0 auto;
  padding: 30px;
}
.contracts-management-wrap .contracts-holder {
  display: flex;
  max-width: 800px;
  margin: 0 auto;
  margin-bottom: 30px;
}
.contracts-management-wrap .contracts-holder .description {
  width: 419px;
}
.contracts-management-wrap .contracts-holder .description .description-block {
  margin: 40px 5px 0 5px;
}
.contracts-management-wrap .contracts-holder .action {
  width: 350px;
}
.contracts-management-wrap .contracts-holder .action .action-block {
  /*margin: 25px 5px 0 5px;*/
  position: relative;
  height: 60px;
  padding-top: 30px;
}
.contracts-management-wrap .contracts-holder .action .action-block .select {
  width: 350px;
}
.contracts-management-wrap .contracts-holder .action .action-block .amount {
  width: 90px;
  padding: 7px;
}
.contracts-management-wrap .contracts-holder .action .action-block .inr,
.contracts-management-wrap .contracts-holder .action .action-block .inr-yes-no {
  font-size: 10px;
  position: absolute;
  top: -12px;
  color: #86868a;
  font-family: 'Avenir', sans-serif;
  line-height: normal;
  letter-spacing: normal;
}
.contracts-management-wrap .contracts-holder .action .action-block .inr-yes-no {
  left: 140px;
}
.contracts-management-wrap .contracts-holder .action .action-block .inr {
  left: 0;
}
.contracts-management-wrap .contracts-holder .action .action-block .bidding-rate {
  display: flex;
  width: 372px;
}
.contracts-management-wrap .contracts-holder .action .action-block .bidding-rate .select {
  margin-right: 20px;
}
.contracts-management-wrap .contracts-holder .action .bidding-time {
  display: flex;
  width: 410px;
}
.contracts-management-wrap .contracts-holder .action .bidding-time .time-holder {
  margin-left: 20px;
  margin-top: 6px;
}
.contracts-management-wrap .contracts-holder .action .bidding-time .time-holder .days-hours-holder .dhm-label {
  top: -27px;
}
.contracts-management-wrap .contracts-holder .action .bidding-time .time-holder .days-hours-holder .times {
  top: -16px;
}
.contracts-management-wrap .contracts-holder .action .dhm {
  display: flex !important;
}
.contracts-management-wrap .apply-settings {
  display: flex;
}
.contracts-management-wrap .apply-settings .label {
  font-family: 'avenir', sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.2px;
  color: #535356;
  margin-left: 15px;
}
.contracts-management-wrap .heading {
  background: #528aea;
  color: #fff;
  padding: 20px 12px;
  font-family: 'avenir', sans-serif;
  font-size: 20px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  display: block;
}
.contracts-management-wrap .heading.sub-heading {
  padding: 2px 16px;
  font-size: 18px;
}
.contracts-management-wrap .button-holder {
  text-align: center;
}
.contracts-management-wrap .golden-box {
  border: 1px solid #528aea;
  margin: 30px 0px;
}
.contracts-management-wrap .golden-box.golden-box-sub {
  margin: 10px 5px;
}
.contracts-management-wrap .golden-box .heading {
  border-bottom: 1px solid #528aea;
}
.contracts-management-wrap .golden-box .golden-box-content {
  display: flex;
  justify-content: space-between;
}
.contracts-management-wrap .golden-box .golden-box-content .tg {
  border-collapse: collapse;
  border-color: #9ABAD9;
  border-spacing: 0;
}
.contracts-management-wrap .golden-box .golden-box-content .tg td {
  background-color: #EBF5FF;
  border-color: #9ABAD9;
  border-style: solid;
  border-width: 1px;
  color: #444;
  font-family: Arial, sans-serif;
  font-size: 14px;
  overflow: hidden;
  padding: 10px 5px;
  word-break: normal;
}
.contracts-management-wrap .golden-box .golden-box-content .tg th {
  background-color: #409cff;
  border-color: #9ABAD9;
  border-style: solid;
  border-width: 1px;
  color: #fff;
  font-family: Arial, sans-serif;
  font-size: 14px;
  font-weight: normal;
  overflow: hidden;
  padding: 10px 5px;
  word-break: normal;
}
.contracts-management-wrap .golden-box .golden-box-content .tg .tg-0lax {
  text-align: left;
  vertical-align: top;
  height: 30px;
  width: 200px;
}
.contracts-management-wrap .golden-box .golden-box-content .input-holder {
  position: relative;
  margin: 50px 20px;
}
.contracts-management-wrap .golden-box .golden-box-content .input-holder .input {
  border: none;
  border-bottom: 1px solid #ededef;
  width: 200px;
  padding: 7px;
}
.contracts-management-wrap .golden-box .golden-box-content .input-holder .labels {
  position: absolute;
  top: -20px;
  left: 0;
  font-family: 'avenir', sans-serif;
  font-size: 12px;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #868e96;
}
.contracts-management-wrap .golden-box .golden-box-content .currecy_converter .input-holder {
  margin: 15px 20px;
}
.contracts-management-wrap .golden-box .golden-box-content .add-role-section {
  padding: 30px 20px 20px 15px;
}
.contracts-management-wrap .golden-box .golden-box-content .add-role-section .add-items {
  display: inline-block;
  vertical-align: middle;
  margin-right: 30px;
}
.contracts-management-wrap .golden-box .golden-box-display {
  display: flex;
  justify-content: space-around;
  border: none;
  border-bottom: 1px solid #ededef;
  padding: 12px;
}
.contracts-management-wrap .golden-box .golden-box-display .headers {
  margin: 0px 20px;
  font-family: 'avenir', sans-serif;
  font-size: 12px;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #868e96;
  width: 150px;
}
.contracts-management-wrap .golden-box .golden-box-display .details {
  width: 150px;
  margin: 0px 20px;
}
.contracts-management-wrap .golden-box .golden-box-display .details .labels {
  margin-top: 6px;
}
.contracts-management-wrap .golden-box .golden-box-display .details .add-button {
  margin: 0px;
}
.contracts-management-wrap .golden-box .add-button {
  padding: 10px 15px;
}
.contracts-management-wrap .golden-box .button-holder {
  padding: 20px;
  text-align: right;
}
.contracts-management-wrap .golden-box .button-holder .add-button {
  font-size: 13px;
}
.drop-open {
  transition: height 0.5s;
  margin: 16px 0px;
}
.drop-open .user-shipment-accessibility-list-wrap {
  padding: 30px 10px 10px 15px;
}
.drop-open .user-shipment-accessibility-list-wrap .user-name,
.drop-open .user-shipment-accessibility-list-wrap .role-name,
.drop-open .user-shipment-accessibility-list-wrap .accessibility-status,
.drop-open .user-shipment-accessibility-list-wrap .list-action {
  display: inline-block;
  vertical-align: middle;
}
.drop-open .user-shipment-accessibility-list-wrap .user-name {
  width: 225px;
}
.drop-open .user-shipment-accessibility-list-wrap .role-name {
  width: 165px;
}
.drop-open .user-shipment-accessibility-list-wrap .accessibility-status {
  width: 300px;
}
.drop-open .user-shipment-accessibility-list-wrap.header {
  font-size: 12px;
  font-weight: 900;
  color: #868e96;
}
.drop-close {
  visibility: hidden;
  height: 0px;
  transition: height 0.2s;
}
.open-arrow,
.closed-arrow {
  display: inline-block;
  transition: all 0.3s;
  cursor: pointer;
  position: relative;
  float: right;
}
.open-arrow input,
.closed-arrow input {
  position: absolute;
  left: 0;
  opacity: 0;
  cursor: pointer;
}
.open-arrow {
  transform: rotate(180deg);
}
.add-button {
  cursor: pointer;
  height: 40px;
  border-radius: 25px;
  color: #fff;
  background: #528aea;
  margin: 0px 20px;
  padding: 13px 30px;
  display: inline-block;
  border: 1px solid #528aea;
}
div.add-button {
  color: #528aea;
  background: #fff;
  border: 1px solid #528aea;
}
div.add-button.add-level {
  margin: 5px 5px;
}
button.add-button {
  font-size: 14px;
}
.arrow-icon {
  background-image: url(/images/drop-up-arrow.svg);
  background-repeat: no-repeat;
  height: 23px;
  width: 23px;
  background-size: contain;
  margin: 0 5px;
  cursor: pointer;
  float: right;
  transition-delay: 0.2s;
  transition-duration: 0.2s;
  -webkit-transition-duration: 0.5s;
  -webkit-transition-delay: 0.2s;
}
.rotate {
  transform: rotate(180deg);
}
.contracts-management-grid-wrap {
  width: 100%;
}
.contracts-management-grid-wrap .description-block,
.contracts-management-grid-wrap .action-block {
  display: inline-block;
  vertical-align: middle;
}
.contracts-management-grid-wrap .description-block {
  width: 51%;
}
.contracts-management-grid-wrap .action-block {
  width: 48%;
}
.contracts-management-grid-wrap .contracts-management-row {
  padding: 15px 0;
}
.contracts-management-grid-wrap .action-block.indent-time .yes-no-root {
  margin-right: 20px;
}
.contracts-management-grid-wrap .action-block .select {
  width: 350px;
}
.contracts-management-grid-wrap .action-block .select-uom {
  width: 150px;
  margin-left: 5px;
  display: inline-block;
}
.contracts-management-grid-wrap .action-block .bidding-rate {
  display: flex;
  width: 372px;
}
.contracts-management-grid-wrap .bidding-time {
  display: flex;
  width: 470px;
}
.contracts-management-grid-wrap .bidding-time .time-holder {
  margin-left: 15px;
  margin-top: 4px;
}
.contracts-management-grid-wrap .bidding-time .time-holder .days-hours-holder .dhm-label {
  text-align: left;
  width: 93px;
}
.contracts-management-grid-wrap .bidding-time .time-holder .days-hours-holder .dhm-input {
  width: 70px;
  padding: 7px;
}
.contracts-management-grid-wrap .amount {
  width: 90px;
  padding: 7px;
}
.contract-error {
  text-align: center;
  color: red;
  font-size: 14px;
  margin-top: 6px;
}
.contracts-management-row.currecy_converter {
  display: flex;
}
.contracts-management-row.currecy_converter .input-holder {
  margin: 15px 20px;
}
.contracts-management-row .css-10nd86i.select {
  width: 338px;
}
.approval-row .select {
  width: 300px;
  margin: 5px;
  display: inline-block;
}
.level-header {
  margin: 5px 0px;
  border-bottom: 1px solid grey;
  padding: 5px;
  color: #528aea;
  font-size: 16px;
  font-weight: 900;
}
.dInBlock {
  display: inline-block;
}
.select-approver {
  margin-left: 20px;
  width: 300px;
}
.input_currency {
  width: 150px;
  margin: 5px;
  height: 30px;
  display: inline-block;
  text-align: center;
}
.currencey_name {
  margin-left: 20px;
}
.currencey_name .currency_label {
  text-align: center;
}
.product-list {
  width: 100%;
  display: flex;
}
.product-list .search-text-wrap .text-search {
  margin-bottom: 10px;
  margin-left: 10px;
}
.product-list .col-2 {
  width: 48%;
  box-shadow: 0 0 27px 4px rgba(69, 68, 68, 0.07);
  max-height: 720px;
  overflow: scroll;
}
.product-list .header {
  padding: 10px;
  text-align: center;
  display: flex;
  color: #528aea;
  justify-content: space-between;
  height: 40px;
}
.product-list .header .add-button {
  padding: 2px 18px;
  height: 20px;
}
.product-list .lists {
  padding: 5px 10px;
  display: flex;
  border-top: 1px solid hsla(0, 0%, 59.2%, 0.2);
}
.product-list .ml-10 {
  margin-left: 10px;
}
.product-list .mr-10 {
  margin-right: 10px;
}
.product-list .space-btw {
  justify-content: space-between;
}
.intial-product-list {
  border-bottom: 1px solid #ececee;
  margin-left: 49px;
  -webkit-user-select: all;
          user-select: all;
  cursor: pointer;
}
.add-product-button {
  cursor: pointer;
  border-radius: 25px;
  color: #1362e9;
  margin: 0px 20px;
  display: inline-block;
}
.select-all-product-list {
  padding-left: 40%;
}
.product-header-name {
  color: #528aea;
  /* border-bottom: 4px solid; */
  margin-bottom: 4px;
  margin-left: 22px;
}
.col-4 {
  width: 25%;
  box-shadow: 0 0 27px 4px rgba(69, 68, 68, 0.07);
  max-height: 720px;
  text-align: center;
}
.seleted-product-list {
  border-bottom: 1px solid #ececee;
  margin-left: 49px;
  cursor: pointer;
  -webkit-user-select: all;
          user-select: all;
}
.product-details-list {
  border-bottom: 1px solid #ececee;
  cursor: pointer;
  min-height: 45px;
  color: black;
  padding-top: 10px;
}
.product-fields {
  width: 80%;
  height: 30px;
  margin-left: 20px;
  padding: 0px 10px;
}
.product-error-message {
  padding-left: 4%;
  color: red;
}
.user-select-product {
  border-bottom: 1 px solid #ececee;
  margin-left: 49 px;
  cursor: pointer;
  background-color: #a1a1a185;
}
.right-single-arrow {
  padding-top: 120px;
}
.right-double-arrow {
  padding-top: 8px;
}
.left-single-arrow {
  padding-top: 10px;
}
.equipment-option .equipment_select__control {
  border: none;
}
.equipment-option input {
  height: 30px;
}

.yes-no-root {
  display: inline-block;
  margin-right: 50px;
}
.yes-no-root .yes-no-holder {
  display: flex;
  width: 90px;
  border: 1px solid #d4d4d4;
  border-right: none;
}
.yes-no-root .yes-no-holder .option {
  padding: 4px 12px;
  border-right: 1px solid #d4d4d4;
  text-align: center;
  font-family: 'Avenir', sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #d4d4d4;
  cursor: pointer;
}
.yes-no-root .yes-no-holder .selected-option {
  background: #528aea;
  color: white;
}

.days-hours-min-root {
  display: flex;
}
.days-hours-min-root .days-hours-holder {
  position: relative;
  margin-right: 14px;
}
.days-hours-min-root .days-hours-holder .dhm-input {
  width: 60px;
  padding: 7px;
}
.days-hours-min-root .days-hours-holder .dhm-label {
  position: absolute;
  top: -16px;
  font-family: 'Avenir', sans-serif;
  font-size: 10px;
  font-weight: 500;
  color: #86868a;
}

.branch-list-root .modal-popup .branch-detail {
  display: flex;
  margin: 20px;
}
.branch-list-root .modal-popup .branch-detail .branch {
  margin: 2px 0px 0px 15px;
}
.branch-list-root .modal-popup .branch-detail .branch.selectAll {
  font-family: 'avenir', sans-serif;
  font-weight: 800;
  margin-top: 0;
}

.fuel-escalation-wrap {
  padding: 20px;
}
.fuel-escalation-wrap .select-rfq {
  display: inline-block;
  vertical-align: middle;
  width: 200px;
  height: 26px;
  margin-right: 10px;
}
.fuel-escalation-wrap .data-picker {
  display: inline-block;
  vertical-align: sub;
  margin-left: 10px;
}
.fuel-escalation-wrap .data-picker .label {
  font-size: 10px;
  color: #86868a;
}
.fuel-escalation-wrap .data-picker .react-datepicker-manager,
.fuel-escalation-wrap .data-picker .react-datepicker-wrapper,
.fuel-escalation-wrap .data-picker .react-datepicker__input-container,
.fuel-escalation-wrap .data-picker .react-datepicker__input-container input {
  width: 142px;
  height: 38px;
  font-size: 16px;
}
.fuel-escalation-wrap .input-section-wrap {
  display: inline-block;
  vertical-align: middle;
  margin-left: 10px;
}
.fuel-escalation-wrap .input-section-wrap .label {
  color: #86868a;
  font-size: 10px;
  font-weight: 500;
}
.fuel-escalation-wrap .input-section-wrap .input-section .input {
  width: 110px;
  height: 38px;
  font-size: 16px;
}
.fuel-escalation-wrap .list-wrap-fuel {
  width: 100%;
  margin-top: 30px;
}
.fuel-escalation-wrap .list-wrap-fuel .list-fuel {
  margin-bottom: 10px;
}
.fuel-escalation-wrap .list-wrap-fuel .list-fuel.header {
  font-size: 12px;
  font-weight: 900;
  color: #868e96;
}
.fuel-escalation-wrap .list-wrap-fuel .list-fuel .rfq-id,
.fuel-escalation-wrap .list-wrap-fuel .list-fuel .from,
.fuel-escalation-wrap .list-wrap-fuel .list-fuel .to,
.fuel-escalation-wrap .list-wrap-fuel .list-fuel .escalation-val,
.fuel-escalation-wrap .list-wrap-fuel .list-fuel .action {
  display: inline-block;
  width: 20%;
}
.fuel-escalation-wrap .list-wrap-fuel .list-fuel .action {
  width: 10%;
}
.fuel-escalation-wrap .list-wrap-fuel .list-fuel .escalation-val {
  width: 30%;
}

.location-network .loaction-btn {
  margin: 10px 0px;
}
.location-network .button-classic {
  margin-left: 10px;
}
.location-network .select {
  width: 130px;
  margin-right: 20px;
}
.location-network table {
  border: white;
}
.location-network .chain-details-section {
  margin-top: 30px;
}
.location-network .chain-details-section .list-section {
  border-bottom: 1px solid rgba(212, 212, 212, 0.5);
  padding-bottom: 25px;
  padding-top: 20px;
  display: flex;
}
.location-network .chain-details-section .list-section.content:hover {
  border: 1px solid rgba(0, 89, 251, 0.38);
  box-shadow: 0px 10px 20px 0px rgba(129, 172, 212, 0.45);
}
.location-network .chain-details-section .list-section.header {
  font-weight: 600;
  padding-top: 0;
  color: #1a2734;
  font-size: 14px;
}
.location-network .chain-details-section .tabel-header {
  width: 170px;
  display: inline-block;
  vertical-align: middle;
  white-space: nowrap;
  /* Prevent line wrapping */
  font-size: 14px;
  padding: 5px;
}
.location-network .chain-details-section .tabel-header.address {
  width: 300px;
}
.location-network .chain-details-section .vol-th {
  background-color: #6464eb;
  color: white;
  border: #6464eb;
  height: 70px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.location-network .chain-details-section .vol-td {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.location-network .chain-details-section .vol-description-td {
  white-space: normal;
  width: 150px;
}
.location-network .chain-details-section .vol-branch-td {
  text-align: center;
  margin-left: 1rem;
  white-space: normal;
}
.location-network .route-chain-details-section {
  margin-top: 30px;
  overflow: auto;
  width: 110%;
}
.location-network .route-chain-details-section .list-section {
  width: max-content;
  border-bottom: 1px solid rgba(212, 212, 212, 0.5);
  padding-bottom: 25px;
  padding-top: 20px;
}
.location-network .route-chain-details-section .list-section.content:hover {
  border: 1px solid rgba(0, 89, 251, 0.38);
  box-shadow: 0px 10px 20px 0px rgba(129, 172, 212, 0.45);
}
.location-network .route-chain-details-section .list-section.header {
  font-weight: 600;
  padding-top: 0;
  color: #1a2734;
  font-size: 14px;
}
.location-network .route-chain-details-section .tabel-header {
  width: 170px;
  display: inline-block;
  vertical-align: middle;
}
.location-network .route-chain-details-section .tabel-header.address {
  width: 300px;
}
.location-network .refresh {
  display: inline-block;
  width: 33%;
}
.location-network .refresh .long {
  margin: 0px 65px;
}
.location-network .refresh-icon {
  background-image: url(/images/refresh-button.svg);
  background-repeat: no-repeat;
  height: 15px;
  width: 15px;
  background-size: contain;
  margin: 0px 58px;
  cursor: pointer;
  display: inline-block;
  position: absolute;
}
.location-network .action-icon {
  background-repeat: no-repeat;
  height: 18px;
  width: 18px;
  background-size: contain;
  cursor: pointer;
  display: inline-block;
  padding: 5px;
  margin: 5px;
}
.location-network .sync-button {
  border: 1px solid;
  border-radius: 5px;
  width: 80px;
  padding: 2px;
}
.location-network .error-color {
  color: #d9042a;
}
.vol-act {
  margin-left: 70px;
}
.cdsTable th {
  width: 1200px;
}
.cdsTable tr {
  width: 1200px;
}
.cdsTable td {
  border: none;
  white-space: nowrap;
  word-wrap: break-word;
}
.depobtn-div {
  margin-left: 750px;
}
.depots-btn {
  border: white;
}
.location-network .chain-details-section .tabel-header {
  text-wrap: wrap;
}

.add-chain .overlay {
  position: fixed;
  z-index: 10;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(27, 33, 67, 0.9);
}
.add-chain .modal-popup {
  max-height: 610px;
  overflow: scroll;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 5px;
  width: 1120px;
  min-height: 500px;
  background-color: white;
  z-index: 11;
}
.add-chain .modal-popup .pop-heading {
  background-color: #f4f4f4;
  padding: 18px;
  font-family: 'avenir', sans-serif;
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #528aea;
  text-align: center;
}
.add-chain .modal-popup .content {
  width: 1065px;
  margin: 0px 0px 20px 30px;
  padding: 0px 0px 20px 30px;
  border-radius: 6px;
  box-shadow: 0 0 27px 4px #dbdbe4;
}
.add-chain .modal-popup .content .heading-section {
  margin-top: 15px;
  font-size: 20px;
  color: #1a2734;
  font-family: 'avenir-medium', sans-serif;
}
.add-chain .modal-popup .content .heading-section.network-consignee {
  padding-top: 20px;
}
.add-chain .modal-popup .content .details {
  margin-top: 20px;
}
.add-chain .modal-popup .data-picker {
  display: inline-block;
  width: 33%;
}
.add-chain .modal-popup .data-picker .label {
  font-size: 10px;
  color: #86868a;
  margin-bottom: 10px;
}
.add-chain .modal-popup .data-picker .react-datepicker-manager,
.add-chain .modal-popup .data-picker .react-datepicker-wrapper,
.add-chain .modal-popup .data-picker .react-datepicker__input-container,
.add-chain .modal-popup .data-picker .react-datepicker__input-container input {
  width: 277px;
  height: 38px;
  font-size: 16px;
}
.add-chain .modal-popup .data-picker .rc-time-picker-input {
  height: 38px;
  font-size: 15px;
  color: #252424;
}
.add-chain .modal-popup .details-section .details {
  margin-top: 20px;
}
.add-chain .modal-popup .details-section .details .select-wrap {
  width: 33%;
  display: inline-block;
  vertical-align: baseline;
}
.add-chain .modal-popup .details-section .details .select-wrap .label {
  font-size: 10px;
  color: #868e96;
  margin-bottom: 10px;
}
.add-chain .modal-popup .details-section .details .select-wrap .select {
  width: 277px;
}
.point-of-contact .heading-section {
  padding: 18px 0px;
}
.point-of-contact .list-section {
  border-bottom: 1px solid rgba(212, 212, 212, 0.5);
  padding-bottom: 25px;
  padding-top: 15px;
}
.point-of-contact .list-sectionheader {
  font-weight: 600;
  padding-top: 0;
  color: #1a2734;
  font-size: 14px;
}
.point-of-contact .tabel-header {
  width: 122px;
  display: inline-block;
  vertical-align: middle;
  margin-left: 20px;
}
.point-of-contact .tabel-header.name {
  width: 207px;
}
.point-of-contact .tabel-header.email {
  width: 350px;
}
.point-of-contact .tabel-header.code {
  margin-left: 20px;
}
.point-of-contact .contact-btn {
  margin-right: 10px;
  margin-top: 10px;
}
.point-of-contact .tabel-header-input {
  width: 122px;
  display: inline-block;
  vertical-align: middle;
  font-size: 16px;
  border: none;
  margin-left: 20px;
}
.point-of-contact .tabel-header-input.name {
  width: 207px;
}
.point-of-contact .tabel-header-input.email {
  width: 350px;
}
.point-of-contact .tabel-header-input.code-value {
  padding: 7px 0px;
  border: 1px solid lightgray;
  border-radius: 5px;
}
.loading-section {
  display: inline-block;
  width: 320px;
}
.loading-section .branch {
  display: inline-block;
  margin-left: 5px;
  vertical-align: middle;
  height: 28px;
}
.Add-logistics-btn {
  margin: 10px;
  float: right;
}
.borderRed {
  border: solid 1px red;
}

.reconciliation .home-navigation-holder {
  min-width: 350px;
  max-width: 400px;
}
.reconciliation .home-navigation-holder.mainTabs {
  max-width: 920px;
}
.reconciliation .select-wrap {
  display: inline-block;
  vertical-align: middle;
  width: 33%;
  margin-bottom: 20px;
}
.reconciliation .select-wrap .label {
  font-size: 10px;
  color: #868e96;
  margin-bottom: 10px;
}
.reconciliation .select-wrap .select {
  width: 277px;
}
.reconciliation .minimum-charge {
  display: inline-block;
}
.reconciliation .minimum-charge .input-text-wrap {
  width: 100%;
}
.reconciliation .minimum-charge .input-text-wrap .text-input {
  width: 150px;
}
.reconciliation .approval-btn {
  text-align: right;
}
.reconciliation .approval-btn .button-classic {
  margin-right: 10px;
  margin-top: 10px;
}
.reconciliation .header {
  background: #528aea;
  color: #fff;
  padding: 10px 12px;
  font-family: 'Avenir', sans-serif;
  font-size: 18px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
}
.reconciliation .credit-master .header {
  background: #528aea;
  color: #fff;
  padding: 10px 12px;
  font-family: 'Avenir', sans-serif;
  font-size: 18px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
}
.reconciliation .credit-master .fuel-btn {
  margin-top: 10px;
  text-align: center;
}
.reconciliation .credit-master .fuel-details {
  margin-top: 10px;
  margin-left: 35px;
}
.reconciliation .credit-master .fuel-details .fuel-rate {
  display: inline-block;
}
.reconciliation .credit-master .fuel-details .freight-rate {
  display: inline-block;
  width: 25%;
}
.reconciliation .credit-master .fuel-details .freight-rate .input-text-wrap {
  width: 100%;
}
.reconciliation .credit-master .fuel-details .freight-rate .input-text-wrap .text-input {
  width: 200px;
}
.reconciliation .credit-master .fuel-details .input-text-wrap {
  width: 10%;
}
.reconciliation .credit-master .fuel-details .input-text-wrap .text-input {
  width: 92px;
}
.reconciliation .credit-master .fuel-details .DateRangePickerInput_arrow {
  display: none;
}
.reconciliation .credit-master .fuel-details .select-wrap {
  display: inline-block;
  vertical-align: middle;
  width: 33%;
  margin-bottom: 20px;
  margin-left: 50px;
}
.reconciliation .credit-master .fuel-details .select-wrap .label {
  font-size: 10px;
  color: #868e96;
  margin-bottom: 10px;
}
.reconciliation .fuel-escalation-table .list-section {
  font-weight: 600;
  color: #1a2734;
  font-size: 15px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgba(212, 212, 212, 0.5);
}
.reconciliation .fuel-escalation-table .list-section .table-header {
  width: 25%;
  padding: 10px 0px;
  margin-left: 30px;
}
.reconciliation .fuel-escalation-table .list-section .table-header.detention-grace {
  width: 10%;
}
.reconciliation .fuel-escalation-table .list-section .table-header.product {
  width: 50%;
}
.reconciliation .fuel-escalation-table .list-section .table-header.weight {
  width: 75%;
}
.reconciliation .fuel-escalation-table .list-section .table-header.weight.weight-detention {
  width: 100%;
}
.reconciliation .fuel-escalation-table .list-section .table-header.weight .label {
  text-align: center;
  border-bottom: 1px solid rgba(212, 212, 212, 0.5);
  margin-bottom: 7px;
}
.reconciliation .fuel-escalation-table .list-section .table-header.weight .slab-weight {
  display: flex;
  justify-content: space-between;
}
.reconciliation .fuel-escalation-table .list-section .table-header.weight .slab-weight div {
  width: 25%;
  text-align: center;
}
.reconciliation .fuel-escalation-table .list-section .table-header.weight .slab-weight div.vehicle-type {
  width: 50%;
}
.reconciliation .fuel-escalation-table .list-section .table-row {
  width: 25%;
  padding: 10px 0px;
  margin-left: 30px;
}
.reconciliation .fuel-escalation-table .list-section .table-row.detention-grace {
  width: 10%;
}
.reconciliation .fuel-escalation-table .list-section .table-row.product {
  width: 50%;
}
.reconciliation .fuel-escalation-table .list-section .table-row.product .product-tooltip {
  display: none;
}
.reconciliation .fuel-escalation-table .list-section .table-row.product:hover .product-tooltip {
  display: block;
  bottom: 47px;
  background: #fff;
  box-shadow: 0 4px 24px 0 rgba(37, 38, 94, 0.1);
  border-radius: 5px;
}
.reconciliation .fuel-escalation-table .list-section .table-row.product:hover .product-tooltip .list {
  padding: 5px;
}
.reconciliation .fuel-escalation-table .list-section .table-row .input-text-wrap {
  margin-bottom: 0px;
}
.reconciliation .fuel-escalation-table .list-section .event {
  width: 50%;
}
.reconciliation .fuel-escalation-table .list-section .action {
  width: 10%;
}
.reconciliation .fuel-escalation-table .list-section .action .switch {
  margin-top: 7px;
}
.reconciliation .fuel-escalation-table .list-section .action.delete-icon {
  margin: 15px 5px;
}
.reconciliation .fuel-escalation-table .list-section .amount {
  width: 20%;
}
.reconciliation .fuel-escalation-table .list-section .uom {
  width: 30%;
}
.reconciliation .fuel-escalation-table .list-section .vehicle-type {
  width: 60%;
}
.reconciliation .fuel-escalation-table .list-section .content {
  width: 20%;
}
.reconciliation .fuel-escalation-table .oda-charges .table-row {
  padding: 0px;
}
.reconciliation .fuel-escalation-table .oda-charges .table-row.align-center {
  display: flex;
  align-items: center;
}
.reconciliation .fuel-escalation-table .oda-charges .table-row .input-text-wrap {
  margin-bottom: 7px;
}
.reconciliation .fuel-escalation-table .oda-charges .table-row .input-text-wrap .text-input {
  width: 120px;
}
.reconciliation .fuel-escalation-table .oda-charges .table-row.slab-weight {
  width: 75%;
}
.reconciliation .fuel-escalation-table .oda-charges .table-row.slab-weight.slab-weight-detention {
  width: 100%;
}
.reconciliation .fuel-escalation-table .oda-charges .table-row.slab-weight .slab-weight-data {
  display: flex;
  justify-content: space-between;
}
.reconciliation .fuel-escalation-table .oda-charges .table-row.slab-weight .slab-weight-data.row-data {
  margin-bottom: 5px;
  border-bottom: 1px solid rgba(212, 212, 212, 0.5);
}
.reconciliation .fuel-escalation-table .oda-charges .table-row.slab-weight .slab-weight-data div {
  width: 25%;
}
.reconciliation .fuel-escalation-table .oda-charges .table-row.slab-weight .slab-weight-data div.vehicle-type {
  width: 50%;
}
.reconciliation .fuel-escalation-table .oda-charges .table-row.slab-weight .slab-weight-data div.vehicle-type .vehicle-type-list {
  width: 100%;
}
.reconciliation .fuel-escalation-table .oda-charges .table-row.slab-weight .slab-weight-data .slab-action {
  display: flex;
  margin-top: 10px;
}
.reconciliation .fuel-escalation-table .oda-charges .table-row.slab-weight .slab-weight-data .input-text-wrap {
  margin-bottom: 7px;
}
.reconciliation .fuel-escalation-table .oda-charges .table-row.slab-weight .slab-weight-data .input-text-wrap .text-input {
  width: 100px;
}
.reconciliation .debit-master .late-delivery {
  margin-top: 10px;
  padding: 10px;
}
.reconciliation .debit-master .late-delivery .grace {
  display: inline-block;
  width: 33%;
}
.reconciliation .debit-master .late-delivery .grace .input-text-wrap {
  width: 30%;
}
.reconciliation .debit-master .late-delivery .grace .input-text-wrap .text-input {
  width: 92px;
}
.reconciliation .debit-master .late-delivery .amount {
  display: inline-block;
  width: 23%;
}
.reconciliation .debit-master .late-delivery .amount .input-text-wrap {
  width: 30%;
}
.reconciliation .debit-master .late-delivery .amount .text-input {
  width: 180px;
}
.reconciliation .debit-master .late-delivery .select-wrap {
  display: inline-block;
  vertical-align: middle;
  width: 33%;
  margin-bottom: 20px;
}
.reconciliation .debit-master .late-delivery .select-wrap .label {
  font-size: 10px;
  color: #868e96;
  margin-bottom: 10px;
}
.reconciliation .debit-master .late-delivery .select-wrap .select {
  width: 277px;
}
.reconciliation .debit-master .non-register-vendor .list-section {
  border: none;
}
.reconciliation .debit-master .non-register-vendor .list-section .table-row {
  padding: 10px 0px;
}
.reconciliation .rfq-input {
  width: 80%;
  height: 3;
  min-height: 33px;
  font-size: 16px;
  border: 1px solid #cccccc;
  border-radius: 6px 5px 5px 5px;
  padding: 7px 9px;
}
.reconciliation .type-select {
  margin-top: 10px;
  text-align: center;
}
.reconciliation .type-select .select {
  width: 277px;
}
.reconciliation .fixed-define-process .heading {
  font-size: 16px;
}
.reconciliation .drop-section {
  margin-top: 20px;
  margin-bottom: 20px;
}
.reconciliation .fixed-define {
  margin: 10px 0px;
}
.DateRangePicker .DateRangePickerInput.DateRangePickerInput_1:after,
.DateRangePickerInput.DateRangePickerInput_1:after {
  content: "to";
  position: absolute;
  left: 47%;
  transform: translateX(-50%);
  margin-left: 1px;
  font-family: 'Proximanova-Bold', sans-serif;
  color: #212224;
  top: 10px;
}
.DateRangePicker input#startDate,
.DateRangePicker input#endDate {
  height: 38px;
  border: 1px solid hsl(0, 0%, 80%);
  border-radius: 5px;
  font-size: 16px;
  color: #212224;
}
.DateRangePicker input#startDate {
  border-right: none;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  background-image: none;
}
.DateRangePicker input#endDate {
  border-left: none;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  background-image: none;
}
.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
  color: #fff !important;
  background: #3f51b5 !important;
  border: 1px double #3f51b5 !important;
}
.CalendarDay__selected_span,
.CalendarDay__hovered_span {
  color: #3f51b5 !important;
  background: rgba(63, 81, 181, 0.1) !important;
  border-color: rgba(63, 81, 181, 0.1) !important;
}
.DateRangePickerInput_arrow_svg {
  display: none;
}
.add-row.detention .modal-popup {
  width: 1300px;
  padding: 20px 20px 15px 20px;
}
.add-row.detention .table-header {
  margin-left: 20px;
}
.add-row.detention .table-header.table-header-max {
  width: 40%;
}
.add-row.detention .table-header.table-header-max-vehicle-type {
  width: 65%;
}
.add-row.detention .select {
  width: 100px;
}
.add-row.detention .select.select-max {
  width: 150px;
}
.add-row.detention .select.select-max-vehcile-type {
  width: 250px;
}
.add-row.detention .select.select-max-vehcile-type-multi {
  width: 350px;
}
.add-row.detention .deliveryPerformance .table-row {
  margin-left: 20px;
}
.add-row.detention .deliveryPerformance .input-text-wrap .text-input {
  width: 80px;
}
.add-row .modal-popup {
  min-height: 284px;
  max-height: 600px;
  overflow: auto;
  width: 1050px;
}
.add-row .modal-popup .heading {
  font-size: 19px;
  font-weight: 200;
  color: #0c0b0b;
  margin-bottom: 10px;
}
.add-row .modal-popup .select-wrap {
  display: inline-block;
  vertical-align: middle;
  width: 33%;
  margin-bottom: 20px;
}
.add-row .modal-popup .select-wrap .select {
  width: 277px;
}
.add-row .modal-popup .select-wrap .label {
  font-size: 10px;
  color: #868e96;
  margin-bottom: 10px;
}
.add-row .modal-popup .select-wrap.product-details {
  width: 100%;
}
.increase-icon {
  background-image: url(/images/up-arrow.svg);
  background-repeat: no-repeat;
  height: 15px;
  width: 15px;
  background-size: contain;
  margin: 0 5px;
  cursor: pointer;
  display: inline-block;
}
.bill-number .section-item {
  display: inline-block;
  vertical-align: super;
  padding: 5px;
  color: #535356;
  font-size: 16px;
  white-space: nowrap;
  text-transform: capitalize;
}
.bill-number .title {
  vertical-align: super;
  margin-left: 10px;
}
.bill-number .check-label {
  vertical-align: top;
  font-weight: 600;
  font-size: 10px;
  color: #868e96;
  margin-bottom: 10px;
}
.bill-number .select {
  width: 250px;
}
.heading {
  color: black;
  font-size: 22px;
}
.zone-rate .text-input {
  width: 240px;
}
.zone-rate .button-add {
  float: right;
}
.deliveryPerformance .input-text-wrap {
  width: auto;
}
.deliveryPerformance .input-text-wrap .text-input {
  width: 200px;
}

.add-freight-rate .w-p-24 {
  width: 24% !important;
}
.add-freight-rate .w-p-25 {
  width: 25% !important;
}
.add-freight-rate .w-200 {
  width: 200px !important;
}
.add-freight-rate .w-225 {
  width: 225px !important;
}
.add-freight-rate .w-p-16 {
  width: 16% !important;
}
.add-freight-rate .w-p-18 {
  width: 18% !important;
}
.add-freight-rate .w-150 {
  width: 150px !important;
}
.add-freight-rate .w-p-50 {
  width: 50% !important;
}
.add-freight-rate .m-t-15 {
  margin-top: 15px;
}
.add-freight-rate .m-t-10 {
  margin-top: 10px;
}
.add-freight-rate .inline-flex {
  display: inline-flex !important;
}
.add-freight-rate .label {
  font-size: 10px;
  color: #868e96;
  margin-bottom: 5px !important;
}

.pod-cycle .home-title {
  font-size: 12px;
  color: #86868a;
}
.pod-cycle .sub-heading-section {
  margin-top: 22px;
  font-family: 'avenir-medium', sans-serif;
  font-weight: 900;
  overflow: auto;
}
.pod-cycle .sub-heading-section .sub-heading {
  font-size: 24px;
  color: #282626;
  float: left;
}
.pod-cycle .sub-heading-section .add-company-section {
  float: right;
  margin-right: 30px;
}
.pod-cycle .search-filter-section {
  clear: both;
  margin-top: 25px;
}
.pod-cycle .company-list-wrap {
  margin-top: 60px;
}
.pod-cycle .company-list-wrap .no-records-section {
  margin-top: 40px;
}
.pod-cycle .company-list-wrap .list-section {
  border-bottom: 1px solid rgba(212, 212, 212, 0.5);
}
.pod-cycle .company-list-wrap .list-section .checkbox-section {
  display: inline-block;
  vertical-align: middle;
}
.pod-cycle .company-list-wrap .list-section .company-name-section {
  display: inline-block;
  vertical-align: middle;
  margin-left: 10px;
  width: 370px;
}
.pod-cycle .company-list-wrap .list-section .company-name-section.table-header {
  margin-left: 85px;
  width: 290px;
}
.pod-cycle .company-list-wrap .list-section .company-name-section .comany-logo,
.pod-cycle .company-list-wrap .list-section .company-name-section .compnay-details {
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
}
.pod-cycle .company-list-wrap .list-section .company-name-section .comany-logo {
  width: 50px;
  height: 35px;
  background-size: contain;
  background-repeat: no-repeat;
}
.pod-cycle .company-list-wrap .list-section .company-name-section .compnay-details {
  margin-left: 25px;
  color: #1a2734;
  font-size: 15px;
  font-weight: 900;
  width: 220px;
}
.pod-cycle .company-list-wrap .list-section .company-name-section .compnay-details .date {
  color: #86868a;
  font-size: 12px;
}
.pod-cycle .company-list-wrap .list-section .company-name-section .compnay-details .name {
  word-break: break-word;
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}
.pod-cycle .company-list-wrap .list-section .admin-details {
  display: inline-block;
  vertical-align: middle;
  width: 340px;
  font-size: 15px;
  color: #1a2734;
}
.pod-cycle .company-list-wrap .list-section .admin-details.table-header {
  margin-left: 8px;
  width: 337px;
}
.pod-cycle .company-list-wrap .list-section .admin-details .admin-email {
  font-size: 15px;
  color: #1a2734;
  cursor: pointer;
}
.pod-cycle .company-list-wrap .list-section .admin-details .admin-pan {
  font-size: 12px;
  color: #86868a;
}
.pod-cycle .company-list-wrap .list-section .account-type {
  display: inline-block;
  vertical-align: middle;
  width: 140px;
  font-size: 15px;
  color: #1a2734;
}
.pod-cycle .company-list-wrap .list-section .status {
  display: inline-block;
  vertical-align: middle;
  width: 100px;
}
.pod-cycle .company-list-wrap .list-section.header {
  font-weight: 600;
  padding-top: 0;
  color: #1a2734;
  font-size: 14px;
}
.pod-cycle .company-list-wrap .list-section.content:hover {
  border: 1px solid rgba(0, 89, 251, 0.38);
  box-shadow: 0px 10px 20px 0px rgba(129, 172, 212, 0.45);
}
.pod-cycle .company-list-wrap .list-section.content:hover div,
.pod-cycle .company-list-wrap .list-section.content:hover div .date {
  /*color: #528aea;*/
  color: black;
}
.pod-cycle .company-list-wrap .list-section.content:hover .company-name-section .compnay-details .name {
  display: block;
}
.pod-cycle .action-icon {
  background-repeat: no-repeat;
  height: 18px;
  width: 18px;
  background-size: contain;
  margin: auto !important;
  cursor: pointer;
  display: inline-block;
  padding: 5px;
}
.pod-cycle .m-b-10 {
  margin-bottom: 10px;
}
.pod-cycle .pod-cell {
  border: 1px solid #dedede;
  border-radius: 5px;
  padding: 5px;
}
.pod-cycle .w-p-100 {
  width: 100%;
}
.pod-cycle .pod-status-cell {
  width: 60%;
}
.pod-cycle .info {
  margin-left: 5px;
  border: 1px solid;
  padding-top: 2px;
  padding-right: 8px;
  background: #80808085;
  border-radius: 10px;
  padding-left: 8px;
  color: white;
  cursor: pointer;
  margin-top: -3px;
}
.pod-cycle .divTableCell {
  vertical-align: middle;
}
.pod-cycle .tooltip {
  position: absolute;
  display: inline-block;
}
.pod-cycle .tooltip .tooltiptext {
  visibility: hidden;
  width: 600px;
  background-color: #b9b6b6;
  color: black;
  text-align: left;
  border-radius: 4px;
  padding: 10px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  right: 80%;
  opacity: 0;
  transition: opacity 0.3s;
  font-size: 14px;
}
.pod-cycle .tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}
.pod-cycle .w-p-5 {
  width: 5% !important;
}
.pod-cycle .w-p-10 {
  width: 10% !important;
}
.pod-cycle .w-p-20 {
  width: 20% !important;
}
.pod-cycle .w-p-50 {
  width: 100% !important;
}
.pod-cycle .select {
  display: inline-block;
  width: 150px;
}

.pod-cycle-form .serviceTypes .section-item {
  display: inline-block;
  margin-right: 30px;
  margin-top: 20px;
}
.pod-cycle-form .serviceTypes .section-item .title {
  padding-left: 10px;
}
.pod-cycle-form .modal-popup {
  padding: 10px !important;
}
.pod-cycle-form .checkbox {
  margin-top: 10px !important;
}
.pod-cycle-form .cycle-row {
  display: flex;
}
.pod-cycle-form .title {
  width: unset;
  display: inline-block;
  vertical-align: top;
}
.pod-cycle-form .w-p-40 {
  width: 40%;
}
.pod-cycle-form .w-p-20 {
  width: 20%;
}
.pod-cycle-form .w-p-60 {
  width: 60%;
}
.pod-cycle-form .w-p-100 {
  width: 100%;
}
.pod-cycle-form .m-b-10 {
  margin-bottom: 10px;
}
.pod-cycle-form .f-s-16 {
  font-size: 16px !important;
}
.pod-cycle-form .table-header {
  color: white !important;
}
.pod-cycle-form .inline-flex {
  display: inline-flex !important;
}
.pod-cycle-form .input-text {
  padding: 5px;
  border: 1px solid grey;
  height: 30px;
  border-radius: 5px;
  font-size: 18px;
  width: 100%;
}
.pod-cycle-form .align-right {
  margin-left: auto;
  margin-right: 10px;
}
.pod-cycle-form .align-left {
  margin-left: 10px;
  margin-right: auto;
  text-align: left;
}
.pod-cycle-form .divTableCell {
  border: 1px solid #5289ea;
  border-top: 0px !important;
}
.pod-cycle-form .list-section {
  border-bottom: 0px !important;
}
.pod-cycle-form .m-l-20 {
  margin-left: 20px;
}
.pod-cycle-form .m-t-10 {
  margin-top: 10px;
}
.pod-cycle-form .info-cell {
  position: absolute;
  margin-top: 10px;
}
.pod-cycle-form .input-textarea {
  padding: 5px;
  border: 1px solid grey;
  border-radius: 5px;
  font-size: 18px;
  width: 100%;
}
.pod-cycle-form .divTable {
  margin-top: 0px !important;
}

.route_code_detail .overlay {
  position: fixed;
  z-index: 10;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(27, 33, 67, 0.9);
}
.route_code_detail .modal-popup .pop-heading {
  background-color: #f4f4f4;
  padding: 18px;
  font-family: 'avenir', sans-serif;
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #528aea;
  text-align: center;
}
.route_code_detail .modal-popup {
  max-height: 670px;
  overflow: scroll;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 5px;
  width: 1000px;
  min-height: 550px;
  background-color: white;
  z-index: 11;
}
.route_code_detail .route-detail {
  margin: 55px;
}
.route_code_detail .scheduler-detail {
  margin: 10px;
}
.route_code_detail .button_route {
  margin-right: 10px;
  float: right;
}
.route_code_detail .error-message {
  margin-right: 10px;
  float: right;
  font-size: 12px;
  color: red;
}
.route_code_detail .select-wrap-depot {
  width: 33%;
  display: inline-block;
  vertical-align: baseline;
}
.route_code_detail .select-wrap-depot .label {
  font-size: 10px;
  color: #868e96;
  margin-bottom: 10px;
}
.route_code_detail .service_mode {
  width: 277px;
  margin-right: 15px;
}
.route_code_detail.disposal-product .select {
  width: 275px;
  margin-bottom: 5px;
}
.route_code_detail.disposal-product .input-text-wrap {
  width: 100%;
  color: #868e96;
}
.download-icon {
  background-repeat: no-repeat;
  height: 30px;
  width: 30px;
  background-size: contain;
  cursor: pointer;
  display: inline-block;
  padding: 5px;
  margin: 5px;
}
.selectpack {
  width: 250px;
  margin-bottom: 5px;
}
.RouteStages {
  margin-top: 10px;
  display: flex;
}
.ml-20 {
  margin-left: 20px;
}
.mt--30 {
  margin-top: -30px;
}
.heading-section {
  font-size: 24px;
  margin-top: 20px;
}
.sub-heading-section {
  font-size: 18px;
  font-weight: bold;
  margin-top: -50px;
}
.no-routestage-msg {
  display: flex;
  justify-content: center;
  margin-top: 50;
  color: red;
}
.routestages-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  border-radius: 10px;
}
.routestages-table th {
  background-color: #44569e;
  color: white;
  text-align: left;
}
.routestages-table tbody tr:nth-child(even) {
  background-color: #eff1fd;
}
.routestg-input {
  width: 120px;
  padding-left: 10px;
  margin-left: 10px;
}
.rs-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 5px 0px;
}
.rs-delete-item {
  display: flex;
  justify-content: flex-end;
  margin-top: 70px;
  margin-left: 50px;
}
.disabled {
  pointer-events: none;
  background: #e6e6e6;
}
.NotSelectablediv {
  pointer-events: none;
  background: white;
}

.material-details-section {
  margin-top: 30px;
}
.material-details-section .list-section {
  border-bottom: 1px solid rgba(212, 212, 212, 0.5);
  padding-bottom: 25px;
  padding-top: 20px;
  display: flex;
}
.material-details-section .list-section.content:hover {
  border: 1px solid rgba(0, 89, 251, 0.38);
  box-shadow: 0px 10px 20px 0px rgba(129, 172, 212, 0.45);
}
.material-details-section .list-section.header {
  font-weight: 600;
  padding-top: 0;
  color: #1a2734;
  font-size: 14px;
}
.material-details-section .tabel-header {
  width: 120px;
  display: inline-block;
  vertical-align: middle;
  white-space: nowrap;
  /* Prevent line wrapping */
  text-wrap: wrap;
  font-size: 14px;
  padding: 5px;
}
.material-details-section .tabel-header.material {
  width: 300px;
}
.material-details-section .tabel-header.gap1221 {
  width: 200px;
}
.date .DateInput {
  width: 125px;
}
.date .DateInput .DateInput_input {
  font-size: 12px;
  border: 1px solid #d8dcf0;
  padding: 0px 10px 0px;
  line-height: 13px;
  height: 40px;
  border-radius: 5px;
}
.date .wt-80 {
  width: 80px;
}
.material-mrp-main .search-text-wrap {
  margin-top: 1rem !important;
}
.material-mrp-main .button-classic {
  height: 38px;
}
.material-mrp-main .cross-img {
  right: 26% !important;
}
.material-modal .modal-popup {
  max-height: 500px;
  width: 596px;
  min-height: 500px;
}
.wt-state {
  width: 50%;
}
.modal-pad {
  padding: 1.5rem;
}
.materialCode {
  word-break: break-all;
}

.indent-reasons {
  width: 95%;
}
.indent-reasons .header {
  background: #528aea;
  color: #fff;
  padding: 10px 12px;
  font-family: 'avenir', sans-serif;
  font-size: 18px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
}
.indent-reasons .fuel-escalation-table {
  margin-bottom: 20px;
}
.indent-reasons .fuel-escalation-table .list-section {
  font-weight: 600;
  color: #1a2734;
  font-size: 15px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgba(212, 212, 212, 0.5);
}
.indent-reasons .fuel-escalation-table .list-section.no-data {
  padding: 10px 0px;
  text-align: center;
  display: block;
}
.indent-reasons .fuel-escalation-table .list-section .table-header {
  width: 50%;
  padding: 10px 0px;
  margin-left: 30px;
}
.indent-reasons .fuel-escalation-table .list-section .table-row {
  width: 50%;
  padding: 10px 0px;
  margin-left: 30px;
}

.Lr-upload {
  width: 105%;
}
.Lr-upload .border {
  border: 1px solid #528aea;
  padding: 20px 25px;
  border-radius: 8px;
  background-color: white;
}
.Lr-upload .border .flex-item {
  display: flex;
  grid-gap: 20px;
  gap: 20px;
  align-items: center;
}
.Lr-upload .border .flex-item .dropLSP {
  width: 20%;
}
.Lr-upload .border .flex-item .boundType {
  width: 20%;
}
.Lr-upload .border .flex-item .dropLSP::placeholder {
  color: #528aea;
}
.Lr-upload .border .flex-item .file-holder {
  width: 300px;
  height: 36.5px;
  border-radius: 2px;
  background-color: #f9f9f9;
  border: 1px solid #cccccc;
  cursor: pointer;
  position: relative;
}
.Lr-upload .border .flex-item .file-holder input {
  width: 300px;
  height: 35px;
  opacity: 0;
  cursor: pointer;
}
.Lr-upload .border .flex-item .file-holder .file-choose {
  position: absolute;
  right: 9px;
  top: 9px;
  font-family: 'avenir', sans-serif;
  font-size: 12px;
  letter-spacing: 0.3px;
  cursor: pointer;
  color: #528aea;
  border-left: 1px solid black;
  padding-left: 8px;
}
.Lr-upload .border .flex-item .file-holder .file-name {
  font-family: 'avenir', sans-serif;
  font-size: 12px;
  position: absolute;
  top: 9px;
  left: 12px;
  color: #86868a;
}
.Lr-upload .border .flex-item .upload-button {
  border: 1px solid #528aea;
  padding: 8px 25px;
  font-size: 13px;
  background-color: #528aea;
  color: white;
  border-radius: 3px;
  cursor: pointer;
}
.Lr-upload .border .flex-item .template {
  border: 1px solid #528aea;
  display: flex;
  align-items: center;
  padding: 2px 8px;
  grid-gap: 5px;
  gap: 5px;
  border-radius: 5px;
  background-color: white;
  cursor: pointer;
}
.Lr-upload .border .flex-item .template .template-text {
  color: #528aea;
}
.Lr-upload .all {
  padding-top: 20px;
  display: flex;
  grid-gap: 20px;
  gap: 20px;
  align-items: center;
}
.Lr-upload .all .BdropLSP {
  width: 17%;
}
.Lr-upload .all .BboundType {
  width: 17%;
}
.Lr-upload .branchdrop {
  width: 25%;
}
.Lr-upload .lr-range {
  border: 1px solid #528aea;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2px 8px;
  border-radius: 5px;
  width: 300px;
  height: 36px;
  color: #729FEB;
}
.Lr-upload .lr-range .lr-rangeleft {
  width: 70%;
  display: flex;
  align-items: center;
  border-right: 1px solid #729FEB;
}
.Lr-upload .lr-range .lr-rangeleft .lr-range-to {
  width: 30%;
  font-size: 12px;
}
.Lr-upload .lr-range .lr-rangeleft .lr-range1 {
  width: 30%;
  display: flex;
  align-items: center;
}
.Lr-upload .lr-range .lr-rangeleft .lr-range1 input {
  width: 60px;
  height: 33px;
  margin-top: -1px;
  cursor: pointer;
  border: none;
  outline: none;
  margin-left: 5px;
  background-color: transparent;
  color: #729FEB;
}
.Lr-upload .lr-range .lr-rangeright {
  width: 30%;
  display: flex;
  justify-content: center;
  cursor: pointer;
}
.Lr-upload .lr-range .lr-rangeright:hover {
  color: #3261b1;
}
.Lr-upload .del-check {
  padding-top: 20px;
  display: flex;
  grid-gap: 20px;
  gap: 20px;
  align-items: center;
  margin-left: 10px;
}
.Lr-upload .table {
  width: 100%;
  border-radius: 8px;
  margin-left: -30px;
}
.Lr-upload .table th {
  border: 1px solid white;
}
.Lr-upload .table td {
  border: 1px solid white;
  border-right: 1px solid #c4d4f0;
}
.Lr-upload .table .table-tr {
  background-color: #3261b1;
  color: white;
  font-size: 13px;
}
.Lr-upload .table .table-tr .tCenter {
  padding: 10px 15px;
}
.Lr-upload .table .tr-1 {
  background-color: #d6e0ef;
}

.vendor_mapping_detail-popUp {
  background-color: rgba(27, 33, 67, 0.9);
}
.vendor_mapping_detail-popUp .overlay-popUp {
  position: fixed;
  z-index: 10;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(27, 33, 67, 0.9);
}
.vendor_mapping_detail-popUp .modal-popup-popUp .pop-heading-popUp {
  display: flex;
  padding: 18px;
  font-family: 'avenir', sans-serif;
  font-size: 16px;
  font-weight: 800;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  align-items: center;
  margin-left: 35px;
}
.vendor_mapping_detail-popUp .modal-popup-popUp {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 5px;
  width: 800px;
  height: 500px;
  background-color: white;
  z-index: 11;
}
.vendor_mapping_detail-popUp .vendor-detail-popUp {
  padding: 0px 50px;
  overflow-y: scroll;
  height: 400px;
}
.vendor_mapping_detail-popUp .vendor-detail-popUp .table {
  width: 100%;
  border-radius: 8px;
  display: flex;
}
.vendor_mapping_detail-popUp .vendor-detail-popUp .table th {
  border: 1px solid white;
}
.vendor_mapping_detail-popUp .vendor-detail-popUp .table td {
  border: 1px solid white;
  border-right: 1px solid #c4d4f0;
}
.vendor_mapping_detail-popUp .vendor-detail-popUp .table .table-tr {
  background-color: #3261b1;
  color: white;
  font-size: 13px;
}
.vendor_mapping_detail-popUp .vendor-detail-popUp .table .table-tr .tCenter {
  padding: 5px 10px;
}
.vendor_mapping_detail-popUp .vendor-detail-popUp .table .tr-2 {
  background-color: #d6e0ef;
}
.vendor_mapping_detail-popUp .vendor-detail-popUp .table .table-view2 {
  margin-left: 20px;
}
.vendor_mapping_detail-popUp .vendor-detail-popUp .button_route {
  display: flex;
  width: 800px;
  justify-content: center;
  grid-gap: 30px;
  gap: 30px;
  padding-bottom: 30px;
  background-color: white;
  position: fixed;
  bottom: 0;
  left: 0;
}
.vendor_mapping_detail-popUp .vendor-detail-popUp .button_route .save-button {
  border: 1px solid #528aea;
  padding: 8px 25px;
  font-size: 13px;
  background-color: #528aea;
  color: white;
  border-radius: 3px;
  cursor: pointer;
  margin-top: 50px;
}
.vendor_mapping_detail-popUp .vendor-detail-popUp .button_route .cancel-button {
  border: 1px solid #528aea;
  padding: 8px 25px;
  font-size: 13px;
  background-color: white;
  color: #528aea;
  border-radius: 3px;
  cursor: pointer;
  margin-top: 50px;
}
.vendor_mapping_detail-popUp .scheduler-detail {
  margin: 10px;
}
.vendor_mapping_detail-popUp .error-message {
  margin-right: 10px;
  float: right;
  font-size: 12px;
  color: red;
}
.vendor_mapping_detail-popUp .select-wrap-depot {
  width: 33%;
  display: inline-block;
  vertical-align: baseline;
}
.vendor_mapping_detail-popUp .select-wrap-depot .label {
  font-size: 10px;
  color: #868e96;
  margin-bottom: 10px;
}
.popup {
  padding-top: 180px;
  padding-left: 200px;
}

.Lr-summary {
  width: 100%;
}
.Lr-summary .summary .LSP-threshold {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.Lr-summary .summary .LSP-threshold .dropLSP {
  width: 20%;
}
.Lr-summary .summary .LSP-threshold .change-thresold {
  color: #528aea;
  cursor: pointer;
}
.Lr-summary .summary .table {
  width: 100%;
  border-radius: 8px;
  display: flex;
}
.Lr-summary .summary .table th {
  border: 1px solid white;
}
.Lr-summary .summary .table td {
  border: 1px solid white;
  border-right: 1px solid #c4d4f0;
  padding: 8px 8px;
}
.Lr-summary .summary .table .table-tr {
  background-color: #3261b1;
  color: white;
  font-size: 13px;
}
.Lr-summary .summary .table .table-tr .tCenter {
  padding: 5px 10px;
}
.Lr-summary .summary .table .tr-2 {
  background-color: #d6e0ef;
}
.Lr-summary .summary .table .table-view2 {
  margin-left: 20px;
}

.main-LR {
  width: 100%;
}
.main-LR .lr-management {
  display: flex;
  margin-bottom: 20px;
  cursor: pointer;
}
.main-LR .lr-management .LrSelected-option {
  background-color: #528aea;
  color: white;
}
.main-LR .lr-management .options {
  border: 1px solid #b7cdf5;
  padding: 8px 15px;
}
.main-LR .home-navigation-holder {
  min-width: 350px;
  max-width: 400px;
}
.main-LR .home-navigation-holder.mainTabs {
  max-width: 920px;
}

.Lr-upload {
  width: 105%;
}
.Lr-upload .border {
  border: 1px solid #528aea;
  padding: 20px 25px;
  border-radius: 8px;
  background-color: white;
}
.Lr-upload .border .flex-item {
  display: flex;
  grid-gap: 20px;
  gap: 20px;
  align-items: center;
}
.Lr-upload .border .flex-item .dropLSP {
  width: 20%;
}
.Lr-upload .border .flex-item .boundType {
  width: 20%;
}
.Lr-upload .border .flex-item .dropLSP::placeholder {
  color: #528aea;
}
.Lr-upload .border .flex-item .file-holder {
  width: 300px;
  height: 36.5px;
  border-radius: 2px;
  background-color: #f9f9f9;
  border: 1px solid #cccccc;
  cursor: pointer;
  position: relative;
}
.Lr-upload .border .flex-item .file-holder input {
  width: 300px;
  height: 35px;
  opacity: 0;
  cursor: pointer;
}
.Lr-upload .border .flex-item .file-holder .file-choose {
  position: absolute;
  right: 9px;
  top: 9px;
  font-family: 'avenir', sans-serif;
  font-size: 12px;
  letter-spacing: 0.3px;
  cursor: pointer;
  color: #528aea;
  border-left: 1px solid black;
  padding-left: 8px;
}
.Lr-upload .border .flex-item .file-holder .file-name {
  font-family: 'avenir', sans-serif;
  font-size: 12px;
  position: absolute;
  top: 9px;
  left: 12px;
  color: #86868a;
}
.Lr-upload .border .flex-item .upload-button {
  border: 1px solid #528aea;
  padding: 8px 25px;
  font-size: 13px;
  background-color: #528aea;
  color: white;
  border-radius: 3px;
  cursor: pointer;
}
.Lr-upload .border .flex-item .template {
  border: 1px solid #528aea;
  display: flex;
  align-items: center;
  padding: 2px 8px;
  grid-gap: 5px;
  gap: 5px;
  border-radius: 5px;
  background-color: white;
  cursor: pointer;
}
.Lr-upload .border .flex-item .template .template-text {
  color: #528aea;
}
.Lr-upload .all {
  padding-top: 20px;
  display: flex;
  grid-gap: 20px;
  gap: 20px;
  align-items: center;
}
.Lr-upload .all .BdropLSP {
  width: 17%;
}
.Lr-upload .all .BboundType {
  width: 17%;
}
.Lr-upload .branchdrop {
  width: 25%;
}
.Lr-upload .lr-range {
  border: 1px solid #528aea;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2px 8px;
  border-radius: 5px;
  width: 300px;
  height: 36px;
  color: #729FEB;
}
.Lr-upload .lr-range .lr-rangeleft {
  width: 80%;
  display: flex;
  align-items: center;
  border-right: 1px solid #729FEB;
}
.Lr-upload .lr-range .lr-rangeleft .lr-range-to {
  width: 10%;
  font-size: 12px;
}
.Lr-upload .lr-range .lr-rangeleft .lr-range1 {
  width: 45%;
  display: flex;
  align-items: center;
}
.Lr-upload .lr-range .lr-rangeleft .lr-range1 input {
  width: 100px;
  height: 33px;
  margin-top: -1px;
  cursor: pointer;
  border: none;
  outline: none;
  margin-left: 5px;
  background-color: transparent;
  color: #729FEB;
}
.Lr-upload .lr-range .lr-rangeright {
  width: 20%;
  display: flex;
  justify-content: center;
  cursor: pointer;
}
.Lr-upload .lr-range .lr-rangeright:hover {
  color: #3261b1;
}
.Lr-upload .del-check {
  padding-top: 20px;
  display: flex;
  grid-gap: 20px;
  gap: 20px;
  align-items: center;
  margin-left: 10px;
}
.Lr-upload .table {
  width: 100%;
  border-radius: 8px;
  margin-left: -30px;
}
.Lr-upload .table th {
  border: 1px solid white;
}
.Lr-upload .table td {
  border: 1px solid white;
  border-right: 1px solid #c4d4f0;
}
.Lr-upload .table .table-tr {
  background-color: #3261b1;
  color: white;
  font-size: 13px;
}
.Lr-upload .table .table-tr .tCenter {
  padding: 10px 15px;
}
.Lr-upload .table .tr-1 {
  background-color: #d6e0ef;
}

.Lr-upload {
  width: 105%;
}
.Lr-upload .border {
  border: 1px solid #528aea;
  padding: 20px 25px;
  border-radius: 8px;
  background-color: white;
}
.Lr-upload .border .flex-item {
  display: flex;
  grid-gap: 20px;
  gap: 20px;
  align-items: center;
}
.Lr-upload .border .flex-item .dropLSP {
  width: 20%;
}
.Lr-upload .border .flex-item .boundType {
  width: 20%;
}
.Lr-upload .border .flex-item .dropLSP::placeholder {
  color: #528aea;
}
.Lr-upload .border .flex-item .file-holder {
  width: 300px;
  height: 36.5px;
  border-radius: 2px;
  background-color: #f9f9f9;
  border: 1px solid #cccccc;
  cursor: pointer;
  position: relative;
}
.Lr-upload .border .flex-item .file-holder input {
  width: 300px;
  height: 35px;
  opacity: 0;
  cursor: pointer;
}
.Lr-upload .border .flex-item .file-holder .file-choose {
  position: absolute;
  right: 9px;
  top: 9px;
  font-family: 'avenir', sans-serif;
  font-size: 12px;
  letter-spacing: 0.3px;
  cursor: pointer;
  color: #528aea;
  border-left: 1px solid black;
  padding-left: 8px;
}
.Lr-upload .border .flex-item .file-holder .file-name {
  font-family: 'avenir', sans-serif;
  font-size: 12px;
  position: absolute;
  top: 9px;
  left: 12px;
  color: #86868a;
}
.Lr-upload .border .flex-item .upload-button {
  border: 1px solid #528aea;
  padding: 8px 25px;
  font-size: 13px;
  background-color: #528aea;
  color: white;
  border-radius: 3px;
  cursor: pointer;
}
.Lr-upload .border .flex-item .template {
  border: 1px solid #528aea;
  display: flex;
  align-items: center;
  padding: 2px 8px;
  grid-gap: 5px;
  gap: 5px;
  border-radius: 5px;
  background-color: white;
  cursor: pointer;
}
.Lr-upload .border .flex-item .template .template-text {
  color: #528aea;
}
.Lr-upload .all {
  padding-top: 20px;
  display: flex;
  grid-gap: 20px;
  gap: 20px;
  align-items: center;
}
.Lr-upload .all .BdropLSP {
  width: 17%;
}
.Lr-upload .all .BboundType {
  width: 17%;
}
.Lr-upload .branchdrop {
  width: 25%;
}
.Lr-upload .lr-range {
  border: 1px solid #528aea;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2px 8px;
  border-radius: 5px;
  width: 300px;
  height: 36px;
  color: #729FEB;
}
.Lr-upload .lr-range .lr-rangeleft {
  width: 80%;
  display: flex;
  align-items: center;
  border-right: 1px solid #729FEB;
}
.Lr-upload .lr-range .lr-rangeleft .lr-range-to {
  width: 10%;
  font-size: 12px;
}
.Lr-upload .lr-range .lr-rangeleft .lr-range1 {
  width: 45%;
  display: flex;
  align-items: center;
}
.Lr-upload .lr-range .lr-rangeleft .lr-range1 input {
  width: 100px;
  height: 33px;
  margin-top: -1px;
  cursor: pointer;
  border: none;
  outline: none;
  margin-left: 5px;
  background-color: transparent;
  color: #729FEB;
}
.Lr-upload .lr-range .lr-rangeright {
  width: 20%;
  display: flex;
  justify-content: center;
  cursor: pointer;
}
.Lr-upload .lr-range .lr-rangeright:hover {
  color: #3261b1;
}
.Lr-upload .del-check {
  padding-top: 20px;
  display: flex;
  grid-gap: 20px;
  gap: 20px;
  align-items: center;
  margin-left: 10px;
}
.Lr-upload .table {
  width: 100%;
  border-radius: 8px;
  margin-left: -30px;
}
.Lr-upload .table th {
  border: 1px solid white;
}
.Lr-upload .table td {
  border: 1px solid white;
  border-right: 1px solid #c4d4f0;
}
.Lr-upload .table .table-tr {
  background-color: #3261b1;
  color: white;
  font-size: 13px;
}
.Lr-upload .table .table-tr .tCenter {
  padding: 10px 15px;
}
.Lr-upload .table .tr-1 {
  background-color: #d6e0ef;
}

.Lr-summary {
  width: 100%;
}
.Lr-summary .summary .LSP-threshold {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.Lr-summary .summary .LSP-threshold .dropLSP {
  width: 20%;
}
.Lr-summary .summary .LSP-threshold .change-thresold {
  color: #528aea;
  cursor: pointer;
}
.Lr-summary .summary .table {
  width: 100%;
  border-radius: 8px;
  display: flex;
}
.Lr-summary .summary .table th {
  border: 1px solid white;
}
.Lr-summary .summary .table td {
  border: 1px solid white;
  border-right: 1px solid #c4d4f0;
  padding: 8px 8px;
}
.Lr-summary .summary .table .table-tr {
  background-color: #3261b1;
  color: white;
  font-size: 13px;
}
.Lr-summary .summary .table .table-tr .tCenter {
  padding: 5px 10px;
}
.Lr-summary .summary .table .tr-2 {
  background-color: #d6e0ef;
}
.Lr-summary .summary .table .table-view2 {
  margin-left: 20px;
}

.vendor_mapping_detail-popUp {
  background-color: rgba(27, 33, 67, 0.9);
}
.vendor_mapping_detail-popUp .overlay-popUp {
  position: fixed;
  z-index: 10;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(27, 33, 67, 0.9);
}
.vendor_mapping_detail-popUp .modal-popup-popUp .pop-heading-popUp {
  display: flex;
  padding: 18px;
  font-family: 'avenir', sans-serif;
  font-size: 16px;
  font-weight: 800;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  align-items: center;
  margin-left: 35px;
}
.vendor_mapping_detail-popUp .modal-popup-popUp {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 5px;
  width: 800px;
  height: 500px;
  background-color: white;
  z-index: 11;
}
.vendor_mapping_detail-popUp .vendor-detail-popUp {
  padding: 0px 50px;
  overflow-y: scroll;
  height: 400px;
}
.vendor_mapping_detail-popUp .vendor-detail-popUp .table {
  width: 100%;
  border-radius: 8px;
  display: flex;
}
.vendor_mapping_detail-popUp .vendor-detail-popUp .table th {
  border: 1px solid white;
}
.vendor_mapping_detail-popUp .vendor-detail-popUp .table td {
  border: 1px solid white;
  border-right: 1px solid #c4d4f0;
}
.vendor_mapping_detail-popUp .vendor-detail-popUp .table .table-tr {
  background-color: #3261b1;
  color: white;
  font-size: 13px;
}
.vendor_mapping_detail-popUp .vendor-detail-popUp .table .table-tr .tCenter {
  padding: 5px 10px;
}
.vendor_mapping_detail-popUp .vendor-detail-popUp .table .tr-2 {
  background-color: #d6e0ef;
}
.vendor_mapping_detail-popUp .vendor-detail-popUp .table .table-view2 {
  margin-left: 20px;
}
.vendor_mapping_detail-popUp .vendor-detail-popUp .button_route {
  display: flex;
  width: 800px;
  justify-content: center;
  grid-gap: 30px;
  gap: 30px;
  padding-bottom: 30px;
  background-color: white;
  position: fixed;
  bottom: 0;
  left: 0;
}
.vendor_mapping_detail-popUp .vendor-detail-popUp .button_route .save-button {
  border: 1px solid #528aea;
  padding: 8px 25px;
  font-size: 13px;
  background-color: #528aea;
  color: white;
  border-radius: 3px;
  cursor: pointer;
  margin-top: 50px;
}
.vendor_mapping_detail-popUp .vendor-detail-popUp .button_route .cancel-button {
  border: 1px solid #528aea;
  padding: 8px 25px;
  font-size: 13px;
  background-color: white;
  color: #528aea;
  border-radius: 3px;
  cursor: pointer;
  margin-top: 50px;
}
.vendor_mapping_detail-popUp .scheduler-detail {
  margin: 10px;
}
.vendor_mapping_detail-popUp .error-message {
  margin-right: 10px;
  float: right;
  font-size: 12px;
  color: red;
}
.vendor_mapping_detail-popUp .select-wrap-depot {
  width: 33%;
  display: inline-block;
  vertical-align: baseline;
}
.vendor_mapping_detail-popUp .select-wrap-depot .label {
  font-size: 10px;
  color: #868e96;
  margin-bottom: 10px;
}
.popup {
  padding-top: 180px;
  padding-left: 200px;
}

.Lr-upload {
  width: 105%;
}
.Lr-upload .border {
  border: 1px solid #528aea;
  padding: 20px 25px;
  border-radius: 8px;
  background-color: white;
}
.Lr-upload .border .flex-item {
  display: flex;
  grid-gap: 20px;
  gap: 20px;
  align-items: center;
}
.Lr-upload .border .flex-item .dropLSP {
  width: 20%;
}
.Lr-upload .border .flex-item .boundType {
  width: 20%;
}
.Lr-upload .border .flex-item .dropLSP::placeholder {
  color: #528aea;
}
.Lr-upload .border .flex-item .file-holder {
  width: 300px;
  height: 36.5px;
  border-radius: 2px;
  background-color: #f9f9f9;
  border: 1px solid #cccccc;
  cursor: pointer;
  position: relative;
}
.Lr-upload .border .flex-item .file-holder input {
  width: 300px;
  height: 35px;
  opacity: 0;
  cursor: pointer;
}
.Lr-upload .border .flex-item .file-holder .file-choose {
  position: absolute;
  right: 9px;
  top: 9px;
  font-family: 'avenir', sans-serif;
  font-size: 12px;
  letter-spacing: 0.3px;
  cursor: pointer;
  color: #528aea;
  border-left: 1px solid black;
  padding-left: 8px;
}
.Lr-upload .border .flex-item .file-holder .file-name {
  font-family: 'avenir', sans-serif;
  font-size: 12px;
  position: absolute;
  top: 9px;
  left: 12px;
  color: #86868a;
}
.Lr-upload .border .flex-item .upload-button {
  border: 1px solid #528aea;
  padding: 8px 25px;
  font-size: 13px;
  background-color: #528aea;
  color: white;
  border-radius: 3px;
  cursor: pointer;
}
.Lr-upload .border .flex-item .template {
  border: 1px solid #528aea;
  display: flex;
  align-items: center;
  padding: 2px 8px;
  grid-gap: 5px;
  gap: 5px;
  border-radius: 5px;
  background-color: white;
  cursor: pointer;
}
.Lr-upload .border .flex-item .template .template-text {
  color: #528aea;
}
.Lr-upload .all {
  padding-top: 20px;
  display: flex;
  grid-gap: 20px;
  gap: 20px;
  align-items: center;
}
.Lr-upload .all .BdropLSP {
  width: 17%;
}
.Lr-upload .all .BboundType {
  width: 17%;
}
.Lr-upload .branchdrop {
  width: 25%;
}
.Lr-upload .lr-range {
  border: 1px solid #528aea;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2px 8px;
  border-radius: 5px;
  width: 300px;
  height: 36px;
  color: #729FEB;
}
.Lr-upload .lr-range .lr-rangeleft {
  width: 70%;
  display: flex;
  align-items: center;
}
.Lr-upload .lr-range .lr-rangeleft .lr-range-to {
  width: 30%;
  font-size: 12px;
}
.Lr-upload .lr-range .lr-rangeleft .lr-range1 {
  width: 30%;
  display: flex;
  align-items: center;
}
.Lr-upload .lr-range .lr-rangeleft .lr-range1 input {
  width: 60px;
  height: 33px;
  margin-top: -1px;
  cursor: pointer;
  border: none;
  outline: none;
  margin-left: 5px;
  background-color: transparent;
  color: #729FEB;
}
.Lr-upload .lr-range .lr-rangeright {
  width: 30%;
  display: flex;
  justify-content: center;
  cursor: pointer;
}
.Lr-upload .lr-range .lr-rangeright:hover {
  color: #3261b1;
}
.Lr-upload .del-check {
  padding-top: 20px;
  display: flex;
  grid-gap: 20px;
  gap: 20px;
  align-items: center;
  margin-left: 10px;
}
.Lr-upload .table {
  width: 100%;
  border-radius: 8px;
  margin-left: -30px;
}
.Lr-upload .table th {
  border: 1px solid white;
}
.Lr-upload .table td {
  border: 1px solid white;
  border-right: 1px solid #c4d4f0;
}
.Lr-upload .table .table-tr {
  background-color: #3261b1;
  color: white;
  font-size: 13px;
}
.Lr-upload .table .table-tr .tCenter {
  padding: 10px 15px;
}
.Lr-upload .table .tr-1 {
  background-color: #d6e0ef;
}
.Lr-upload .button-classic {
  height: 30px;
  font-size: 14px;
  padding: 0 20px;
  border-radius: 5px;
  margin-right: 10px;
  margin-left: 5px;
}

.module-config {
  margin-left: 30px;
}
.module-config .select-relevant-category-section {
  margin-bottom: 50px;
  display: inline-grid;
  width: 30%;
}
.module-config .select-relevant-category-section .section-item {
  vertical-align: super;
  padding: 5px;
  color: #535356;
  font-size: 16px;
  text-transform: capitalize;
}
.module-config .select-relevant-category-section .section-item.sub-item {
  margin-left: 30px;
}
.module-config .select-relevant-category-section .section-item .title {
  width: auto;
  display: inline-block;
  vertical-align: top;
  margin: 3px 14px;
}

.company-category .button-default {
  cursor: pointer;
  padding: 0 15px;
  border-radius: 2px;
  border: 1px solid #528aea;
  background: #528aea;
  margin: 0px 20px;
  color: #fff;
  min-width: 76px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  display: inline-block;
  vertical-align: bottom;
  margin-bottom: 10px;
}
.company-category .button-white {
  cursor: pointer;
  padding: 0 15px;
  border-radius: 2px;
  border: 1px solid #528aea;
  background: white;
  margin: 0px 20px;
  color: #528aea;
  min-width: 76px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  display: inline-block;
  vertical-align: bottom;
  margin-bottom: 10px;
}
.company-category .f-r {
  float: right;
}
.company-category .sub-title-section-wrap {
  margin-top: 10px;
}
.company-category .w-p-3 {
  width: 3% !important;
}
.company-category .w-p-5 {
  width: 5% !important;
}
.company-category .w-p-6 {
  width: 6% !important;
}
.company-category .w-p-8 {
  width: 8% !important;
}
.company-category .w-p-12 {
  width: 12% !important;
}
.company-category .w-p-16 {
  width: 16% !important;
}
.company-category .w-p-22 {
  width: 22% !important;
}
.company-category .w-p-25 {
  width: 25% !important;
}
.company-category .w-80 {
  width: 80px !important;
}
.company-category .w-100 {
  width: 100px !important;
}
.company-category .w-150 {
  width: 150px !important;
}
.company-category .w-225 {
  width: 225px !important;
}
.company-category .w-p-24 {
  width: 24% !important;
}
.company-category .w-p-10 {
  width: 10% !important;
}
.company-category .button-delete {
  cursor: pointer;
  padding: 0 15px;
  border-radius: 2px;
  border: 1px solid #528aea;
  background: #528aea;
  color: #fff;
  min-width: 76px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  display: inline-block;
  vertical-align: bottom;
  margin-bottom: 5px;
  margin-right: 5px;
}
.company-category .input-text {
  padding: 5px;
  border: 1px solid #cccccc;
  height: 38px;
  border-radius: 5px;
  font-size: 16px;
  width: 100%;
}
.company-category .m-r-8 {
  margin-right: 8px;
}
.company-category .m-l-5 {
  margin-left: 5px;
}
.company-category .align-middle {
  vertical-align: middle !important;
}
.company-category .align-top {
  vertical-align: top !important;
}
.company-category .flex {
  display: flex !important;
}
.top-margin-20 {
  margin-top: 20px;
}
.mr-10 {
  margin-right: 10px;
}
.p-5 {
  padding: 5px;
}
.m-5 {
  margin: 5px;
}
.html2canvas-container {
  height: 3000px !important;
}
.preview-header {
  background-color: #e0e0e0;
  border: 1px solide #f0f0f0;
  text-align: center;
  padding: 10px;
}
.dateTenture {
  display: inline-block;
  margin-right: 20px;
}
.tCenter {
  text-align: center;
}
.mb-5 {
  margin-bottom: 5px;
}
.fs-11 {
  font-size: 11px;
}
.fs-14 {
  font-size: 14px;
}
.header-color {
  color: #86868a;
}
.basic-details-wrap .heading-section {
  margin-top: 33px;
  font-size: 20px;
  color: #1a2734;
  font-family: 'avenir-medium', sans-serif;
}
.basic-details-wrap .details {
  margin-top: 20px;
}
.basic-details-wrap .details .fiscal-section {
  display: inline-block;
  width: 33%;
}
.basic-details-wrap .details .fiscal-section .rupee-icon {
  vertical-align: baseline;
}
.basic-details-wrap .details .fiscal-section .input-text-wrap {
  width: 50%;
}
.basic-details-wrap .details .fiscal-section .input-text-wrap .text-input {
  width: 185px;
}
.basic-details-wrap .details .fiscal-section .select-wrap .select {
  width: 125px;
  margin-left: 30px;
  margin-top: 16px;
}
.basic-details-wrap .details .select-wrap {
  width: 33%;
  display: inline-block;
  vertical-align: baseline;
}
.basic-details-wrap .details .select-wrap .label {
  font-size: 10px;
  color: #868e96;
  margin-bottom: 10px;
}
.basic-details-wrap .details .select-wrap .label-mb-0 {
  font-size: 10px;
  color: #868e96;
}
.basic-details-wrap .details .select-wrap-w-25 {
  width: 25%;
  display: inline-block;
  vertical-align: baseline;
}
.basic-details-wrap .details .select-wrap-w-25 .label {
  font-size: 10px;
  color: #868e96;
  margin-bottom: 10px;
}
.basic-details-wrap .details .select-wrap-w-25 .label-mb-0 {
  font-size: 10px;
  color: #868e96;
}
.basic-details-wrap .details .select {
  width: 180px;
}
.basic-details-wrap .details .select-branch {
  width: 220px;
}
.basic-details-wrap .company-details {
  margin-top: 50px;
}
.basic-details-wrap .company-details .title {
  font-size: 20px;
  color: #1a2734;
  font-family: 'avenir-medium', sans-serif;
}
.basic-details-wrap .company-details .file-upload-section .file-lable-section .company-icon {
  width: 80px;
  height: 80px;
  border-radius: 5px;
  background-color: #f9f9f9;
  display: inline-block;
  vertical-align: middle;
}
.basic-details-wrap .company-details .file-upload-section .file-lable-section .company-icon.img {
  background-size: cover;
  background-repeat: no-repeat;
}
.basic-details-wrap .company-details .file-upload-section .file-lable-section .file-upload-details {
  display: inline-block;
  margin-left: 20px;
  vertical-align: middle;
}
.basic-details-wrap .company-details .file-upload-section .file-lable-section .title {
  font-size: 10px;
  color: #868e96;
}
.basic-details-wrap .company-details .file-upload-section .file-lable-section .desc {
  font-size: 16px;
  color: #1a2734;
  margin-top: 5px;
  margin-bottom: 15px;
}
.basic-details-wrap .company-details .file-upload-section .file-holder {
  width: 300px;
  height: 26px;
  border-radius: 2px;
  background-color: #f9f9f9;
  border: 1px solid #cccccc;
  cursor: pointer;
  position: relative;
}
.basic-details-wrap .company-details .file-upload-section .file-holder input {
  width: 300px;
  height: 26px;
  opacity: 0;
  cursor: pointer;
}
.basic-details-wrap .company-details .file-upload-section .file-holder .file-choose {
  position: absolute;
  right: 9px;
  top: 4px;
  font-family: 'avenir', sans-serif;
  font-size: 12px;
  letter-spacing: 0.3px;
  cursor: pointer;
  color: #528aea;
}
.basic-details-wrap .company-details .file-upload-section .file-holder .file-name {
  font-family: 'avenir', sans-serif;
  font-size: 12px;
  position: absolute;
  top: 3px;
  left: 12px;
  color: #86868a;
}
.basic-details-wrap .company-details .file-upload-section .generate-button {
  cursor: pointer;
  padding: 0 15px;
  border-radius: 23px;
  border: 1px solid #528aea;
  background: #528aea;
  margin: 0px 20px;
  color: #fff;
  min-width: 76px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  display: inline-block;
  vertical-align: bottom;
}
.sub-title-section-wrap {
  margin-top: 35px;
}
.sub-title-section-wrap .circle,
.sub-title-section-wrap .text,
.sub-title-section-wrap .underline {
  display: inline-block;
  vertical-align: baseline;
}
.sub-title-section-wrap .circle {
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background: #528aea;
}
.sub-title-section-wrap .text {
  margin-left: 10px;
  font-size: 16px;
  color: #1a2734;
  font-family: 'avenir-medium', sans-serif;
}
.sub-title-section-wrap .underline {
  width: 20px;
  height: 3px;
  background: #528aea;
  margin-left: 5px;
}
.details .input-text-wrap {
  vertical-align: middle;
  margin-bottom: 15px;
}
.rupee-icon {
  background-image: url(/images/rupee.svg);
  background-repeat: no-repeat;
  height: 17px;
  width: 17px;
  background-size: contain;
  margin: 0 5px;
  display: inline-block;
  vertical-align: middle;
}
.button-add {
  cursor: pointer;
  padding: 0 15px;
  border-radius: 23px;
  border: 1px solid #528aea;
  background: #528aea;
  margin: 0px 20px;
  color: #fff;
  min-width: 76px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  display: inline-block;
  vertical-align: bottom;
  margin-bottom: 10px;
}
.documents-upload-section {
  min-height: 500px;
  padding: 10px;
  margin-bottom: 40px;
  margin-top: 40px;
  border-radius: 5px;
  box-shadow: 0 0 10px 4px rgba(69, 68, 68, 0.07);
}
.documents-upload-section .file-lable-section {
  margin: 10px;
}
.documents-upload-section .file-lable-section .company-icon {
  width: 50px;
  height: 50px;
  border-radius: 5px;
  background-color: #f9f9f9;
  display: inline-block;
  vertical-align: middle;
}
.documents-upload-section .file-lable-section .company-icon.img {
  background-size: cover;
  background-repeat: no-repeat;
}
.documents-upload-section .file-lable-section .file-upload-details {
  display: inline-block;
  vertical-align: middle;
}
.documents-upload-section .file-lable-section .title {
  font-size: 12px;
  color: #868e96;
}
.documents-upload-section .file-lable-section .desc {
  font-size: 16px;
  color: #1a2734;
  margin-top: 5px;
  margin-bottom: 15px;
}
.documents-upload-section .file-lable-section .attachment-container-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 5px;
  border-top: 1px solid hsla(0, 0%, 59.2%, 0.2);
}
.documents-upload-section .file-lable-section .attachment-container-row .attachment-file-icon {
  background-image: url(/images/attachment.svg);
  background-repeat: no-repeat;
  height: 30px;
  width: 30px;
  background-size: contain;
  padding: 5px;
  margin: 5px;
}
.documents-upload-section .file-lable-section .attachment-container-row .attachment-name {
  padding-top: 10px;
  font-size: 14px;
}
.documents-upload-section .file-lable-section .attachment-container-row .delete-icon {
  background-image: url(/images/delete.svg);
  background-repeat: no-repeat;
  height: 23px;
  width: 23px;
  background-size: contain;
  cursor: pointer;
  display: inline-block;
  padding: 5px;
  margin: 5px;
}
.documents-upload-section .file-lable-section input[type="text"] {
  width: 200px;
  height: 26px;
  border-radius: 2px;
  border: 1px solid #cccccc;
}
.documents-upload-section .file-lable-section input[type="text"]:focus {
  border: 1px solid #cccccc;
  outline-offset: 0px;
  outline: none !important;
  padding: 5px;
}
.documents-upload-section .file-lable-section .single-date-input .DateInput_input {
  font-size: 14px;
}
.documents-upload-section .file-holder {
  width: 300px;
  height: 26px;
  border-radius: 2px;
  background-color: #f9f9f9;
  border: 1px solid #cccccc;
  cursor: pointer;
  position: relative;
}
.documents-upload-section .file-holder input {
  width: 300px;
  height: 26px;
  opacity: 0;
  cursor: pointer;
}
.documents-upload-section .file-holder .file-choose {
  position: absolute;
  right: 9px;
  top: 4px;
  font-family: 'avenir', sans-serif;
  font-size: 12px;
  letter-spacing: 0.3px;
  cursor: pointer;
  color: #528aea;
}
.documents-upload-section .file-holder .file-name {
  font-family: 'avenir', sans-serif;
  font-size: 12px;
  position: absolute;
  top: 3px;
  left: 12px;
  color: #86868a;
}
.documents-upload-section .generate-button {
  cursor: pointer;
  padding: 0 15px;
  border-radius: 23px;
  border: 1px solid #528aea;
  background: #528aea;
  margin: 0px 20px;
  color: #fff;
  min-width: 76px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  display: inline-block;
  vertical-align: bottom;
}
.tolerance {
  background-repeat: no-repeat;
  height: 17px;
  width: 17px;
  background-size: contain;
  margin: 0 5px;
  display: inline-block;
  vertical-align: middle;
}
.flex {
  display: flex;
}
.space-between {
  justify-content: space-between;
}
.hl {
  border: 2px solid red;
}
.label-inline {
  font-size: 10px;
  color: #868e96;
  margin-bottom: 10px;
}
.font-1rem {
  font-size: 1rem;
}
.mt-1rem {
  margin-top: 1rem;
}
.pb-13px {
  padding-bottom: 13px !important;
}
.pl-0 {
  padding-left: 0!important ;
}
.mr-20 {
  margin-right: 20px;
}
.datepicker {
  font-size: 10px;
}

.advance-filter {
    width: 12%;
    margin: 10px;
    padding: 10px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 8px 0px, rgba(0, 0, 0, 0.17) 0px 7px 20px 0px;
    position: absolute;
    background: white;
    left: 82%;
    z-index: 10000;
}
.tag-elements {
    border: 1px solid grey;
    margin-right: 5px;
    margin-top: 5px;
    padding: 4px 10px 2px;
    border-radius: 15px;
    font-size: 14px;
    display: inline-block;
    cursor: pointer;
}
.tag-elements-active {
    border: 1px solid grey;
    margin-right: 5px;
    margin-top: 5px;
    padding: 4px 10px 2px;
    border-radius: 15px;
    display: inline-block;
    background: black;
    color: white;
    font-size: 14px;
    cursor: pointer;
}
.advance-filter-title {
    border-bottom: 2px solid #dfdfdf;
    padding-left: 5px;
}
.m-5 {
    margin: 5px;
}
.m-t-0 {
    margin-top: 0px;
}
.m-t-10 {
    margin-top: 10px;
}
.h-l {
    border: 1px solid #dfdfdf;
}
.apply-filter-button {
    cursor: pointer;
    height: 30px;
    padding: 0 20px;
    color: #fff;
    background: #528aea;
    margin: 0px 20px;
    display: inline-block;
    border: 1px solid #528aea;
    font-size: 16px;
}
.align-center {
    text-align: center;
}
.tCenter {
  text-align: center;
}
.mh-30 {
  margin-left: 30px;
  margin-right: 30px;
}
.page {
  /*width: 700px;*/
  height: 850px;
  padding: 50px;
}
p {
  text-align: justify;
}
ol,
li {
  margin-top: 20px;
  text-align: justify;
}
table,
th,
td {
  border: 1px solid black;
  border-collapse: collapse;
  /*margin-right: 40px;*/
  text-align: justify;
  padding: 5px;
  margin-top: 10px;
}
h3 {
  margin-top: 60px;
}
table {
  justify-content: center;
  margin: auto;
  margin-top: 20px;
}

.vendor_detail .overlay {
  position: fixed;
  z-index: 10;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(27, 33, 67, 0.9);
}
.vendor_detail .modal-popup .pop-heading {
  background-color: #f4f4f4;
  padding: 18px;
  font-family: 'avenir', sans-serif;
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #528aea;
  text-align: center;
}
.vendor_detail .modal-popup {
  max-height: 670px;
  overflow: scroll;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 5px;
  width: 1000px;
  min-height: 270px;
  background-color: white;
  z-index: 11;
}
.vendor_detail .col-3 {
  width: 33.33%;
}
.vendor_detail .col-4 {
  width: 25%;
  box-shadow: none;
}
.vendor_detail .p-10 {
  padding: 10px;
}
.vendor_detail .mt-10 {
  margin-top: 10px;
}
.vendor_detail .ml-20 {
  margin-left: 20px;
}
.vendor_detail .mr-20 {
  margin-right: 20px;
}
.vendor_detail .bg-white {
  background-color: white;
}
.vendor_detail .bg-theme {
  background-color: #5289ea;
}
.vendor_detail .sub-heading {
  background-color: #f4f4f4;
  font-size: 16px;
  font-weight: 600;
}
.vendor_detail .vendor-header {
  font-size: 14px;
  font-weight: 600;
  color: black;
}
.vendor_detail .vendor-data {
  color: #528AEA;
  font-size: 14px;
  margin-top: 2px;
}

.scheduler {
  border-color: #aecdf7;
}
.scheduler .button-classic {
  margin-left: 10px;
}
.scheduler .scheduler_detail_section {
  overflow: scroll;
  border-color: #aecdf7;
}
.scheduler .scheduler_detail {
  margin-bottom: 150px;
  width: 260%;
  text-align: center;
  border-color: #aecdf7;
}
.scheduler .scheduler_detail .header {
  background-color: #528aea;
  color: white;
  border-color: #aecdf7;
}
.scheduler .scheduler_detail .header .subheader {
  background-color: #aecdf7;
  border-color: #aecdf7;
}
.scheduler .scheduler_detail .action-header {
  text-align: center;
  border-color: #aecdf7;
}
.scheduler .scheduler_detail .main-header {
  width: 30%;
  text-align: center;
  border-color: #aecdf7;
}
.scheduler .scheduler_detail .main-header .subtabs {
  width: 10%;
  text-align: center;
  border: none;
}
.scheduler .scheduler_detail .code-header {
  text-align: center;
  border-color: #aecdf7;
}
.scheduler .scheduler_detail .code-header .date-filter .DateRangePicker {
  width: 275px;
}
.scheduler .scheduler_detail .code-header .select-option {
  z-index: 999 !important;
}
.scheduler .scheduler_detail .code-header .input-text-wrap {
  margin-right: 35px;
  padding-top: 10px;
}
.scheduler .scheduler_detail .code-header .input-text-wrap .input-element {
  width: 20px;
}
.scheduler .scheduler_detail .code-header .input-text-wrap .input-element .text-input {
  width: 70px;
  font-size: 12px;
}
.scheduler .scheduler_detail .delete-icon {
  height: 20px;
  margin: 0px;
}
.scheduler_code_detail .overlay {
  position: fixed;
  z-index: 10;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(27, 33, 67, 0.9);
}
.scheduler_code_detail .modal-popup .pop-heading {
  background-color: #f4f4f4;
  padding: 18px;
  font-family: 'avenir', sans-serif;
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #528aea;
  text-align: center;
}
.scheduler_code_detail .modal-popup {
  max-height: 670px;
  overflow: scroll;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 5px;
  width: 1000px;
  min-height: 400px;
  background-color: white;
  z-index: 11;
}
.scheduler_code_detail .scheduler-detail {
  margin: 10px;
}
.scheduler_code_detail .scheduler-detail .code-header {
  display: flex;
  text-align: center;
  border: none;
}
.scheduler_code_detail .scheduler-detail .code-header .select {
  width: 230px;
  height: 35px;
  margin: 10px;
}
.scheduler_code_detail .scheduler-detail .code-header .date-filter {
  margin: 10px;
  height: 35px;
}
.scheduler_code_detail .scheduler-detail .code-header .table {
  width: 150%;
}
.scheduler_code_detail .scheduler-detail .code-header .table .subtabs {
  width: 10%;
  text-align: center;
  border: none;
}
.scheduler_code_detail .scheduler-detail .code-header .input-text-wrap {
  width: 15%;
  margin: 10px;
}
.scheduler_code_detail .scheduler-detail .code-header .input-text-wrap .input-element {
  width: 30px;
}
.scheduler_code_detail .scheduler-detail .code-header .input-text-wrap .input-element .text-input {
  width: 150px;
  font-size: 12px;
  padding-bottom: 5px;
}
.scheduler_code_detail .button_route {
  margin-right: 10px;
  float: right;
}
.scheduler_code_detail .error-message {
  margin-right: 10px;
  float: right;
  font-size: 12px;
  color: red;
}
.scheduler_code_detail .select-wrap-depot {
  width: 33%;
  display: inline-block;
  vertical-align: baseline;
}
.scheduler_code_detail .select-wrap-depot .label {
  font-size: 10px;
  color: #868e96;
  margin-bottom: 10px;
}
.download-icon {
  background-repeat: no-repeat;
  height: 30px;
  width: 30px;
  background-size: contain;
  cursor: pointer;
  display: inline-block;
  padding: 5px;
  margin: 5px;
}
.ml-10 {
  margin-left: 10px;
}
.mr-10 {
  margin-right: 10px;
}
.wt-230 {
  width: 230px;
}
.mt-30 {
  margin-top: 30px;
}
.wt-100p {
  width: 100%;
}
.wt-80p {
  width: 80%;
}
.wt-90p {
  width: 90%;
}
.pl-30 {
  padding-left: 30px;
}
.danger-color {
  color: #d80027;
}
.fs-12 {
  font-size: 12px;
}

.vendor-mapping .vendor_btn {
  margin: 10px 0px;
}
.vendor-mapping .vendor_btn .search-text-wrap .text-search {
  width: 250px;
}
.vendor-mapping .button-classic {
  margin-left: 10px;
}
.vendor-mapping .vendor-detail {
  margin: 20px 40px;
}
.vendor-mapping .vendor-detail .list-section {
  padding: 10px 0px;
  border-bottom: 1px solid lightgrey;
  justify-content: space-between;
}
.vendor-mapping .vendor-detail .select-btn {
  display: flex;
  justify-content: space-between;
  padding-top: 10px;
}
.vendor-mapping .vendor-detail .select-btn .select {
  width: 300px;
}
.vendor-mapping .button_route {
  margin-top: 10px;
  margin-bottom: 10px;
}
.vendor-mapping .mapping-details-section {
  margin-top: 30px;
}
.vendor-mapping .mapping-details-section .list-section {
  border-bottom: 1px solid rgba(212, 212, 212, 0.5);
  padding-bottom: 10px;
  padding-top: 10px;
  display: flex;
}
.vendor-mapping .mapping-details-section .list-section.content .tabel-header {
  margin-left: 20px;
}
.vendor-mapping .mapping-details-section .list-section.content:hover {
  border: 1px solid rgba(0, 89, 251, 0.38);
  box-shadow: 0px 10px 20px 0px rgba(129, 172, 212, 0.45);
}
.vendor-mapping .mapping-details-section .list-section.header {
  font-weight: 600;
  padding-top: 0;
  color: #1a2734;
  font-size: 14px;
  display: flex;
}
.vendor-mapping .mapping-details-section .list-section.header .tabel-header {
  margin-left: 20px;
}
.vendor-mapping .mapping-details-section .list-section .table-header {
  width: 320px;
  margin-left: 20px;
}
.vendor-mapping .mapping-details-section .list-section .table-header.weight .label {
  text-align: center;
  border-bottom: 1px solid rgba(212, 212, 212, 0.5);
  margin-bottom: 7px;
}
.vendor-mapping .mapping-details-section .list-section .table-header.weight .slab-weight {
  display: flex;
  justify-content: space-between;
}
.vendor-mapping .mapping-details-section .list-section .table-row.slab-weight {
  width: 35%;
  margin-left: 5px;
}
.vendor-mapping .mapping-details-section .list-section .table-row.slab-weight.slab-weight-detention {
  width: 100%;
}
.vendor-mapping .mapping-details-section .list-section .table-row.slab-weight .slab-weight-data {
  display: flex;
  justify-content: space-between;
}
.vendor-mapping .mapping-details-section .list-section .table-row.slab-weight .slab-weight-data.row-data {
  margin-bottom: 5px;
  border-bottom: 1px solid rgba(212, 212, 212, 0.5);
}
.vendor-mapping .mapping-details-section .list-section .table-row.slab-weight .slab-weight-data div {
  width: 25%;
}
.vendor-mapping .mapping-details-section .list-section .table-row.slab-weight .slab-weight-data div.vendor {
  width: 70%;
}
.vendor-mapping .mapping-details-section .tabel-header {
  width: 170px;
  display: inline-block;
  vertical-align: middle;
}
.vendor-mapping .mapping-details-section .tabel-header.address {
  width: 300px;
}

.vendor_mapping_detail .overlay {
  position: fixed;
  z-index: 10;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(27, 33, 67, 0.9);
}
.vendor_mapping_detail .modal-popup .pop-heading {
  background-color: #f4f4f4;
  padding: 18px;
  font-family: 'avenir', sans-serif;
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #528aea;
  text-align: center;
}
.vendor_mapping_detail .modal-popup {
  max-height: 670px;
  overflow: scroll;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 5px;
  width: 1000px;
  min-height: 270px;
  background-color: white;
  z-index: 11;
}
.vendor_mapping_detail .vendor-detail {
  margin: 55px;
}
.vendor_mapping_detail .scheduler-detail {
  margin: 10px;
}
.vendor_mapping_detail .button_route {
  margin-right: 10px;
  float: right;
}
.vendor_mapping_detail .error-message {
  margin-right: 10px;
  float: right;
  font-size: 12px;
  color: red;
}
.vendor_mapping_detail .select-wrap-depot {
  width: 33%;
  display: inline-block;
  vertical-align: baseline;
}
.vendor_mapping_detail .select-wrap-depot .label {
  font-size: 10px;
  color: #868e96;
  margin-bottom: 10px;
}
.vendor_mapping_detail.lane-mapping .select-wrap-depot {
  width: 32%;
  margin-right: 10px;
}

.bill-series .modal-popup .content {
  width: 1065px;
  margin: 0px 10px 20px 30px;
  padding: 0px 20px 20px 30px;
  border-radius: 6px;
  box-shadow: 0 0 27px 4px #dbdbe4;
}
.bill-series .header {
  font-size: 20px;
  font-weight: 600;
  color: #528aea;
}
.bill-series .sub-header {
  font-size: 14px;
  font-weight: 600;
  color: #528aea;
}
.bill-series .contact-btn .button-classic {
  margin: 10px;
  padding: 5px 20px;
}
.bill-series .contact-btn .wt-40p {
  width: 40%;
}
.bill-series .contact-btn .wt-60p {
  width: 60%;
}
.bill-series .table {
  width: 100%;
  border-radius: 8px;
  margin-left: -30px;
}
.bill-series .table .table-tr {
  background-color: #3261b1;
  color: white;
  font-size: 13px;
}
.bill-series .table .table-content {
  background-color: white;
  font-size: 13px;
  color: black;
  padding: 5px;
  text-align: center;
}
.bill-series .table .table-content .acCenter {
  align-content: center;
}
.bill-series .table .table-content :hover {
  background-color: #f4f4f4;
}
.bill-series .branchdrop {
  width: 80%;
}
.bill-series .pop-heading {
  background-color: #f4f4f4;
  padding: 18px;
  font-family: 'avenir', sans-serif;
  font-size: 20px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #3261b1;
  text-align: center;
}
.bill-series .pop-heading .button-footer {
  margin: 10px;
  padding: 5px 20px;
  background-color: #3261b1;
  color: white;
  border-radius: 5px;
  cursor: pointer;
}
.bill-series .noteList {
  margin-top: 0px;
}
.bill-series .wt-50p {
  width: 50%;
}
.bill-series .col-3 {
  width: 33.33%;
}
.bill-series .footer {
  background-color: #3261b1;
  float: right;
}
.bill-series .action-icon {
  background-repeat: no-repeat;
  height: 15px;
  width: 15px;
  background-size: contain;
  margin: 0 5px;
  cursor: pointer;
  display: inline-block;
}
.bill-series .tooltip {
  position: absolute;
  display: inline-block;
}
.bill-series .tooltip .tooltiptext {
  visibility: hidden;
  width: 250px;
  background-color: #b9b6b6;
  color: black;
  text-align: left;
  border-radius: 4px;
  padding: 10px;
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 30%;
  opacity: 0;
  transition: opacity 0.3s;
  font-size: 14px;
}
.bill-series .tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}
.bill-series .series {
  text-align: left;
  padding: 10px;
}

.companyUserInfo .search-input {
  padding: 4px 8px;
  border: 1px solid rgb(190, 179, 179);
  outline: 0px;
  min-width: 200px;
}
.companyUserInfo .search-input:hover {
  box-shadow: 0px 0px 2px rgb(190, 179, 179);
}
.companyUserInfo .pagination-wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
  margin-top: 15px;
  margin-right: 10px;
}
.companyUserInfo .pagination-wrapper button {
  padding: 10px 20px;
  border-radius: 50px;
  color: #fff;
  background-color: #42569E;
  outline: none;
  border: 0px;
}
.companyUserInfo .pagination-wrapper button:not(:disabled):hover {
  box-shadow: 0px 0px 2px #42569E;
  cursor: pointer;
}
.companyUserInfo .pagination-wrapper button:disabled {
  cursor: not-allowed;
  opacity: 0.6;
}
.companyUserInfo .pagination-wrapper button:not(:disabled):active {
  outline: 1px solid #42569E;
}
.companyUserInfo .view-more-less-btn {
  padding: 2px 10px;
  border-radius: 30px;
  color: #42569E;
  background-color: transparent;
  outline: none;
  border: 0px;
  display: flex;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
}
.companyUserInfo .view-more-less-btn:not(:disabled):hover {
  background-color: rgba(66, 86, 158, 0.1450980392);
  cursor: pointer;
}
.companyUserInfo .view-more-less-btn:disabled {
  cursor: not-allowed;
  opacity: 0.6;
}
.companyUserInfo .view-more-less-btn:active {
  color: #375ad7;
}
.message-card {
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  padding: 10px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100px;
}
.message-card .message-card__title {
  font-size: 20px;
}
.update-plant-depots-wrap .wrapper {
  width: 1280px;
  margin: 0 auto;
}
.update-plant-depots-wrap .plant-header-section {
  margin-top: 21px;
  font-size: 24px;
}
.update-plant-depots-wrap .plant-other-details .content {
  display: block;
  margin-right: 20px;
  font-size: 14px;
  font-family: 'avenir-light', sans-serif;
  color: #86868a;
  margin-bottom: 7px;
}
.update-plant-depots-wrap .plant-other-details .content .bold {
  font-family: 'avenir-medium', sans-serif;
}
.update-plant-depots-wrap .go-back-prev-screen {
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-size: contain;
  margin-bottom: 20px;
  cursor: pointer;
}
.update-plant-depots-wrap .current-screen {
  text-decoration: underline;
}
.update-plant-depots-wrap .plant-edit-title {
  width: 75%;
  display: inline-block;
}
.update-plant-depots-wrap .card-width {
  width: 20%;
}
.update-plant-depots-wrap .heading-section {
  margin-top: 15px;
  font-size: 20px;
  color: #1a2734;
  font-family: 'avenir-medium', sans-serif;
}
.update-plant-depots-wrap .refresh {
  display: inline-block;
  width: 33%;
}
.update-plant-depots-wrap .refresh .long {
  margin: 0px 65px;
}
.update-plant-depots-wrap .refresh-icon {
  background-image: url(/images/refresh-button.svg);
  background-repeat: no-repeat;
  height: 15px;
  width: 15px;
  background-size: contain;
  margin: 0px 58px;
  cursor: pointer;
  display: inline-block;
  position: absolute;
}
.update-plant-depots-wrap .data-picker {
  display: inline-block;
  width: 33%;
}
.update-plant-depots-wrap .data-picker .label {
  font-size: 10px;
  color: #86868a;
  margin-bottom: 10px;
}
.update-plant-depots-wrap .data-picker .react-datepicker-manager,
.update-plant-depots-wrap .data-picker .react-datepicker-wrapper,
.update-plant-depots-wrap .data-picker .react-datepicker__input-container,
.update-plant-depots-wrap .data-picker .react-datepicker__input-container input {
  width: 277px;
  height: 38px;
  font-size: 16px;
}
.update-plant-depots-wrap .data-picker .rc-time-picker-input {
  height: 38px;
  font-size: 15px;
  color: #252424;
}
.update-plant-depots-wrap .point-of-contact .heading-section {
  padding: 18px 0px;
}
.update-plant-depots-wrap .point-of-contact .list-section {
  border-bottom: 1px solid rgba(212, 212, 212, 0.5);
  padding-bottom: 25px;
  padding-top: 15px;
}
.update-plant-depots-wrap .point-of-contact .list-sectionheader {
  font-weight: 600;
  padding-top: 0;
  color: #1a2734;
  font-size: 14px;
}
.update-plant-depots-wrap .point-of-contact .tabel-header {
  width: 122px;
  display: inline-block;
  vertical-align: middle;
  margin-left: 20px;
}
.update-plant-depots-wrap .point-of-contact .tabel-header.name {
  width: 207px;
}
.update-plant-depots-wrap .point-of-contact .tabel-header.email {
  width: 306px;
}
.update-plant-depots-wrap .point-of-contact .tabel-header.code {
  margin-left: 20px;
}
.update-plant-depots-wrap .point-of-contact .contact-btn {
  margin-right: 10px;
  margin-top: 10px;
}
.update-plant-depots-wrap .point-of-contact .tabel-header-input {
  width: 122px;
  display: inline-block;
  vertical-align: middle;
  font-size: 16px;
  border: none;
  margin-left: 20px;
}
.update-plant-depots-wrap .point-of-contact .tabel-header-input.name {
  width: 207px;
}
.update-plant-depots-wrap .point-of-contact .tabel-header-input.email {
  width: 306px;
}
.update-plant-depots-wrap .point-of-contact .tabel-header-input.code-value {
  padding: 7px 0px;
  border: 1px solid lightgray;
  border-radius: 5px;
}
.update-plant-depots-wrap .button-add {
  margin-bottom: 27px;
}
.select-container {
  clear: both;
  margin-bottom: 20px;
  float: left;
  width: 100%;
}
.select-container .select-holder {
  width: 30%;
  margin-left: 5%;
  float: left;
}
.select-container .select-holder:first-child {
  margin-left: 0;
}
.select-container-moves {
  clear: both;
  margin-bottom: 20px;
}
.select-container-moves .moves-select-container {
  clear: both;
  margin-bottom: 20px;
  float: left;
  width: 100%;
}
.select-container-moves .moves-select-container .select-box {
  width: 33.33%;
  display: inline-block;
  padding-left: 15px;
}
.select-container-moves .moves-select-container .select-box:first-child {
  padding-left: 0;
}
.select-container-moves .moves {
  float: left;
  width: 100%;
  position: relative;
  border-bottom: solid 1px #ccc;
  margin-bottom: 20px;
}
.select-container-moves .moves:last-child {
  border-bottom: none;
}
.select-container-moves .submoves-bucket {
  float: left;
  width: 100%;
  position: relative;
  border: solid 1px #ccc;
  margin-bottom: 20px;
  background-color: #f0f3f7;
  padding: 10px;
}
.select-container-moves .submoves-bucket:last-child {
  border-bottom: none;
}
.select-container-moves .remove-button {
  position: absolute;
  top: 15px;
  right: 0px;
  color: #fff;
  font-size: 14px;
  line-height: 1px;
  margin: 0px 0px;
  padding: 12px 14px;
  display: inline-block;
  cursor: pointer;
  border-radius: 10px;
}
.add-more-moves {
  float: right;
  margin-right: 0px;
}
.submit-section {
  clear: both;
}
.mt-10 {
  margin-top: 10px;
}
.m-10 {
  margin: 10px;
}
.mb-3p {
  margin-bottom: 3%;
}
.mt-1p {
  margin-top: 1%;
}
.ml-23p {
  margin-left: 23%;
}
.add-indents-warp .select-holder,
.add-indents-warp .select-container-moves {
  font-size: 14px;
}
.flow-head {
  margin: 10px 15px 10px 5px;
  font-size: 14px;
  width: 25%!important;
}
.border-bottom {
  border-bottom: 1px solid #ececee;
}
.disable-btn {
  pointer-events: none;
  background: #efeded;
  border: none;
}
.no_data_found {
  margin: 10px 15px 10px 5px;
  font-size: 14px;
  text-align: center;
}
.golden-box.golden-box-sub {
  border: 1px solid #528aea;
  margin: 30px 0px;
}
.golden-box.warehouse_particulars .home-navigation-holder {
  min-width: 200px;
  max-width: 300px;
}
.golden-box.warehouse_particulars .delete-icon {
  height: 17px;
  width: 20px;
  padding: 0px;
  margin: 0px;
}
.golden-box .heading {
  border-bottom: 1px solid #528aea;
}
.golden-box .golden-box-content {
  display: flex;
  justify-content: space-between;
}
.golden-box .golden-box-content .input-holder {
  position: relative;
  margin: 50px 20px;
}
.golden-box .golden-box-content .input-holder .input {
  border: none;
  border-bottom: 1px solid #ededef;
  width: 200px;
  padding: 7px;
}
.golden-box .golden-box-content .input-holder .labels {
  position: absolute;
  top: -20px;
  left: 0;
  font-family: 'Avenir', sans-serif;
  font-size: 12px;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #868e96;
}
.golden-box .golden-box-content .add-role-section {
  padding: 30px 20px 20px 15px;
}
.golden-box .golden-box-content .add-role-section .add-items {
  display: inline-block;
  vertical-align: middle;
  margin-right: 30px;
}
.golden-box .golden-box-display {
  display: flex;
  justify-content: space-around;
  border: none;
  border-bottom: 1px solid #ededef;
  padding: 12px;
}
.golden-box .golden-box-display .headers {
  margin: 0px 20px;
  font-family: 'Avenir', sans-serif;
  font-size: 12px;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #868e96;
  width: 150px;
}
.golden-box .golden-box-display .details {
  width: 150px;
  margin: 0px 20px;
}
.golden-box .golden-box-display .details .labels {
  margin-top: 6px;
}
.golden-box .golden-box-display .details .add-button {
  margin: 0px;
}
.golden-box .add-button {
  padding: 10px 15px;
}
.golden-box .button-holder {
  padding: 20px;
  text-align: right;
}
.golden-box .button-holder.save {
  text-align: center;
}
.golden-box .button-holder.save .add-button {
  padding: 13px 30px;
}
.golden-box .button-holder .add-button {
  font-size: 13px;
}
.golden-box .heading {
  background: #528aea;
  color: #fff;
  padding: 20px 12px;
  font-family: 'Avenir', sans-serif;
  font-size: 20px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  display: block;
}
.golden-box .heading.sub-heading {
  font-size: 18px;
  margin-left: 0px;
  margin-top: 0px;
}
.golden-box .apply-settings {
  display: flex;
}
.golden-box .apply-settings .label {
  font-family: 'Avenir', sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.2px;
  color: #535356;
  margin-left: 15px;
}
.golden-box .add-company-wrap .modal-popup.small-modal {
  width: 420px;
}
.level-header {
  margin: 5px 0px;
  border-bottom: 1px solid grey;
  padding: 5px;
  color: #528aea;
  font-size: 16px;
  font-weight: 900;
}
.level-header.particulars-header {
  margin: 5px 5px;
  color: #212020;
  background-color: #a2e1f6;
}
.cancel-icon {
  display: inline-block;
  pointer-events: cursor;
  cursor: pointer;
  font-size: larger;
}
.wt-200 {
  width: 200px;
}
.VAM {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.VAMT1 {
  padding: 10px 15px;
}
.VAMT1 .rowWrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 40px;
  gap: 40px;
  justify-content: space-around;
}
.VAMT1 .p-10 {
  padding: 10px;
}
.VAMT1 .p-5 {
  padding: 10px;
}

.plants-basic-details-wrap .details {
  margin-top: 40px;
}
.plants-basic-details-wrap .details .address,
.plants-basic-details-wrap .details .pin-code {
  display: inline-block;
  vertical-align: top;
}
.plants-basic-details-wrap .details .vehicle_placement {
  display: inline-block;
}
.plants-basic-details-wrap .details .vehicle_placement .select {
  width: 120px;
}
.plants-basic-details-wrap .details .select-wrap-depot {
  width: 33%;
  display: inline-block;
  vertical-align: baseline;
}
.plants-basic-details-wrap .details .select-wrap-depot .label {
  font-size: 10px;
  color: #868e96;
  margin-bottom: 10px;
}
.plants-basic-details-wrap .details .select-wrap-depot.auto_move .custome-checkbox-wrap {
  margin-right: 10px;
}
.plants-basic-details-wrap .details .select {
  width: 277px;
}
.plants-basic-details-wrap .details .address {
  width: 600px;
}
.plants-basic-details-wrap .details .address .input-text-wrap {
  width: 100%;
}
.plants-basic-details-wrap .details .address .input-text-wrap .text-input {
  width: 100%;
}
.plants-basic-details-wrap .details .pin-code {
  margin-left: 50px;
}
.plants-basic-details-wrap .submit-section-depot {
  margin-top: 30px;
}
.address-details .input-text-wrap {
  vertical-align: top;
}
.address-details span {
  display: flex;
  width: 277px;
}
.delete-company-wrap .overlay {
  position: fixed;
  z-index: 10;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(27, 33, 67, 0.9);
}
.delete-company-wrap .modal-popup1 {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 5px;
  width: 500px;
  min-height: 200px;
  background-color: white;
  z-index: 11;
  padding: 40px 80px 15px 72px;
  text-align: center;
  margin-top: 20px;
}
.delete-company-wrap .modal-popup1 .question {
  margin-top: 20px;
  font-size: 20px;
}
.delete-company-wrap .modal-popup1 .submit-section {
  float: right;
}
.delete-company-wrap .modal-popup1 .submit-section .button-section {
  margin-left: 10px;
  float: right;
}
.delete-company-wrap .modal-popup1 .submit-section.delete-confirmation {
  padding: 20px 0px;
}
.error-sec {
  font-size: 14px;
  font-weight: 600;
  color: #7c1f1f;
  background-color: #e6bdbd;
  padding: 10px;
  width: 90%;
  margin: 5px;
}
.warning-sec {
  font-size: 14px;
  font-weight: 600;
  color: #d7ab04;
  background-color: #fbf3d5;
  padding: 10px;
  width: 90%;
  margin: 5px;
}
.dedicated-vehicle {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}
.dedicated-vehicle .first-header {
  display: flex;
}
.dedicated-vehicle .dedicated-content {
  display: flex;
  margin-top: 10px;
  width: 90%;
  justify-content: space-between;
}
.recheck-approval {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}
.recheck-approval .first-header {
  display: flex;
}
.recheck-approval .label {
  font-size: 16px;
}
.recheck-approval .mt-25 {
  margin-top: 25px;
}
.recheck-approval .input-container {
  display: flex;
  align-items: center;
}
.recheck-approval .input-container .label {
  width: 250px;
}
.recheck-approval .input-container span {
  font-size: 12px;
  margin-top: 30px;
  align-self: center;
  margin-left: -12px;
}
.recheck-approval .input-container .select-wrap-depot {
  width: 180px;
}
.recheck-approval .approval-content {
  display: flex;
  margin-top: 10px;
  width: 90%;
  grid-gap: 20px;
  gap: 20px;
}
.recheck-approval .approval-content input {
  padding: 5px 0px 5px 10px;
  width: 180px;
  height: 35px;
  border: 1px solid lightgray;
  border-radius: 5px;
}

.plants-depots-main-wrap {
  max-width: 944px;
  font-family: 'Avenir', sans-serif;
}
.plants-depots-main-wrap .search-holder {
  display: flex;
  justify-content: space-between;
  margin: 35px 4px;
}
.plants-depots-main-wrap .search-holder .new {
  border-radius: 5px;
  border: none;
}
.plants-depots-main-wrap .heading {
  display: flex;
  font-size: 12px;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.2px;
  color: #ababae;
}
.plants-depots-main-wrap .depot-users-list {
  display: flex;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.2px;
  color: #535356;
  margin: 4px 0px;
  padding-bottom: 10px;
  border-bottom: 1px solid #ececee;
}
.plants-depots-main-wrap .depot-users-list .edit-delete {
  display: flex;
  justify-content: space-between;
  max-height: 23px;
  width: 99px!important;
}
.plants-depots-main-wrap .depot-users-list .edit-delete .add-button {
  padding: 4px 6px;
  margin: 0px 4px 0px 0px;
}
.plants-depots-main-wrap .heading .head,
.plants-depots-main-wrap .depot-users-list .head {
  margin: 10px 15px 10px 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.plants-depots-main-wrap .heading .usn,
.plants-depots-main-wrap .depot-users-list .usn {
  width: 135px;
}
.plants-depots-main-wrap .heading .email,
.plants-depots-main-wrap .depot-users-list .email {
  width: 150px;
}
.plants-depots-main-wrap .heading .phone,
.plants-depots-main-wrap .depot-users-list .phone {
  width: 100px!important;
}
.plants-depots-main-wrap .heading .branch,
.plants-depots-main-wrap .depot-users-list .branch {
  width: 120px!important;
}
.plants-depots-main-wrap .heading .role,
.plants-depots-main-wrap .depot-users-list .role,
.plants-depots-main-wrap .heading .actions,
.plants-depots-main-wrap .depot-users-list .actions {
  width: 75px;
}
.plants-depots-main-wrap .heading .active,
.plants-depots-main-wrap .depot-users-list .active {
  width: 100px;
}
.plants-depots-main-wrap .depot-users-list .usn {
  font-weight: 900;
}
.loading_bay_gang .home-navigation-holder {
  min-width: 200px;
  max-width: 750px;
  margin: 20px 0px;
}
.add-user-wrap.add-bay .modal-popup {
  min-height: 300px;
  max-height: 600px;
}
.add-user-wrap.add-bay .modal-popup .content {
  margin-top: 2px;
}
.add-user-wrap.standard-time .rc-time-picker-input {
  height: 38px;
}
.add-user-wrap.standard-time .delete-icon {
  margin: 5px;
}

.plants-depots-main-wrap {
  max-width: 944px;
  font-family: 'Avenir', sans-serif;
}
.plants-depots-main-wrap .search-holder {
  display: flex;
  justify-content: space-between;
  margin: 35px 4px;
}
.plants-depots-main-wrap .search-holder .select {
  width: 250px;
}
.plants-depots-main-wrap .search-holder .new {
  border-radius: 5px;
  border: none;
}
.plants-depots-main-wrap .heading {
  display: flex;
  font-size: 12px;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.2px;
  color: #ababae;
}
.plants-depots-main-wrap .depot-users-list {
  display: flex;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.2px;
  color: #535356;
  margin: 4px 0px;
  padding-bottom: 10px;
  border-bottom: 1px solid #ececee;
}
.plants-depots-main-wrap .depot-users-list .edit-delete {
  display: flex;
  justify-content: space-between;
  max-height: 23px;
  width: 99px!important;
}
.plants-depots-main-wrap .depot-users-list .edit-delete .add-button {
  padding: 4px 6px;
  margin: 0px 4px 0px 0px;
}
.plants-depots-main-wrap .heading .head,
.plants-depots-main-wrap .depot-users-list .head {
  margin: 10px 15px 10px 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.plants-depots-main-wrap .heading .usn,
.plants-depots-main-wrap .depot-users-list .usn {
  width: 135px;
}
.plants-depots-main-wrap .heading .email,
.plants-depots-main-wrap .depot-users-list .email {
  width: 150px;
}
.plants-depots-main-wrap .heading .phone,
.plants-depots-main-wrap .depot-users-list .phone {
  width: 100px!important;
}
.plants-depots-main-wrap .heading .branch,
.plants-depots-main-wrap .depot-users-list .branch {
  width: 120px!important;
}
.plants-depots-main-wrap .heading .role,
.plants-depots-main-wrap .depot-users-list .role,
.plants-depots-main-wrap .heading .actions,
.plants-depots-main-wrap .depot-users-list .actions {
  width: 70px;
}
.plants-depots-main-wrap .heading .active,
.plants-depots-main-wrap .depot-users-list .active {
  width: 100px;
}
.plants-depots-main-wrap .heading .subheading,
.plants-depots-main-wrap .depot-users-list .subheading {
  width: 250px;
}
.plants-depots-main-wrap .heading .time,
.plants-depots-main-wrap .depot-users-list .time {
  margin: none !important;
  width: 50px !important;
}
.plants-depots-main-wrap .depot-users-list .usn {
  font-weight: 900;
}
.plants-depots-main-wrap .tooltip {
  position: absolute;
  display: inline-block;
}
.plants-depots-main-wrap .tooltip .tooltiptext {
  visibility: hidden;
  background-color: #b9b6b6;
  color: black;
  text-align: left;
  border-radius: 4px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  right: 80%;
  transition: opacity 0.3s;
  font-size: 14px;
}
.plants-depots-main-wrap .tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}
.plants-depots-main-wrap .search-text-wrap .text-search {
  width: 190px;
}
.plants-depots-main-wrap .search-text-wrap .cross-img {
  right: 60px;
}
.advanced-button {
  margin-bottom: 20px;
  margin-left: 0;
  float: left;
}
.select-container-advanced .select-holder {
  width: 25%;
  padding-left: 20px;
  margin-left: 0;
}
.select-container-advanced .select-holder:first-child {
  margin-left: 0;
  padding-left: 0;
}
.depot-users-list-first {
  float: left;
  width: 50%;
}
.depot-users-list-second {
  float: right;
  width: 50%;
}
.depot-users-list-first .first,
.depot-users-list-second .first {
  float: left;
  width: 50%;
}
.depot-users-list-first .second,
.depot-users-list-second .second {
  float: right;
  width: 50%;
}
.depot-users-list-complete {
  width: 100%;
}
.heading .first,
.heading .second {
  font-weight: 900;
}
.float-right {
  float: right;
}
.float-left {
  float: left;
}
.view-flow-box {
  min-width: 885px;
  max-width: 920px;
  height: 30px;
}

.add-user-wrap .overlay {
  position: fixed;
  z-index: 10;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(27, 33, 67, 0.9);
}
.add-user-wrap .modal-popup {
  max-height: 600px;
  overflow: scroll;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 5px;
  width: 1000px;
  min-height: 500px;
  background-color: white;
  z-index: 11;
}
.add-user-wrap .modal-popup .pop-heading {
  background-color: #f4f4f4;
  padding: 18px;
  font-family: 'avenir', sans-serif;
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #528aea;
  text-align: center;
  margin-bottom: 30px;
}
.add-user-wrap .modal-popup .content {
  padding: 20px 0px 20px 30px;
}
.add-user-wrap .modal-popup .content .select-container {
  display: flex;
  margin: 20px 0px;
}
.add-user-wrap .modal-popup .content .select-container .select-holder {
  width: 277px;
  position: relative;
}
.add-user-wrap .modal-popup .content .select-container .select-holder .sub-head {
  font-size: 10px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #868e96;
  margin-bottom: 7px;
}
.add-user-wrap .modal-popup .content .select-container .action-holder {
  display: flex;
  flex-wrap: wrap;
  width: 330px;
  margin: 15px;
  position: relative;
}
.add-user-wrap .modal-popup .content .select-container .action-holder .actions {
  margin: 5px;
}
.add-user-wrap .modal-popup .content .select-container .action-holder .actions .add-button {
  margin: 0;
  height: 35px;
  padding: 0 15px;
  font-size: 20px;
  margin-left: 10px;
}
.add-user-wrap .modal-popup .content .select-container .action-holder .actions .select {
  width: 235px;
}
.add-user-wrap .modal-popup .content .select-container .action-holder .add-button {
  margin: 5px 5px;
  padding: 10px 20px;
}
.add-user-wrap .modal-popup .content .select-container .action-holder .sub-head {
  position: absolute;
  font-size: 10px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #868e96;
  top: -10px;
  left: 7px;
}
.add-user-wrap .modal-popup .content .usn-container {
  margin: 17px 0px;
  display: flex;
}
.add-user-wrap .modal-popup .content .usn-container .add-button {
  margin: 20px;
  border-radius: 5px;
}
.add-user-wrap .modal-popup .content .usn-container .btn-disabled {
  background-color: #cac2c2;
  border: 1px solid #cac2c2;
  pointer-events: none;
  color: white;
}
.add-user-wrap .modal-popup .content .submit-section {
  display: flex;
  justify-content: flex-end;
}
.add-user-wrap .modal-popup .content .submit-section .add-button {
  border-radius: 5px;
  width: 145px;
}
.add-user-wrap .modal-popup .content .submit-section .discard {
  background: white;
  color: #528aea;
  border: 1px solid #528aea;
}
.add-user-wrap.add-indents-warp .apply-settings {
  display: flex;
  justify-content: center;
}
.add-user-wrap.add-indents-warp .apply-settings .label {
  font-family: 'Avenir', sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.2px;
  color: #535356;
  margin-left: 15px;
}
.form-section .input-text-wrap {
  vertical-align: top;
}
.small-font {
  font-size: 12px;
  font-weight: normal;
}
.add-edit-user {
  margin-top: -4%;
}
.padding-left-0 {
  padding-left: 0px !important;
}
.arrow-icon {
  background-image: url(/images/drop-up-arrow.svg);
  background-repeat: no-repeat;
  height: 23px;
  width: 23px;
  background-size: contain;
  margin: 0 5px;
  cursor: pointer;
  float: right;
  transition-delay: 0.2s;
  transition-duration: 0.2s;
  -webkit-transition-duration: 0.5s;
  -webkit-transition-delay: 0.2s;
}
.rotate {
  transform: rotate(180deg);
}
.submoves {
  background-color: #5289ea;
  height: 30px;
  font-size: 16px;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 5px;
  color: white;
}

.plants-depots-main-wrap {
  max-width: 944px;
  font-family: 'Avenir', sans-serif;
}
.plants-depots-main-wrap .search-holder {
  display: flex;
  justify-content: space-between;
  margin: 35px 4px;
}
.plants-depots-main-wrap .search-holder .select {
  width: 250px;
}
.plants-depots-main-wrap .search-holder .new {
  border-radius: 5px;
  border: none;
}
.plants-depots-main-wrap .button-classic {
  margin-right: 0px !important;
}
.plants-depots-main-wrap .heading {
  display: flex;
  font-size: 12px;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.2px;
  color: #ababae;
}
.plants-depots-main-wrap .depot-users-list {
  display: flex;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.2px;
  color: #535356;
  margin: 4px 0px;
  padding-bottom: 10px;
  border-bottom: 1px solid #ececee;
}
.plants-depots-main-wrap .depot-users-list .edit-delete {
  display: flex;
  justify-content: space-between;
  max-height: 23px;
  width: 99px!important;
}
.plants-depots-main-wrap .depot-users-list .edit-delete .add-button {
  padding: 4px 6px;
  margin: 0px 4px 0px 0px;
}
.plants-depots-main-wrap .heading .head,
.plants-depots-main-wrap .depot-users-list .head {
  margin: 10px 15px 10px 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.plants-depots-main-wrap .heading .usn,
.plants-depots-main-wrap .depot-users-list .usn {
  width: 135px;
}
.plants-depots-main-wrap .heading .email,
.plants-depots-main-wrap .depot-users-list .email {
  width: 150px;
}
.plants-depots-main-wrap .heading .phone,
.plants-depots-main-wrap .depot-users-list .phone {
  width: 100px!important;
}
.plants-depots-main-wrap .heading .branch,
.plants-depots-main-wrap .depot-users-list .branch {
  width: 120px!important;
}
.plants-depots-main-wrap .heading .role,
.plants-depots-main-wrap .depot-users-list .role,
.plants-depots-main-wrap .heading .actions,
.plants-depots-main-wrap .depot-users-list .actions {
  width: 70px;
}
.plants-depots-main-wrap .heading .active,
.plants-depots-main-wrap .depot-users-list .active {
  width: 100px;
}
.plants-depots-main-wrap .depot-users-list .usn {
  font-weight: 900;
}

.add-user-wrap .overlay {
  position: fixed;
  z-index: 10;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(27, 33, 67, 0.9);
}
.add-user-wrap .modal-popup {
  max-height: 600px;
  overflow: scroll;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 5px;
  width: 1000px;
  min-height: 500px;
  background-color: white;
  z-index: 11;
}
.add-user-wrap .modal-popup .pop-heading {
  background-color: #f4f4f4;
  padding: 18px;
  font-family: 'avenir', sans-serif;
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #528aea;
  text-align: center;
  margin-bottom: 30px;
}
.add-user-wrap .modal-popup .content {
  padding: 20px 0px 20px 30px;
}
.add-user-wrap .modal-popup .content .select-container {
  display: flex;
  margin: 20px 0px;
}
.add-user-wrap .modal-popup .content .select-container .select-holder {
  width: 277px;
  position: relative;
}
.add-user-wrap .modal-popup .content .select-container .select-holder .sub-head {
  font-size: 10px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #868e96;
  margin-bottom: 7px;
}
.add-user-wrap .modal-popup .content .select-container .action-holder {
  display: flex;
  flex-wrap: wrap;
  width: 330px;
  margin: 15px;
  position: relative;
}
.add-user-wrap .modal-popup .content .select-container .action-holder .actions {
  margin: 5px;
}
.add-user-wrap .modal-popup .content .select-container .action-holder .actions .add-button {
  margin: 0;
  height: 35px;
  padding: 0 15px;
  font-size: 20px;
  margin-left: 10px;
}
.add-user-wrap .modal-popup .content .select-container .action-holder .actions .select {
  width: 235px;
}
.add-user-wrap .modal-popup .content .select-container .action-holder .add-button {
  margin: 5px 5px;
  padding: 10px 20px;
}
.add-user-wrap .modal-popup .content .select-container .action-holder .sub-head {
  position: absolute;
  font-size: 10px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #868e96;
  top: -10px;
  left: 7px;
}
.add-user-wrap .modal-popup .content .usn-container {
  margin: 17px 0px;
  display: flex;
}
.add-user-wrap .modal-popup .content .usn-container .add-button {
  margin: 20px;
  border-radius: 5px;
}
.add-user-wrap .modal-popup .content .usn-container .btn-disabled {
  background-color: #cac2c2;
  border: 1px solid #cac2c2;
  pointer-events: none;
  color: white;
}
.add-user-wrap .modal-popup .content .submit-section {
  display: flex;
  justify-content: flex-end;
}
.add-user-wrap .modal-popup .content .submit-section .add-button {
  border-radius: 5px;
  width: 145px;
}
.add-user-wrap .modal-popup .content .submit-section .discard {
  background: white;
  color: #528aea;
  border: 1px solid #528aea;
}
.form-section .input-text-wrap {
  vertical-align: top;
}
.small-font {
  font-size: 12px;
  font-weight: normal;
}

.form-section-map .input-text-wrap {
  width: 32%;
}

.discount-wrap .wrapper {
  width: 1280px;
  margin: 0 auto;
}
.discount-wrap .wrapper .home-title {
  font-size: 12px;
  color: #86868a;
}
.discount-wrap .wrapper .sub-heading-section {
  margin-top: 22px;
  font-family: 'avenir-medium', sans-serif;
  font-weight: 900;
  overflow: auto;
}
.discount-wrap .wrapper .sub-heading-section .sub-heading {
  font-size: 24px;
  color: #282626;
  float: left;
}
.discount-wrap .wrapper .sub-heading-section .add-company-section {
  float: right;
  margin-right: 30px;
}
.discount-wrap .wrapper .search-filter-section {
  clear: both;
  margin-top: 25px;
}
.discount-wrap .wrapper .company-list-wrap {
  margin-top: 60px;
}
.discount-wrap .wrapper .company-list-wrap .no-records-section {
  margin-top: 40px;
}
.discount-wrap .wrapper .company-list-wrap .list-section {
  border-bottom: 1px solid rgba(212, 212, 212, 0.5);
  padding-bottom: 25px;
  padding-top: 15px;
}
.discount-wrap .wrapper .company-list-wrap .list-section .checkbox-section {
  display: inline-block;
  vertical-align: middle;
}
.discount-wrap .wrapper .company-list-wrap .list-section .company-name-section {
  display: inline-block;
  vertical-align: middle;
  margin-left: 10px;
  width: 370px;
}
.discount-wrap .wrapper .company-list-wrap .list-section .company-name-section.table-header {
  margin-left: 85px;
  width: 290px;
}
.discount-wrap .wrapper .company-list-wrap .list-section .company-name-section .comany-logo,
.discount-wrap .wrapper .company-list-wrap .list-section .company-name-section .compnay-details {
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
}
.discount-wrap .wrapper .company-list-wrap .list-section .company-name-section .comany-logo {
  width: 50px;
  height: 35px;
  background-size: contain;
  background-repeat: no-repeat;
}
.discount-wrap .wrapper .company-list-wrap .list-section .company-name-section .compnay-details {
  margin-left: 25px;
  color: #1a2734;
  font-size: 15px;
  font-weight: 900;
  width: 220px;
}
.discount-wrap .wrapper .company-list-wrap .list-section .company-name-section .compnay-details .date {
  color: #86868a;
  font-size: 12px;
}
.discount-wrap .wrapper .company-list-wrap .list-section .company-name-section .compnay-details .name {
  word-break: break-word;
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}
.discount-wrap .wrapper .company-list-wrap .list-section .admin-details {
  display: inline-block;
  vertical-align: middle;
  width: 340px;
  font-size: 15px;
  color: #1a2734;
}
.discount-wrap .wrapper .company-list-wrap .list-section .admin-details.table-header {
  margin-left: 8px;
  width: 337px;
}
.discount-wrap .wrapper .company-list-wrap .list-section .admin-details .admin-email {
  font-size: 15px;
  color: #1a2734;
  cursor: pointer;
}
.discount-wrap .wrapper .company-list-wrap .list-section .admin-details .admin-pan {
  font-size: 12px;
  color: #86868a;
}
.discount-wrap .wrapper .company-list-wrap .list-section .account-type {
  display: inline-block;
  vertical-align: middle;
  width: 140px;
  font-size: 15px;
  color: #1a2734;
}
.discount-wrap .wrapper .company-list-wrap .list-section .status {
  display: inline-block;
  vertical-align: middle;
  width: 100px;
}
.discount-wrap .wrapper .company-list-wrap .list-section.header {
  font-weight: 600;
  padding-top: 0;
  color: #1a2734;
  font-size: 14px;
}
.discount-wrap .wrapper .company-list-wrap .list-section.content:hover {
  border: 1px solid rgba(0, 89, 251, 0.38);
  box-shadow: 0px 10px 20px 0px rgba(129, 172, 212, 0.45);
}
.discount-wrap .wrapper .company-list-wrap .list-section.content:hover div,
.discount-wrap .wrapper .company-list-wrap .list-section.content:hover div .date {
  /*color: #528aea;*/
  color: black;
}
.discount-wrap .wrapper .company-list-wrap .list-section.content:hover .company-name-section .compnay-details .name {
  display: block;
}
.select {
  display: inline-block;
  width: 150px;
}

.liability-wrap .overflowAuto {
  overflow: auto;
}
.liability-wrap .wt-max-content {
  width: max-content;
}
.liability-wrap .min-wt-100p {
  min-width: 100%;
}
@media all and (max-width: 1024px) {
  .liability-wrap .responsive-table {
    overflow-x: auto;
    overflow-y: visible;
    width: inherit;
  }
  .liability-wrap .responsive-table .inner-container {
    min-width: 1025px;
  }
}
.liability-wrap .wrapper {
  width: 1280px;
  margin: 0 auto;
}
.liability-wrap .wrapper .home-title {
  font-size: 12px;
  color: #86868a;
}
.liability-wrap .wrapper .sub-heading-section {
  margin-top: 22px;
  font-family: 'avenir-medium', sans-serif;
  font-weight: 900;
  overflow: auto;
}
.liability-wrap .wrapper .sub-heading-section .sub-heading {
  font-size: 24px;
  color: #282626;
  float: left;
}
.liability-wrap .wrapper .sub-heading-section .add-company-section {
  float: right;
  margin-right: 30px;
}
.liability-wrap .wrapper .cta-action-btns .button-classic {
  height: 30px;
  font-size: 14px;
  padding: 0 20px;
  border-radius: 5px;
  margin-top: 10px;
  margin-right: 10px;
}
.liability-wrap .wrapper .search-filter-section {
  clear: both;
  margin-top: 25px;
}
.liability-wrap .wrapper .company-list-wrap {
  margin-top: 60px;
}
.liability-wrap .wrapper .company-list-wrap .no-records-section {
  margin-top: 40px;
}
.liability-wrap .wrapper .company-list-wrap .rules-tab {
  width: 300px;
}
.liability-wrap .wrapper .company-list-wrap .list-section {
  border-bottom: 1px solid rgba(212, 212, 212, 0.5);
  padding-bottom: 25px;
  padding-top: 15px;
}
.liability-wrap .wrapper .company-list-wrap .list-section .checkbox-section {
  display: inline-block;
  vertical-align: middle;
}
.liability-wrap .wrapper .company-list-wrap .list-section .sub-header-inline {
  display: inline-block;
  margin-right: 6px;
  width: 100px;
  vertical-align: top;
}
.liability-wrap .wrapper .company-list-wrap .list-section .table-header {
  width: 11%;
  font-size: 12px;
  display: inline-block;
  word-break: break-word;
}
.liability-wrap .wrapper .company-list-wrap .list-section .table-header.select-row {
  width: 4%;
}
.liability-wrap .wrapper .company-list-wrap .list-section .table-header.rules-tab {
  width: 18%;
}
.liability-wrap .wrapper .company-list-wrap .list-section .company-name-section {
  display: inline-block;
  vertical-align: middle;
  margin-left: 10px;
  width: 370px;
}
.liability-wrap .wrapper .company-list-wrap .list-section .company-name-section.table-header {
  margin-left: 85px;
  width: 290px;
}
.liability-wrap .wrapper .company-list-wrap .list-section .company-name-section .comany-logo,
.liability-wrap .wrapper .company-list-wrap .list-section .company-name-section .compnay-details {
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
}
.liability-wrap .wrapper .company-list-wrap .list-section .company-name-section .comany-logo {
  width: 50px;
  height: 35px;
  background-size: contain;
  background-repeat: no-repeat;
}
.liability-wrap .wrapper .company-list-wrap .list-section .company-name-section .compnay-details {
  margin-left: 25px;
  color: #1a2734;
  font-size: 15px;
  font-weight: 900;
  width: 220px;
}
.liability-wrap .wrapper .company-list-wrap .list-section .company-name-section .compnay-details .date {
  color: #86868a;
  font-size: 12px;
}
.liability-wrap .wrapper .company-list-wrap .list-section .company-name-section .compnay-details .name {
  word-break: break-word;
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}
.liability-wrap .wrapper .company-list-wrap .list-section .admin-details {
  display: inline-block;
  vertical-align: middle;
  width: 340px;
  font-size: 15px;
  color: #1a2734;
}
.liability-wrap .wrapper .company-list-wrap .list-section .admin-details.table-header {
  margin-left: 8px;
  width: 337px;
}
.liability-wrap .wrapper .company-list-wrap .list-section .admin-details .admin-email {
  font-size: 15px;
  color: #1a2734;
  cursor: pointer;
}
.liability-wrap .wrapper .company-list-wrap .list-section .admin-details .admin-pan {
  font-size: 12px;
  color: #86868a;
}
.liability-wrap .wrapper .company-list-wrap .list-section .account-type {
  display: inline-block;
  vertical-align: middle;
  width: 140px;
  font-size: 15px;
  color: #1a2734;
}
.liability-wrap .wrapper .company-list-wrap .list-section .status {
  display: inline-block;
  vertical-align: middle;
  width: 100px;
}
.liability-wrap .wrapper .company-list-wrap .list-section.header {
  font-weight: 600;
  padding-top: 0;
  color: #1a2734;
  font-size: 14px;
}
.liability-wrap .wrapper .company-list-wrap .list-section.content:hover {
  border: 1px solid rgba(0, 89, 251, 0.38);
  box-shadow: 0px 10px 20px 0px rgba(129, 172, 212, 0.45);
}
.liability-wrap .wrapper .company-list-wrap .list-section.content:hover div,
.liability-wrap .wrapper .company-list-wrap .list-section.content:hover div .date {
  /*color: #528aea;*/
  color: black;
}
.liability-wrap .wrapper .company-list-wrap .list-section.content:hover .company-name-section .compnay-details .name {
  display: block;
}
.liability-wrap .wrapper .action-icon {
  background-repeat: no-repeat;
  height: 18px;
  width: 18px;
  background-size: contain;
  cursor: pointer;
  display: inline-block;
  padding: 5px;
  margin: 5px;
}
.divTable {
  margin-top: 20px;
  margin-bottom: 20px;
  display: table;
  width: 100%;
}
.divTableRow {
  display: table-row;
}
.divTableHeading {
  color: white;
  background-color: #EEE;
  display: table-header-group;
}
.divTableCell,
.divTableHead {
  /*border: 1px solid #999999;*/
  display: table-cell;
  padding: 3px 10px;
  font-size: 12px;
}
.divTableHeading {
  background-color: #EEE;
  display: table-header-group;
  font-weight: bold;
}
.divTableFoot {
  background-color: #EEE;
  display: table-footer-group;
  font-weight: bold;
}
.divTableBody {
  display: table-row-group;
  margin-top: 10px;
}
.select {
  display: inline-block;
  width: 150px;
}
.claim-box {
  margin: 5px;
  padding: 10px 20px;
  border: 1px solid #e0e0e0;
}
.claim-box .claim-item {
  cursor: pointer;
  padding: 5px 10px;
  text-align: center;
  margin-bottom: 5px;
  border-radius: 20px;
  border: 1px solid #e0e0e0;
}
.claim-box .claim-item.active {
  background: #e0e0e0;
}
.mb-10 {
  margin-bottom: 10px;
}
.liabilityInput .input-text-wrap {
  width: 50px;
}
.liabilityInput .input-text-wrap .text-input {
  width: 50px;
  font-size: 12px;
  padding-bottom: 5px;
}
.search-text-wrap {
  margin-bottom: 10px;
}

.discount-wrap .wrapper {
  width: 1280px;
  margin: 0 auto;
}
.discount-wrap .wrapper .home-title {
  font-size: 12px;
  color: #86868a;
}
.discount-wrap .wrapper .sub-heading-section {
  margin-top: 22px;
  font-family: 'avenir-medium', sans-serif;
  font-weight: 900;
  overflow: auto;
}
.discount-wrap .wrapper .sub-heading-section .sub-heading {
  font-size: 24px;
  color: #282626;
  float: left;
}
.discount-wrap .wrapper .sub-heading-section .add-company-section {
  float: right;
  margin-right: 30px;
}
.discount-wrap .wrapper .cta-action-btns {
  display: inline-block;
}
.discount-wrap .wrapper .cta-action-btns .button-classic {
  height: 30px;
  font-size: 14px;
  padding: 0 20px;
  border-radius: 5px;
  margin-top: 10px;
  margin-right: 10px;
}
.discount-wrap .wrapper .cta-action-btns.cta-btn-right {
  float: right;
}
.discount-wrap .wrapper .search-filter-section {
  clear: both;
  margin-top: 25px;
}
.discount-wrap .wrapper .company-list-wrap {
  margin-top: 45px;
}
.discount-wrap .wrapper .company-list-wrap .no-records-section {
  margin-top: 40px;
}
.discount-wrap .wrapper .company-list-wrap .dpcell {
  display: flex;
}
.discount-wrap .wrapper .company-list-wrap .list-section {
  border-bottom: 1px solid rgba(212, 212, 212, 0.5);
  padding-bottom: 25px;
  padding-top: 15px;
}
.discount-wrap .wrapper .company-list-wrap .list-section .checkbox-section {
  display: inline-block;
  vertical-align: middle;
}
.discount-wrap .wrapper .company-list-wrap .list-section .sub-header-inline {
  display: inline-block;
  margin-right: 6px;
  width: 75px;
}
.discount-wrap .wrapper .company-list-wrap .list-section .table-header {
  width: 11%;
  font-size: 12px;
  display: inline-block;
  word-break: break-word;
}
.discount-wrap .wrapper .company-list-wrap .list-section .table-header.select-row {
  width: 4%;
}
.discount-wrap .wrapper .company-list-wrap .list-section .table-header.rules-tab {
  width: 18%;
}
.discount-wrap .wrapper .company-list-wrap .list-section .company-name-section {
  display: inline-block;
  vertical-align: middle;
  margin-left: 10px;
  width: 370px;
}
.discount-wrap .wrapper .company-list-wrap .list-section .company-name-section.table-header {
  margin-left: 85px;
  width: 290px;
}
.discount-wrap .wrapper .company-list-wrap .list-section .company-name-section .comany-logo,
.discount-wrap .wrapper .company-list-wrap .list-section .company-name-section .compnay-details {
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
}
.discount-wrap .wrapper .company-list-wrap .list-section .company-name-section .comany-logo {
  width: 50px;
  height: 35px;
  background-size: contain;
  background-repeat: no-repeat;
}
.discount-wrap .wrapper .company-list-wrap .list-section .company-name-section .compnay-details {
  margin-left: 25px;
  color: #1a2734;
  font-size: 15px;
  font-weight: 900;
  width: 220px;
}
.discount-wrap .wrapper .company-list-wrap .list-section .company-name-section .compnay-details .date {
  color: #86868a;
  font-size: 12px;
}
.discount-wrap .wrapper .company-list-wrap .list-section .company-name-section .compnay-details .name {
  word-break: break-word;
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}
.discount-wrap .wrapper .company-list-wrap .list-section .admin-details {
  display: inline-block;
  vertical-align: middle;
  width: 340px;
  font-size: 15px;
  color: #1a2734;
}
.discount-wrap .wrapper .company-list-wrap .list-section .admin-details.table-header {
  margin-left: 8px;
  width: 337px;
}
.discount-wrap .wrapper .company-list-wrap .list-section .admin-details .admin-email {
  font-size: 15px;
  color: #1a2734;
  cursor: pointer;
}
.discount-wrap .wrapper .company-list-wrap .list-section .admin-details .admin-pan {
  font-size: 12px;
  color: #86868a;
}
.discount-wrap .wrapper .company-list-wrap .list-section .account-type {
  display: inline-block;
  vertical-align: middle;
  width: 140px;
  font-size: 15px;
  color: #1a2734;
}
.discount-wrap .wrapper .company-list-wrap .list-section .status {
  display: inline-block;
  vertical-align: middle;
  width: 100px;
}
.discount-wrap .wrapper .company-list-wrap .list-section.header {
  font-weight: 600;
  padding-top: 0;
  color: #1a2734;
  font-size: 14px;
}
.discount-wrap .wrapper .company-list-wrap .list-section.content:hover {
  border: 1px solid rgba(12, 12, 12, 0.38);
  box-shadow: 0px 10px 20px 0px rgba(158, 170, 181, 0.45);
}
.discount-wrap .wrapper .company-list-wrap .list-section.content:hover .company-name-section .compnay-details .name {
  display: block;
}
.discount-wrap .wrapper .company-list-wrap .action-icon {
  background-repeat: no-repeat;
  height: 18px;
  width: 18px;
  background-size: contain;
  cursor: pointer;
  display: inline-block;
  padding: 5px;
  margin: 5px;
}
.divTable {
  margin-top: 20px;
  margin-bottom: 20px;
  display: table;
  width: 100%;
}
.divTableRow {
  display: table-row;
}
.divTableHeading {
  color: white;
  background-color: #EEE;
  display: table-header-group;
}
.divTableCell,
.divTableHead {
  /*border: 1px solid #999999;*/
  display: table-cell;
  padding: 10px 10px;
  font-size: 12px;
}
.divTableCell.bordered,
.divTableHead.bordered {
  border-bottom: 1px solid #999999;
}
.divTableHeading {
  background-color: #528aea;
  display: table-header-group;
  font-weight: bold;
}
.divTableFoot {
  background-color: #EEE;
  display: table-footer-group;
  font-weight: bold;
}
.divTableBody {
  display: table-row-group;
  margin-top: 10px;
}
.select {
  display: inline-block;
  width: 150px;
}
.slabRange .input-text-wrap {
  margin-bottom: 0;
}
.slabRange .input-element input {
  width: auto;
  font-size: 12px;
}
.penaltyPercent .input-text-wrap .text-input {
  width: 50px;
  font-size: 12px;
  padding-bottom: 5px;
}
.penaltyBox {
  text-align: center;
}
.download-btn {
  text-decoration: none;
}
.add-user-wrap .overlay {
  position: fixed;
  z-index: 10;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(27, 33, 67, 0.9);
}
.add-user-wrap .modal-popup {
  max-height: 600px;
  overflow: scroll;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 5px;
  width: 1000px;
  min-height: 500px;
  background-color: white;
  z-index: 11;
}
.add-user-wrap .modal-popup .pop-heading {
  background-color: #f4f4f4;
  padding: 18px;
  font-family: 'avenir', sans-serif;
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #528aea;
  text-align: center;
  margin-bottom: 30px;
}
.add-user-wrap .modal-popup .content {
  padding: 20px 0px 20px 30px;
}
.add-user-wrap .modal-popup .content .select-container {
  display: flex;
  margin: 20px 0px;
}
.add-user-wrap .modal-popup .content .select-container .select-holder {
  width: 277px;
  position: relative;
}
.add-user-wrap .modal-popup .content .select-container .select-holder .sub-head {
  font-size: 10px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #868e96;
  margin-bottom: 7px;
}
.add-user-wrap .modal-popup .content .select-container .action-holder {
  display: flex;
  flex-wrap: wrap;
  width: 330px;
  margin: 15px;
  position: relative;
}
.add-user-wrap .modal-popup .content .select-container .action-holder .actions {
  margin: 5px;
}
.add-user-wrap .modal-popup .content .select-container .action-holder .actions .add-button {
  margin: 0;
  height: 35px;
  padding: 0 15px;
  font-size: 20px;
  margin-left: 10px;
}
.add-user-wrap .modal-popup .content .select-container .action-holder .actions .select {
  width: 235px;
}
.add-user-wrap .modal-popup .content .select-container .action-holder .add-button {
  margin: 5px 5px;
  padding: 10px 20px;
}
.add-user-wrap .modal-popup .content .select-container .action-holder .sub-head {
  position: absolute;
  font-size: 10px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #868e96;
  top: -10px;
  left: 7px;
}
.add-user-wrap .modal-popup .content .usn-container {
  margin: 17px 0px;
  display: flex;
}
.add-user-wrap .modal-popup .content .usn-container .add-button {
  margin: 20px;
  border-radius: 5px;
}
.add-user-wrap .modal-popup .content .usn-container .btn-disabled {
  background-color: #cac2c2;
  border: 1px solid #cac2c2;
  pointer-events: none;
  color: white;
}
.add-user-wrap .modal-popup .content .submit-section {
  display: flex;
  justify-content: flex-end;
}
.add-user-wrap .modal-popup .content .submit-section .add-button {
  border-radius: 5px;
  width: 145px;
}
.add-user-wrap .modal-popup .content .submit-section .discard {
  background: white;
  color: #528aea;
  border: 1px solid #528aea;
}
.add-user-wrap .modal-popup .content .submit-section.business-button button.add-button {
  margin: 0;
  color: black;
  padding: 10px 20px;
  background: #fd8989;
  border-radius: 20px;
  border-color: #ff000052;
}
.add-user-wrap .modal-popup .content .submit-section.business-button button.add-button.discard {
  background: #fff;
}
.divTableCell.rules-tab {
  width: 350px !important;
}
.penalty-slab {
  color: grey;
}
.penalty-value {
  color: black;
}
/*.table-header {
    .divTableCell {
        font-size: 15px !important;
    }
}*/
.delay-penalty-popup .divTableCell.bordered,
.delay-penalty-popup .divTableHead.bordered {
  border-bottom: 1px solid #999999;
  font-size: 0.9rem !important;
}

.advance-filter {
    width: 12%;
    margin: 10px;
    padding: 10px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 8px 0px, rgba(0, 0, 0, 0.17) 0px 7px 20px 0px;
    position: absolute;
    background: white;
    left: 82%;
}
.tag-elements {
    border: 1px solid grey;
    margin-right: 5px;
    margin-top: 5px;
    padding: 4px 10px 2px;
    border-radius: 15px;
    font-size: 14px;
    display: inline-block;
    cursor: pointer;
}
.tag-elements-active {
    border: 1px solid grey;
    margin-right: 5px;
    margin-top: 5px;
    padding: 4px 10px 2px;
    border-radius: 15px;
    display: inline-block;
    background: black;
    color: white;
    font-size: 14px;
    cursor: pointer;
}
.advance-filter-title {
    border-bottom: 2px solid #dfdfdf;
    padding-left: 5px;
}
.m-5 {
    margin: 5px;
}
.m-t-0 {
    margin-top: 0px;
}
.m-t-10 {
    margin-top: 10px;
}
.h-l {
    border: 1px solid #dfdfdf;
}
.apply-filter-button {
    cursor: pointer;
    height: 30px;
    padding: 0 20px;
    color: #fff;
    background: #528aea;
    margin: 0px 20px;
    display: inline-block;
    border: 1px solid #528aea;
    font-size: 16px;
}
.align-center {
    text-align: center;
}
.discount-wrap .wrapper {
  width: 1280px;
  margin: 0 auto;
}
.discount-wrap .wrapper .home-title {
  font-size: 12px;
  color: #86868a;
}
.discount-wrap .wrapper .sub-heading-section {
  margin-top: 22px;
  font-family: 'avenir-medium', sans-serif;
  font-weight: 900;
  overflow: auto;
}
.discount-wrap .wrapper .sub-heading-section .sub-heading {
  font-size: 24px;
  color: #282626;
  float: left;
}
.discount-wrap .wrapper .sub-heading-section .add-company-section {
  float: right;
  margin-right: 30px;
}
.discount-wrap .wrapper .cta-action-btns {
  display: inline-block;
}
.discount-wrap .wrapper .cta-action-btns .button-classic {
  height: 30px;
  font-size: 14px;
  padding: 0 20px;
  border-radius: 5px;
  margin-top: 10px;
  margin-right: 10px;
}
.discount-wrap .wrapper .cta-action-btns.cta-btn-right {
  float: right;
}
.discount-wrap .wrapper .search-filter-section {
  clear: both;
  margin-top: 25px;
}
.discount-wrap .wrapper .company-list-wrap {
  margin-top: 45px;
}
.discount-wrap .wrapper .company-list-wrap .no-records-section {
  margin-top: 40px;
}
.discount-wrap .wrapper .company-list-wrap .list-section {
  border-bottom: 1px solid rgba(212, 212, 212, 0.5);
  padding-bottom: 25px;
  padding-top: 15px;
}
.discount-wrap .wrapper .company-list-wrap .list-section .checkbox-section {
  display: inline-block;
  vertical-align: middle;
}
.discount-wrap .wrapper .company-list-wrap .list-section .sub-header-inline {
  display: inline-block;
  margin-right: 6px;
  min-width: 70px;
}
.discount-wrap .wrapper .company-list-wrap .list-section .table-header {
  width: 11%;
  font-size: 12px;
  display: inline-block;
  word-break: break-word;
}
.discount-wrap .wrapper .company-list-wrap .list-section .table-header.select-row {
  width: 4%;
}
.discount-wrap .wrapper .company-list-wrap .list-section .table-header.rules-tab {
  width: 18%;
}
.discount-wrap .wrapper .company-list-wrap .list-section .company-name-section {
  display: inline-block;
  vertical-align: middle;
  margin-left: 10px;
  width: 370px;
}
.discount-wrap .wrapper .company-list-wrap .list-section .company-name-section.table-header {
  margin-left: 85px;
  width: 290px;
}
.discount-wrap .wrapper .company-list-wrap .list-section .company-name-section .comany-logo,
.discount-wrap .wrapper .company-list-wrap .list-section .company-name-section .compnay-details {
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
}
.discount-wrap .wrapper .company-list-wrap .list-section .company-name-section .comany-logo {
  width: 50px;
  height: 35px;
  background-size: contain;
  background-repeat: no-repeat;
}
.discount-wrap .wrapper .company-list-wrap .list-section .company-name-section .compnay-details {
  margin-left: 25px;
  color: #1a2734;
  font-size: 15px;
  font-weight: 900;
  width: 220px;
}
.discount-wrap .wrapper .company-list-wrap .list-section .company-name-section .compnay-details .date {
  color: #86868a;
  font-size: 12px;
}
.discount-wrap .wrapper .company-list-wrap .list-section .company-name-section .compnay-details .name {
  word-break: break-word;
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}
.discount-wrap .wrapper .company-list-wrap .list-section .admin-details {
  display: inline-block;
  vertical-align: middle;
  width: 340px;
  font-size: 15px;
  color: #1a2734;
}
.discount-wrap .wrapper .company-list-wrap .list-section .admin-details.table-header {
  margin-left: 8px;
  width: 337px;
}
.discount-wrap .wrapper .company-list-wrap .list-section .admin-details .admin-email {
  font-size: 15px;
  color: #1a2734;
  cursor: pointer;
}
.discount-wrap .wrapper .company-list-wrap .list-section .admin-details .admin-pan {
  font-size: 12px;
  color: #86868a;
}
.discount-wrap .wrapper .company-list-wrap .list-section .account-type {
  display: inline-block;
  vertical-align: middle;
  width: 140px;
  font-size: 15px;
  color: #1a2734;
}
.discount-wrap .wrapper .company-list-wrap .list-section .status {
  display: inline-block;
  vertical-align: middle;
  width: 100px;
}
.discount-wrap .wrapper .company-list-wrap .list-section.header {
  font-weight: 600;
  padding-top: 0;
  color: #1a2734;
  font-size: 14px;
}
.discount-wrap .wrapper .company-list-wrap .list-section.content:hover {
  border: 1px solid rgba(12, 12, 12, 0.38);
  box-shadow: 0px 10px 20px 0px rgba(158, 170, 181, 0.45);
}
.discount-wrap .wrapper .company-list-wrap .list-section.content:hover .company-name-section .compnay-details .name {
  display: block;
}
.discount-wrap .wrapper .action-icon {
  background-repeat: no-repeat;
  height: 18px;
  width: 18px;
  background-size: contain;
  cursor: pointer;
  display: inline-block;
  padding: 5px;
  margin: 5px;
}
.divTable {
  margin-top: 20px;
  margin-bottom: 20px;
  display: table;
  width: 100%;
}
.divTableRow {
  display: table-row;
}
.divTableHeading {
  color: white;
  background-color: #EEE;
  display: table-header-group;
}
.divTableCell,
.divTableHead {
  /*border: 1px solid #999999;*/
  display: table-cell;
  padding: 10px 10px;
  font-size: 12px;
}
.divTableCell.bordered,
.divTableHead.bordered {
  border-bottom: 1px solid #999999;
}
.divTableHeading {
  background-color: #528aea;
  display: table-header-group;
  font-weight: bold;
}
.divTableFoot {
  background-color: #EEE;
  display: table-footer-group;
  font-weight: bold;
}
.divTableBody {
  display: table-row-group;
  margin-top: 10px;
}
.select {
  display: inline-block;
  width: 150px;
}
.slabRange .input-text-wrap {
  margin-bottom: 0;
}
.slabRange .input-element input {
  width: auto;
  font-size: 12px;
}
.penaltyPercent .input-text-wrap .text-input {
  width: 50px;
  font-size: 12px;
  padding-bottom: 5px;
}
.penaltyBox {
  text-align: center;
}
.download-btn {
  text-decoration: none;
}
.add-user-wrap .overlay {
  position: fixed;
  z-index: 10;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(27, 33, 67, 0.9);
}
.add-user-wrap .modal-popup {
  max-height: 600px;
  overflow: scroll;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 5px;
  width: 1000px;
  min-height: 500px;
  background-color: white;
  z-index: 11;
}
.add-user-wrap .modal-popup .pop-heading {
  background-color: #f4f4f4;
  padding: 18px;
  font-family: 'avenir', sans-serif;
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #528aea;
  text-align: center;
  margin-bottom: 30px;
}
.add-user-wrap .modal-popup .content {
  padding: 20px 0px 20px 30px;
}
.add-user-wrap .modal-popup .content .select-container {
  display: flex;
  margin: 20px 0px;
}
.add-user-wrap .modal-popup .content .select-container .select-holder {
  width: 277px;
  position: relative;
}
.add-user-wrap .modal-popup .content .select-container .select-holder .sub-head {
  font-size: 10px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #868e96;
  margin-bottom: 7px;
}
.add-user-wrap .modal-popup .content .select-container .action-holder {
  display: flex;
  flex-wrap: wrap;
  width: 330px;
  margin: 15px;
  position: relative;
}
.add-user-wrap .modal-popup .content .select-container .action-holder .actions {
  margin: 5px;
}
.add-user-wrap .modal-popup .content .select-container .action-holder .actions .add-button {
  margin: 0;
  height: 35px;
  padding: 0 15px;
  font-size: 20px;
  margin-left: 10px;
}
.add-user-wrap .modal-popup .content .select-container .action-holder .actions .select {
  width: 235px;
}
.add-user-wrap .modal-popup .content .select-container .action-holder .add-button {
  margin: 5px 5px;
  padding: 10px 20px;
}
.add-user-wrap .modal-popup .content .select-container .action-holder .sub-head {
  position: absolute;
  font-size: 10px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #868e96;
  top: -10px;
  left: 7px;
}
.add-user-wrap .modal-popup .content .usn-container {
  margin: 17px 0px;
  display: flex;
}
.add-user-wrap .modal-popup .content .usn-container .add-button {
  margin: 20px;
  border-radius: 5px;
}
.add-user-wrap .modal-popup .content .usn-container .btn-disabled {
  background-color: #cac2c2;
  border: 1px solid #cac2c2;
  pointer-events: none;
  color: white;
}
.add-user-wrap .modal-popup .content .submit-section {
  display: flex;
  justify-content: flex-end;
}
.add-user-wrap .modal-popup .content .submit-section .add-button {
  border-radius: 5px;
  width: 145px;
}
.add-user-wrap .modal-popup .content .submit-section .discard {
  background: white;
  color: #528aea;
  border: 1px solid #528aea;
}
.add-user-wrap .modal-popup .content .submit-section.business-button button.add-button {
  margin: 0;
  color: black;
  padding: 10px 20px;
  background: #fd8989;
  border-radius: 20px;
  border-color: #ff000052;
}
.add-user-wrap .modal-popup .content .submit-section.business-button button.add-button.discard {
  background: #fff;
}
.serviceTypes .section-item {
  display: inline-block;
  margin-right: 30px;
  margin-top: 20px;
}
.serviceTypes .section-item .title {
  padding-left: 10px;
}
.laneInput .input-text-wrap .text-input {
  width: 50px;
  font-size: 12px;
  padding-bottom: 5px;
}

.discount-wrap .wrapper {
  width: 1280px;
  margin: 0 auto;
}
.discount-wrap .wrapper .home-title {
  font-size: 12px;
  color: #86868a;
}
.discount-wrap .wrapper .sub-heading-section {
  margin-top: 22px;
  font-family: 'avenir-medium', sans-serif;
  font-weight: 900;
  overflow: auto;
}
.discount-wrap .wrapper .sub-heading-section .sub-heading {
  font-size: 24px;
  color: #282626;
  float: left;
}
.discount-wrap .wrapper .cta-action-btns {
  display: inline-block;
}
.discount-wrap .wrapper .cta-action-btns .button-classic {
  height: 30px;
  font-size: 14px;
  padding: 0 20px;
  border-radius: 5px;
  margin-top: 10px;
  margin-right: 10px;
}
.discount-wrap .wrapper .cta-action-btns.cta-btn-right {
  float: right;
}
.discount-wrap .wrapper .search-filter-section {
  clear: both;
  margin-top: 25px;
}
.discount-wrap .wrapper .company-list-wrap {
  margin-top: 45px;
}
.discount-wrap .wrapper .company-list-wrap .no-records-section {
  margin-top: 40px;
}
.discount-wrap .wrapper .company-list-wrap .list-section {
  border-bottom: 1px solid rgba(212, 212, 212, 0.5);
  padding-bottom: 25px;
  padding-top: 15px;
}
.discount-wrap .wrapper .company-list-wrap .list-section .checkbox-section {
  display: inline-block;
  vertical-align: middle;
}
.discount-wrap .wrapper .company-list-wrap .list-section .sub-header-inline {
  display: inline-block;
  margin-right: 6px;
  min-width: 70px;
}
.discount-wrap .wrapper .company-list-wrap .list-section .table-header {
  width: 11%;
  font-size: 12px;
  display: inline-block;
  word-break: break-word;
}
.discount-wrap .wrapper .company-list-wrap .list-section .table-header.select-row {
  width: 4%;
}
.discount-wrap .wrapper .company-list-wrap .list-section .table-header.rules-tab {
  width: 18%;
}
.discount-wrap .wrapper .company-list-wrap .list-section .company-name-section {
  display: inline-block;
  vertical-align: middle;
  margin-left: 10px;
  width: 370px;
}
.discount-wrap .wrapper .company-list-wrap .list-section .company-name-section.table-header {
  margin-left: 85px;
  width: 290px;
}
.discount-wrap .wrapper .company-list-wrap .list-section .company-name-section .comany-logo,
.discount-wrap .wrapper .company-list-wrap .list-section .company-name-section .compnay-details {
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
}
.discount-wrap .wrapper .company-list-wrap .list-section .company-name-section .comany-logo {
  width: 50px;
  height: 35px;
  background-size: contain;
  background-repeat: no-repeat;
}
.discount-wrap .wrapper .company-list-wrap .list-section .company-name-section .compnay-details {
  margin-left: 25px;
  color: #1a2734;
  font-size: 15px;
  font-weight: 900;
  width: 220px;
}
.discount-wrap .wrapper .company-list-wrap .list-section .company-name-section .compnay-details .date {
  color: #86868a;
  font-size: 12px;
}
.discount-wrap .wrapper .company-list-wrap .list-section .company-name-section .compnay-details .name {
  word-break: break-word;
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}
.discount-wrap .wrapper .company-list-wrap .list-section .admin-details {
  display: inline-block;
  vertical-align: middle;
  width: 340px;
  font-size: 15px;
  color: #1a2734;
}
.discount-wrap .wrapper .company-list-wrap .list-section .admin-details.table-header {
  margin-left: 8px;
  width: 337px;
}
.discount-wrap .wrapper .company-list-wrap .list-section .admin-details .admin-email {
  font-size: 15px;
  color: #1a2734;
  cursor: pointer;
}
.discount-wrap .wrapper .company-list-wrap .list-section .admin-details .admin-pan {
  font-size: 12px;
  color: #86868a;
}
.discount-wrap .wrapper .company-list-wrap .list-section .account-type {
  display: inline-block;
  vertical-align: middle;
  width: 140px;
  font-size: 15px;
  color: #1a2734;
}
.discount-wrap .wrapper .company-list-wrap .list-section .status {
  display: inline-block;
  vertical-align: middle;
  width: 100px;
}
.discount-wrap .wrapper .company-list-wrap .list-section.header {
  font-weight: 600;
  padding-top: 0;
  color: #1a2734;
  font-size: 14px;
}
.discount-wrap .wrapper .company-list-wrap .list-section.content:hover {
  border: 1px solid rgba(12, 12, 12, 0.38);
  box-shadow: 0px 10px 20px 0px rgba(158, 170, 181, 0.45);
}
.discount-wrap .wrapper .company-list-wrap .list-section.content:hover .company-name-section .compnay-details .name {
  display: block;
}
.discount-wrap .wrapper .action-icon {
  background-repeat: no-repeat;
  height: 18px;
  width: 18px;
  background-size: contain;
  cursor: pointer;
  display: inline-block;
  padding: 5px;
  margin: 5px;
}
.discount-wrap .wrapper .download-icon {
  background-repeat: no-repeat;
  height: 30px;
  width: 30px;
  background-size: contain;
  cursor: pointer;
  display: inline-block;
  padding: 5px;
  margin: 5px;
}
.divTable {
  margin-top: 20px;
  margin-bottom: 20px;
  display: table;
  width: 100%;
}
.divTableRow {
  display: table-row;
}
.divTableHeading {
  color: white;
  background-color: #EEE;
  display: table-header-group;
}
.divTableCell,
.divTableHead {
  /*border: 1px solid #999999;*/
  display: table-cell;
  padding: 10px 10px;
  font-size: 12px;
}
.divTableCell.bordered,
.divTableHead.bordered {
  border-bottom: 1px solid #999999;
}
.divTableHeading {
  background-color: #528aea;
  display: table-header-group;
  font-weight: bold;
}
.divTableFoot {
  background-color: #EEE;
  display: table-footer-group;
  font-weight: bold;
}
.divTableBody {
  display: table-row-group;
  margin-top: 10px;
}
.select {
  display: inline-block;
  width: 150px;
}
.slabRange .input-text-wrap {
  margin-bottom: 0;
}
.slabRange .input-element input {
  width: auto;
  font-size: 12px;
}
.penaltyPercent .input-text-wrap .text-input {
  width: 50px;
  font-size: 12px;
  padding-bottom: 5px;
}
.penaltyBox {
  text-align: center;
}
.download-btn {
  text-decoration: none;
}
.add-user-wrap .overlay {
  position: fixed;
  z-index: 10;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(27, 33, 67, 0.9);
}
.add-user-wrap .modal-popup {
  max-height: 600px;
  overflow: scroll;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 5px;
  width: 1000px;
  min-height: 500px;
  background-color: white;
  z-index: 11;
}
.add-user-wrap .modal-popup .pop-heading {
  background-color: #f4f4f4;
  padding: 18px;
  font-family: 'avenir', sans-serif;
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #528aea;
  text-align: center;
  margin-bottom: 30px;
}
.add-user-wrap .modal-popup .content {
  padding: 20px 0px 20px 30px;
}
.add-user-wrap .modal-popup .content .select-container {
  display: flex;
  margin: 20px 0px;
}
.add-user-wrap .modal-popup .content .select-container .select-holder {
  width: 277px;
  position: relative;
}
.add-user-wrap .modal-popup .content .select-container .select-holder .sub-head {
  font-size: 10px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #868e96;
  margin-bottom: 7px;
}
.add-user-wrap .modal-popup .content .select-container .action-holder {
  display: flex;
  flex-wrap: wrap;
  width: 330px;
  margin: 15px;
  position: relative;
}
.add-user-wrap .modal-popup .content .select-container .action-holder .actions {
  margin: 5px;
}
.add-user-wrap .modal-popup .content .select-container .action-holder .actions .add-button {
  margin: 0;
  height: 35px;
  padding: 0 15px;
  font-size: 20px;
  margin-left: 10px;
}
.add-user-wrap .modal-popup .content .select-container .action-holder .actions .select {
  width: 235px;
}
.add-user-wrap .modal-popup .content .select-container .action-holder .add-button {
  margin: 5px 5px;
  padding: 10px 20px;
}
.add-user-wrap .modal-popup .content .select-container .action-holder .sub-head {
  position: absolute;
  font-size: 10px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #868e96;
  top: -10px;
  left: 7px;
}
.add-user-wrap .modal-popup .content .usn-container {
  margin: 17px 0px;
  display: flex;
}
.add-user-wrap .modal-popup .content .usn-container .add-button {
  margin: 20px;
  border-radius: 5px;
}
.add-user-wrap .modal-popup .content .usn-container .btn-disabled {
  background-color: #cac2c2;
  border: 1px solid #cac2c2;
  pointer-events: none;
  color: white;
}
.add-user-wrap .modal-popup .content .submit-section {
  display: flex;
  justify-content: flex-end;
}
.add-user-wrap .modal-popup .content .submit-section .add-button {
  border-radius: 5px;
  width: 145px;
}
.add-user-wrap .modal-popup .content .submit-section .discard {
  background: white;
  color: #528aea;
  border: 1px solid #528aea;
}
.add-user-wrap .modal-popup .content .submit-section.business-button button.add-button {
  margin: 0;
  color: black;
  padding: 10px 20px;
  background: #fd8989;
  border-radius: 20px;
  border-color: #ff000052;
}
.add-user-wrap .modal-popup .content .submit-section.business-button button.add-button.discard {
  background: #fff;
}
.filter-section {
  background-color: #f0f3f7;
  border: 1px solid #c2c0c0;
  border-radius: 5px;
  padding: 10px;
  box-shadow: 10px 10px 20px 0px rgba(158, 170, 181, 0.45);
}
.serviceTypes .section-item {
  display: inline-block;
  margin-right: 30px;
  margin-top: 20px;
}
.serviceTypes .section-item .title {
  padding-left: 10px;
}
.tatInput {
  width: 210px;
  margin-right: 20px;
}
.tatInput .input-text-wrap {
  width: 100%;
}
.tatInput .input-text-wrap .text-input {
  width: 100%;
  font-size: 12px;
  padding-bottom: 5px;
  margin-top: 10px;
}
.tatInput .DateInput {
  width: 200px;
}
.tatInput .DateInput .DateInput_input {
  font-size: 12px;
  border-bottom: 1px solid #d8dcf0;
  padding: 10px 10px 0px;
  line-height: 13px;
  height: 40px;
}
.tatInput .wt-80 {
  width: 80px;
}
.filterSelect {
  font-size: 12px;
}
.filterSelect .select {
  width: 210px;
}
.filterSelect .subSelect {
  width: 160px;
}
.label {
  font-size: 12px;
  font-weight: 600;
  margin: 2px;
}
.border-bottom-input {
  border-top: none;
  border-right: none;
  border-left: none;
  border-bottom: 1px solid #86868a;
}
.outline-none {
  outline: none;
}
.ht-30 {
  height: 30px;
}
.button-classic-small {
  height: 24px;
  padding: 3px 30px;
  border-radius: 5px;
  background-color: white;
  color: #528aea;
  font-size: 16px;
  font-weight: bold;
  outline: none;
  cursor: pointer;
  border: 1px solid #528aea;
}
.btn-disabled {
  height: 30px;
  font-size: 14px;
  padding: 0 20px;
  border-radius: 5px;
  margin-top: 10px;
  margin-right: 10px;
  background-color: white;
  color: #9bb7e8;
  pointer-events: none;
  border: 1px solid #9bb7e8;
}

.discount-wrap .wrapper {
  width: 1280px;
  margin: 0 auto;
}
.discount-wrap .wrapper .home-title {
  font-size: 12px;
  color: #86868a;
}
.discount-wrap .wrapper .sub-heading-section {
  margin-top: 22px;
  font-family: 'avenir-medium', sans-serif;
  font-weight: 900;
  overflow: auto;
}
.discount-wrap .wrapper .sub-heading-section .sub-heading {
  font-size: 24px;
  color: #282626;
  float: left;
}
.discount-wrap .wrapper .sub-heading-section .add-company-section {
  float: right;
  margin-right: 30px;
}
.discount-wrap .wrapper .cta-action-btns {
  display: inline-block;
}
.discount-wrap .wrapper .cta-action-btns .button-classic {
  height: 30px;
  font-size: 14px;
  padding: 0 20px;
  border-radius: 5px;
  margin-top: 10px;
  margin-right: 10px;
}
.discount-wrap .wrapper .cta-action-btns.cta-btn-right {
  float: right;
}
.discount-wrap .wrapper .search-filter-section {
  clear: both;
  margin-top: 25px;
}
.discount-wrap .wrapper .company-list-wrap {
  margin-top: 45px;
}
.discount-wrap .wrapper .company-list-wrap .no-records-section {
  margin-top: 40px;
}
.discount-wrap .wrapper .company-list-wrap .list-section {
  border-bottom: 1px solid rgba(212, 212, 212, 0.5);
  padding-bottom: 25px;
  padding-top: 15px;
}
.discount-wrap .wrapper .company-list-wrap .list-section .checkbox-section {
  display: inline-block;
  vertical-align: middle;
}
.discount-wrap .wrapper .company-list-wrap .list-section .sub-header-inline {
  display: inline-block;
  margin-right: 6px;
  min-width: 70px;
}
.discount-wrap .wrapper .company-list-wrap .list-section .table-header {
  width: 11%;
  font-size: 12px;
  display: inline-block;
  word-break: break-word;
}
.discount-wrap .wrapper .company-list-wrap .list-section .table-header.select-row {
  width: 4%;
}
.discount-wrap .wrapper .company-list-wrap .list-section .table-header.rules-tab {
  width: 18%;
}
.discount-wrap .wrapper .company-list-wrap .list-section .company-name-section {
  display: inline-block;
  vertical-align: middle;
  margin-left: 10px;
  width: 370px;
}
.discount-wrap .wrapper .company-list-wrap .list-section .company-name-section.table-header {
  margin-left: 85px;
  width: 290px;
}
.discount-wrap .wrapper .company-list-wrap .list-section .company-name-section .comany-logo,
.discount-wrap .wrapper .company-list-wrap .list-section .company-name-section .compnay-details {
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
}
.discount-wrap .wrapper .company-list-wrap .list-section .company-name-section .comany-logo {
  width: 50px;
  height: 35px;
  background-size: contain;
  background-repeat: no-repeat;
}
.discount-wrap .wrapper .company-list-wrap .list-section .company-name-section .compnay-details {
  margin-left: 25px;
  color: #1a2734;
  font-size: 15px;
  font-weight: 900;
  width: 220px;
}
.discount-wrap .wrapper .company-list-wrap .list-section .company-name-section .compnay-details .date {
  color: #86868a;
  font-size: 12px;
}
.discount-wrap .wrapper .company-list-wrap .list-section .company-name-section .compnay-details .name {
  word-break: break-word;
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}
.discount-wrap .wrapper .company-list-wrap .list-section .admin-details {
  display: inline-block;
  vertical-align: middle;
  width: 340px;
  font-size: 15px;
  color: #1a2734;
}
.discount-wrap .wrapper .company-list-wrap .list-section .admin-details.table-header {
  margin-left: 8px;
  width: 337px;
}
.discount-wrap .wrapper .company-list-wrap .list-section .admin-details .admin-email {
  font-size: 15px;
  color: #1a2734;
  cursor: pointer;
}
.discount-wrap .wrapper .company-list-wrap .list-section .admin-details .admin-pan {
  font-size: 12px;
  color: #86868a;
}
.discount-wrap .wrapper .company-list-wrap .list-section .account-type {
  display: inline-block;
  vertical-align: middle;
  width: 140px;
  font-size: 15px;
  color: #1a2734;
}
.discount-wrap .wrapper .company-list-wrap .list-section .status {
  display: inline-block;
  vertical-align: middle;
  width: 100px;
}
.discount-wrap .wrapper .company-list-wrap .list-section.header {
  font-weight: 600;
  padding-top: 0;
  color: #1a2734;
  font-size: 14px;
}
.discount-wrap .wrapper .company-list-wrap .list-section.content:hover {
  border: 1px solid rgba(12, 12, 12, 0.38);
  box-shadow: 0px 10px 20px 0px rgba(158, 170, 181, 0.45);
}
.discount-wrap .wrapper .company-list-wrap .list-section.content:hover .company-name-section .compnay-details .name {
  display: block;
}
.divTable {
  margin-top: 20px;
  margin-bottom: 20px;
  display: table;
  width: 100%;
}
.divTableRow {
  display: table-row;
}
.divTableHeading {
  color: white;
  background-color: #EEE;
  display: table-header-group;
}
.divTableCell,
.divTableHead {
  /*border: 1px solid #999999;*/
  display: table-cell;
  padding: 10px 10px;
  font-size: 12px;
}
.divTableCell.bordered,
.divTableHead.bordered {
  border-bottom: 1px solid #999999;
}
.divTableCell input,
.divTableHead input {
  width: 100px;
  height: 30px;
  border: none;
  outline: none;
  border-bottom: 1px solid lightgray;
}
.divTableHeading {
  background-color: #528aea;
  display: table-header-group;
  font-weight: bold;
}
.divTableFoot {
  background-color: #EEE;
  display: table-footer-group;
  font-weight: bold;
}
.divTableBody {
  display: table-row-group;
  margin-top: 10px;
}
.select {
  display: inline-block;
  width: 150px;
}
.slabRange .input-text-wrap {
  margin-bottom: 0;
}
.slabRange .input-element input {
  width: auto;
  font-size: 12px;
}
.penaltyPercent .input-text-wrap .text-input {
  width: 50px;
  font-size: 12px;
  padding-bottom: 5px;
}
.penaltyBox {
  text-align: center;
}
.download-btn {
  text-decoration: none;
}
.add-user-wrap .overlay {
  position: fixed;
  z-index: 10;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(27, 33, 67, 0.9);
}
.add-user-wrap .modal-popup {
  max-height: 600px;
  overflow: scroll;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 5px;
  width: 1000px;
  min-height: 500px;
  background-color: white;
  z-index: 11;
}
.add-user-wrap .modal-popup .pop-heading {
  background-color: #f4f4f4;
  padding: 18px;
  font-family: 'avenir', sans-serif;
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #528aea;
  text-align: center;
  margin-bottom: 30px;
}
.add-user-wrap .modal-popup .content {
  padding: 20px 0px 20px 30px;
}
.add-user-wrap .modal-popup .content .select-container {
  display: flex;
  margin: 20px 0px;
}
.add-user-wrap .modal-popup .content .select-container .select-holder {
  width: 277px;
  position: relative;
}
.add-user-wrap .modal-popup .content .select-container .select-holder .sub-head {
  font-size: 10px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #868e96;
  margin-bottom: 7px;
}
.add-user-wrap .modal-popup .content .select-container .action-holder {
  display: flex;
  flex-wrap: wrap;
  width: 330px;
  margin: 15px;
  position: relative;
}
.add-user-wrap .modal-popup .content .select-container .action-holder .actions {
  margin: 5px;
}
.add-user-wrap .modal-popup .content .select-container .action-holder .actions .add-button {
  margin: 0;
  height: 35px;
  padding: 0 15px;
  font-size: 20px;
  margin-left: 10px;
}
.add-user-wrap .modal-popup .content .select-container .action-holder .actions .select {
  width: 235px;
}
.add-user-wrap .modal-popup .content .select-container .action-holder .add-button {
  margin: 5px 5px;
  padding: 10px 20px;
}
.add-user-wrap .modal-popup .content .select-container .action-holder .sub-head {
  position: absolute;
  font-size: 10px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #868e96;
  top: -10px;
  left: 7px;
}
.add-user-wrap .modal-popup .content .usn-container {
  margin: 17px 0px;
  display: flex;
}
.add-user-wrap .modal-popup .content .usn-container .add-button {
  margin: 20px;
  border-radius: 5px;
}
.add-user-wrap .modal-popup .content .usn-container .btn-disabled {
  background-color: #cac2c2;
  border: 1px solid #cac2c2;
  pointer-events: none;
  color: white;
}
.add-user-wrap .modal-popup .content .submit-section {
  display: flex;
  justify-content: flex-end;
}
.add-user-wrap .modal-popup .content .submit-section .add-button {
  border-radius: 5px;
  width: 145px;
}
.add-user-wrap .modal-popup .content .submit-section .discard {
  background: white;
  color: #528aea;
  border: 1px solid #528aea;
}
.add-user-wrap .modal-popup .content .submit-section.business-button button.add-button {
  margin: 0;
  color: black;
  padding: 10px 20px;
  background: #fd8989;
  border-radius: 20px;
  border-color: #ff000052;
}
.add-user-wrap .modal-popup .content .submit-section.business-button button.add-button.discard {
  background: #fff;
}
.serviceTypes .section-item {
  display: inline-block;
  margin-right: 30px;
  margin-top: 20px;
}
.serviceTypes .section-item .title {
  padding-left: 10px;
}
.curP {
  cursor: pointer;
}
.modalContentList .discount-wrap .wrapper .company-list-wrap {
  margin-top: 0;
}
.wt-40 {
  width: 40px;
}

.discount-wrap .wrapper {
  width: 1280px;
  margin: 0 auto;
}
.discount-wrap .wrapper .home-title {
  font-size: 12px;
  color: #86868a;
}
.discount-wrap .wrapper .sub-heading-section {
  margin-top: 22px;
  font-family: 'avenir-medium', sans-serif;
  font-weight: 900;
  overflow: auto;
}
.discount-wrap .wrapper .sub-heading-section .sub-heading {
  font-size: 24px;
  color: #282626;
  float: left;
}
.discount-wrap .wrapper .sub-heading-section .add-company-section {
  float: right;
  margin-right: 30px;
}
.discount-wrap .wrapper .cta-action-btns {
  display: inline-block;
}
.discount-wrap .wrapper .cta-action-btns .button-classic {
  height: 30px;
  font-size: 14px;
  padding: 0 20px;
  border-radius: 5px;
  margin-top: 10px;
  margin-right: 10px;
}
.discount-wrap .wrapper .cta-action-btns.cta-btn-right {
  float: right;
}
.discount-wrap .wrapper .search-filter-section {
  clear: both;
  margin-top: 25px;
}
.discount-wrap .wrapper .company-list-wrap {
  margin-top: 45px;
}
.discount-wrap .wrapper .company-list-wrap .no-records-section {
  margin-top: 40px;
}
.discount-wrap .wrapper .company-list-wrap .list-section {
  border-bottom: 1px solid rgba(212, 212, 212, 0.5);
  padding-bottom: 25px;
  padding-top: 15px;
}
.discount-wrap .wrapper .company-list-wrap .list-section .checkbox-section {
  display: inline-block;
  vertical-align: middle;
}
.discount-wrap .wrapper .company-list-wrap .list-section .sub-header-inline {
  display: inline-block;
  margin-right: 6px;
  min-width: 70px;
}
.discount-wrap .wrapper .company-list-wrap .list-section .table-header {
  width: 11%;
  font-size: 12px;
  display: inline-block;
  word-break: break-word;
}
.discount-wrap .wrapper .company-list-wrap .list-section .table-header.select-row {
  width: 4%;
}
.discount-wrap .wrapper .company-list-wrap .list-section .table-header.rules-tab {
  width: 18%;
}
.discount-wrap .wrapper .company-list-wrap .list-section .company-name-section {
  display: inline-block;
  vertical-align: middle;
  margin-left: 10px;
  width: 370px;
}
.discount-wrap .wrapper .company-list-wrap .list-section .company-name-section.table-header {
  margin-left: 85px;
  width: 290px;
}
.discount-wrap .wrapper .company-list-wrap .list-section .company-name-section .comany-logo,
.discount-wrap .wrapper .company-list-wrap .list-section .company-name-section .compnay-details {
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
}
.discount-wrap .wrapper .company-list-wrap .list-section .company-name-section .comany-logo {
  width: 50px;
  height: 35px;
  background-size: contain;
  background-repeat: no-repeat;
}
.discount-wrap .wrapper .company-list-wrap .list-section .company-name-section .compnay-details {
  margin-left: 25px;
  color: #1a2734;
  font-size: 15px;
  font-weight: 900;
  width: 220px;
}
.discount-wrap .wrapper .company-list-wrap .list-section .company-name-section .compnay-details .date {
  color: #86868a;
  font-size: 12px;
}
.discount-wrap .wrapper .company-list-wrap .list-section .company-name-section .compnay-details .name {
  word-break: break-word;
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}
.discount-wrap .wrapper .company-list-wrap .list-section .admin-details {
  display: inline-block;
  vertical-align: middle;
  width: 340px;
  font-size: 15px;
  color: #1a2734;
}
.discount-wrap .wrapper .company-list-wrap .list-section .admin-details.table-header {
  margin-left: 8px;
  width: 337px;
}
.discount-wrap .wrapper .company-list-wrap .list-section .admin-details .admin-email {
  font-size: 15px;
  color: #1a2734;
  cursor: pointer;
}
.discount-wrap .wrapper .company-list-wrap .list-section .admin-details .admin-pan {
  font-size: 12px;
  color: #86868a;
}
.discount-wrap .wrapper .company-list-wrap .list-section .account-type {
  display: inline-block;
  vertical-align: middle;
  width: 140px;
  font-size: 15px;
  color: #1a2734;
}
.discount-wrap .wrapper .company-list-wrap .list-section .status {
  display: inline-block;
  vertical-align: middle;
  width: 100px;
}
.discount-wrap .wrapper .company-list-wrap .list-section.header {
  font-weight: 600;
  padding-top: 0;
  color: #1a2734;
  font-size: 14px;
}
.discount-wrap .wrapper .company-list-wrap .list-section.content:hover {
  border: 1px solid rgba(12, 12, 12, 0.38);
  box-shadow: 0px 10px 20px 0px rgba(158, 170, 181, 0.45);
}
.discount-wrap .wrapper .company-list-wrap .list-section.content:hover .company-name-section .compnay-details .name {
  display: block;
}
.divTable {
  margin-top: 20px;
  margin-bottom: 20px;
  display: table;
  width: 100%;
}
.divTableRow {
  display: table-row;
}
.divTableHeading {
  color: white;
  background-color: #EEE;
  display: table-header-group;
}
.divTableCell,
.divTableHead {
  /*border: 1px solid #999999;*/
  display: table-cell;
  padding: 10px 10px;
  font-size: 12px;
}
.divTableCell.bordered,
.divTableHead.bordered {
  border-bottom: 1px solid #999999;
}
.divTableHeading {
  background-color: #528aea;
  display: table-header-group;
  font-weight: bold;
}
.divTableFoot {
  background-color: #EEE;
  display: table-footer-group;
  font-weight: bold;
}
.divTableBody {
  display: table-row-group;
  margin-top: 10px;
}
.select {
  display: inline-block;
  width: 150px;
}
.slabRange .input-text-wrap {
  margin-bottom: 0;
}
.slabRange .input-element input {
  width: auto;
  font-size: 12px;
}
.penaltyPercent .input-text-wrap .text-input {
  width: 50px;
  font-size: 12px;
  padding-bottom: 5px;
}
.penaltyBox {
  text-align: center;
}
.download-btn {
  text-decoration: none;
}
.add-user-wrap .overlay {
  position: fixed;
  z-index: 10;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(27, 33, 67, 0.9);
}
.add-user-wrap .modal-popup {
  max-height: 600px;
  overflow: scroll;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 5px;
  width: 1000px;
  min-height: 500px;
  background-color: white;
  z-index: 11;
}
.add-user-wrap .modal-popup .pop-heading {
  background-color: #f4f4f4;
  padding: 18px;
  font-family: 'avenir', sans-serif;
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #528aea;
  text-align: center;
  margin-bottom: 30px;
}
.add-user-wrap .modal-popup .content {
  padding: 20px 0px 20px 30px;
}
.add-user-wrap .modal-popup .content .select-container {
  display: flex;
  margin: 20px 0px;
}
.add-user-wrap .modal-popup .content .select-container .select-holder {
  width: 277px;
  position: relative;
}
.add-user-wrap .modal-popup .content .select-container .select-holder .sub-head {
  font-size: 10px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #868e96;
  margin-bottom: 7px;
}
.add-user-wrap .modal-popup .content .select-container .action-holder {
  display: flex;
  flex-wrap: wrap;
  width: 330px;
  margin: 15px;
  position: relative;
}
.add-user-wrap .modal-popup .content .select-container .action-holder .actions {
  margin: 5px;
}
.add-user-wrap .modal-popup .content .select-container .action-holder .actions .add-button {
  margin: 0;
  height: 35px;
  padding: 0 15px;
  font-size: 20px;
  margin-left: 10px;
}
.add-user-wrap .modal-popup .content .select-container .action-holder .actions .select {
  width: 235px;
}
.add-user-wrap .modal-popup .content .select-container .action-holder .add-button {
  margin: 5px 5px;
  padding: 10px 20px;
}
.add-user-wrap .modal-popup .content .select-container .action-holder .sub-head {
  position: absolute;
  font-size: 10px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #868e96;
  top: -10px;
  left: 7px;
}
.add-user-wrap .modal-popup .content .usn-container {
  margin: 17px 0px;
  display: flex;
}
.add-user-wrap .modal-popup .content .usn-container .add-button {
  margin: 20px;
  border-radius: 5px;
}
.add-user-wrap .modal-popup .content .usn-container .btn-disabled {
  background-color: #cac2c2;
  border: 1px solid #cac2c2;
  pointer-events: none;
  color: white;
}
.add-user-wrap .modal-popup .content .submit-section {
  display: flex;
  justify-content: flex-end;
}
.add-user-wrap .modal-popup .content .submit-section .add-button {
  border-radius: 5px;
  width: 145px;
}
.add-user-wrap .modal-popup .content .submit-section .discard {
  background: white;
  color: #528aea;
  border: 1px solid #528aea;
}
.add-user-wrap .modal-popup .content .submit-section.business-button button.add-button {
  margin: 0;
  color: black;
  padding: 10px 20px;
  background: #fd8989;
  border-radius: 20px;
  border-color: #ff000052;
}
.add-user-wrap .modal-popup .content .submit-section.business-button button.add-button.discard {
  background: #fff;
}
.serviceTypes .section-item {
  display: inline-block;
  margin-right: 30px;
  margin-top: 20px;
}
.serviceTypes .section-item .title {
  padding-left: 10px;
}
.curP {
  cursor: pointer;
}
.modalContentList .discount-wrap .wrapper .company-list-wrap {
  margin-top: 0;
}
.wt-100px-imp {
  width: 100px !important;
}
.vis-hidden {
  visibility: hidden;
}

.input-text-wrap {
  display: inline-block;
  vertical-align: middle;
  width: 33%;
  margin-bottom: 4px;
}
.input-text-wrap .label {
  font-size: 10px;
  color: #868e96;
  margin-bottom: 10px;
}
.input-text-wrap .text-input {
  border: none;
  border-bottom: 1px solid #d8dbf0;
  padding-bottom: 13px;
  font-size: 16px;
  color: #212224;
  outline: none;
  width: 277px;
}
.input-text-wrap .pwd-input {
  display: inline-block;
}
.input-text-wrap .gen-btn {
  background-repeat: no-repeat;
  height: 20px;
  background-size: contain;
  cursor: pointer;
  width: 20px;
  display: inline-block;
  vertical-align: top;
}
.error-font {
  font-size: 12px;
}
.input-text-wrap input:read-only {
  border: none;
}
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}

.discount-wrap .wrapper {
  width: 1280px;
  margin: 0 auto;
}
.discount-wrap .wrapper .home-title {
  font-size: 12px;
  color: #86868a;
}
.discount-wrap .wrapper .sub-heading-section {
  margin-top: 22px;
  font-family: 'avenir-medium', sans-serif;
  font-weight: 900;
  overflow: auto;
}
.discount-wrap .wrapper .sub-heading-section .sub-heading {
  font-size: 24px;
  color: #282626;
  float: left;
}
.discount-wrap .wrapper .sub-heading-section .add-company-section {
  float: right;
  margin-right: 30px;
}
.discount-wrap .wrapper .cta-action-btns {
  display: inline-block;
}
.discount-wrap .wrapper .cta-action-btns .button-classic {
  height: 30px;
  font-size: 14px;
  padding: 0 20px;
  border-radius: 5px;
  margin-top: 10px;
  margin-right: 10px;
}
.discount-wrap .wrapper .cta-action-btns.cta-btn-right {
  float: right;
}
.discount-wrap .wrapper .search-filter-section {
  clear: both;
  margin-top: 25px;
}
.discount-wrap .wrapper .company-list-wrap {
  margin-top: 45px;
}
.discount-wrap .wrapper .company-list-wrap .no-records-section {
  margin-top: 40px;
}
.discount-wrap .wrapper .company-list-wrap .list-section {
  border-bottom: 1px solid rgba(212, 212, 212, 0.5);
  padding-bottom: 25px;
  padding-top: 15px;
}
.discount-wrap .wrapper .company-list-wrap .list-section .checkbox-section {
  display: inline-block;
  vertical-align: middle;
}
.discount-wrap .wrapper .company-list-wrap .list-section .sub-header-inline {
  display: inline-block;
  margin-right: 6px;
  min-width: 70px;
}
.discount-wrap .wrapper .company-list-wrap .list-section .table-header {
  width: 11%;
  font-size: 12px;
  display: inline-block;
  word-break: break-word;
}
.discount-wrap .wrapper .company-list-wrap .list-section .table-header.select-row {
  width: 4%;
}
.discount-wrap .wrapper .company-list-wrap .list-section .table-header.rules-tab {
  width: 18%;
}
.discount-wrap .wrapper .company-list-wrap .list-section .company-name-section {
  display: inline-block;
  vertical-align: middle;
  margin-left: 10px;
  width: 370px;
}
.discount-wrap .wrapper .company-list-wrap .list-section .company-name-section.table-header {
  margin-left: 85px;
  width: 290px;
}
.discount-wrap .wrapper .company-list-wrap .list-section .company-name-section .comany-logo,
.discount-wrap .wrapper .company-list-wrap .list-section .company-name-section .compnay-details {
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
}
.discount-wrap .wrapper .company-list-wrap .list-section .company-name-section .comany-logo {
  width: 50px;
  height: 35px;
  background-size: contain;
  background-repeat: no-repeat;
}
.discount-wrap .wrapper .company-list-wrap .list-section .company-name-section .compnay-details {
  margin-left: 25px;
  color: #1a2734;
  font-size: 15px;
  font-weight: 900;
  width: 220px;
}
.discount-wrap .wrapper .company-list-wrap .list-section .company-name-section .compnay-details .date {
  color: #86868a;
  font-size: 12px;
}
.discount-wrap .wrapper .company-list-wrap .list-section .company-name-section .compnay-details .name {
  word-break: break-word;
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}
.discount-wrap .wrapper .company-list-wrap .list-section .admin-details {
  display: inline-block;
  vertical-align: middle;
  width: 340px;
  font-size: 15px;
  color: #1a2734;
}
.discount-wrap .wrapper .company-list-wrap .list-section .admin-details.table-header {
  margin-left: 8px;
  width: 337px;
}
.discount-wrap .wrapper .company-list-wrap .list-section .admin-details .admin-email {
  font-size: 15px;
  color: #1a2734;
  cursor: pointer;
}
.discount-wrap .wrapper .company-list-wrap .list-section .admin-details .admin-pan {
  font-size: 12px;
  color: #86868a;
}
.discount-wrap .wrapper .company-list-wrap .list-section .account-type {
  display: inline-block;
  vertical-align: middle;
  width: 140px;
  font-size: 15px;
  color: #1a2734;
}
.discount-wrap .wrapper .company-list-wrap .list-section .status {
  display: inline-block;
  vertical-align: middle;
  width: 100px;
}
.discount-wrap .wrapper .company-list-wrap .list-section.header {
  font-weight: 600;
  padding-top: 0;
  color: #1a2734;
  font-size: 14px;
}
.discount-wrap .wrapper .company-list-wrap .list-section.content:hover {
  border: 1px solid rgba(12, 12, 12, 0.38);
  box-shadow: 0px 10px 20px 0px rgba(158, 170, 181, 0.45);
}
.discount-wrap .wrapper .company-list-wrap .list-section.content:hover .company-name-section .compnay-details .name {
  display: block;
}
.discount-wrap .wrapper .action-icon {
  background-repeat: no-repeat;
  height: 18px;
  width: 18px;
  background-size: contain;
  cursor: pointer;
  display: inline-block;
  padding: 5px;
  margin: 5px;
}
.divTable {
  margin-top: 20px;
  margin-bottom: 20px;
  display: table;
  width: 100%;
}
.divTableRow {
  display: table-row;
}
.divTableHeading {
  color: white;
  background-color: #EEE;
  display: table-header-group;
}
.divTableCell,
.divTableHead {
  /*border: 1px solid #999999;*/
  display: table-cell;
  padding: 10px 10px;
  font-size: 12px;
}
.divTableCell.bordered,
.divTableHead.bordered {
  border-bottom: 1px solid #999999;
}
.divTableHeading {
  background-color: #528aea;
  display: table-header-group;
  font-weight: bold;
}
.divTableFoot {
  background-color: #EEE;
  display: table-footer-group;
  font-weight: bold;
}
.divTableBody {
  display: table-row-group;
  margin-top: 10px;
}
.select {
  display: inline-block;
  width: 150px;
}
.slabRange .input-text-wrap {
  margin-bottom: 0;
}
.slabRange .input-element input {
  width: auto;
  font-size: 12px;
}
.penaltyPercent .input-text-wrap .text-input {
  width: 50px;
  font-size: 12px;
  padding-bottom: 5px;
}
.penaltyBox {
  text-align: center;
}
.download-btn {
  text-decoration: none;
}
.add-user-wrap .overlay {
  position: fixed;
  z-index: 10;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(27, 33, 67, 0.9);
}
.add-user-wrap .modal-popup {
  max-height: 600px;
  overflow: scroll;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 5px;
  width: 1000px;
  min-height: 500px;
  background-color: white;
  z-index: 11;
}
.add-user-wrap .modal-popup .pop-heading {
  background-color: #f4f4f4;
  padding: 18px;
  font-family: 'avenir', sans-serif;
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #528aea;
  text-align: center;
  margin-bottom: 30px;
}
.add-user-wrap .modal-popup .content {
  padding: 20px 0px 20px 30px;
}
.add-user-wrap .modal-popup .content .select-container {
  display: flex;
  margin: 20px 0px;
}
.add-user-wrap .modal-popup .content .select-container .select-holder {
  width: 277px;
  position: relative;
}
.add-user-wrap .modal-popup .content .select-container .select-holder .sub-head {
  font-size: 10px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #868e96;
  margin-bottom: 7px;
}
.add-user-wrap .modal-popup .content .select-container .action-holder {
  display: flex;
  flex-wrap: wrap;
  width: 330px;
  margin: 15px;
  position: relative;
}
.add-user-wrap .modal-popup .content .select-container .action-holder .actions {
  margin: 5px;
}
.add-user-wrap .modal-popup .content .select-container .action-holder .actions .add-button {
  margin: 0;
  height: 35px;
  padding: 0 15px;
  font-size: 20px;
  margin-left: 10px;
}
.add-user-wrap .modal-popup .content .select-container .action-holder .actions .select {
  width: 235px;
}
.add-user-wrap .modal-popup .content .select-container .action-holder .add-button {
  margin: 5px 5px;
  padding: 10px 20px;
}
.add-user-wrap .modal-popup .content .select-container .action-holder .sub-head {
  position: absolute;
  font-size: 10px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #868e96;
  top: -10px;
  left: 7px;
}
.add-user-wrap .modal-popup .content .usn-container {
  margin: 17px 0px;
  display: flex;
}
.add-user-wrap .modal-popup .content .usn-container .add-button {
  margin: 20px;
  border-radius: 5px;
}
.add-user-wrap .modal-popup .content .usn-container .btn-disabled {
  background-color: #cac2c2;
  border: 1px solid #cac2c2;
  pointer-events: none;
  color: white;
}
.add-user-wrap .modal-popup .content .submit-section {
  display: flex;
  justify-content: flex-end;
}
.add-user-wrap .modal-popup .content .submit-section .add-button {
  border-radius: 5px;
  width: 145px;
}
.add-user-wrap .modal-popup .content .submit-section .discard {
  background: white;
  color: #528aea;
  border: 1px solid #528aea;
}
.add-user-wrap .modal-popup .content .submit-section.business-button button.add-button {
  margin: 0;
  color: black;
  padding: 10px 20px;
  background: #fd8989;
  border-radius: 20px;
  border-color: #ff000052;
}
.add-user-wrap .modal-popup .content .submit-section.business-button button.add-button.discard {
  background: #fff;
}
.serviceTypes .section-item {
  display: inline-block;
  margin-right: 30px;
  margin-top: 20px;
}
.serviceTypes .section-item .title {
  padding-left: 10px;
}
.laneInput .input-text-wrap .text-input {
  width: 50px;
  font-size: 12px;
  padding-bottom: 5px;
}
.laneInput textarea.text-input-content {
  width: 200px;
  height: 60px;
  font-size: 12px;
  padding: 5px;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
}

.discount-wrap .wrapper {
  width: 1280px;
  margin: 0 auto;
}
.discount-wrap .wrapper .home-title {
  font-size: 12px;
  color: #86868a;
}
.discount-wrap .wrapper .sub-heading-section {
  margin-top: 22px;
  font-family: 'avenir-medium', sans-serif;
  font-weight: 900;
  overflow: auto;
}
.discount-wrap .wrapper .sub-heading-section .sub-heading {
  font-size: 24px;
  color: #282626;
  float: left;
}
.discount-wrap .wrapper .sub-heading-section .add-company-section {
  float: right;
  margin-right: 30px;
}
.discount-wrap .wrapper .cta-action-btns {
  display: inline-block;
}
.discount-wrap .wrapper .cta-action-btns .button-classic {
  height: 30px;
  font-size: 14px;
  padding: 0 20px;
  border-radius: 5px;
  margin-top: 10px;
  margin-right: 10px;
}
.discount-wrap .wrapper .cta-action-btns.cta-btn-right {
  float: right;
}
.discount-wrap .wrapper .search-filter-section {
  clear: both;
  margin-top: 25px;
}
.discount-wrap .wrapper .company-list-wrap {
  margin-top: 45px;
}
.discount-wrap .wrapper .company-list-wrap .no-records-section {
  margin-top: 40px;
}
.discount-wrap .wrapper .company-list-wrap .list-section {
  border-bottom: 1px solid rgba(212, 212, 212, 0.5);
  padding-bottom: 25px;
  padding-top: 15px;
}
.discount-wrap .wrapper .company-list-wrap .list-section .checkbox-section {
  display: inline-block;
  vertical-align: middle;
}
.discount-wrap .wrapper .company-list-wrap .list-section .sub-header-inline {
  display: inline-block;
  margin-right: 6px;
  min-width: 70px;
}
.discount-wrap .wrapper .company-list-wrap .list-section .table-header {
  width: 11%;
  font-size: 12px;
  display: inline-block;
  word-break: break-word;
}
.discount-wrap .wrapper .company-list-wrap .list-section .table-header.select-row {
  width: 4%;
}
.discount-wrap .wrapper .company-list-wrap .list-section .table-header.rules-tab {
  width: 18%;
}
.discount-wrap .wrapper .company-list-wrap .list-section .company-name-section {
  display: inline-block;
  vertical-align: middle;
  margin-left: 10px;
  width: 370px;
}
.discount-wrap .wrapper .company-list-wrap .list-section .company-name-section.table-header {
  margin-left: 85px;
  width: 290px;
}
.discount-wrap .wrapper .company-list-wrap .list-section .company-name-section .comany-logo,
.discount-wrap .wrapper .company-list-wrap .list-section .company-name-section .compnay-details {
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
}
.discount-wrap .wrapper .company-list-wrap .list-section .company-name-section .comany-logo {
  width: 50px;
  height: 35px;
  background-size: contain;
  background-repeat: no-repeat;
}
.discount-wrap .wrapper .company-list-wrap .list-section .company-name-section .compnay-details {
  margin-left: 25px;
  color: #1a2734;
  font-size: 15px;
  font-weight: 900;
  width: 220px;
}
.discount-wrap .wrapper .company-list-wrap .list-section .company-name-section .compnay-details .date {
  color: #86868a;
  font-size: 12px;
}
.discount-wrap .wrapper .company-list-wrap .list-section .company-name-section .compnay-details .name {
  word-break: break-word;
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}
.discount-wrap .wrapper .company-list-wrap .list-section .admin-details {
  display: inline-block;
  vertical-align: middle;
  width: 340px;
  font-size: 15px;
  color: #1a2734;
}
.discount-wrap .wrapper .company-list-wrap .list-section .admin-details.table-header {
  margin-left: 8px;
  width: 337px;
}
.discount-wrap .wrapper .company-list-wrap .list-section .admin-details .admin-email {
  font-size: 15px;
  color: #1a2734;
  cursor: pointer;
}
.discount-wrap .wrapper .company-list-wrap .list-section .admin-details .admin-pan {
  font-size: 12px;
  color: #86868a;
}
.discount-wrap .wrapper .company-list-wrap .list-section .account-type {
  display: inline-block;
  vertical-align: middle;
  width: 140px;
  font-size: 15px;
  color: #1a2734;
}
.discount-wrap .wrapper .company-list-wrap .list-section .status {
  display: inline-block;
  vertical-align: middle;
  width: 100px;
}
.discount-wrap .wrapper .company-list-wrap .list-section.header {
  font-weight: 600;
  padding-top: 0;
  color: #1a2734;
  font-size: 14px;
}
.discount-wrap .wrapper .company-list-wrap .list-section.content:hover {
  border: 1px solid rgba(12, 12, 12, 0.38);
  box-shadow: 0px 10px 20px 0px rgba(158, 170, 181, 0.45);
}
.discount-wrap .wrapper .company-list-wrap .list-section.content:hover .company-name-section .compnay-details .name {
  display: block;
}
.discount-wrap .wrapper .action-icon {
  background-repeat: no-repeat;
  height: 18px;
  width: 18px;
  background-size: contain;
  cursor: pointer;
  display: inline-block;
  padding: 5px;
  margin: 5px;
}
.divTable {
  margin-top: 20px;
  margin-bottom: 20px;
  display: table;
  width: 100%;
}
.divTableRow {
  display: table-row;
}
.divTableHeading {
  color: white;
  background-color: #EEE;
  display: table-header-group;
}
.divTableCell,
.divTableHead {
  /*border: 1px solid #999999;*/
  display: table-cell;
  padding: 10px 10px;
  font-size: 12px;
}
.divTableCell.bordered,
.divTableHead.bordered {
  border-bottom: 1px solid #999999;
}
.divTableHeading {
  background-color: #528aea;
  display: table-header-group;
  font-weight: bold;
}
.divTableFoot {
  background-color: #EEE;
  display: table-footer-group;
  font-weight: bold;
}
.divTableBody {
  display: table-row-group;
  margin-top: 10px;
}
.select {
  display: inline-block;
  width: 150px;
}
.slabRange .input-text-wrap {
  margin-bottom: 0;
}
.slabRange .input-element input {
  width: auto;
  font-size: 12px;
}
.penaltyPercent .input-text-wrap .text-input {
  width: 50px;
  font-size: 12px;
  padding-bottom: 5px;
}
.penaltyBox {
  text-align: center;
}
.download-btn {
  text-decoration: none;
}
.add-user-wrap .overlay {
  position: fixed;
  z-index: 10;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(27, 33, 67, 0.9);
}
.add-user-wrap .modal-popup {
  max-height: 600px;
  overflow: scroll;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 5px;
  width: 1000px;
  min-height: 500px;
  background-color: white;
  z-index: 11;
}
.add-user-wrap .modal-popup .pop-heading {
  background-color: #f4f4f4;
  padding: 18px;
  font-family: 'avenir', sans-serif;
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #528aea;
  text-align: center;
  margin-bottom: 30px;
}
.add-user-wrap .modal-popup .content {
  padding: 20px 0px 20px 30px;
}
.add-user-wrap .modal-popup .content .select-container {
  display: flex;
  margin: 20px 0px;
}
.add-user-wrap .modal-popup .content .select-container .select-holder {
  width: 277px;
  position: relative;
}
.add-user-wrap .modal-popup .content .select-container .select-holder .sub-head {
  font-size: 10px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #868e96;
  margin-bottom: 7px;
}
.add-user-wrap .modal-popup .content .select-container .action-holder {
  display: flex;
  flex-wrap: wrap;
  width: 330px;
  margin: 15px;
  position: relative;
}
.add-user-wrap .modal-popup .content .select-container .action-holder .actions {
  margin: 5px;
}
.add-user-wrap .modal-popup .content .select-container .action-holder .actions .add-button {
  margin: 0;
  height: 35px;
  padding: 0 15px;
  font-size: 20px;
  margin-left: 10px;
}
.add-user-wrap .modal-popup .content .select-container .action-holder .actions .select {
  width: 235px;
}
.add-user-wrap .modal-popup .content .select-container .action-holder .add-button {
  margin: 5px 5px;
  padding: 10px 20px;
}
.add-user-wrap .modal-popup .content .select-container .action-holder .sub-head {
  position: absolute;
  font-size: 10px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #868e96;
  top: -10px;
  left: 7px;
}
.add-user-wrap .modal-popup .content .usn-container {
  margin: 17px 0px;
  display: flex;
}
.add-user-wrap .modal-popup .content .usn-container .add-button {
  margin: 20px;
  border-radius: 5px;
}
.add-user-wrap .modal-popup .content .usn-container .btn-disabled {
  background-color: #cac2c2;
  border: 1px solid #cac2c2;
  pointer-events: none;
  color: white;
}
.add-user-wrap .modal-popup .content .submit-section {
  display: flex;
  justify-content: flex-end;
}
.add-user-wrap .modal-popup .content .submit-section .add-button {
  border-radius: 5px;
  width: 145px;
}
.add-user-wrap .modal-popup .content .submit-section .discard {
  background: white;
  color: #528aea;
  border: 1px solid #528aea;
}
.add-user-wrap .modal-popup .content .submit-section.business-button button.add-button {
  margin: 0;
  color: black;
  padding: 10px 20px;
  background: #fd8989;
  border-radius: 20px;
  border-color: #ff000052;
}
.add-user-wrap .modal-popup .content .submit-section.business-button button.add-button.discard {
  background: #fff;
}
.serviceTypes .section-item {
  display: inline-block;
  margin-right: 30px;
  margin-top: 20px;
}
.serviceTypes .section-item .title {
  padding-left: 10px;
}
.dphDiscInput .input-text-wrap .text-input {
  width: 150px;
  font-size: 12px;
  padding-bottom: 5px;
}
.laneInput textarea.text-input-content {
  width: 200px;
  height: 60px;
  font-size: 12px;
  padding: 5px;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
}

.grey-box {
  display: inline-block;
  padding: 5px;
  background-color: #e0e0e0;
  border-radius: 20px;
  margin-right: 7px;
  font-size: 11px;
  cursor: not-allowed;
}
.grey-box.approve {
  background-color: rgba(4, 167, 4, 0.4);
}
.grey-box.reject {
  background-color: rgba(216, 6, 6, 0.35);
}
.grey-box.resend {
  background-color: rgba(216, 6, 6, 0.35);
}
.grey-box.active {
  background-color: blue;
  color: white;
}
.tab-box {
  display: inline-block;
  padding: 10px;
  background-color: #e0e0e0;
  border-radius: 10px;
  margin-right: 7px;
  font-size: 14px;
  cursor: pointer;
}
.tab-box.active {
  background-color: blue;
  color: white;
}
.vendor-email {
  width: 200px !important;
}
.approvals-listing {
  display: inline-block;
  vertical-align: middle;
  margin-left: 15px;
  font-size: 13px;
  width: 300px;
  text-align: center;
}
.comany-logo {
  width: 25px;
  height: 25px;
  float: left;
  margin-right: 10px;
}
.dInline {
  display: inline;
  vertical-align: middle;
}
.form-textarea .text-input-content {
  margin-top: 15px;
  width: auto;
}
.vendors-list-wrap.home-wrap .wrapper .company-list-wrap .list-section .status {
  width: 80px;
}
.vendors-list-wrap.home-wrap .wrapper .company-list-wrap .list-section .account-type {
  margin-right: 10px;
  font-size: 13px;
}
.cancel-btn .button-classic {
  text-align: center;
  background: #528aea;
  color: white;
}
.mt-20 {
  margin-top: 20px;
}
.mb-10 {
  margin-bottom: 10px;
}
.enable-menu {
  cursor: pointer;
  border: 1px solid #528aea;
  background-color: #fff;
}
.list-count-box {
  padding: 8px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  font-size: 12px;
}
.fs-14 {
  font-size: 14px;
}
div.list-count-box {
  transition: transform 200ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.overlay {
  position: absolute;
  z-index: 10;
  width: 100%;
  height: auto;
  background-color: rgba(27, 33, 67, 0.9);
  border: 1px solid rgba(212, 212, 212, 0.5);
  border-radius: 5px;
  padding: 5px;
  margin-left: 5px;
}
.white {
  color: white;
}
.button-container button {
  border: none;
  background-color: transparent;
  cursor: pointer;
  padding: 10px 20px;
  font-size: 16px;
  margin-right: 10px;
  font-weight: normal;
  /* Reset font weight */
}
.button-container button.active {
  border-bottom: 3px solid #627FE9;
  font-weight: bold !important;
  /* Make font bold for active button */
  font-size: 21px;
}
.content {
  margin-top: 20px;
}
.newpop .msg-section {
  background-color: #E3E9FF;
}
.newpop .msg-section .approveBtn {
  background-color: #627FE9;
  color: #ffff;
}
.newpop .msg-section .cancel-icon {
  height: 20px;
  width: 20px;
  position: absolute;
  top: -20px;
  left: 400px;
  cursor: pointer;
  display: inline-block;
  background-size: contain;
  background-repeat: no-repeat;
}

.new-vendor.add-company-wrap .overlay {
  position: fixed;
  z-index: 10;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(27, 33, 67, 0.9);
}
.new-vendor.add-company-wrap .modal-popup {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 5px;
  width: 1065px;
  min-height: 100px;
  height: 500px;
  overflow: scroll;
  background-color: white;
  z-index: 11;
  padding: 40px 47px 15px 47px;
}
.new-vendor.add-company-wrap .modal-popup .heading {
  color: #fff;
  padding: 20px;
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 20px;
  background-color: lightgray;
}
.new-vendor.add-company-wrap .modal-popup .form-section {
  margin-bottom: 42px;
}
.new-vendor.add-company-wrap .modal-popup .select-relevant-category-section {
  margin-bottom: 50px;
  display: inline-grid;
  width: 30%;
}
.new-vendor.add-company-wrap .modal-popup .select-relevant-category-section.sub-category .section-item {
  white-space: nowrap;
}
.new-vendor.add-company-wrap .modal-popup .select-relevant-category-section.sub-category .section-item .title {
  white-space: normal;
}
.new-vendor.add-company-wrap .modal-popup .select-relevant-category-section.sub-services .section-item {
  white-space: nowrap;
}
.new-vendor.add-company-wrap .modal-popup .select-relevant-category-section.sub-services .section-item .title {
  white-space: normal;
}
.new-vendor.add-company-wrap .modal-popup .select-relevant-category-section .header {
  display: inline-block;
  vertical-align: top;
  color: #528aea;
  font-weight: 600;
  border-bottom: 1px solid;
  padding-bottom: 15px;
  margin-bottom: 20px;
}
.new-vendor.add-company-wrap .modal-popup .select-relevant-category-section .section-item {
  display: inline-block;
  vertical-align: super;
  padding: 5px;
  color: #535356;
  font-size: 16px;
  text-transform: capitalize;
}
.new-vendor.add-company-wrap .modal-popup .select-relevant-category-section .section-item .title {
  display: inline-block;
  vertical-align: top;
  margin: 3px 14px;
}
.new-vendor.add-company-wrap .modal-popup .submit-section {
  float: right;
}
.new-vendor.add-company-wrap .modal-popup .submit-section .button-section {
  margin-left: 20px;
  float: right;
}
.custome-checkbox-wrap {
  width: 20px;
  height: 20px;
  border-radius: 5px;
  border: 1px solid #d9d9d9;
  display: inline-block;
  position: relative;
  cursor: pointer;
}
.custome-checkbox-wrap.selected {
  border: 1px solid #528aea;
}
.custome-checkbox-wrap .inner-circle {
  width: 10px;
  height: 10px;
  border-radius: 15%;
  background: #528aea;
  position: absolute;
  top: 4px;
  left: 4px;
}
.margin-right {
  margin-right: 20px;
}
.no-records {
  padding: 10px;
  font-size: 16px;
  text-align: center;
}
.error {
  color: red;
}
.input-text-align-top .input-text-wrap {
  vertical-align: top;
}
.vAlignCenter {
  vertical-align: middle;
}
.inBlock {
  display: inline-block;
}
.wt-150 {
  width: 150px;
}
.mb-10 {
  margin-bottom: 10px;
}
.mb-20 {
  margin-bottom: 20px;
}
.ml-10 {
  margin-left: 10px;
}
.mr-12 {
  margin-right: 12px;
}
.wt-60 {
  width: 60px;
}
.input-spacing div {
  margin-right: 20px;
}
.input-spacing div .input-text-wrap {
  vertical-align: middle;
  margin-bottom: 5px;
}
.vendor-select {
  width: 300px;
}

.newVendor {
  margin: 30px;
  padding: 10px;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
  letter-spacing: 0.01em;
  background: #E3E9FF;
}
.newVendor .overlay {
  position: fixed;
  z-index: 10;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(27, 33, 67, 0.9);
}
.newVendor .modal-popup {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 5px;
  width: 1065px;
  min-height: 500px;
  height: 700px;
  overflow-y: scroll;
  background-color: #E3E9FF;
  z-index: 11;
  padding: 40px 47px 15px 47px;
}
.newVendor .modal-popup .mainHeading {
  font-family: 'Poppins', sans-serif;
  font-size: 30px;
  font-weight: 800;
  line-height: 26px;
  letter-spacing: 0.01em;
  text-align: left;
  margin-bottom: 20px;
}
.newVendor .modal-popup .inputBlock {
  min-width: 250px;
  height: 40px;
  padding-left: 5px;
  border: 1px solid #627FE9;
}
.newVendor .modal-popup .inputBlock:focus {
  border: 2px solid #627FE9;
  outline-offset: 0px;
  outline: none !important;
}
.newVendor .modal-popup .labels {
  margin-bottom: 10px;
}
.newVendor .modal-popup .vendorDetails {
  padding: 20px;
  width: 908px;
  height: 240px;
  margin: 0 20px 20px 20px;
  background-color: #FFFFFF;
  border-radius: 7px;
}
.newVendor .modal-popup .BlockDetails {
  padding: 20px;
  width: 908px;
  height: 180px;
  margin: 0 20px 20px 20px;
  background-color: #FFFFFF;
  border-radius: 7px;
}
.newVendor .modal-popup .emailDetails {
  padding: 20px;
  width: 945px;
  min-height: 210px;
  background-color: #E3E9FF;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.newVendor .modal-popup .emailDetails .Bclr {
  background-color: #FFFFFF;
  width: 310px;
  min-height: 170px;
  margin: 0px 0px;
  border-radius: 7px;
}
.newVendor .modal-popup .emailDetails .Bclr .delete-icon {
  margin: 0px;
  margin-top: 10px;
}
.newVendor .modal-popup .emailDetails .Bclr1 {
  background-color: #FFFFFF;
  width: 582px;
  height: 170px;
  margin: 0px 0px;
  border-radius: 7px;
}
.newVendor .modal-popup .emailDetails .Bclr1 .select {
  width: 270px;
}
.newVendor .modal-popup .emailDetails .Bclr1 .source {
  display: flex;
  height: 40px;
}
.newVendor .modal-popup .cecBlock {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}
.newVendor .modal-popup .select {
  width: 420px;
  border: 1px solid #627FE9;
  border-radius: 4px;
}
.newVendor .modal-popup .select:focus {
  border: 2px solid #627FE9;
  border-radius: 4px;
  outline-offset: 0px;
  outline: none !important;
}
.newVendor .modal-popup span {
  color: red;
}
.newVendor .submit-section {
  display: flex;
  justify-content: flex-end;
}
.newVendor .submit-section .button-section1 {
  border: 1px solid #627FE9;
  width: 120px;
  border-radius: 6px;
  background-color: #FFFFFF;
  color: #627FE9;
  padding: 10px;
}
.newVendor .submit-section .button-section1:hover {
  cursor: pointer;
}
.newVendor .submit-section .sections {
  background-color: #627FE9;
  color: #FFFFFF;
}

.volumetric-wrap .wrapper {
  width: 1280px;
  margin: 0 auto;
}
.volumetric-wrap .wrapper .home-title {
  font-size: 12px;
  color: #86868a;
}
.volumetric-wrap .wrapper .sub-heading-section {
  margin-top: 22px;
  font-family: 'avenir-medium', sans-serif;
  font-weight: 900;
  overflow: auto;
}
.volumetric-wrap .wrapper .sub-heading-section .sub-heading {
  font-size: 24px;
  color: #282626;
  float: left;
}
.volumetric-wrap .wrapper .sub-heading-section .add-company-section {
  float: right;
  margin-right: 30px;
}
.volumetric-wrap .wrapper .cta-action-btns {
  display: inline-block;
}
.volumetric-wrap .wrapper .cta-action-btns .button-classic {
  height: 30px;
  font-size: 14px;
  padding: 0 20px;
  border-radius: 5px;
  margin-top: 10px;
  margin-right: 10px;
}
.volumetric-wrap .wrapper .cta-action-btns.cta-btn-right {
  float: right;
}
.volumetric-wrap .wrapper .search-filter-section {
  clear: both;
  margin-top: 25px;
}
.volumetric-wrap .wrapper .company-list-wrap {
  margin-top: 45px;
}
.volumetric-wrap .wrapper .company-list-wrap .no-records-section {
  margin-top: 40px;
}
.volumetric-wrap .wrapper .company-list-wrap .list-section {
  border-bottom: 1px solid rgba(212, 212, 212, 0.5);
  padding-bottom: 25px;
  padding-top: 15px;
}
.volumetric-wrap .wrapper .company-list-wrap .list-section .checkbox-section {
  display: inline-block;
  vertical-align: middle;
}
.volumetric-wrap .wrapper .company-list-wrap .list-section .sub-header-inline {
  display: inline-block;
  margin-right: 6px;
  min-width: 70px;
}
.volumetric-wrap .wrapper .company-list-wrap .list-section .table-header {
  width: 11%;
  font-size: 12px;
  display: inline-block;
  word-break: break-word;
}
.volumetric-wrap .wrapper .company-list-wrap .list-section .table-header.select-row {
  width: 4%;
}
.volumetric-wrap .wrapper .company-list-wrap .list-section .table-header.rules-tab {
  width: 18%;
}
.volumetric-wrap .wrapper .company-list-wrap .list-section .company-name-section {
  display: inline-block;
  vertical-align: middle;
  margin-left: 10px;
  width: 370px;
}
.volumetric-wrap .wrapper .company-list-wrap .list-section .company-name-section.table-header {
  margin-left: 85px;
  width: 290px;
}
.volumetric-wrap .wrapper .company-list-wrap .list-section .company-name-section .comany-logo,
.volumetric-wrap .wrapper .company-list-wrap .list-section .company-name-section .compnay-details {
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
}
.volumetric-wrap .wrapper .company-list-wrap .list-section .company-name-section .comany-logo {
  width: 50px;
  height: 35px;
  background-size: contain;
  background-repeat: no-repeat;
}
.volumetric-wrap .wrapper .company-list-wrap .list-section .company-name-section .compnay-details {
  margin-left: 25px;
  color: #1a2734;
  font-size: 15px;
  font-weight: 900;
  width: 220px;
}
.volumetric-wrap .wrapper .company-list-wrap .list-section .company-name-section .compnay-details .date {
  color: #86868a;
  font-size: 12px;
}
.volumetric-wrap .wrapper .company-list-wrap .list-section .company-name-section .compnay-details .name {
  word-break: break-word;
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}
.volumetric-wrap .wrapper .company-list-wrap .list-section .admin-details {
  display: inline-block;
  vertical-align: middle;
  width: 340px;
  font-size: 15px;
  color: #1a2734;
}
.volumetric-wrap .wrapper .company-list-wrap .list-section .admin-details.table-header {
  margin-left: 8px;
  width: 337px;
}
.volumetric-wrap .wrapper .company-list-wrap .list-section .admin-details .admin-email {
  font-size: 15px;
  color: #1a2734;
  cursor: pointer;
}
.volumetric-wrap .wrapper .company-list-wrap .list-section .admin-details .admin-pan {
  font-size: 12px;
  color: #86868a;
}
.volumetric-wrap .wrapper .company-list-wrap .list-section .account-type {
  display: inline-block;
  vertical-align: middle;
  width: 140px;
  font-size: 15px;
  color: #1a2734;
}
.volumetric-wrap .wrapper .company-list-wrap .list-section .status {
  display: inline-block;
  vertical-align: middle;
  width: 100px;
}
.volumetric-wrap .wrapper .company-list-wrap .list-section.header {
  font-weight: 600;
  padding-top: 0;
  color: #1a2734;
  font-size: 14px;
}
.volumetric-wrap .wrapper .company-list-wrap .list-section.content:hover {
  border: 1px solid rgba(12, 12, 12, 0.38);
  box-shadow: 0px 10px 20px 0px rgba(158, 170, 181, 0.45);
}
.volumetric-wrap .wrapper .company-list-wrap .list-section.content:hover .company-name-section .compnay-details .name {
  display: block;
}
.volumetric-wrap .wrapper .action-icon {
  background-repeat: no-repeat;
  height: 18px;
  width: 18px;
  background-size: contain;
  cursor: pointer;
  display: inline-block;
  padding: 5px;
  margin: 5px;
}
.divTable {
  margin-top: 20px;
  margin-bottom: 20px;
  display: table;
  width: 100%;
}
.divTableRow {
  display: table-row;
}
.divTableHeading {
  color: white;
  background-color: #EEE;
  display: table-header-group;
}
.divTableCell,
.divTableHead {
  /*border: 1px solid #999999;*/
  display: table-cell;
  padding: 10px 10px;
  font-size: 12px;
}
.divTableCell.bordered,
.divTableHead.bordered {
  border-bottom: 1px solid #999999;
}
.divTableHeading {
  background-color: #528aea;
  display: table-header-group;
  font-weight: bold;
}
.divTableFoot {
  background-color: #EEE;
  display: table-footer-group;
  font-weight: bold;
}
.divTableBody {
  display: table-row-group;
  margin-top: 10px;
}
.select {
  display: inline-block;
  width: 150px;
}
.slabRange .input-text-wrap {
  margin-bottom: 0;
}
.slabRange .input-element input {
  width: auto;
  font-size: 12px;
}
.penaltyPercent .input-text-wrap .text-input {
  width: 50px;
  font-size: 12px;
  padding-bottom: 5px;
}
.penaltyBox {
  text-align: center;
}
.download-btn {
  text-decoration: none;
}
.ml-19px {
  margin-left: 19px;
}
.inputBoxes .input-text-wrap {
  margin-bottom: 0;
}
.inputBoxes .input-text-wrap .text-input {
  width: 150px;
  font-size: 12px;
}
.inputBoxes.lbhBox .input-text-wrap .text-input {
  width: 90px;
}
.mr-10 {
  margin-right: 10px;
}

.rate-masters .rates-table {
  width: 975px;
  overflow: scroll;
}
.rate-masters .wrapper {
  width: 1280px;
  margin: 0 auto;
}
.rate-masters .wrapper .home-title {
  font-size: 12px;
  color: #86868a;
}
.rate-masters .wrapper .sub-heading-section {
  margin-top: 5px;
  font-family: 'avenir-medium', sans-serif;
  font-weight: 900;
  overflow: auto;
}
.rate-masters .wrapper .sub-heading-section .sub-heading {
  font-size: 18px;
  color: #282626;
  float: left;
  width: 100%;
}
.rate-masters .wrapper .sub-heading-section .add-company-section {
  float: right;
  margin-right: 30px;
}
.rate-masters .wrapper .cta-action-btns {
  display: flex;
}
.rate-masters .wrapper .cta-action-btns .button-classic {
  height: 30px;
  font-size: 14px;
  padding: 0 20px;
  border-radius: 5px;
  margin-top: 10px;
  margin-right: 10px;
}
.rate-masters .wrapper .cta-action-btns.cta-btn-right {
  float: right;
}
.rate-masters .wrapper .cta-action-btns .secondary {
  background: #5289ea;
  color: white;
}
.rate-masters .wrapper .company-list-wrap {
  margin-top: 10px;
}
.rate-masters .wrapper .company-list-wrap .no-records-section {
  margin-top: 40px;
}
.rate-masters .wrapper .company-list-wrap .list-section {
  border-bottom: 1px solid rgba(212, 212, 212, 0.5);
  padding-bottom: 25px;
  padding-top: 15px;
}
.rate-masters .wrapper .company-list-wrap .list-section .checkbox-section {
  display: inline-block;
  vertical-align: middle;
}
.rate-masters .wrapper .company-list-wrap .list-section .sub-header-inline {
  display: inline-block;
  margin-right: 6px;
  width: 75px;
}
.rate-masters .wrapper .company-list-wrap .list-section .table-header {
  margin: 10px;
  display: inline-block;
  word-break: break-word;
  text-transform: uppercase;
  font-size: 14px;
  text-align: center;
}
.rate-masters .wrapper .company-list-wrap .list-section .table-header.select-row {
  width: 4%;
}
.rate-masters .wrapper .company-list-wrap .list-section .table-header.rules-tab {
  width: 18%;
}
.rate-masters .wrapper .company-list-wrap .list-section .company-name-section {
  display: inline-block;
  vertical-align: middle;
  margin-left: 10px;
  width: 370px;
}
.rate-masters .wrapper .company-list-wrap .list-section .company-name-section.table-header {
  margin-left: 85px;
  width: 290px;
}
.rate-masters .wrapper .company-list-wrap .list-section .company-name-section .comany-logo,
.rate-masters .wrapper .company-list-wrap .list-section .company-name-section .compnay-details {
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
}
.rate-masters .wrapper .company-list-wrap .list-section .company-name-section .comany-logo {
  width: 50px;
  height: 35px;
  background-size: contain;
  background-repeat: no-repeat;
}
.rate-masters .wrapper .company-list-wrap .list-section .company-name-section .compnay-details {
  margin-left: 25px;
  color: #1a2734;
  font-size: 15px;
  font-weight: 900;
  width: 220px;
}
.rate-masters .wrapper .company-list-wrap .list-section .company-name-section .compnay-details .date {
  color: #86868a;
  font-size: 12px;
}
.rate-masters .wrapper .company-list-wrap .list-section .company-name-section .compnay-details .name {
  word-break: break-word;
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}
.rate-masters .wrapper .company-list-wrap .list-section.header {
  font-weight: 600;
  padding-top: 0;
  color: #1a2734;
  font-size: 14px;
}
.rate-masters .wrapper .company-list-wrap .list-section.content:hover {
  border: 1px solid rgba(12, 12, 12, 0.38);
  box-shadow: 0px 10px 20px 0px rgba(158, 170, 181, 0.45);
}
.rate-masters .wrapper .company-list-wrap .indicate-icon {
  background-repeat: no-repeat;
  height: 15px;
  width: 15px;
  background-size: contain;
  margin: 0 5px;
  cursor: pointer;
  display: inline-block;
}
.rate-masters .wrapper .company-list-wrap .divTable {
  margin-top: 20px;
  margin-bottom: 20px;
  display: table;
  width: 100%;
}
.rate-masters .wrapper .company-list-wrap .divTableRow {
  display: table-row;
}
.rate-masters .wrapper .company-list-wrap .divTableCell,
.rate-masters .wrapper .company-list-wrap .divTableHead {
  /*border: 1px solid #999999;*/
  display: table-cell;
  padding: 10px 10px;
  font-size: 12px;
}
.rate-masters .wrapper .company-list-wrap .divTableCell.bordered,
.rate-masters .wrapper .company-list-wrap .divTableHead.bordered {
  border-bottom: 1px solid #999999;
}
.rate-masters .wrapper .company-list-wrap .divTableHeading {
  background-color: #528aea;
  display: table-header-group;
  font-weight: bold;
}
.rate-masters .wrapper .company-list-wrap .divTableFoot {
  background-color: #EEE;
  display: table-footer-group;
  font-weight: bold;
}
.rate-masters .wrapper .company-list-wrap .divTableBody {
  display: table-row-group;
  margin-top: 10px;
}
.rate-masters .wrapper .action-row {
  display: inline-flex;
  width: 100%;
}
.rate-masters .wrapper .details-block {
  border: 1px solid #b9b9b9;
  border-radius: 2px;
  padding: 10px;
  padding-top: 8px;
  padding-bottom: 4px;
  margin-right: 0;
  margin-left: auto;
  /* width: 415px; */
}
.rate-masters .wrapper .zone-wise {
  margin-top: 5px;
  margin-bottom: 5px;
}
.rate-masters .wrapper .zone-wise-label {
  border-radius: 2px;
  padding: 5px;
  margin-right: 10px;
  font-size: 14px;
}
.rate-masters .wrapper .zone-wise-value {
  border-radius: 2px;
  padding: 5px;
  font-size: 18px;
}
.rate-masters .wrapper .pan-average-label {
  border-radius: 2px;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 2px;
  padding-bottom: 2px;
  font-size: 14px;
}
.rate-masters .wrapper .pan-average-value {
  border-radius: 2px;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 2px;
  padding-bottom: 2px;
  font-size: 18px;
}
.rate-masters .wrapper .zone-average {
  margin-left: 5px;
  font-size: 18px;
  color: #5289ea;
}
.rate-masters .wrapper .m-b-5 {
  margin-bottom: 5px;
}
.rate-masters .wrapper .value-highlight {
  background: #5289ea;
  color: white;
}
.rate-masters .input-text {
  padding: 5px;
  border: 1px solid #cccccc;
  height: 35px;
  border-radius: 4px;
  font-size: 14px;
  width: 100%;
}
.rate-masters .m-t-5 {
  margin-top: 5px;
}
.rate-masters .m-l-5 {
  margin-left: 5px;
}
.rate-masters .action-icon {
  background-repeat: no-repeat;
  height: 18px;
  width: 18px;
  background-size: contain;
  margin: auto !important;
  cursor: pointer;
  display: inline-block;
  padding: 5px;
}
.rate-masters .lanes-link {
  color: #5289ea;
  cursor: pointer;
}
.error {
  color: red;
}
.m-t-10 {
  margin-top: 10px;
}

.add-user-wrap .overlay {
  position: fixed;
  z-index: 10;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(27, 33, 67, 0.9);
}
.add-user-wrap .modal-popup {
  max-height: 300px;
  overflow: scroll;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 5px;
  width: 1000px;
  min-height: 500px;
  background-color: white;
  z-index: 11;
}
.add-user-wrap .modal-popup .pop-heading {
  background-color: #f4f4f4;
  padding: 18px;
  font-family: 'avenir', sans-serif;
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #528aea;
  text-align: center;
  margin-bottom: 30px;
}
.add-user-wrap .modal-popup .content {
  padding: 20px 0px 20px 20px;
  padding-top: 0px;
}
.add-user-wrap .modal-popup .content .select-container {
  display: flex;
  margin: 20px 0px;
}
.add-user-wrap .modal-popup .content .select-container .select-holder {
  width: 277px;
  position: relative;
}
.add-user-wrap .modal-popup .content .select-container .select-holder .sub-head {
  font-size: 10px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #868e96;
  margin-bottom: 7px;
}
.add-user-wrap .modal-popup .content .select-container .action-holder {
  display: flex;
  flex-wrap: wrap;
  width: 330px;
  margin: 15px;
  position: relative;
}
.add-user-wrap .modal-popup .content .select-container .action-holder .actions {
  margin: 5px;
}
.add-user-wrap .modal-popup .content .select-container .action-holder .actions .add-button {
  margin: 0;
  height: 35px;
  padding: 0 15px;
  font-size: 20px;
  margin-left: 10px;
}
.add-user-wrap .modal-popup .content .select-container .action-holder .actions .select {
  width: 235px;
}
.add-user-wrap .modal-popup .content .select-container .action-holder .add-button {
  margin: 5px 5px;
  padding: 10px 20px;
}
.add-user-wrap .modal-popup .content .select-container .action-holder .sub-head {
  position: absolute;
  font-size: 10px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #868e96;
  top: -10px;
  left: 7px;
}
.add-user-wrap .modal-popup .content .usn-container {
  margin: 17px 0px;
  display: flex;
}
.add-user-wrap .modal-popup .content .usn-container .add-button {
  margin: 20px;
  border-radius: 5px;
}
.add-user-wrap .modal-popup .content .usn-container .btn-disabled {
  background-color: #cac2c2;
  border: 1px solid #cac2c2;
  pointer-events: none;
  color: white;
}
.add-user-wrap .modal-popup .content .submit-section {
  display: flex;
  justify-content: flex-end;
}
.add-user-wrap .modal-popup .content .submit-section .add-button {
  border-radius: 5px;
  width: 145px;
}
.add-user-wrap .modal-popup .content .submit-section .discard {
  background: white;
  color: #528aea;
  border: 1px solid #528aea;
}
.add-user-wrap .modal-popup .content .submit-section.business-button button.add-button {
  margin: 0;
  color: black;
  padding: 10px 20px;
  background: #fd8989;
  border-radius: 20px;
  border-color: #ff000052;
}
.add-user-wrap .modal-popup .content .submit-section.business-button button.add-button.discard {
  background: #fff;
}
.add-user-wrap .subheading {
  margin: 5px;
  font-weight: bold;
  font-size: 14px;
  padding-left: 20px;
  padding-right: 30px;
}
.add-user-wrap .heading {
  margin: 20px;
  font-weight: bold;
  font-size: 24px;
}
.add-user-wrap .close-button {
  margin-right: 0;
  margin-left: auto;
  cursor: pointer;
  height: 30px;
  font-size: 14px;
  border-radius: 2px;
  color: #fff;
  background: #528aea;
  padding: 6px 13px;
  display: inline-block;
  border: 1px solid #528aea;
}
.add-user-wrap .close-button-disabled {
  margin-right: 20px;
  margin-left: auto;
  cursor: not-allowed;
  height: 30px;
  font-size: 14px;
  padding: 0 20px;
  border-radius: 2px;
  display: inline-block;
  color: #b0b0b0;
  /* Light gray color for the text */
  background: #e0e0e0;
  /* Light gray background */
  border: 1px solid #d0d0d0;
  /* Lighter border color */
  opacity: 0.6;
  /* Reduced opacity for a muted look */
}

.demurrages.discount-wrap .wrapper {
  width: 1280px;
  margin: 0 auto;
}
.demurrages.discount-wrap .wrapper .home-title {
  font-size: 12px;
  color: #86868a;
}
.demurrages.discount-wrap .wrapper .sub-heading-section {
  margin-top: 22px;
  font-family: 'avenir-medium', sans-serif;
  font-weight: 900;
  overflow: auto;
}
.demurrages.discount-wrap .wrapper .sub-heading-section .sub-heading {
  font-size: 24px;
  color: #282626;
  float: left;
}
.demurrages.discount-wrap .wrapper .sub-heading-section .add-company-section {
  float: right;
  margin-right: 30px;
}
.demurrages.discount-wrap .wrapper .cta-action-btns {
  display: inline-block;
}
.demurrages.discount-wrap .wrapper .cta-action-btns .button-classic {
  height: 30px;
  font-size: 14px;
  padding: 0 20px;
  border-radius: 5px;
  margin-top: 10px;
  margin-right: 10px;
}
.demurrages.discount-wrap .wrapper .cta-action-btns.cta-btn-right {
  float: right;
}
.demurrages.discount-wrap .wrapper .search-filter-section {
  clear: both;
  margin-top: 25px;
}
.demurrages.discount-wrap .wrapper .company-list-wrap {
  margin-top: 45px;
}
.demurrages.discount-wrap .wrapper .company-list-wrap .no-records-section {
  margin-top: 40px;
}
.demurrages.discount-wrap .wrapper .company-list-wrap .list-section {
  border-bottom: 1px solid rgba(212, 212, 212, 0.5);
  padding-bottom: 25px;
  padding-top: 15px;
}
.demurrages.discount-wrap .wrapper .company-list-wrap .list-section .checkbox-section {
  display: inline-block;
  vertical-align: middle;
}
.demurrages.discount-wrap .wrapper .company-list-wrap .list-section .sub-header-inline {
  width: 100%;
  margin-top: 5px;
  margin-right: 6px;
  text-align: center;
  padding-bottom: 5px;
  border-bottom: 1px solid #e0e0e0;
}
.demurrages.discount-wrap .wrapper .company-list-wrap .list-section .table-header {
  width: 11%;
  font-size: 12px;
  display: inline-block;
  word-break: break-word;
}
.demurrages.discount-wrap .wrapper .company-list-wrap .list-section .table-header.select-row {
  width: 4%;
}
.demurrages.discount-wrap .wrapper .company-list-wrap .list-section .table-header.rules-tab {
  width: 18%;
}
.demurrages.discount-wrap .wrapper .company-list-wrap .list-section .company-name-section {
  display: inline-block;
  vertical-align: middle;
  margin-left: 10px;
  width: 370px;
}
.demurrages.discount-wrap .wrapper .company-list-wrap .list-section .company-name-section.table-header {
  margin-left: 85px;
  width: 290px;
}
.demurrages.discount-wrap .wrapper .company-list-wrap .list-section .company-name-section .comany-logo,
.demurrages.discount-wrap .wrapper .company-list-wrap .list-section .company-name-section .compnay-details {
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
}
.demurrages.discount-wrap .wrapper .company-list-wrap .list-section .company-name-section .comany-logo {
  width: 50px;
  height: 35px;
  background-size: contain;
  background-repeat: no-repeat;
}
.demurrages.discount-wrap .wrapper .company-list-wrap .list-section .company-name-section .compnay-details {
  margin-left: 25px;
  color: #1a2734;
  font-size: 15px;
  font-weight: 900;
  width: 220px;
}
.demurrages.discount-wrap .wrapper .company-list-wrap .list-section .company-name-section .compnay-details .date {
  color: #86868a;
  font-size: 12px;
}
.demurrages.discount-wrap .wrapper .company-list-wrap .list-section .company-name-section .compnay-details .name {
  word-break: break-word;
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}
.demurrages.discount-wrap .wrapper .company-list-wrap .list-section .admin-details {
  display: inline-block;
  vertical-align: middle;
  width: 340px;
  font-size: 15px;
  color: #1a2734;
}
.demurrages.discount-wrap .wrapper .company-list-wrap .list-section .admin-details.table-header {
  margin-left: 8px;
  width: 337px;
}
.demurrages.discount-wrap .wrapper .company-list-wrap .list-section .admin-details .admin-email {
  font-size: 15px;
  color: #1a2734;
  cursor: pointer;
}
.demurrages.discount-wrap .wrapper .company-list-wrap .list-section .admin-details .admin-pan {
  font-size: 12px;
  color: #86868a;
}
.demurrages.discount-wrap .wrapper .company-list-wrap .list-section .account-type {
  display: inline-block;
  vertical-align: middle;
  width: 140px;
  font-size: 15px;
  color: #1a2734;
}
.demurrages.discount-wrap .wrapper .company-list-wrap .list-section .status {
  display: inline-block;
  vertical-align: middle;
  width: 100px;
}
.demurrages.discount-wrap .wrapper .company-list-wrap .list-section.header {
  font-weight: 600;
  padding-top: 0;
  color: #1a2734;
  font-size: 14px;
}
.demurrages.discount-wrap .wrapper .company-list-wrap .list-section.content:hover {
  border: 1px solid rgba(12, 12, 12, 0.38);
  box-shadow: 0px 10px 20px 0px rgba(158, 170, 181, 0.45);
}
.demurrages.discount-wrap .wrapper .company-list-wrap .list-section.content:hover .company-name-section .compnay-details .name {
  display: block;
}
.demurrages.discount-wrap .wrapper .company-list-wrap .action-icon {
  background-repeat: no-repeat;
  height: 18px;
  width: 18px;
  background-size: contain;
  cursor: pointer;
  display: inline-block;
  padding: 5px;
  margin: 5px;
}
.divTable {
  margin-top: 20px;
  margin-bottom: 20px;
  display: table;
  width: 100%;
}
.divTableRow {
  display: table-row;
}
.divTableHeading {
  color: white;
  background-color: #EEE;
  display: table-header-group;
}
.divTableCell,
.divTableHead {
  /*border: 1px solid #999999;*/
  display: table-cell;
  padding: 10px 10px;
  font-size: 12px;
}
.divTableCell.bordered,
.divTableHead.bordered {
  border-bottom: 1px solid #999999;
}
.divTableHeading {
  background-color: #528aea;
  display: table-header-group;
  font-weight: bold;
}
.divTableFoot {
  background-color: #EEE;
  display: table-footer-group;
  font-weight: bold;
}
.divTableBody {
  display: table-row-group;
  margin-top: 10px;
}
.select {
  display: inline-block;
  width: 150px;
}
.slabRange .input-text-wrap {
  margin-bottom: 0;
}
.slabRange .input-element input {
  width: auto;
  font-size: 12px;
}
.penaltyPercent .input-text-wrap .text-input {
  width: 50px;
  font-size: 12px;
  padding-bottom: 5px;
}
.penaltyBox {
  text-align: center;
}
.download-btn {
  text-decoration: none;
}
.add-user-wrap .overlay {
  position: fixed;
  z-index: 10;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(27, 33, 67, 0.9);
}
.add-user-wrap .modal-popup {
  max-height: 600px;
  overflow: scroll;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 5px;
  width: 1000px;
  min-height: 500px;
  background-color: white;
  z-index: 11;
}
.add-user-wrap .modal-popup .pop-heading {
  background-color: #f4f4f4;
  padding: 18px;
  font-family: 'avenir', sans-serif;
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #528aea;
  text-align: center;
  margin-bottom: 30px;
}
.add-user-wrap .modal-popup .content {
  padding: 20px 0px 20px 30px;
}
.add-user-wrap .modal-popup .content .select-container {
  display: flex;
  margin: 20px 0px;
}
.add-user-wrap .modal-popup .content .select-container .select-holder {
  width: 277px;
  position: relative;
}
.add-user-wrap .modal-popup .content .select-container .select-holder .sub-head {
  font-size: 10px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #868e96;
  margin-bottom: 7px;
}
.add-user-wrap .modal-popup .content .select-container .action-holder {
  display: flex;
  flex-wrap: wrap;
  width: 330px;
  margin: 15px;
  position: relative;
}
.add-user-wrap .modal-popup .content .select-container .action-holder .actions {
  margin: 5px;
}
.add-user-wrap .modal-popup .content .select-container .action-holder .actions .add-button {
  margin: 0;
  height: 35px;
  padding: 0 15px;
  font-size: 20px;
  margin-left: 10px;
}
.add-user-wrap .modal-popup .content .select-container .action-holder .actions .select {
  width: 235px;
}
.add-user-wrap .modal-popup .content .select-container .action-holder .add-button {
  margin: 5px 5px;
  padding: 10px 20px;
}
.add-user-wrap .modal-popup .content .select-container .action-holder .sub-head {
  position: absolute;
  font-size: 10px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #868e96;
  top: -10px;
  left: 7px;
}
.add-user-wrap .modal-popup .content .usn-container {
  margin: 17px 0px;
  display: flex;
}
.add-user-wrap .modal-popup .content .usn-container .add-button {
  margin: 20px;
  border-radius: 5px;
}
.add-user-wrap .modal-popup .content .usn-container .btn-disabled {
  background-color: #cac2c2;
  border: 1px solid #cac2c2;
  pointer-events: none;
  color: white;
}
.add-user-wrap .modal-popup .content .submit-section {
  display: flex;
  justify-content: flex-end;
}
.add-user-wrap .modal-popup .content .submit-section .add-button {
  border-radius: 5px;
  width: 145px;
}
.add-user-wrap .modal-popup .content .submit-section .discard {
  background: white;
  color: #528aea;
  border: 1px solid #528aea;
}
.add-user-wrap .modal-popup .content .submit-section.business-button button.add-button {
  margin: 0;
  color: black;
  padding: 10px 20px;
  background: #fd8989;
  border-radius: 20px;
  border-color: #ff000052;
}
.add-user-wrap .modal-popup .content .submit-section.business-button button.add-button.discard {
  background: #fff;
}
.divTableCell.rules-tab {
  width: 350px !important;
}
.penalty-slab {
  color: grey;
}
.penalty-value {
  color: black;
}
/*.table-header {
    .divTableCell {
        font-size: 15px !important;
    }
}*/

.advance-filter {
    width: 12%;
    margin: 10px;
    padding: 10px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 8px 0px, rgba(0, 0, 0, 0.17) 0px 7px 20px 0px;
    position: absolute;
    background: white;
    left: 82%;
}
.tag-elements {
    border: 1px solid grey;
    margin-right: 5px;
    margin-top: 5px;
    padding: 4px 10px 2px;
    border-radius: 15px;
    font-size: 14px;
    display: inline-block;
    cursor: pointer;
}
.tag-elements-active {
    border: 1px solid grey;
    margin-right: 5px;
    margin-top: 5px;
    padding: 4px 10px 2px;
    border-radius: 15px;
    display: inline-block;
    background: black;
    color: white;
    font-size: 14px;
    cursor: pointer;
}
.advance-filter-title {
    border-bottom: 2px solid #dfdfdf;
    padding-left: 5px;
}
.m-5 {
    margin: 5px;
}
.m-t-0 {
    margin-top: 0px;
}
.m-t-10 {
    margin-top: 10px;
}
.h-l {
    border: 1px solid #dfdfdf;
}
.apply-filter-button {
    cursor: pointer;
    height: 30px;
    padding: 0 20px;
    color: #fff;
    background: #528aea;
    margin: 0px 20px;
    display: inline-block;
    border: 1px solid #528aea;
    font-size: 16px;
}
.align-center {
    text-align: center;
}
.demurrages.discount-wrap .wrapper {
  width: 1280px;
  margin: 0 auto;
}
.demurrages.discount-wrap .wrapper .home-title {
  font-size: 12px;
  color: #86868a;
}
.demurrages.discount-wrap .wrapper .filterSelect {
  margin-right: 2rem;
}
.demurrages.discount-wrap .wrapper .sub-heading-section {
  margin-top: 22px;
  font-family: 'avenir-medium', sans-serif;
  font-weight: 900;
  overflow: auto;
}
.demurrages.discount-wrap .wrapper .sub-heading-section .sub-heading {
  font-size: 24px;
  color: #282626;
  float: left;
}
.demurrages.discount-wrap .wrapper .sub-heading-section .add-company-section {
  float: right;
  margin-right: 30px;
}
.demurrages.discount-wrap .wrapper .cta-action-btns {
  display: inline-block;
}
.demurrages.discount-wrap .wrapper .cta-action-btns .button-classic {
  height: 30px;
  font-size: 14px;
  padding: 0 20px;
  border-radius: 5px;
  margin-top: 10px;
  margin-right: 10px;
}
.demurrages.discount-wrap .wrapper .cta-action-btns.cta-btn-right {
  float: right;
}
.demurrages.discount-wrap .wrapper .search-filter-section {
  clear: both;
  margin-top: 25px;
}
.demurrages.discount-wrap .wrapper .company-list-wrap {
  margin-top: 45px;
}
.demurrages.discount-wrap .wrapper .company-list-wrap .no-records-section {
  margin-top: 40px;
}
.demurrages.discount-wrap .wrapper .company-list-wrap .list-section {
  border-bottom: 1px solid rgba(212, 212, 212, 0.5);
  padding-bottom: 25px;
  padding-top: 15px;
}
.demurrages.discount-wrap .wrapper .company-list-wrap .list-section .checkbox-section {
  display: inline-block;
  vertical-align: middle;
}
.demurrages.discount-wrap .wrapper .company-list-wrap .list-section .sub-header-inline {
  width: 100%;
  margin-top: 5px;
  margin-right: 6px;
  text-align: center;
  padding-bottom: 5px;
  border-bottom: 1px solid #e0e0e0;
}
.demurrages.discount-wrap .wrapper .company-list-wrap .list-section .table-header {
  width: 11%;
  font-size: 12px;
  display: inline-block;
  word-break: break-word;
}
.demurrages.discount-wrap .wrapper .company-list-wrap .list-section .table-header.select-row {
  width: 4%;
}
.demurrages.discount-wrap .wrapper .company-list-wrap .list-section .table-header.rules-tab {
  width: 18%;
}
.demurrages.discount-wrap .wrapper .company-list-wrap .list-section .company-name-section {
  display: inline-block;
  vertical-align: middle;
  margin-left: 10px;
  width: 370px;
}
.demurrages.discount-wrap .wrapper .company-list-wrap .list-section .company-name-section.table-header {
  margin-left: 85px;
  width: 290px;
}
.demurrages.discount-wrap .wrapper .company-list-wrap .list-section .company-name-section .comany-logo,
.demurrages.discount-wrap .wrapper .company-list-wrap .list-section .company-name-section .compnay-details {
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
}
.demurrages.discount-wrap .wrapper .company-list-wrap .list-section .company-name-section .comany-logo {
  width: 50px;
  height: 35px;
  background-size: contain;
  background-repeat: no-repeat;
}
.demurrages.discount-wrap .wrapper .company-list-wrap .list-section .company-name-section .compnay-details {
  margin-left: 25px;
  color: #1a2734;
  font-size: 15px;
  font-weight: 900;
  width: 220px;
}
.demurrages.discount-wrap .wrapper .company-list-wrap .list-section .company-name-section .compnay-details .date {
  color: #86868a;
  font-size: 12px;
}
.demurrages.discount-wrap .wrapper .company-list-wrap .list-section .company-name-section .compnay-details .name {
  word-break: break-word;
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}
.demurrages.discount-wrap .wrapper .company-list-wrap .list-section .admin-details {
  display: inline-block;
  vertical-align: middle;
  width: 340px;
  font-size: 15px;
  color: #1a2734;
}
.demurrages.discount-wrap .wrapper .company-list-wrap .list-section .admin-details.table-header {
  margin-left: 8px;
  width: 337px;
}
.demurrages.discount-wrap .wrapper .company-list-wrap .list-section .admin-details .admin-email {
  font-size: 15px;
  color: #1a2734;
  cursor: pointer;
}
.demurrages.discount-wrap .wrapper .company-list-wrap .list-section .admin-details .admin-pan {
  font-size: 12px;
  color: #86868a;
}
.demurrages.discount-wrap .wrapper .company-list-wrap .list-section .account-type {
  display: inline-block;
  vertical-align: middle;
  width: 140px;
  font-size: 15px;
  color: #1a2734;
}
.demurrages.discount-wrap .wrapper .company-list-wrap .list-section .status {
  display: inline-block;
  vertical-align: middle;
  width: 100px;
}
.demurrages.discount-wrap .wrapper .company-list-wrap .list-section.header {
  font-weight: 600;
  padding-top: 0;
  color: #1a2734;
  font-size: 14px;
}
.demurrages.discount-wrap .wrapper .company-list-wrap .list-section.content:hover {
  border: 1px solid rgba(12, 12, 12, 0.38);
  box-shadow: 0px 10px 20px 0px rgba(158, 170, 181, 0.45);
}
.demurrages.discount-wrap .wrapper .company-list-wrap .list-section.content:hover .company-name-section .compnay-details .name {
  display: block;
}
.demurrages.discount-wrap .wrapper .company-list-wrap .action-icon {
  background-repeat: no-repeat;
  height: 18px;
  width: 18px;
  background-size: contain;
  cursor: pointer;
  display: inline-block;
  padding: 5px;
  margin: 5px;
}
.divTable {
  margin-top: 20px;
  margin-bottom: 20px;
  display: table;
  width: 100%;
}
.divTableRow {
  display: table-row;
}
.divTableHeading {
  color: white;
  background-color: #EEE;
  display: table-header-group;
}
.divTableCell,
.divTableHead {
  /*border: 1px solid #999999;*/
  display: table-cell;
  padding: 10px 10px;
  font-size: 12px;
}
.divTableCell.bordered,
.divTableHead.bordered {
  border-bottom: 1px solid #999999;
}
.divTableHeading {
  background-color: #528aea;
  display: table-header-group;
  font-weight: bold;
}
.divTableFoot {
  background-color: #EEE;
  display: table-footer-group;
  font-weight: bold;
}
.divTableBody {
  display: table-row-group;
  margin-top: 10px;
}
.select {
  display: inline-block;
  width: 150px;
}
.slabRange .input-text-wrap {
  margin-bottom: 0;
}
.slabRange .input-element input {
  width: auto;
  font-size: 12px;
}
.penaltyPercent .input-text-wrap .text-input {
  width: 50px;
  font-size: 12px;
  padding-bottom: 5px;
}
.penaltyBox {
  text-align: center;
}
.download-btn {
  text-decoration: none;
}
.add-user-wrap .overlay {
  position: fixed;
  z-index: 10;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(27, 33, 67, 0.9);
}
.add-user-wrap .modal-popup {
  max-height: 600px;
  overflow: scroll;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 5px;
  width: 1000px;
  min-height: 500px;
  background-color: white;
  z-index: 11;
}
.add-user-wrap .modal-popup .pop-heading {
  background-color: #f4f4f4;
  padding: 18px;
  font-family: 'avenir', sans-serif;
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #528aea;
  text-align: center;
  margin-bottom: 30px;
}
.add-user-wrap .modal-popup .content {
  padding: 20px 0px 20px 30px;
}
.add-user-wrap .modal-popup .content .select-container {
  display: flex;
  margin: 20px 0px;
}
.add-user-wrap .modal-popup .content .select-container .select-holder {
  width: 277px;
  position: relative;
}
.add-user-wrap .modal-popup .content .select-container .select-holder .sub-head {
  font-size: 10px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #868e96;
  margin-bottom: 7px;
}
.add-user-wrap .modal-popup .content .select-container .action-holder {
  display: flex;
  flex-wrap: wrap;
  width: 330px;
  margin: 15px;
  position: relative;
}
.add-user-wrap .modal-popup .content .select-container .action-holder .actions {
  margin: 5px;
}
.add-user-wrap .modal-popup .content .select-container .action-holder .actions .add-button {
  margin: 0;
  height: 35px;
  padding: 0 15px;
  font-size: 20px;
  margin-left: 10px;
}
.add-user-wrap .modal-popup .content .select-container .action-holder .actions .select {
  width: 235px;
}
.add-user-wrap .modal-popup .content .select-container .action-holder .add-button {
  margin: 5px 5px;
  padding: 10px 20px;
}
.add-user-wrap .modal-popup .content .select-container .action-holder .sub-head {
  position: absolute;
  font-size: 10px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #868e96;
  top: -10px;
  left: 7px;
}
.add-user-wrap .modal-popup .content .usn-container {
  margin: 17px 0px;
  display: flex;
}
.add-user-wrap .modal-popup .content .usn-container .add-button {
  margin: 20px;
  border-radius: 5px;
}
.add-user-wrap .modal-popup .content .usn-container .btn-disabled {
  background-color: #cac2c2;
  border: 1px solid #cac2c2;
  pointer-events: none;
  color: white;
}
.add-user-wrap .modal-popup .content .submit-section {
  display: flex;
  justify-content: flex-end;
}
.add-user-wrap .modal-popup .content .submit-section .add-button {
  border-radius: 5px;
  width: 145px;
}
.add-user-wrap .modal-popup .content .submit-section .discard {
  background: white;
  color: #528aea;
  border: 1px solid #528aea;
}
.add-user-wrap .modal-popup .content .submit-section.business-button button.add-button {
  margin: 0;
  color: black;
  padding: 10px 20px;
  background: #fd8989;
  border-radius: 20px;
  border-color: #ff000052;
}
.add-user-wrap .modal-popup .content .submit-section.business-button button.add-button.discard {
  background: #fff;
}
.divTableCell.rules-tab {
  width: 350px !important;
}
.penalty-slab {
  color: grey;
}
.penalty-value {
  color: black;
}
/*.table-header {
    .divTableCell {
        font-size: 15px !important;
    }
}*/
.mb-5 {
  margin-bottom: 5px;
}
.fs-11 {
  font-size: 11px;
}
.header-color {
  color: #86868a;
}
.levy-logic-container {
  max-height: 400px;
  overflow: scroll;
  padding-bottom: 10px;
  margin-bottom: 15px;
}
.delete-icon {
  background-image: url(/images/delete.svg);
  background-repeat: no-repeat;
  height: 23px;
  width: 23px;
  background-size: contain;
  cursor: pointer;
  display: inline-block;
  padding: 5px;
  margin: 5px;
}
.delete-icon.levyDelete {
  height: 18px;
  width: 18px;
}

.hsdMaster .wrapper {
  width: 1280px;
  margin: 0 auto;
}
.hsdMaster .wrapper .search {
  width: 170px;
  font-size: 10px;
  border: 1px Solid #cccccc;
  border-radius: 5px;
  height: 38px;
  margin-top: 10px;
}
.hsdMaster .wrapper .search .input-text-wrap {
  width: 100%;
}
.hsdMaster .wrapper .search .input-text-wrap input {
  width: 150px;
  border-bottom: none;
  padding-bottom: 5px;
  padding-left: 5px;
  font-size: 14px;
}
.hsdMaster .wrapper .close-button {
  margin-right: 0;
  cursor: pointer;
  height: 30px;
  font-size: 14px;
  border-radius: 2px;
  color: #fff;
  background: #528aea;
  padding: 6px 12px;
  display: inline-block;
  border: 1px solid #528aea;
}
.hsdMaster .wrapper .disable-btn {
  pointer-events: none;
  background: #efeded;
  border: none;
}
.hsdMaster .wrapper .hsdTable {
  width: 100%;
  border: 1px Solid #cccccc;
}
.hsdMaster .wrapper .hsdTable .hsdHeader {
  border: 1px Solid #cccccc;
  background-color: #528aea;
  color: white;
  font-size: 12px;
}
.hsdMaster .wrapper .hsdTable .hsdHeader th {
  border: 1px Solid #cccccc;
  text-align: center;
}
.hsdMaster .wrapper .hsdTable tr .hsdDetails {
  border: none;
  text-align: center;
  font-size: 14px;
}
.hsdMaster .wrapper .hsdTable tr .hsdDetails .lanes-link {
  color: #5289ea;
  cursor: pointer;
}
.hsdMaster .wrapper .hsdTable tr .hsdDetails.approve {
  color: green;
}
.hsdMaster .wrapper .hsdTable .action-icon {
  background-repeat: no-repeat;
  height: 18px;
  width: 18px;
  background-size: contain;
  cursor: pointer;
  display: inline-block;
  padding: 5px;
  margin: 5px;
}
.hsdMaster .wrapper .zoneTable {
  width: 100%;
  border: 3px Solid #cccccc;
}
.hsdMaster .wrapper .zoneTable .zoneHeader {
  border: 1px Solid  white;
  background-color: white;
  color: #528aea;
  font-size: 12px;
}
.hsdMaster .wrapper .zoneTable .zoneHeader th {
  border: 1px Solid white;
  text-align: center;
  color: black;
}
.hsdMaster .wrapper .zoneTable tr .zoneDetails {
  border: none;
  text-align: center;
  font-size: 14px;
  color: black;
}
.error-font {
  color: red;
}

.add-user-wrap .overlay {
  position: fixed;
  z-index: 10;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(27, 33, 67, 0.9);
}
.add-user-wrap .modal-popup {
  max-height: 300px;
  overflow: scroll;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 5px;
  width: 1000px;
  min-height: 500px;
  background-color: white;
  z-index: 11;
}
.add-user-wrap .modal-popup .pop-heading {
  background-color: #f4f4f4;
  padding: 18px;
  font-family: 'avenir', sans-serif;
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #528aea;
  text-align: center;
  margin-bottom: 30px;
}
.add-user-wrap .modal-popup .content {
  padding: 20px 0px 20px 20px;
  padding-top: 0px;
}
.add-user-wrap .modal-popup .content .select-container {
  display: flex;
  margin: 20px 0px;
}
.add-user-wrap .modal-popup .content .select-container .select-holder {
  width: 277px;
  position: relative;
}
.add-user-wrap .modal-popup .content .select-container .select-holder .sub-head {
  font-size: 10px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #868e96;
  margin-bottom: 7px;
}
.add-user-wrap .modal-popup .content .select-container .action-holder {
  display: flex;
  flex-wrap: wrap;
  width: 330px;
  margin: 15px;
  position: relative;
}
.add-user-wrap .modal-popup .content .select-container .action-holder .actions {
  margin: 5px;
}
.add-user-wrap .modal-popup .content .select-container .action-holder .actions .add-button {
  margin: 0;
  height: 35px;
  padding: 0 15px;
  font-size: 20px;
  margin-left: 10px;
}
.add-user-wrap .modal-popup .content .select-container .action-holder .actions .select {
  width: 235px;
}
.add-user-wrap .modal-popup .content .select-container .action-holder .add-button {
  margin: 5px 5px;
  padding: 10px 20px;
}
.add-user-wrap .modal-popup .content .select-container .action-holder .sub-head {
  position: absolute;
  font-size: 10px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #868e96;
  top: -10px;
  left: 7px;
}
.add-user-wrap .modal-popup .content .usn-container {
  margin: 17px 0px;
  display: flex;
}
.add-user-wrap .modal-popup .content .usn-container .add-button {
  margin: 20px;
  border-radius: 5px;
}
.add-user-wrap .modal-popup .content .usn-container .btn-disabled {
  background-color: #cac2c2;
  border: 1px solid #cac2c2;
  pointer-events: none;
  color: white;
}
.add-user-wrap .modal-popup .content .submit-section {
  display: flex;
  justify-content: flex-end;
}
.add-user-wrap .modal-popup .content .submit-section .add-button {
  border-radius: 5px;
  width: 145px;
}
.add-user-wrap .modal-popup .content .submit-section .discard {
  background: white;
  color: #528aea;
  border: 1px solid #528aea;
}
.add-user-wrap .modal-popup .content .submit-section.business-button button.add-button {
  margin: 0;
  color: black;
  padding: 10px 20px;
  background: #fd8989;
  border-radius: 20px;
  border-color: #ff000052;
}
.add-user-wrap .modal-popup .content .submit-section.business-button button.add-button.discard {
  background: #fff;
}
.add-user-wrap .subheading {
  margin: 5px;
  font-weight: bold;
  font-size: 14px;
  padding-left: 20px;
  padding-right: 30px;
}
.add-user-wrap .heading {
  margin: 20px;
  font-weight: bold;
  font-size: 24px;
}
.add-user-wrap .close-button {
  margin-right: 0;
  cursor: pointer;
  height: 30px;
  font-size: 14px;
  border-radius: 2px;
  color: #fff;
  background: #528aea;
  padding: 6px 12px;
  display: inline-block;
  border: 1px solid #528aea;
}
.pl-20 {
  padding-left: 20px;
}
.wt-100p {
  width: 100%;
}
.fs-10 {
  font-size: 10px;
  font-weight: 500;
}
.tCenter {
  text-align: center;
}
.company-logo {
  width: 150px;
  height: 100px;
  float: left;
}
.hsd-checkbox .divTableCell input {
  width: 15px;
  height: 15px;
  border: none;
  outline: none;
  border-bottom: 1px solid lightgray;
}
.action-icon {
  background-repeat: no-repeat;
  height: 18px;
  width: 18px;
  background-size: contain;
  cursor: pointer;
  display: inline-block;
  padding: 5px;
  margin: 5px;
}

.navigation-panel-root {
  font-size: 16px;
}
.navigation-panel-root .navigation-holder {
  display: flex;
  justify-content: space-between;
  border-bottom: 4px solid #f9f9f9;
  max-width: 885px;
  margin: 30px 0px;
}
.navigation-panel-root .navigation-holder .options {
  border-bottom: 4px solid #f9f9f9;
  padding: 5px 10px;
  cursor: pointer;
  margin-bottom: -4px;
}
.navigation-panel-root .navigation-holder .selected-option {
  color: #528aea;
  border-bottom: 4px solid;
}
body {
  -webkit-box-sizing: border-box;
}

.sub-navigation-panel-root .sub-navigation-holder {
  display: flex;
  justify-content: space-between;
  border-bottom: 4px solid #f9f9f9;
  max-width: 370px;
  margin: 40px auto;
  font-size: 16px;
}
.sub-navigation-panel-root .sub-navigation-holder .options {
  border-bottom: 4px solid #f9f9f9;
  padding: 5px 10px;
  cursor: pointer;
  margin-bottom: -4px;
}
.sub-navigation-panel-root .sub-navigation-holder .selected-option {
  color: #528aea;
  border-bottom: 4px solid;
}

.add-item-details .item-select {
  display: inline-block;
  width: 277px;
}
.add-item-details .select-wrap {
  width: 33%;
  display: inline-block;
  vertical-align: baseline;
}
.add-item-details .select-wrap-vehicle {
  width: 25%;
  display: inline-block;
  vertical-align: text-bottom;
}
.add-item-details .label {
  font-size: 13px;
  color: #868e96;
  margin-bottom: 10px;
  width: 150px;
  font-weight: bold;
}
.add-item-details .label1 {
  font-size: 10px;
  color: #868e96;
  margin-bottom: 10px;
  margin-top: 30px;
}
.item-table .item-header .item-label {
  width: 98px;
  word-wrap: break-word;
}
.add-user-wrap .modal-popup .pop-heading {
  background-color: #f4f4f4;
  padding: 18px;
  font-family: 'avenir', sans-serif;
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #528aea;
  text-align: center;
  margin-bottom: 30px;
  margin-top: 30px;
}
.add-user-wrap .modal-popup .pop-heading.select-wrap.data-picke.label {
  margin-top: 30px;
}
.search-text-wrap .text-search {
  width: 240px;
}

.master {
  width: 1280px;
  margin: 0 auto;
}
.flex-between {
  justify-content: space-between;
}
.border-bottom-light {
  border-bottom: 1px solid rgba(151, 151, 151, 0.2);
}
.select-wrap {
  width: 23%;
  display: inline-block;
  vertical-align: baseline;
  margin: 6px;
}
.pv-20 {
  padding-top: 20px;
  padding-bottom: 20px;
}
.ht-400 {
  height: 400px;
}
.overflowDiv {
  overflow-x: auto;
  overflow-y: scroll;
}
.compartment .input-text-wrap .text-input {
  width: 100px;
  font-size: 12px;
  padding-bottom: 5px;
}
.reason-modal-popup {
  height: 300px;
  overflow: scroll;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 5px;
  width: 500px;
  background-color: white;
  z-index: 2000;
  display: flex;
  flex-direction: column;
}
.wt-220 {
  width: 220px;
}

.tatForm {
  overflow: scroll;
  height: 100%;
  width: 100%;
}
.tatForm .tat-header {
  font-size: 20px;
  color: #627FE9;
  text-align: center;
  margin: 10px;
}
.tatForm .tat-table {
  background-color: #627FE9;
  color: white;
  font-size: 14px;
  font-weight: bolder;
  text-align: center;
  border: 1px solid #F6F9FF;
}
.tatForm .tat-body {
  font-size: 14px;
  font-weight: bolder;
  text-align: center;
  border: 1px solid #9f9e9e63;
  background-color: #F6F9FF;
}
.tatForm .tat-body .m-5 {
  margin: 5px;
}
.tatForm .tat-body .laneInput .input-text-wrap {
  width: 90%;
  margin-bottom: 0px !important;
}
.tatForm .tat-body .laneInput .input-text-wrap .label {
  margin-bottom: 0px !important;
}
.tatForm .tat-body .laneInput .input-text-wrap .text-input {
  width: 100%;
  font-size: 14px;
  padding-bottom: 2px;
  height: 30px;
}
.tatForm .wt-5p {
  width: 5%;
}
.tatForm .wt-10p {
  width: 10%;
}
.tatForm .wt-15p {
  width: 15%;
}
.tatForm .approveButton .button-classic {
  color: green;
  border: 1px solid green;
}
.tatForm .rejectButton .button-classic {
  color: red;
  border: 1px solid red;
}

.main-container {
  width: 100%;
  background-color: #E3E9FF;
  min-height: 100vh;
}
.main-container .boarding-form {
  padding: 29px 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.main-container .boarding-form .header {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.main-container .boarding-form .heading {
  display: flex;
  justify-content: space-between;
  margin-top: 70px;
}
.main-container .boarding-form .heading .header-title {
  color: Black;
  font-size: 25px;
  margin: 30px 0;
  font-weight: bolder;
}
.main-container .boarding-form .heading .form-status {
  width: 24%;
}
.main-container .boarding-form .heading .form-status .progress-status-bar .progressbar-container {
  width: 400px;
}
.main-container .boarding-form .heading .form-status .progress-status-bar .progressbar {
  counter-reset: step;
}
.main-container .boarding-form .heading .form-status .progress-status-bar .progressbar li {
  list-style-type: none;
  width: 30%;
  display: flex;
  float: left;
  font-size: 12px;
  position: relative;
  text-align: center;
  color: blue;
  flex-direction: column;
}
.main-container .boarding-form .heading .form-status .progress-status-bar .progressbar li:before {
  width: 30px;
  height: 30px;
  content: counter(step);
  counter-increment: step;
  line-height: 30px;
  border: 2px solid blue;
  display: block;
  text-align: center;
  margin: 0 auto 10px auto;
  border-radius: 50%;
  background-color: white;
}
.main-container .boarding-form .heading .form-status .progress-status-bar .progressbar li:after {
  width: 100%;
  height: 2px;
  content: '';
  position: absolute;
  background-color: #7d7d7d;
  top: 15px;
  left: -50%;
  z-index: -1;
}
.main-container .boarding-form .heading .form-status .progress-status-bar .progressbar li:first-child:after {
  content: none;
}
.main-container .boarding-form .heading .form-status .progress-status-bar .progressbar li.active {
  color: blue;
  display: flex;
  flex-direction: column;
}
.main-container .boarding-form .heading .form-status .progress-status-bar .progressbar li.active:before {
  border-color: blue;
  background-color: blue;
  color: white;
}
.main-container .boarding-form .heading .form-status .progress-status-bar .progressbar li.active + li:after {
  background-color: blue;
}
.main-container .boarding-form .form-page-1 {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.main-container .boarding-form .form-page-1 .contact-table {
  background-color: white;
  border: 1px solid black;
  border-radius: 8px;
  margin-top: 80px;
}
.main-container .boarding-form .form-page-1 .Table-header {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.main-container .boarding-form .form-page-1 .Table-header .declaration-content {
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  width: 100%;
}
.main-container .boarding-form .form-page-1 .Table-header .declaration-inside-content {
  display: flex;
  padding-left: 20px;
  width: 100%;
  align-items: center;
  padding-top: 5px;
  margin-bottom: 10px;
}
.main-container .boarding-form .form-page-1 .Table-header .declaration-inside-content .declaration-inside-content-name {
  width: 20%;
}
.main-container .boarding-form .form-page-1 .Table-header .declaration-inside-content .declaration-button {
  height: 38px;
  background-color: white;
  color: #627fe9;
  font-size: 14px;
  font-weight: bold;
  outline: none;
  cursor: pointer;
  margin-left: 20px;
  border: 1px solid #627fe9;
  align-items: center;
  display: flex;
  padding: 0 15px;
  text-decoration: none;
}
.main-container .boarding-form .form-page-1 .Table-header .table-heading {
  display: flex;
  border-bottom: 1px solid black;
  width: 100%;
  padding: 20px 10px;
}
.main-container .boarding-form .form-page-1 .Table-header .first-table-body {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 10px 10px;
}
.main-container .boarding-form .form-page-1 .Table-header .first-table-body .new-vendor-input {
  height: 35px;
  width: 40%;
  padding: 10px;
  border: 1px solid #627FE9;
}
.main-container .boarding-form .form-page-1 .Table-header .first-table-body .upload-img {
  text-align: right;
  width: 30%;
}
.main-container .boarding-form .form-page-1 .Table-header .second-table-body-verify {
  display: flex;
  padding: 10px 10px;
  justify-content: space-between;
  width: 100%;
}
.main-container .boarding-form .form-page-1 .Table-header .second-table-body-verify .contact-details-vendor-input {
  height: 35px;
  width: 80%;
  padding: 10px;
  border: 1px solid #627FE9;
}
.main-container .boarding-form .form-page-1 .files-link {
  color: #627fe9;
  text-decoration: none;
  margin-left: 15px;
}
.main-container .boarding-form .form-page-1 .ml-25 {
  margin-left: 25px;
}
.main-container .boarding-form .form-page-1 .gst-details-table {
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: white;
  border: 1px solid black;
  border-radius: 10px;
  margin-top: 15px;
}
.main-container .boarding-form .form-page-1 .gst-details-table .gst-table-header {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.main-container .boarding-form .form-page-1 .gst-details-table .gst-table-header .gst-table-heading {
  display: flex;
  width: 20%;
  padding: 20px 10px;
}
.main-container .boarding-form .form-page-1 .regional-office-details {
  background-color: white;
  border: 1px solid black;
  border-radius: 8px;
  margin-top: 10px;
}
.main-container .boarding-form .form-page-1 .regional-office-details .regional-table-header {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.main-container .boarding-form .form-page-1 .regional-office-details .regional-table-header .regional-table-heading {
  display: flex;
  border-bottom: 1px solid black;
  width: 100%;
  padding: 20px 10px;
}
.main-container .boarding-form .form-page-1 .regional-office-details .regional-table-header .regional-table-body {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 10px 10px;
}
.main-container .boarding-form .form-page-1 .regional-office-details .regional-table-header .regional-table-body .regional-left-row {
  width: 85%;
  display: flex;
  justify-content: space-between;
}
.main-container .boarding-form .form-page-1 .regional-office-details .regional-table-header .regional-table-body .regional-left-row .regional-new-vendor-input {
  height: 35px;
  width: 90%;
  padding: 10px;
  border: 1px solid #627FE9;
}
.main-container .boarding-form .form-page-1 .regional-office-details .regional-table-header .regional-table-body .regional-left-row .regional-details-vendor-select {
  height: 35px;
  width: 90%;
}
.main-container .boarding-form .form-page-1 .bank-details {
  background-color: white;
  border: 1px solid black;
  border-radius: 8px;
  margin-top: 10px;
  width: 100%;
}
.main-container .boarding-form .form-page-1 .bank-details .bank-details-table-header {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.main-container .boarding-form .form-page-1 .bank-details .bank-details-table-heading {
  display: flex;
  border-bottom: 1px solid black;
  width: 100%;
  padding: 20px 10px;
}
.main-container .boarding-form .form-page-1 .bank-details .bank-table-body {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 10px 10px;
}
.main-container .boarding-form .form-page-1 .bank-details .bank-table-body .bank-new-vendor-input {
  height: 35px;
  width: 90%;
  padding: 10px;
  border: 1px solid #627FE9;
}
.main-container .boarding-form .form-page-1 .customer-details-table {
  background-color: white;
  border: 1px solid black;
  border-radius: 8px;
  margin-top: 10px;
}
.main-container .boarding-form .form-page-1 .customer-details-table .customer-table-header {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.main-container .boarding-form .form-page-1 .customer-details-table .customer-table-header .customer-table-heading {
  display: flex;
  border-bottom: 1px solid black;
  width: 100%;
  padding: 20px 10px;
}
.main-container .boarding-form .form-page-1 .customer-details-table .customer-table-header .customer-details-body {
  display: flex;
  flex-direction: column;
  padding: 10px 10px;
  width: 100%;
}
.main-container .boarding-form .form-page-1 .customer-details-table .customer-table-header .customer-details-body .customer-details-row {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 15px;
}
.main-container .boarding-form .form-page-1 .customer-details-table .customer-table-header .customer-details-body .customer-details-row .customer-details-vendor-input {
  height: 35px;
  width: 90%;
  padding: 10px;
  border: 1px solid #627FE9;
}
.main-container .boarding-form .form-page-1 .customer-details-table .customer-table-header .customer-details-body .customer-details-row .customer-details-vendor-select {
  height: 35px;
  width: 90%;
}
.main-container .boarding-form .form-page-1 .customer-details-table .customer-table-header .customer-details-body .customer-details-row2 {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 15px;
}
.main-container .boarding-form .form-page-1 .customer-details-table .customer-table-header .customer-details-body .customer-details-row2 .customer-details-left {
  display: flex;
  width: 70%;
}
.main-container .boarding-form .form-page-1 .customer-details-table .customer-table-header .customer-details-body .customer-details-row2 .customer-details-left .customer-details-vendor-input2 {
  height: 35px;
  width: 80%;
  padding: 10px;
  border: 1px solid #627FE9;
}
.main-container .boarding-form .form-page-1 .customer-details-table .customer-table-header .customer-details-body .customer-details-row2 .customer-details-left .customer-details-vendor-select2 {
  height: 35px;
  width: 90%;
}
.main-container .boarding-form .form-page-1 .vendor-type-table {
  background-color: white;
  border: 1px solid black;
  border-radius: 8px;
  margin-top: 10px;
}
.main-container .boarding-form .form-page-1 .vendor-type-table .vendor-type-header {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.main-container .boarding-form .form-page-1 .vendor-type-table .vendor-type-header .vendor-type-heading {
  display: flex;
  border-bottom: 1px solid black;
  width: 100%;
  padding: 20px 10px;
}
.main-container .boarding-form .form-page-1 .vendor-type-table .vendor-type-header .vendor-table-body {
  width: 100%;
  padding: 10px 10px;
  display: flex;
  flex-wrap: wrap;
}
.main-container .boarding-form .form-page-1 .vendor-type-table .vendor-type-header .vendor-table-body .vendor-type-select {
  height: 35px;
  width: 40%;
  padding: 10px;
  border: 1px solid #627FE9;
}
.main-container .grey {
  color: #86868a;
}
.main-container .theme {
  color: #5289ea;
}
.main-container .black {
  color: #1a2734;
}
.main-container .headerColor {
  color: black;
  border-bottom: 1px solid #85919e;
  padding: 12px 12px;
  font-size: 20px;
}
.main-container .headerColor span {
  margin-left: 5px;
}
.main-container .dInBlock {
  display: inline-block;
}
.main-container .VAlign {
  vertical-align: top;
}
.main-container .mt-5 {
  margin-top: 5px;
}
.main-container .mt-10 {
  margin-top: 10px;
}
.main-container .mt-15 {
  margin-top: 15px;
}
.main-container .mt-20 {
  margin-bottom: 20px;
}
.main-container .mt-25 {
  margin-top: 25px;
}
.main-container .mt-40 {
  margin-top: 40px;
}
.main-container .mt-60 {
  margin-top: 60px;
}
.main-container .mb-5 {
  margin-bottom: 5px;
}
.main-container .mb-10 {
  margin-bottom: 10px;
}
.main-container .mb-15 {
  margin-bottom: 15px;
}
.main-container .mb-20 {
  margin-bottom: 20px;
}
.main-container .mb-25 {
  margin-bottom: 25px;
}
.main-container .mr-5 {
  margin-right: 5px;
}
.main-container .mr-10 {
  margin-right: 10px;
}
.main-container .mr-15 {
  margin-right: 15px;
}
.main-container .mr-20 {
  margin-right: 20px;
}
.main-container .mt-18 {
  margin-top: 18px;
}
.main-container .mr-25 {
  margin-right: 25px;
}
.main-container .mwt-80 {
  min-width: 80px;
}
.main-container .mwt-120 {
  min-width: 120px;
}
.main-container .pt-10 {
  padding-top: 10px;
}
.main-container .wt-100 {
  width: 100px;
}
.main-container .wt-120 {
  width: 120px;
}
.main-container .wt-150 {
  width: 150px;
}
.main-container .wt-160 {
  width: 160px;
}
.main-container .ht-150 {
  height: 150px;
}
.main-container .ht-50 {
  height: 50px;
}
.main-container .ht-65 {
  height: 65px;
}
.main-container .ht-90 {
  height: 90px;
}
.main-container .wt-200 {
  width: 200px;
}
.main-container .wt-250 {
  width: 250px;
}
.main-container .wt-10p {
  width: 10%;
}
.main-container .wt-12p {
  width: 15%;
}
.main-container .wt-18p {
  width: 18%;
}
.main-container .wt-20p {
  width: 20%;
}
.main-container .wt-25p {
  width: 25%;
}
.main-container .wt-50p {
  width: 50%;
}
.main-container .wt-60p {
  width: 60%;
}
.main-container .wt-75p {
  width: 75%;
}
.main-container .wt-100p {
  width: 100%;
}
.main-container .mwt-170 {
  width: 170px;
}
.main-container .fRight {
  float: right;
}
.main-container .tCenter {
  text-align: center;
}
.main-container .tLeft {
  text-align: left;
}
.main-container .pointer {
  cursor: pointer;
}
.main-container .fs-13 {
  font-size: 13px;
}
.main-container .fs-15 {
  font-size: 15px;
}
.main-container .marginAuto {
  margin: auto;
}
.main-container .noBorder {
  border: none;
}
.main-container .fTop {
  vertical-align: top;
}
.main-container .checkbox-flex {
  display: flex;
  align-items: center;
}
.main-container .fMiddle {
  vertical-align: middle;
}
.main-container .area-name {
  font-size: 17px;
  color: #7d7d7d;
}
.main-container .borderLight {
  border: solid 1px #dadada;
}
.main-container .bg-light {
  background-color: #d5e2f9;
}
.ml-20 {
  margin-left: 20px;
}

.PC {
  width: 110%;
  margin-left: -40px;
}
.PC .border {
  border: 1px solid #528aea;
  padding: 20px 25px;
  border-radius: 8px;
  background-color: white;
}
.PC .border .flex-item {
  display: flex;
  grid-gap: 20px;
  gap: 20px;
  align-items: center;
}
.PC .border .flex-item .plantName {
  width: 150px;
}
.PC .border .flex-item .plantName::placeholder {
  color: #528aea;
}
.PC .border .flex-item .loc {
  width: 150px;
}
.PC .border .flex-item .loc::placeholder {
  color: #528aea;
}
.PC .border .flex-item .locCode {
  width: 200px;
}
.PC .border .flex-item .locCode::placeholder {
  color: #528aea;
}
.PC .border .flex-item .zone {
  width: 150px;
}
.PC .border .flex-item .zone::placeholder {
  color: #528aea;
}
.PC .border .flex-item .state {
  width: 150px;
}
.PC .border .flex-item .state::placeholder {
  color: #528aea;
}
.PC .border .flex-item .clusterName {
  width: 170px;
}
.PC .border .flex-item .clusterName::placeholder {
  color: #528aea;
}
.PC .border .flex-item .locCodeParent {
  width: 200px;
}
.PC .border .flex-item .locCodeParent::placeholder {
  color: #528aea;
}
.PC .border .flex-item .upload-button {
  border: 1px solid #528aea;
  padding: 8px 25px;
  font-size: 13px;
  background-color: #528aea;
  color: white;
  border-radius: 3px;
  cursor: pointer;
}
.PC .branchdrop {
  width: 25%;
}
.PC .del-check {
  padding-top: 20px;
  display: flex;
  grid-gap: 20px;
  gap: 20px;
  align-items: center;
  margin-left: 10px;
}
.PC .table {
  width: 100%;
  margin-left: 0px;
}
.PC .table th {
  border: 1px solid white;
}
.PC .table td {
  border: 1px solid white;
  border-right: 1px solid #c4d4f0;
}
.PC .table .table-tr {
  background-color: #3261b1;
  color: white;
  font-size: 13px;
}
.PC .table .table-tr .tCenter {
  padding: 10px 15px;
}
.PC .table .tr-1 {
  background-color: #d6e0ef;
}
.PC .template {
  border: 1px solid #528aea;
  display: flex;
  align-items: center;
  padding: 2px 15px 2px 8px;
  grid-gap: 0px;
  gap: 0px;
  border-radius: 5px;
  background-color: white;
  cursor: pointer;
}
.PC .template .template-text {
  color: #528aea;
}
.search-filter-section {
  clear: both;
  margin-top: 10px;
}
.flex-between {
  justify-content: space-between;
}
.link {
  color: #528aea;
  text-decoration: underline;
}
.order-daterange {
  border: none;
  height: 30px;
}
.order-daterange #startDate {
  padding: 8px 10px 8px 32px;
  height: 30px;
  background-image: url(/teg/media/calendar.3dd3131e.svg) !important;
  background-repeat: no-repeat;
  font-size: 12.8px;
  font-family: 'SFPro-Display-Medium', sans-serif;
  background-position-x: 8px;
  background-position-y: 10px;
  border: 0.9px solid #d8dbf0;
  border-right: 0px solid white;
  border-bottom-right-radius: 0px;
  border-top-right-radius: 0px;
}
.order-daterange #endDate {
  padding: 8px 10px 8px 10px;
  background-image: none !important;
  background-repeat: no-repeat;
  font-size: 12.8px;
  font-family: 'SFPro-Display-Medium', sans-serif;
  background-position-x: 100px;
  background-position-y: 7px;
  height: 30px;
  border: 0.9px solid #d8dbf0;
  border-left: 0px solid white;
  border-bottom-left-radius: 0px;
  border-top-left-radius: 0px;
}
.order-daterange .DateRangePickerInput {
  width: max-content;
  margin-left: 0px !important;
}
.button-classic {
  height: 30px;
  font-size: 14px;
  padding: 0 20px;
  border-radius: 5px;
  margin-right: 10px;
}

.LM-upload {
  width: 100%;
  margin-left: -40px;
}
.LM-upload .border {
  border: 1px solid #528aea;
  padding: 20px 25px;
  border-radius: 8px;
  background-color: white;
}
.LM-upload .border .flex-item {
  display: flex;
  grid-gap: 20px;
  gap: 20px;
  align-items: center;
}
.LM-upload .border .flex-item .plantName {
  width: 15%;
}
.LM-upload .border .flex-item .plantName::placeholder {
  color: #528aea;
}
.LM-upload .border .flex-item .loc {
  width: 15%;
}
.LM-upload .border .flex-item .loc::placeholder {
  color: #528aea;
}
.LM-upload .border .flex-item .locCode {
  width: 15%;
}
.LM-upload .border .flex-item .locCode::placeholder {
  color: #528aea;
}
.LM-upload .border .flex-item .zone {
  width: 15%;
}
.LM-upload .border .flex-item .zone::placeholder {
  color: #528aea;
}
.LM-upload .border .flex-item .state {
  width: 15%;
}
.LM-upload .border .flex-item .state::placeholder {
  color: #528aea;
}
.LM-upload .border .flex-item .upload-button {
  border: 1px solid #528aea;
  padding: 8px 25px;
  font-size: 13px;
  background-color: #528aea;
  color: white;
  border-radius: 3px;
  cursor: pointer;
  text-decoration: none;
}
.LM-upload .branchdrop {
  width: 25%;
}
.LM-upload .del-check {
  padding-top: 20px;
  display: flex;
  grid-gap: 20px;
  gap: 20px;
  align-items: center;
  margin-left: 10px;
}
.LM-upload .table {
  width: 100%;
  margin-left: 0px;
  margin-top: -15px;
}
.LM-upload .table th {
  border: 1px solid white;
}
.LM-upload .table td {
  border: 1px solid white;
  border-right: 1px solid #c4d4f0;
}
.LM-upload .table .table-tr {
  background-color: #3261b1;
  color: white;
  font-size: 13px;
}
.LM-upload .table .table-tr .tCenter {
  padding: 10px 15px;
}
.LM-upload .table .tr-1 {
  background-color: #d6e0ef;
}
.search-filter-section {
  clear: both;
  margin-top: 10px;
}
.flex-between {
  justify-content: space-between;
}
.link {
  color: #528aea;
  text-decoration: underline;
  cursor: pointer;
}
.order-daterange {
  border: none;
  height: 30px;
}
.order-daterange #startDate {
  padding: 8px 10px 8px 32px;
  height: 30px;
  background-image: url(/teg/media/calendar.3dd3131e.svg) !important;
  background-repeat: no-repeat;
  font-size: 12.8px;
  font-family: 'SFPro-Display-Medium', sans-serif;
  background-position-x: 8px;
  background-position-y: 10px;
  border: 0.9px solid #d8dbf0;
  border-right: 0px solid white;
  border-bottom-right-radius: 0px;
  border-top-right-radius: 0px;
}
.order-daterange #endDate {
  padding: 8px 10px 8px 10px;
  background-image: none !important;
  background-repeat: no-repeat;
  font-size: 12.8px;
  font-family: 'SFPro-Display-Medium', sans-serif;
  background-position-x: 100px;
  background-position-y: 7px;
  height: 30px;
  border: 0.9px solid #d8dbf0;
  border-left: 0px solid white;
  border-bottom-left-radius: 0px;
  border-top-left-radius: 0px;
}
.order-daterange .DateRangePickerInput {
  width: max-content;
  margin-left: 0px !important;
}
.input-box-location-mgmt {
  height: 38px;
  border: 1px solid #528aea;
  padding: 10px 15px;
  width: 150px;
}
.custome-checkbox-wrap-parent-child {
  width: 15px;
  height: 15px;
  border-radius: 4px;
  border: 1px solid #cac3c3;
  display: inline-block;
  position: relative;
  cursor: pointer;
  margin: 5px;
  background-color: white;
}
.custome-checkbox-wrap-parent-child.selected {
  border: 1px solid #528aea;
}
.custome-checkbox-wrap-parent-child .inner-circle {
  width: 11px;
  height: 11px;
  border-radius: 15%;
  background: #528aea;
  position: absolute;
  top: 1px;
  left: 1px;
}

.UL {
  width: max-content;
  margin-top: 10px;
}
.UL .border {
  border: 1px solid #528aea;
  padding: 20px 25px;
  border-radius: 8px;
  background-color: white;
}
.UL .border .flex-item {
  display: flex;
  grid-gap: 20px;
  gap: 20px;
  align-items: center;
}
.UL .border .flex-item .plantName {
  width: 150px;
}
.UL .border .flex-item .plantName::placeholder {
  color: #528aea;
}
.UL .border .flex-item .loc {
  width: 150px;
}
.UL .border .flex-item .loc::placeholder {
  color: #528aea;
}
.UL .border .flex-item .locCode {
  width: 200px;
}
.UL .border .flex-item .locCode::placeholder {
  color: #528aea;
}
.UL .border .flex-item .zone {
  width: 150px;
}
.UL .border .flex-item .zone::placeholder {
  color: #528aea;
}
.UL .border .flex-item .state {
  width: 150px;
}
.UL .border .flex-item .state::placeholder {
  color: #528aea;
}
.UL .border .flex-item .clusterName {
  width: 170px;
}
.UL .border .flex-item .clusterName::placeholder {
  color: #528aea;
}
.UL .border .flex-item .locCodeParent {
  width: 200px;
}
.UL .border .flex-item .locCodeParent::placeholder {
  color: #528aea;
}
.UL .border .flex-item .upload-button {
  border: 1px solid #528aea;
  padding: 8px 25px;
  font-size: 13px;
  background-color: #528aea;
  color: white;
  border-radius: 3px;
  cursor: pointer;
}
.UL .branchdrop {
  width: 25%;
}
.UL .del-check {
  padding-top: 20px;
  display: flex;
  grid-gap: 20px;
  gap: 20px;
  align-items: center;
  margin-left: 10px;
}
.UL .table {
  width: max-content;
  margin-left: 0px;
}
.UL .table th {
  border: 1px solid white;
}
.UL .table td {
  border: 1px solid white;
  border-right: 1px solid #c4d4f0;
}
.UL .table .table-tr {
  background-color: #3261b1;
  color: white;
  font-size: 13px;
}
.UL .table .table-tr .tCenter {
  padding: 10px 15px;
}
.UL .table .tr-1 {
  background-color: #d6e0ef;
}
.UL .template {
  border: 1px solid #528aea;
  display: flex;
  align-items: center;
  padding: 2px 15px 2px 8px;
  grid-gap: 0px;
  gap: 0px;
  border-radius: 5px;
  background-color: white;
  cursor: pointer;
}
.UL .template .template-text {
  color: #528aea;
}
.UL .file-holder {
  width: 300px;
  height: 36.5px;
  border-radius: 2px;
  background-color: #f9f9f9;
  border: 1px solid #cccccc;
  cursor: pointer;
  position: relative;
}
.UL .file-holder input {
  width: 300px;
  height: 35px;
  opacity: 0;
  cursor: pointer;
}
.UL .file-holder .file-choose {
  position: absolute;
  right: 9px;
  top: 9px;
  font-family: 'avenir', sans-serif;
  font-size: 12px;
  letter-spacing: 0.3px;
  cursor: pointer;
  color: #528aea;
  border-left: 1px solid black;
  padding-left: 8px;
}
.UL .file-holder .file-name {
  font-family: 'avenir', sans-serif;
  font-size: 12px;
  position: absolute;
  top: 9px;
  left: 12px;
  color: #86868a;
}
.search-filter-section {
  clear: both;
  margin-top: 10px;
}
.flex-between {
  justify-content: space-between;
}
.link {
  color: #528aea;
  text-decoration: underline;
}
.order-daterange {
  border: none;
  height: 30px;
}
.order-daterange #startDate {
  padding: 8px 10px 8px 32px;
  height: 30px;
  background-image: url(/teg/media/calendar.3dd3131e.svg) !important;
  background-repeat: no-repeat;
  font-size: 12.8px;
  font-family: 'SFPro-Display-Medium', sans-serif;
  background-position-x: 8px;
  background-position-y: 10px;
  border: 0.9px solid #d8dbf0;
  border-right: 0px solid white;
  border-bottom-right-radius: 0px;
  border-top-right-radius: 0px;
}
.order-daterange #endDate {
  padding: 8px 10px 8px 10px;
  background-image: none !important;
  background-repeat: no-repeat;
  font-size: 12.8px;
  font-family: 'SFPro-Display-Medium', sans-serif;
  background-position-x: 100px;
  background-position-y: 7px;
  height: 30px;
  border: 0.9px solid #d8dbf0;
  border-left: 0px solid white;
  border-bottom-left-radius: 0px;
  border-top-left-radius: 0px;
}
.order-daterange .DateRangePickerInput {
  width: max-content;
  margin-left: 0px !important;
}
.button-classic {
  height: 30px;
  font-size: 14px;
  padding: 0 20px;
  border-radius: 5px;
  margin-right: 10px;
}

.CM {
  width: max-content;
  margin-top: 10px;
  margin-left: -40px;
}
.CM .border {
  border: 1px solid #528aea;
  padding: 20px 25px;
  border-radius: 8px;
  background-color: white;
}
.CM .border .flex-item {
  display: flex;
  grid-gap: 20px;
  gap: 20px;
  align-items: center;
}
.CM .border .flex-item .upload-button {
  border: 1px solid #528aea;
  padding: 8px 25px;
  font-size: 13px;
  width: 120px;
  text-align: center;
  background-color: #528aea;
  color: white;
  border-radius: 3px;
  cursor: pointer;
}
.CM .branchdrop {
  width: 25%;
}
.CM .del-check {
  padding-top: 20px;
  display: flex;
  grid-gap: 20px;
  gap: 20px;
  align-items: center;
  margin-left: 10px;
}
.CM .table {
  width: max-content;
  margin-left: 0px;
  margin-top: -10px;
}
.CM .table th {
  border: 1px solid white;
}
.CM .table td {
  border: 1px solid white;
  border-right: 1px solid #c4d4f0;
}
.CM .table .singleDatePickerParent .SingleDatePicker {
  border: 1px solid #627FE9;
  width: 95%;
  height: 35px;
}
.CM .table .singleDatePickerParent .SingleDatePicker input {
  height: 30px;
  border: none;
  font-size: 14px;
}
.CM .table .table-tr {
  background-color: #3261b1;
  color: white;
  font-size: 13px;
}
.CM .table .table-tr .tCenter {
  padding: 10px 15px;
}
.CM .table .tr-1 {
  background-color: #d6e0ef;
}
.CM .template {
  border: 1px solid #528aea;
  display: flex;
  align-items: center;
  padding: 2px 15px 2px 8px;
  grid-gap: 0px;
  gap: 0px;
  border-radius: 5px;
  background-color: white;
  cursor: pointer;
}
.CM .template .template-text {
  color: #528aea;
}
.CM .file-holder {
  width: 300px;
  height: 36.5px;
  border-radius: 2px;
  background-color: #f9f9f9;
  border: 1px solid #cccccc;
  cursor: pointer;
  position: relative;
}
.CM .file-holder input {
  width: 300px;
  height: 35px;
  opacity: 0;
  cursor: pointer;
}
.CM .file-holder .file-choose {
  position: absolute;
  right: 9px;
  top: 9px;
  font-family: 'avenir', sans-serif;
  font-size: 12px;
  letter-spacing: 0.3px;
  cursor: pointer;
  color: #528aea;
  border-left: 1px solid black;
  padding-left: 8px;
}
.CM .file-holder .file-name {
  font-family: 'avenir', sans-serif;
  font-size: 12px;
  position: absolute;
  top: 9px;
  left: 12px;
  color: #86868a;
}
.CM .cancel-button {
  border: 1px solid #528aea;
  width: 120px;
  text-align: center;
  padding: 8px 25px;
  font-size: 13px;
  background-color: white;
  color: #528aea;
  border-radius: 3px;
  cursor: pointer;
}
.CM .input-box {
  height: 38px;
  border: 1px solid #528aea;
  padding: 10px 15px;
  width: 150px;
}
.CM .dropdown-cluster {
  width: 150px;
}
.search-filter-section {
  clear: both;
  margin-top: 10px;
}
.flex-between {
  justify-content: space-between;
}
.link {
  color: #528aea;
  text-decoration: underline;
}
.order-daterange {
  border: none;
  height: 30px;
}
.order-daterange #startDate {
  padding: 8px 10px 8px 32px;
  height: 30px;
  background-image: url(/teg/media/calendar.3dd3131e.svg) !important;
  background-repeat: no-repeat;
  font-size: 12.8px;
  font-family: 'SFPro-Display-Medium', sans-serif;
  background-position-x: 8px;
  background-position-y: 10px;
  border: 0.9px solid #d8dbf0;
  border-right: 0px solid white;
  border-bottom-right-radius: 0px;
  border-top-right-radius: 0px;
}
.order-daterange #endDate {
  padding: 8px 10px 8px 10px;
  background-image: none !important;
  background-repeat: no-repeat;
  font-size: 12.8px;
  font-family: 'SFPro-Display-Medium', sans-serif;
  background-position-x: 100px;
  background-position-y: 7px;
  height: 30px;
  border: 0.9px solid #d8dbf0;
  border-left: 0px solid white;
  border-bottom-left-radius: 0px;
  border-top-left-radius: 0px;
}
.order-daterange .DateRangePickerInput {
  width: max-content;
  margin-left: 0px !important;
}
.button-classic {
  height: 30px;
  font-size: 14px;
  padding: 0 20px;
  border-radius: 5px;
  margin-right: 10px;
}
.upload-button {
  border: 1px solid #528aea;
  padding: 8px 10px;
  text-align: center;
  font-size: 13px;
  background-color: #528aea;
  color: white;
  border-radius: 3px;
  cursor: pointer;
}
.custome-checkbox-wrap-parent-child {
  width: 15px;
  height: 15px;
  border-radius: 4px;
  border: 1px solid #cac3c3;
  display: inline-block;
  position: relative;
  cursor: pointer;
  margin: 5px;
  background-color: white;
}
.custome-checkbox-wrap-parent-child.selected {
  border: 1px solid #528aea;
}
.custome-checkbox-wrap-parent-child .inner-circle {
  width: 11px;
  height: 11px;
  border-radius: 15%;
  background: #528aea;
  position: absolute;
  top: 1px;
  left: 1px;
}
.singleDatePickerCluster .SingleDatePicker {
  border: 1px solid #627FE9;
  width: 100%;
  height: 38px;
}
.singleDatePickerCluster .SingleDatePicker input {
  height: 35px;
  border: none;
  font-size: 14px;
}

.main-container {
  width: 100%;
  background-color: #E3E9FF;
  min-height: 100vh;
}
.main-container .boarding-form {
  padding: 29px 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
}
.main-container .boarding-form .header {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.main-container .boarding-form .heading {
  display: flex;
  justify-content: space-between;
  margin-top: 70px;
  width: 100%;
}
.main-container .boarding-form .heading .header-title {
  color: Black;
  font-size: 25px;
  margin: 30px 0;
  font-weight: bolder;
}
.main-container .boarding-form .form-page-1 {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.main-container .boarding-form .form-page-1 .first-table {
  background-color: white;
  border: 1px solid black;
  border-radius: 8px;
  margin-top: 10px;
}
.main-container .boarding-form .form-page-1 .Table-header {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.main-container .boarding-form .form-page-1 .Table-header .declaration-content {
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  width: 100%;
}
.main-container .boarding-form .form-page-1 .Table-header .declaration-inside-content {
  display: flex;
  padding-left: 20px;
  width: 100%;
  align-items: center;
  padding-top: 5px;
  margin-bottom: 10px;
}
.main-container .boarding-form .form-page-1 .Table-header .declaration-inside-content .declaration-inside-content-name {
  width: 20%;
}
.main-container .boarding-form .form-page-1 .Table-header .declaration-inside-content .declaration-button {
  height: 38px;
  background-color: white;
  color: #627fe9;
  font-size: 14px;
  font-weight: bold;
  outline: none;
  cursor: pointer;
  margin-left: 20px;
  border: 1px solid #627fe9;
  align-items: center;
  display: flex;
  padding: 0 15px;
  text-decoration: none;
}
.main-container .boarding-form .form-page-1 .Table-header .table-heading {
  display: flex;
  border-bottom: 1px solid black;
  width: 100%;
  padding: 20px 10px;
}
.main-container .boarding-form .form-page-1 .Table-header .first-table-body {
  display: flex;
  width: 100%;
  padding: 10px 10px;
  flex-wrap: wrap;
}
.main-container .boarding-form .form-page-1 .Table-header .first-table-body .new-vendor-input {
  height: 35px;
  width: 100%;
  padding: 10px;
  border: 1px solid #627FE9;
}
.main-container .boarding-form .form-page-1 .Table-header .first-table-body .new-vendor-select {
  height: 35px;
  width: 100%;
}
.main-container .boarding-form .form-page-1 .Table-header .first-table-body .singleDatePicker .SingleDatePicker {
  border: 1px solid #627FE9;
  width: 100%;
  height: 35px;
}
.main-container .boarding-form .form-page-1 .Table-header .first-table-body .singleDatePicker .SingleDatePicker input {
  height: 30px;
  border: none;
  font-size: 14px;
}
.main-container .boarding-form .form-page-1 .files-link {
  color: #627fe9;
  text-decoration: none;
  margin-left: 15px;
}
.main-container .boarding-form .form-page-1 .ml-25 {
  margin-left: 25px;
}
.main-container .grey {
  color: #86868a;
}
.main-container .theme {
  color: #5289ea;
}
.main-container .black {
  color: #1a2734;
}
.main-container .headerColor {
  color: black;
  border-bottom: 1px solid #85919e;
  padding: 12px 12px;
  font-size: 20px;
}
.main-container .headerColor span {
  margin-left: 5px;
}
.main-container .onboarding-container {
  background: #fff;
  border: 1px solid #85919e;
  border-radius: 6px;
}
.main-container .onboarding-container .operational_area {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  margin-left: 15px ;
}
.main-container .onboarding-container .service-offered-container {
  display: flex;
  flex-direction: column;
  width: 20%;
  margin-top: 10px;
}
.main-container .dInBlock {
  display: inline-block;
}
.main-container .VAlign {
  vertical-align: top;
}
.main-container .mt-5 {
  margin-top: 5px;
}
.main-container .mt-10 {
  margin-top: 10px;
}
.main-container .mt-15 {
  margin-top: 15px;
}
.main-container .mt-20 {
  margin-bottom: 20px;
}
.main-container .mt-25 {
  margin-top: 25px;
}
.main-container .mt-40 {
  margin-top: 40px;
}
.main-container .mt-60 {
  margin-top: 60px;
}
.main-container .mb-5 {
  margin-bottom: 5px;
}
.main-container .mb-10 {
  margin-bottom: 10px;
}
.main-container .mb-15 {
  margin-bottom: 15px;
}
.main-container .mb-20 {
  margin-bottom: 20px;
}
.main-container .mb-25 {
  margin-bottom: 25px;
}
.main-container .mr-5 {
  margin-right: 5px;
}
.main-container .mr-10 {
  margin-right: 10px;
}
.main-container .mr-15 {
  margin-right: 15px;
}
.main-container .mr-20 {
  margin-right: 20px;
}
.main-container .mt-18 {
  margin-top: 18px;
}
.main-container .mr-25 {
  margin-right: 25px;
}
.main-container .mwt-80 {
  min-width: 80px;
}
.main-container .mwt-120 {
  min-width: 120px;
}
.main-container .pt-10 {
  padding-top: 10px;
}
.main-container .wt-100 {
  width: 100px;
}
.main-container .wt-120 {
  width: 120px;
}
.main-container .wt-150 {
  width: 150px;
}
.main-container .wt-160 {
  width: 160px;
}
.main-container .ht-150 {
  height: 150px;
}
.main-container .ht-50 {
  height: 50px;
}
.main-container .ht-65 {
  height: 65px;
}
.main-container .ht-90 {
  height: 90px;
}
.main-container .wt-200 {
  width: 200px;
}
.main-container .wt-250 {
  width: 250px;
}
.main-container .wt-10p {
  width: 10%;
}
.main-container .wt-12p {
  width: 15%;
}
.main-container .wt-18p {
  width: 18%;
}
.main-container .wt-20p {
  width: 20%;
}
.main-container .wt-25p {
  width: 25%;
}
.main-container .wt-50p {
  width: 50%;
}
.main-container .wt-60p {
  width: 60%;
}
.main-container .wt-75p {
  width: 75%;
}
.main-container .wt-100p {
  width: 100%;
}
.main-container .mwt-170 {
  width: 170px;
}
.main-container .fRight {
  float: right;
}
.main-container .tCenter {
  text-align: center;
}
.main-container .tLeft {
  text-align: left;
}
.main-container .pointer {
  cursor: pointer;
}
.main-container .fs-13 {
  font-size: 13px;
}
.main-container .fs-15 {
  font-size: 15px;
}
.main-container .marginAuto {
  margin: auto;
}
.main-container .noBorder {
  border: none;
}
.main-container .fTop {
  vertical-align: top;
}
.main-container .checkbox-flex {
  display: flex;
  align-items: center;
}
.main-container .fMiddle {
  vertical-align: middle;
}
.main-container .area-name {
  font-size: 17px;
  color: #7d7d7d;
}
.main-container .borderLight {
  border: solid 1px #dadada;
}
.main-container .bg-light {
  background-color: #d5e2f9;
}
.main-container .table-container {
  display: flex;
}
.main-container .table-container:first-child {
  margin-left: 20px;
}
.main-container .operational-area-text {
  color: #000;
  font-weight: 800;
  font-size: 16px;
}
.main-container .vendor-form-input {
  width: 200px;
  padding: 4px 10px;
  border: none;
  border-bottom: 1px solid grey;
  font-size: 14px;
  margin-bottom: 10px;
}
.main-container .counter-ip {
  padding: 3px;
  margin-right: 5px;
}
.main-container .success {
  color: #0c8434;
}
.main-container .error {
  color: red;
}
.main-container .vendor-name {
  color: gray;
  font-size: 10px;
  margin-bottom: 5px;
}
.main-container .history-icon {
  height: 15px;
  width: 15px;
  margin: 0 8px;
  cursor: pointer;
  display: inline-block;
  background-size: contain;
  background-repeat: no-repeat;
}
.main-container .pr {
  position: relative;
}
.main-container .history-block {
  width: 350px;
  max-height: 300px;
  overflow: scroll;
  position: absolute;
  padding: 15px;
  top: 16px;
  z-index: 2;
  background: #efeded;
  box-shadow: 0px 10px 20px 0px rgba(158, 170, 181, 0.45);
}
.main-container .scroll-table {
  height: 500px;
  overflow: scroll;
}
.main-container .table-border-none {
  border: none;
}
.main-container .qualification-table {
  border: none;
  border-bottom: 1px solid #868e96;
}
.main-container .tr-sanity {
  padding-left: 25px;
}
.main-container .tr-sanity-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 10px ;
  margin: 10px;
  border-bottom: 1px solid #868e96;
}
.main-container .upload-button {
  float: right;
}
.main-container .disabled-upload {
  height: 40px;
  padding: 0 30px;
  border-radius: 5px;
  background-color: #8089a9;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  outline: none;
  cursor: none;
  border: 1px solid #fff;
}
.main-container .button-classic-doc {
  height: 38px;
  padding: 0 15px;
  background-color: white;
  color: #627fe9;
  font-size: 16px;
  font-weight: bold;
  outline: none;
  cursor: pointer;
  border: 1px solid #627fe9;
}
.ml-20 {
  margin-left: 20px;
}
.vendor-comment-input {
  width: 1000px;
  padding: 4px 10px;
  border: none;
  font-size: 14px;
  outline: none;
}
.wt-30p {
  width: 30%;
}
.wt-25p {
  width: 25%;
}
.wt-45p {
  width: 45%;
}
.disabled-download {
  height: 30px;
  background-color: white;
  color: #b6ccf1;
  font-size: 14px;
  font-weight: bold;
  outline: none;
  cursor: none;
  border: 1px solid #b6ccf1;
}

.wholeNetWorkMaster {
  margin-left: 70px;
  background-color: #E3E9FF;
  display: flex;
  flex-direction: column;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
}
.wholeNetWorkMaster .heading {
  display: flex;
  flex-direction: column;
  grid-column-gap: 7;
  column-gap: 7;
}
.wholeNetWorkMaster .heading .mainHeading {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 32px;
  color: #000000;
}
.wholeNetWorkMaster .heading .col2 {
  display: flex;
  flex-direction: row !important;
  grid-row-gap: 3;
  row-gap: 3;
  margin-top: 20px;
}
.wholeNetWorkMaster .heading .col2 .boxsearch {
  background-color: white;
  border-radius: 4px;
  width: 350px;
  display: flex;
  box-shadow: 0px 0px 8px rgba(82, 138, 234, 0.45);
  border: 3px solid #7fabdb;
}
.wholeNetWorkMaster .heading .col2 .newsearch {
  width: 290px;
  height: 40px;
  background: #FFFFFF;
  border: none;
  text-align: center;
  background-image: url(/teg/media/Search.00aa1fd7.svg);
  background-repeat: no-repeat;
  background-position-y: 50%;
  background-position-x: 10px;
}
.wholeNetWorkMaster .heading .col2 .newsearch:focus {
  border: none;
  outline-offset: 0px;
  outline: none !important;
}
.wholeNetWorkMaster .heading .col2 #xinsearch {
  margin-top: 2px;
  z-index: 10;
}
.wholeNetWorkMaster .heading .col2 #xinsearch#xinsearch:hover {
  cursor: pointer;
}
.wholeNetWorkMaster .heading .col2 .uploadTemplate {
  width: 180px;
  height: 40px;
  text-align: left;
  position: relative;
}
.wholeNetWorkMaster .heading .col2 .uploadTemplate .download-icon {
  position: absolute;
  width: 18px;
  top: 5px;
}
.wholeNetWorkMaster .heading .col2 .templatebutton {
  width: 208px;
  height: 40px;
  text-align: left;
  margin-left: 10px;
  position: relative;
}
.wholeNetWorkMaster .heading .col2 .templatebutton .download-icon {
  position: absolute;
  top: 2px;
}
.wholeNetWorkMaster .heading .col2 .downloadData {
  width: 140px;
  height: 40px;
}
.wholeNetWorkMaster .heading .col2 .addButton {
  box-sizing: border-box;
  background: #627FE9;
  color: #FFFFFF;
  width: 163px;
  height: 36px;
  border: 1px solid #627FE9;
  border-radius: 5px;
}
.wholeNetWorkMaster .heading .col2 .addButton:hover {
  cursor: pointer;
}
.wholeNetWorkMaster .bodytable {
  margin-top: 10px;
  min-width: 1020px;
  height: 450px;
  overflow: scroll;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
}
.wholeNetWorkMaster .bodytable .alternaterow:nth-child(odd) {
  background: #EFF2FD;
}
.wholeNetWorkMaster .bodytable .alternaterow:nth-child(even) {
  background: #FFFFFF;
}
.wholeNetWorkMaster .bodytable .completeTable {
  border-spacing: 0;
  border-collapse: collapse;
  border: none;
}
.wholeNetWorkMaster .bodytable .completeTable .tableheader {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.32), rgba(0, 0, 0, 0.32)), #627FE9;
  color: #FFFFFF;
  border: none;
}
.wholeNetWorkMaster .bodytable .completeTable .tableCell {
  text-align: center;
  border: none;
  border-left: 1px solid white;
  min-width: 150px;
  padding: 10px;
}
.wholeNetWorkMaster .bodytable .completeTable .divTableBody {
  min-width: 100px;
  border: none;
}
.wholeNetWorkMaster .bodytable .completeTable .divTableBody .divTableCell {
  border: none;
  text-align: center;
  padding: 10px;
  border-right: 1px solid #528AEAB2;
}
.wholeNetWorkMaster .bodytable .completeTable .divTableRow {
  border: none;
}
.wholeNetWorkMaster .bodytable .action-icon {
  background-repeat: no-repeat;
  height: 18px;
  width: 18px;
  border: none;
  background-size: contain;
  cursor: pointer;
  display: inline-block;
  padding: 5px;
  margin: 5px;
}
.wholeNetWorkMaster .popupBody {
  width: 1332px;
  height: 396px;
  background-color: #E3E9FF;
  overflow: hidden;
}
.wholeNetWorkMaster .popupBody .popupHeading {
  width: 284px;
  height: 32px;
  margin: 15px 20px 0px 10px;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 32px;
  color: #0A0D17;
}
.wholeNetWorkMaster .popupBody .alldropdown {
  box-sizing: border-box;
  position: absolute;
  width: 1000px;
  height: 255px;
  left: 40px;
  top: 77px;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 5px;
  padding: 15px;
  background: #FFFFFF;
  border: 1px solid #85919E;
  border-radius: 5px;
}
.wholeNetWorkMaster .popupBody input {
  border: 1px solid #ccc;
  padding: 8px;
  color: #333;
  border-radius: 4px;
}
.wholeNetWorkMaster input:focus {
  border: 3px solid #7fabdb;
  outline-offset: 0px;
  outline: none !important;
}
.wholeNetWorkMaster .buttonstab {
  position: absolute;
  top: 330px;
  left: 650px;
}
.wholeNetWorkMaster .cta-action-btns {
  width: 210px;
}
.wholeNetWorkMaster .element {
  display: inline;
}

/* SAPVendorForm.less */
input {
  height: 35px;
  border: 1.5px solid #627fe9;
  border-radius: 5px;
}
.heading {
  margin-top: 40px;
  font-size: 24px;
  font-weight: 500;
  margin-left: 40px;
}
.button {
  padding: 30px;
  display: flex;
  justify-content: flex-end;
}
.button-classic-doc {
  height: 38px;
  padding: 0 15px;
  background-color: white;
  color: #627fe9;
  font-size: 16px;
  font-weight: bold;
  outline: none;
  cursor: pointer;
  border: 1px solid #627fe9;
}
#btn {
  height: 28px;
  padding: 8 15px;
  background-color: #627fe9;
  width: 110px;
  color: #FFFF;
  font-size: 16px;
  font-weight: bold;
  outline: none;
  cursor: pointer;
  border: 1px solid #627fe9;
}
#btn:disabled {
  background-color: gainsboro !important;
}
#btn1 {
  width: 110px;
}
#btn1:disabled {
  background-color: gainsboro !important;
}
#btn2 {
  width: 110px;
}
#btn2:disabled {
  background-color: gainsboro !important;
}
.form-container {
  background-color: #e3e9ff;
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  padding-right: 38px;
  /* Add any other styles you want for the form container */
}
.form-container .container {
  background-color: white;
  border: 1px solid grey;
  border-radius: 10px;
  height: -moz-fit-content;
  height: fit-content;
  width: 100%;
  margin-top: 30px;
  margin-left: 15px;
}
.form-container .container .container-heading {
  font-size: 20px;
  font-weight: 500;
  line-height: 22px;
  margin-left: 20px;
  margin-top: 15px;
  margin-bottom: 15px;
}
.form-container .container .form-section {
  display: flex;
  margin-bottom: 20px;
  /* Add some spacing between form sections */
}
.form-container .container .form-section .form-item {
  display: grid;
  margin-bottom: 5px;
  margin-left: 20px;
  /* Add some spacing between form items */
}
.form-container .container .form-section .form-item .form-item label {
  width: 150px;
  /* Set the width of the labels (adjust as needed) */
  margin-right: 10px;
  /* Add some spacing between labels and inputs */
}
.form-container .GST-div {
  background-color: white;
  border: 1px solid grey;
  border-radius: 10px;
  width: 100%;
  height: -moz-fit-content;
  height: fit-content;
  padding-bottom: 13px;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 30px;
  margin-left: 13px;
}
.form-container .GST-div .GST-div-hr {
  margin-top: 20px;
}
.form-container .GST-div .GST-div-top {
  display: flex;
}
.form-container .GST-div .GST-div-top .GST-div-heading {
  font-size: 20px;
  font-weight: 500;
  line-height: 22px;
  margin-left: 20px;
  margin-top: 20px;
  margin-bottom: 15px;
  margin-right: 20px;
}
.form-container .GST-div .GST-div-top .GST-div-inp {
  margin-top: 15px;
}
.form-container .GST-div .GST-div-bot .GST-table {
  width: 1148px;
}
.form-container .GST-div .GST-div-bot .GST-table th {
  background-color: #e3e9ff;
}
.form-container .GST-div .GST-div-bot .GST-table td,
.form-container .GST-div .GST-div-bot .GST-table th {
  text-align: center;
}
.form-container .SAPcontainer {
  background-color: white;
  border: 1px solid grey;
  border-radius: 10px;
  height: 230px;
  width: 100%;
  margin-top: 30px;
  margin-left: 13px;
}
.form-container .SAPcontainer .container-heading {
  font-size: 20px;
  font-weight: 500;
  line-height: 22px;
  margin-left: 20px;
  margin-top: 15px;
  margin-bottom: 15px;
}
.form-container .SAPcontainer .form-section {
  display: flex;
  margin-bottom: 20px;
  /* Add some spacing between form sections */
  margin-left: 20px;
}
.form-container .SAPcontainer .form-section .form-item {
  display: grid;
  margin-bottom: 5px;
  margin-left: 30px;
  /* Add some spacing between form items */
}
.form-container .SAPcontainer .form-section .form-item .form-item label {
  width: 150px;
  /* Set the width of the labels (adjust as needed) */
  margin-right: 10px;
  /* Add some spacing between labels and inputs */
}
#company_name {
  width: 490px;
}
#email {
  width: 320px;
}
#contact_number {
  width: 130px;
}
#pan_number {
  width: 120px;
}
#address {
  width: 360px;
}
#state {
  width: 188px;
}
#city {
  width: 188px;
}
#pincode {
  width: 109px;
}
#gst_number {
  width: 433px;
}
#bank_name {
  width: 330px;
}
#bank_address {
  width: 440px;
}
#account_number {
  width: 157px;
}
#ifsc_code {
  width: 144px;
}
#account_group {
  width: 170px;
}
#central_blocking {
  width: 142px;
}
#company_code {
  width: 129px;
}
#recon_account {
  width: 254px;
}
#payment_method {
  width: 254px;
}
#purchase_org {
  width: 215px;
}
#order_currency {
  width: 254px;
}
#payment_terms {
  width: 254px;
}
#inco_terms {
  width: 254px;
}
.labelname {
  padding: 4px;
}
.common {
  padding: 10px;
}
#Acctlabel {
  margin-bottom: 15px;
}
.Acct {
  margin-top: 0px;
}
.regional-details-vendor-select {
  height: 35px;
  width: 200px;
  margin-top: 2px;
}
.dropdownT1 {
  margin-left: 20px;
}
.GST-table th:nth-child(2) {
  width: 35%;
}
.GST-table th:nth-child(3) {
  width: 14%;
}
.GST-table th:nth-child(4) {
  width: 10%;
}
#ContactheadOffice {
  margin-left: 30px;
}
#addressheadOffice {
  margin-left: 40px;
  margin-bottom: 10px;
}
#CityheadOffice {
  margin-left: 40px;
}
/* Add any other styles you want for form items and labels */

.navBarT1 > .header-panel {
  width: 100%;
}

.auditDateWrapper {
  display: flex;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
  border-radius: 2px;
  border: 1px solid #627FE9;
  background: #FFF;
  padding: 2px 5px;
}
.auditDateWrapper td {
  border: 1px solid #e4e7e7;
}
.auditDateWrapper .SingleDatePickerInput {
  border: 0px;
}
.auditDateWrapper .DateInput_1 {
  width: 110px;
}
.auditDateWrapper #auditLogToDate,
.auditDateWrapper #auditLogFromDate {
  max-height: 31px;
  font-size: 16px;
}
.auditDateWrapper #auditLogToDate::placeholder,
.auditDateWrapper #auditLogFromDate::placeholder {
  font-size: 14px;
}

.audit-text-wrap {
  word-wrap: break-word;
}
.pageWrapperT121 {
  padding: 24px;
  position: relative;
  background-color: #E3E9FF;
  height: calc(100vh - 80px);
  overflow-y: auto;
}

.br253 {
  border: 3px solid red;
}

.table-container121 {
  margin: 20px;
}

.enlarging-table121 {
  width: 100%;
  border-collapse: collapse;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.blue-header121 {
  background-color: #44569e;
  color: white;
  padding: 15px;
  text-align: left;
}

.enlarging-table121 tbody tr {
  background-color: #fff;
}

.enlarging-table121 th {
  padding: 15px;
  text-align: left;
}

.enlarging-table121 td {
  padding: 15px;
  text-align: left;
}

.enlarging-table121 tbody tr:hover {
  background-color: #f0f0f0;
  cursor: default;
}

.action-icon121 {
  background-repeat: no-repeat;
  height: 18px;
  width: 18px;
  background-size: contain;
  cursor: pointer;
  display: inline-block;
  padding: 5px;
  margin: 5px;
}

.popup-container121 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
  z-index: 999;
}

.popup121 {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  width: 500px;
  max-height: 90%;
  max-width: 90%;
  position: relative;
  overflow: auto;
}

.popup121::-webkit-scrollbar {
  width: 5px; /* Adjust the width as needed */
}

.popup121::-webkit-scrollbar-thumb {
  background-color: white; /* Adjust the color as needed */
}

.popup121::-webkit-scrollbar-track {
  background-color: transparent;
}

.header121 {
  padding: 10px;
  text-align: center;
  background: white;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.header121 h2 {
  margin: 0;
}

.content121 {
  display: flex;
  flex-direction: column;
  padding: 20px;
  overflow: hidden;
}

.form-group121 {
  margin-bottom: 20px;
}

.label121 {
  display: block;
  margin-bottom: 5px;
}

.textarea121 {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: none;
}

.date-picker121 {
  display: inline-block;
  margin-top: 10px;
}

.footer121 {
  padding: 10px;
  text-align: right;
  background: white;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.footer121 button {
  margin-left: 10px;
}

.checkbox-group121 {
  display: flex;
  flex-wrap: wrap;
}

.checkbox-group121 > div {
  margin-right: 20px; /* Adjust margin between checkboxes */
}

.checkbox-group121 input[type=checkbox] {
  margin-right: 5px; /* Adjust margin between checkbox and label */
}

.checkbox-group121 label {
  margin-bottom: 5px; /* Adjust margin between checkbox and label */
}

.banner {
  background-color: orange;
  padding: 5px;
}
.rate-masters .rates-table {
  width: 975px;
  overflow: scroll;
}
.rate-masters .wrapper {
  width: 1280px;
  margin: 0 auto;
}
.rate-masters .wrapper .home-title {
  font-size: 12px;
  color: #86868a;
}
.rate-masters .wrapper .sub-heading-section {
  margin-top: 5px;
  font-family: avenir-medium;
  font-weight: 900;
  overflow: auto;
}
.rate-masters .wrapper .sub-heading-section .sub-heading {
  font-size: 18px;
  color: #282626;
  float: left;
  width: 100%;
}
.rate-masters .wrapper .sub-heading-section .add-company-section {
  float: right;
  margin-right: 30px;
}
.rate-masters .wrapper .cta-action-btns {
  display: flex;
}
.rate-masters .wrapper .cta-action-btns .button-classic {
  height: 30px;
  font-size: 14px;
  padding: 0 20px;
  border-radius: 5px;
  margin-top: 10px;
  margin-right: 10px;
}
.rate-masters .wrapper .cta-action-btns.cta-btn-right {
  float: right;
}
.rate-masters .wrapper .cta-action-btns .secondary {
  background: #5289ea;
  color: white;
}
.rate-masters .wrapper .company-list-wrap {
  margin-top: 10px;
}
.rate-masters .wrapper .company-list-wrap .no-records-section {
  margin-top: 40px;
}
.rate-masters .wrapper .company-list-wrap .list-section {
  border-bottom: 1px solid rgba(212, 212, 212, 0.5);
  padding-bottom: 25px;
  padding-top: 15px;
}
.rate-masters .wrapper .company-list-wrap .list-section .checkbox-section {
  display: inline-block;
  vertical-align: middle;
}
.rate-masters .wrapper .company-list-wrap .list-section .sub-header-inline {
  display: inline-block;
  margin-right: 6px;
  width: 75px;
}
.rate-masters .wrapper .company-list-wrap .list-section .table-header {
  margin: 10px;
  display: inline-block;
  word-break: break-word;
  text-transform: uppercase;
  font-size: 14px;
  text-align: center;
}
.rate-masters .wrapper .company-list-wrap .list-section .table-header.select-row {
  width: 4%;
}
.rate-masters .wrapper .company-list-wrap .list-section .table-header.rules-tab {
  width: 18%;
}
.rate-masters .wrapper .company-list-wrap .list-section .company-name-section {
  display: inline-block;
  vertical-align: middle;
  margin-left: 10px;
  width: 370px;
}
.rate-masters .wrapper .company-list-wrap .list-section .company-name-section.table-header {
  margin-left: 85px;
  width: 290px;
}
.rate-masters .wrapper .company-list-wrap .list-section .company-name-section .comany-logo,
.rate-masters .wrapper .company-list-wrap .list-section .company-name-section .compnay-details {
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
}
.rate-masters .wrapper .company-list-wrap .list-section .company-name-section .comany-logo {
  width: 50px;
  height: 35px;
  background-size: contain;
  background-repeat: no-repeat;
}
.rate-masters .wrapper .company-list-wrap .list-section .company-name-section .compnay-details {
  margin-left: 25px;
  color: #1a2734;
  font-size: 15px;
  font-weight: 900;
  width: 220px;
}
.rate-masters .wrapper .company-list-wrap .list-section .company-name-section .compnay-details .date {
  color: #86868a;
  font-size: 12px;
}
.rate-masters .wrapper .company-list-wrap .list-section .company-name-section .compnay-details .name {
  word-break: break-word;
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}
.rate-masters .wrapper .company-list-wrap .list-section.header {
  font-weight: 600;
  padding-top: 0;
  color: #1a2734;
  font-size: 14px;
}
.rate-masters .wrapper .company-list-wrap .list-section.content:hover {
  border: 1px solid rgba(12, 12, 12, 0.38);
  box-shadow: 0px 10px 20px 0px rgba(158, 170, 181, 0.45);
}
.rate-masters .wrapper .company-list-wrap .indicate-icon {
  background-repeat: no-repeat;
  height: 15px;
  width: 15px;
  background-size: contain;
  margin: 0 5px;
  cursor: pointer;
  display: inline-block;
}
.rate-masters .wrapper .company-list-wrap .divTable {
  margin-top: 20px;
  margin-bottom: 20px;
  display: table;
  width: 100%;
}
.rate-masters .wrapper .company-list-wrap .divTableRow {
  display: table-row;
}
.rate-masters .wrapper .company-list-wrap .divTableCell,
.rate-masters .wrapper .company-list-wrap .divTableHead {
  /*border: 1px solid #999999;*/
  display: table-cell;
  padding: 10px 10px;
  font-size: 12px;
}
.rate-masters .wrapper .company-list-wrap .divTableCell.bordered,
.rate-masters .wrapper .company-list-wrap .divTableHead.bordered {
  border-bottom: 1px solid #999999;
}
.rate-masters .wrapper .company-list-wrap .divTableHeading {
  background-color: #528aea;
  display: table-header-group;
  font-weight: bold;
}
.rate-masters .wrapper .company-list-wrap .divTableFoot {
  background-color: #EEE;
  display: table-footer-group;
  font-weight: bold;
}
.rate-masters .wrapper .company-list-wrap .divTableBody {
  display: table-row-group;
  margin-top: 10px;
}
.rate-masters .wrapper .action-row {
  display: inline-flex;
  width: 100%;
}
.rate-masters .wrapper .details-block {
  border: 1px solid #b9b9b9;
  border-radius: 2px;
  padding: 10px;
  padding-top: 8px;
  padding-bottom: 4px;
  margin-right: 0;
  margin-left: auto;
  /* width: 415px; */
}
.rate-masters .wrapper .zone-wise {
  margin-top: 5px;
  margin-bottom: 5px;
}
.rate-masters .wrapper .zone-wise-label {
  border-radius: 2px;
  padding: 5px;
  margin-right: 10px;
  font-size: 14px;
}
.rate-masters .wrapper .zone-wise-value {
  border-radius: 2px;
  padding: 5px;
  font-size: 18px;
}
.rate-masters .wrapper .pan-average-label {
  border-radius: 2px;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 2px;
  padding-bottom: 2px;
  font-size: 14px;
}
.rate-masters .wrapper .pan-average-value {
  border-radius: 2px;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 2px;
  padding-bottom: 2px;
  font-size: 18px;
}
.rate-masters .wrapper .zone-average {
  margin-left: 5px;
  font-size: 18px;
  color: #5289ea;
}
.rate-masters .wrapper .m-b-5 {
  margin-bottom: 5px;
}
.rate-masters .wrapper .value-highlight {
  background: #5289ea;
  color: white;
}
.rate-masters .input-text {
  padding: 5px;
  border: 1px solid #cccccc;
  height: 35px;
  border-radius: 4px;
  font-size: 14px;
  width: 100%;
}
.rate-masters .m-t-5 {
  margin-top: 5px;
}
.rate-masters .m-l-5 {
  margin-left: 5px;
}
.rate-masters .action-icon {
  background-repeat: no-repeat;
  height: 18px;
  width: 18px;
  background-size: contain;
  margin: auto !important;
  cursor: pointer;
  display: inline-block;
  padding: 5px;
}
.rate-masters .lanes-link {
  color: #5289ea;
  cursor: pointer;
}
.error {
  color: red;
}
.m-t-10 {
  margin-top: 10px;
}

.add-user-wrap .overlay {
  position: fixed;
  z-index: 10;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(27, 33, 67, 0.9);
}
.add-user-wrap .modal-popup {
  max-height: 300px;
  overflow: scroll;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 5px;
  width: 1500px;
  min-height: 500px;
  background-color: white;
  z-index: 11;
}
.add-user-wrap .modal-popup .pop-heading {
  background-color: #f4f4f4;
  padding: 18px;
  font-family: avenir;
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #528aea;
  text-align: center;
  margin-bottom: 30px;
}
.add-user-wrap .modal-popup .content {
  padding: 20px 0px 20px 20px;
  padding-top: 0px;
}
.add-user-wrap .modal-popup .content .select-container {
  display: flex;
  margin: 20px 0px;
}
.add-user-wrap .modal-popup .content .select-container .select-holder {
  width: 277px;
  position: relative;
}
.add-user-wrap .modal-popup .content .select-container .select-holder .sub-head {
  font-size: 10px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #868e96;
  margin-bottom: 7px;
}
.add-user-wrap .modal-popup .content .select-container .action-holder {
  display: flex;
  flex-wrap: wrap;
  width: 330px;
  margin: 15px;
  position: relative;
}
.add-user-wrap .modal-popup .content .select-container .action-holder .actions {
  margin: 5px;
}
.add-user-wrap .modal-popup .content .select-container .action-holder .actions .add-button {
  margin: 0;
  height: 35px;
  padding: 0 15px;
  font-size: 20px;
  margin-left: 10px;
}
.add-user-wrap .modal-popup .content .select-container .action-holder .actions .select {
  width: 235px;
}
.add-user-wrap .modal-popup .content .select-container .action-holder .add-button {
  margin: 5px 5px;
  padding: 10px 20px;
}
.add-user-wrap .modal-popup .content .select-container .action-holder .sub-head {
  position: absolute;
  font-size: 10px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #868e96;
  top: -10px;
  left: 7px;
}
.add-user-wrap .modal-popup .content .usn-container {
  margin: 17px 0px;
  display: flex;
}
.add-user-wrap .modal-popup .content .usn-container .add-button {
  margin: 20px;
  border-radius: 5px;
}
.add-user-wrap .modal-popup .content .usn-container .btn-disabled {
  background-color: #cac2c2;
  border: 1px solid #cac2c2;
  pointer-events: none;
  color: white;
}
.add-user-wrap .modal-popup .content .submit-section {
  display: flex;
  justify-content: flex-end;
}
.add-user-wrap .modal-popup .content .submit-section .add-button {
  border-radius: 5px;
  width: 145px;
}
.add-user-wrap .modal-popup .content .submit-section .discard {
  background: white;
  color: #528aea;
  border: 1px solid #528aea;
}
.add-user-wrap .modal-popup .content .submit-section.business-button button.add-button {
  margin: 0;
  color: black;
  padding: 10px 20px;
  background: #fd8989;
  border-radius: 20px;
  border-color: #ff000052;
}
.add-user-wrap .modal-popup .content .submit-section.business-button button.add-button.discard {
  background: #fff;
}
.add-user-wrap .subheading {
  margin: 5px;
  font-weight: bold;
  font-size: 14px;
  padding-left: 20px;
  padding-right: 30px;
}
.add-user-wrap .heading {
  margin: 20px;
  font-weight: bold;
  font-size: 24px;
}
.add-user-wrap .close-button {
  margin-right: 0;
  margin-left: auto;
  cursor: pointer;
  height: 30px;
  font-size: 14px;
  padding: 0 20px;
  border-radius: 2px;
  color: #fff;
  background: #528aea;
  padding: 6px 13px;
  display: inline-block;
  border: 1px solid #528aea;
}
.add-user-wrap .close-button-disabled {
  margin-right: 20px;
  margin-left: auto;
  cursor: not-allowed;
  height: 30px;
  font-size: 14px;
  padding: 0 20px;
  border-radius: 2px;
  display: inline-block;
  color: #b0b0b0;
  /* Light gray color for the text */
  background: #e0e0e0;
  /* Light gray background */
  border: 1px solid #d0d0d0;
  /* Lighter border color */
  opacity: 0.6;
  /* Reduced opacity for a muted look */
}
.vehicleAttendenceHeaderDate {
  margin-left: 20px;
}
.vehicleAttendenceHeaderDate .dateInput {
  width: 20%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 2px;
  border: 1px solid #528aea;
  background: #FFF;
  width: 162px;
  height: 36px;
  flex-shrink: 0;
  padding: 5px;
}

.login-as-container {
  background: linear-gradient(87deg, #597EF7 15.86%, #C4BEFE 124.08%);
  background-size: cover;
  background-repeat: no-repeat;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
  color: #30343C;
  overflow: hidden;
}
.login-as-container * {
  font-family: Inter;
  color: #30343C;
}
.login-as-container .bg-design-wrapper {
  position: relative;
  z-index: 1;
}
.login-as-container .bg-design-wrapper .design01 {
  position: absolute;
  top: 0px;
  left: 0px;
  transform: translate(-30%, -45%);
  z-index: -1;
}
.login-as-container .bg-design-wrapper .design02 {
  position: absolute;
  bottom: 0px;
  right: 0px;
  transform: translate(50%, 50%);
  z-index: -1;
}
.login-as-container .card-wrapper {
  background-color: #fff;
  max-width: 410px;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.12);
}
.login-as-container .card-wrapper .card-title {
  text-align: center;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 33px;
  color: #30343C;
  margin-bottom: 24px;
}
.login-as-container .card-wrapper .form-wrapper {
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  gap: 10px;
}
.login-as-container .card-wrapper .form-wrapper .btn-group {
  display: flex;
  border-radius: 5px;
  border: 1px solid #597EF7;
  background: #FFF;
  margin-bottom: 8px;
  align-items: stretch;
}
.login-as-container .card-wrapper .form-wrapper .btn-group button {
  all: unset;
  color: #597EF7;
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  flex: 1 1;
  padding: 10px 12px;
}
.login-as-container .card-wrapper .form-wrapper .btn-group button:hover, .login-as-container .card-wrapper .form-wrapper .btn-group button:focus {
  cursor: pointer;
  background-color: rgba(89, 126, 247, 0.0980392157);
}
.login-as-container .card-wrapper .form-wrapper .btn-group button:first-of-type {
  border-radius: 4px 0px 0px 4px;
}
.login-as-container .card-wrapper .form-wrapper .btn-group button:last-of-type {
  border-radius: 0px 4px 4px 0px;
}
.login-as-container .card-wrapper .form-wrapper .btn-group button.active {
  background-color: #597EF7;
  color: #FFF;
}
.login-as-container .card-wrapper .form-wrapper .btn-group button.active:hover,
.login-as-container .card-wrapper .form-wrapper .btn-group button.active :focus {
  cursor: pointer;
  background-color: rgba(89, 126, 247, 0.831372549);
}
.login-as-container .card-wrapper .form-wrapper .label {
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  color: #30343C;
  display: inline-block;
  margin-bottom: 2px;
  display: inline-block;
}
.login-as-container .card-wrapper .form-wrapper .chip-btn-group-wrapper {
  display: flex;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
  flex-wrap: wrap;
}
.login-as-container .card-wrapper .form-wrapper .chip-btn-group-wrapper .chip-btn {
  all: unset;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  border-radius: 20px;
  background: rgba(89, 126, 247, 0.12);
  padding: 2px 8px;
  display: flex;
  align-items: center;
  grid-gap: 4px;
  gap: 4px;
  border: 1px solid transparent;
}
.login-as-container .card-wrapper .form-wrapper .chip-btn-group-wrapper .chip-btn:focus {
  box-shadow: 0 0 0 1px #597EF7;
}
.login-as-container .card-wrapper .form-wrapper .chip-btn-group-wrapper .chip-btn:hover {
  border: 1px solid #597EF7;
}
.login-as-container .card-wrapper .form-wrapper .chip-btn-group-wrapper:hover {
  cursor: pointer;
}
.login-as-container .card-wrapper .form-wrapper .chip-btn-group-wrapper .active {
  background-color: #597EF7;
  color: #FFF;
}
.login-as-container .card-wrapper .form-wrapper .remarks {
  width: 100%;
  padding: 6px 12px;
  border-radius: 4px;
  border: 1px solid var(--Textfield-Border-Colour, #DAE0F5);
  background: #FFF;
  resize: none;
  outline: none;
}
.login-as-container .card-wrapper .form-wrapper .remarks:hover {
  border: 1px solid #597EF7;
}
.login-as-container .card-wrapper .form-wrapper .remarks:focus {
  box-shadow: 0 0 0 1px #597EF7;
}
.login-as-container .card-wrapper .form-wrapper .remarks::placeholder {
  color: "#83858A";
  font-size: "14px";
}
.login-as-container .card-wrapper .form-wrapper .form-submit-btn {
  background-color: #597EF7;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
  color: #fff;
  border: 0px;
  padding: 8.5px 20px;
  border-radius: 4px;
}
.login-as-container .card-wrapper .form-wrapper .form-submit-btn:not(:disabled):hover {
  cursor: pointer;
  box-shadow: 0px 0px 2px #0d44f6;
}
.login-as-container .card-wrapper .form-wrapper .form-submit-btn:disabled {
  cursor: not-allowed;
  opacity: 0.6;
}
.login-as-container .card-wrapper .form-wrapper .form-submit-btn:not(:disabled):active {
  outline: 1px solid #597EF7;
}
.loginFormMain {
  padding: 0;
  margin: 0 auto;
  box-sizing: border-box;
  height: 100vh;
  background: linear-gradient(87deg, #597EF7 15.86%, #C4BEFE 124.08%);
  position: relative;
}

.forceLogin {
  display: flex;
  justify-content: center;
  bottom: 200px;
  background-image: linear-gradient(to right, #597ef7, #c4befe);
}

.forceLoginContent {
  width: 1000px;
  color: #FFFFFF;
}
.loginFormCardMain {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.loginFormCard {
  width: 396px;
  z-index: 2;
  background-color: #ffffff;
  border-radius: 10px;
  flex-shrink: 0;
  box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.12);
  margin: 0 auto;
}

.loginFormLogo {
  display: flex;
  justify-content: center;
}

.loginFormLogoImage {
  margin-top: 20px;
  width: 312px;
  margin-bottom: 30px;
}

.companyTypeButton {
  width: 312px;
  margin-left: 42px;
  align-items: center;
}

.tabButtonStyle {
  height: 36px;
}

.loginFormEmailMain {
  margin-left: 42px;
  margin-top: 15px;
  width: 313px;
}

.loginFormEmailLable {
  font-family: "Inter";
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  color: #30343c;
}

.loginFormEmailInput {
  height: 32px;
  width: 90%;
  background: #fff;
  margin-left: 5px;
  border: none;
  outline: none;
}

.loginFormPasswordMain {
  margin-left: 42px;
  margin-top: 15px;
  width: 313px;
}

.loginFormPasswordLable {
  font-family: "Inter";
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  color: #30343c;
}

.loginFormPasswordInput {
  width: 90%;
  height: 32px;
  background: #fff;
  margin-left: 5px;
  border: none;
  outline: none;
}

.loginFormPasswordIcon {
  width: 16px;
  height: 16px;
  flex-shrink: 0;
  margin: 10px;
  color: #47484c;
  cursor: pointer;
}

.loginFormPasswordBox {
  width: 312px;
  height: 36px;
  flex-shrink: 0;
  border-radius: 4px;
  border: 1px solid #dae0f5;
  display: flex;
  justify-content: space-between;
}

.loginFormEmailBox {
  width: 312px;
  height: 36px;
  flex-shrink: 0;
  border-radius: 4px;
  border: 1px solid #dae0f5;
}

.forgotPasswordLable {
  font-family: "Inter";
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  color: #597ef7;
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
}

.forgotPasswordLable:hover {
  font-family: "Inter";
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  color: #000000;
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
}

.forgotPasswordLableBox {
  margin-left: 42px;
  margin-top: 15px;
  width: 313px;
  height: 18px;
}

.loginButton {
  display: flex;
  width: 312px;
  height: 40px;
  padding: 9px 0px 10px 0px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: #597ef7;
  border: none;
  color: #fff;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
  outline: none;
  cursor: pointer;
}

.loginButtonBox {
  margin-left: 42px;
  margin-top: 20px;
  width: 313px;
}

.loginWithOtpButtonBox {
  margin-left: 42px;
  margin-top: 20px;
  width: 313px;
}

.loginWithOtpButton {
  display: flex;
  width: 312px;
  height: 40px;
  padding: 9px 0px 10px 0px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  color: #597ef7;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
  outline: none;
  border: 1px solid #597ef7;
  background: #fff;
  cursor: pointer;
}

.orLableBox {
  margin-left: 42px;
  margin-top: 5px;
  width: 313px;
  height: 21px;
}

.orLable {
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  color: #30343c;
  display: flex;
  justify-content: center;
}

.loginWithSsoLableBox {
  margin-left: 42px;
  margin-top: 5px;
  width: 313px;
  height: 21px;
  margin-bottom: 20px;
}

.loginWithSso {
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
  color: #597ef7;
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.loginWithSso:hover {
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
  color: #000000;
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.signInLableBox {
  width: 313px;
  margin-left: 42px;
  margin-bottom: 20px;
}

.signInLable {
  font-family: "Inter";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  color: #86868a;
}

.loginButton:hover {
  display: flex;
  width: 312px;
  height: 40px;
  padding: 9px 0px 10px 0px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: linear-gradient(87deg, #597EF7 15.86%, #C4BEFE 124.08%);
  border: none;
  color: #fff;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
  outline: none;
  cursor: pointer;
}

.loginWithOtpButton:hover {
  display: flex;
  width: 312px;
  height: 40px;
  padding: 9px 0px 10px 0px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: linear-gradient(87deg, #597EF7 15.86%, #C4BEFE 124.08%);
  border: none;
  color: #fff;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
  outline: none;
  cursor: pointer;
}

.loginFormPasswordBox.box-focused {
  border-color: #3a86ff;
}

.loginFormEmailBox.box-focused {
  border-color: #3a86ff;
}

.recaptcha-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
}
.rectangleTopL {
  top: 0;
  left: 0;
  transform: translate(550px, 130px);
  z-index: 1;
  position: absolute;
  width: 167px;
  height: 171px;
  flex-shrink: 0;
  border-radius: 10px;
  background: #7a98ff;
  opacity: 0.5;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.rectangleTopS {
  top: 0;
  left: 0;
  transform: translate(650px, 110px);
  z-index: 1;
  position: absolute;
  width: 130px;
  height: 109px;
  flex-shrink: 0;
  border-radius: 15px;
  border: 1px dashed #6083ff;
}

.rectangleBottomL {
  bottom: 0;
  right: 0;
  transform: translate(-570px, -120px);
  z-index: 1;
  position: absolute;
  width: 154px;
  height: 154px;
  flex-shrink: 0;
  border-radius: 10px;
  border: 1px dashed #6083ff;
}

.rectangleBottomS {
  bottom: 0;
  right: 0;
  transform: translate(-590px, -140px);
  z-index: 1;
  position: absolute;
  width: 116px;
  height: 116px;
  flex-shrink: 0;
  background: #7a98ff;
  border-radius: 10px;
  opacity: 0.5;
  box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.12);
}

.otpRectangleTopL {
  top: 230px;
  left: 560px;
  z-index: 1;
  position: absolute;
  width: 167px;
  height: 171px;
  flex-shrink: 0;
  border-radius: 10px;
  background: #7a98ff;
  opacity: 0.5;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.otpRectangleTopS {
  top: 210px;
  left: 650px;
  z-index: 1;
  position: absolute;
  width: 130px;
  height: 109px;
  flex-shrink: 0;
  border-radius: 15px;
  border: 1px dashed #6083ff;
}

.otpRectangleBottomL {
  bottom: 230px;
  right: 570px;
  z-index: 1;
  position: absolute;
  width: 154px;
  height: 154px;
  flex-shrink: 0;
  border-radius: 10px;
  border: 1px dashed #6083ff;
}

.otpRectangleBottomS {
  bottom: 250px;
  right: 590px;
  z-index: 1;
  position: absolute;
  width: 116px;
  height: 116px;
  flex-shrink: 0;
  background: #7a98ff;
  border-radius: 10px;
  opacity: 0.5;
  box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.12);
}

.changePasswordRectangleTopL {
  top: 190px;
  left: 560px;
  z-index: 1;
  position: absolute;
  width: 167px;
  height: 171px;
  flex-shrink: 0;
  border-radius: 10px;
  background: #7a98ff;
  opacity: 0.5;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.changePasswordRectangleTopS {
  top: 160px;
  left: 650px;
  z-index: 1;
  position: absolute;
  width: 130px;
  height: 109px;
  flex-shrink: 0;
  border-radius: 15px;
  border: 1px dashed #6083ff;
}

.changePasswordRectangleBottomL {
  bottom: 150px;
  right: 570px;
  z-index: 1;
  position: absolute;
  width: 154px;
  height: 154px;
  flex-shrink: 0;
  border-radius: 10px;
  border: 1px dashed #6083ff;
}

.changePasswordRectangleBottomS {
  bottom: 170px;
  right: 590px;
  z-index: 1;
  position: absolute;
  width: 116px;
  height: 116px;
  flex-shrink: 0;
  background: #7a98ff;
  border-radius: 10px;
  opacity: 0.5;
  box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.12);
}
.loginWithSsoFormCardMain {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.loginWithSsoFormCard {
  width: 396px;
  z-index: 2;
  background-color: #ffffff;
  border-radius: 10px;
  flex-shrink: 0;
  box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.12);
  margin: 0 auto;
}

.loginWithSsoFormLogo {
  display: flex;
  justify-content: center;
}

.loginWithSsoLogoImage {
  margin-top: 20px;
  width: 312px;
  margin-bottom: 30px;
}

.companyTypeButton {
  width: 312px;
  margin-left: 42px;
  margin-top: 10px;
  align-items: center;
}

.tabButtonStyle {
  height: 36px;
}

.loginWithSsoFormEmailMain {
  margin-left: 42px;
  margin-top: 15px;
  width: 313px;
}

.loginWithSsoFormEmailLable {
  font-family: "Inter";
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  color: #30343c;
}

.loginWithSsoFormEmailInput {
  height: 32px;
  width: 90%;
  background: #fff;
  margin-left: 5px;
  border: none;
  outline: none;
}

.loginWithSsoFormPasswordMain {
  margin-left: 42px;
  margin-top: 15px;
  width: 313px;
}

.loginWithSsoFormPasswordLable {
  font-family: "Inter";
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  color: #30343c;
}

.loginWithSsoFormPasswordInput {
  width: 90%;
  background: #fff;
  margin-left: 5px;
  border: none;
}

.loginWithSsoFormPasswordIcon {
  width: 16px;
  height: 16px;
  flex-shrink: 0;
  margin: 10px;
  color: #47484c;
  cursor: pointer;
}

.loginWithSsoFormPasswordBox {
  width: 312px;
  height: 36px;
  flex-shrink: 0;
  border-radius: 4px;
  border: 1px solid #dae0f5;
  display: flex;
  justify-content: space-between;
}

.loginWithSsoFormEmailBox {
  width: 312px;
  height: 36px;
  flex-shrink: 0;
  border-radius: 4px;
  border: 1px solid #dae0f5;
}

.loginWithSsoBackToSignInLable {
  font-family: "Inter";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  color: #597ef7;
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
}

.loginWithSsoBackToSignInLable:hover {
  font-family: "Inter";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  color: #000000;
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
}

.loginWithSsoBackToSignInLableBox {
  margin-left: 42px;
  margin-top: 15px;
  width: 313px;
  height: 18px;
  margin-bottom: 20px;
}

.loginWithSsoButton {
  display: flex;
  width: 312px;
  height: 40px;
  padding: 9px 0px 10px 0px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: #597ef7;
  border: none;
  color: #fff;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
  outline: none;
  cursor: pointer;
}

.loginWithSsoButtonBox {
  margin-left: 42px;
  margin-top: 30px;
  width: 313px;
}

.loginWithSsoWithOtpButtonBox {
  margin-left: 42px;
  margin-top: 20px;
  width: 313px;
}

.loginWithSsoWithOtpButton {
  display: flex;
  width: 312px;
  height: 40px;
  padding: 9px 0px 10px 0px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  color: #597ef7;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
  outline: none;
  border: 1px solid #597ef7;
  background: #fff;
  cursor: pointer;
}

.signInLableBox {
  width: 313px;
  margin-left: 42px;
  margin-bottom: 20px;
}

.signInLable {
  font-family: "Inter";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  color: #86868a;
}

.loginWithSsoFormEmailBox.box-focused {
  border-color: #3a86ff;
}

.loginWithSsoButton:hover {
  display: flex;
  width: 312px;
  height: 40px;
  padding: 9px 0px 10px 0px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: linear-gradient(87deg, #597EF7 15.86%, #C4BEFE 124.08%);
  border: none;
  color: #fff;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
  outline: none;
  cursor: pointer;
}
.cookie-card-wrapper {
  position: fixed;
  bottom: 40px;
  left: 20px;
  width: 400px;
  color: #333;
  background-color: white;
  padding: 16px;
  text-align: center;
  font-family: Arial, sans-serif;
  z-index: 20;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
}
.cookie-card-wrapper .cookie-card-info-box {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.cookie-card-wrapper .cookie-card-info-box .cookie-icon {
  font-size: 30px;
  margin-bottom: 15px;
}
.cookie-card-wrapper .cookie-card-info-box .cookie-description {
  margin: 6px 0;
  font-size: 14px;
  max-width: 400px;
  margin-bottom: 16px;
  text-align: left;
  line-height: 17px;
  -webkit-text-size-adjust: 2;
     -moz-text-size-adjust: 2;
          text-size-adjust: 2;
}
.cookie-card-wrapper .cookie-card-info-box .cookie-description .fontBold {
  font-weight: 700 !important;
  color: black;
}
.cookie-card-wrapper .cookie-card-info-box .cookie-policy-link {
  margin-left: 6px;
  color: #597ef7;
  text-decoration: none;
}
.cookie-card-wrapper .cookie-card-info-box .cookie-action-wrapper {
  display: flex;
  justify-content: flex-end;
  grid-gap: 10px;
  gap: 10px;
  width: 100%;
}
.cookie-card-wrapper .cookie-card-info-box .btn {
  padding: 8px 18px;
  font-size: 14px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.cookie-card-wrapper .cookie-card-info-box .btn.accept-btn {
  background-color: #597ef7;
  color: white;
  border-radius: 5px;
}
.cookie-card-wrapper .cookie-card-info-box .btn.Preferences-btn {
  color: #597ef7;
  background-color: transparent;
  border-radius: 5px;
  border: 1.5px solid #597ef7;
}
.cookie-card-wrapper .cookie-card-info-box .btn.customize-btn {
  background-color: #555;
  color: white;
  border-radius: 5px;
  margin-top: 10px;
}
.cookie-card-wrapper .cookie-card-info-box .btn:hover {
  opacity: 0.8;
}
.cookie-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.cookie-modal {
  background: white;
  margin: 0px 8px;
  padding: 10px;
  max-width: 800px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: left;
  position: relative;
  overflow: auto;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
}
.cookie-modal .cookie-modal-title {
  margin: 4px 0px;
  font-size: 20px;
}
.cookie-modal .close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 26px;
  cursor: pointer;
}
.cookie-modal .cookie-policy-link {
  display: inline-block;
  vertical-align: middle;
  margin-left: 6px;
  color: #597ef7;
  text-decoration: none;
}
.cookie-modal .cookie-modal-content {
  min-height: 300px;
  height: calc(100vh - 200px);
  max-height: 550px;
  overflow: auto;
}
.cookie-modal .cookie-modal-content h2 {
  margin-bottom: 10px;
  font-weight: 700;
}
.cookie-modal .cookie-modal-content p {
  font-size: 14px;
  margin-bottom: 15px;
}
.cookie-modal .cookie-modal-content .description-1 {
  margin-left: 40px;
  margin-top: 4px;
  margin-bottom: 4px;
}
.cookie-modal .cookie-modal-content .label-1 {
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;
}
.cookie-modal .cookie-modal-content .label-1 input {
  width: 16px;
  height: 16px;
  accent-color: #597ef7;
}
.cookie-modal .cookie-options {
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  gap: 10px;
}
.cookie-modal .cookie-options label {
  display: flex;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
  font-size: 14px;
}
.cookie-modal .cookie-buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
  grid-gap: 20px;
  gap: 20px;
}
.cookie-modal .cookie-buttons button {
  padding: 8px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
}
.cookie-modal .cookie-buttons .decline {
  background: white;
  border: 1.5px solid #597ef7;
  color: #597ef7;
}
.cookie-modal .cookie-buttons .accept {
  background: #597ef7;
  color: white;
}
.cookie-modal .info-card-1 {
  display: flex;
  justify-content: center;
}
.cookie-modal .info-card-1 .info-card-1-message {
  font-size: 16px;
  margin-top: 10px;
}
.cookie-modal .table-container-1 {
  width: 100%;
  overflow-x: auto;
  margin-top: 10px;
}
.cookie-modal .table-container-1 table {
  border: 0px;
  width: 100%;
  border-collapse: collapse;
  font-family: Arial, sans-serif;
}
.cookie-modal .table-container-1 thead {
  background: white;
  position: sticky;
  top: 0;
  z-index: 10;
}
.cookie-modal .table-container-1 th {
  border: 0px;
  text-align: left;
  font-weight: bold;
  padding: 12px;
  color: #222;
  border-bottom: 2px solid #0056b3;
  font-size: 14px;
}
.cookie-modal .table-container-1 td {
  padding: 12px;
  border-left: 0px;
  border-right: 0px;
  border-bottom: 1px solid #ddd;
  color: #222;
  font-size: 14px;
}
.cookie-modal .table-container-1 tr:last-child td {
  border-bottom: none;
}
.cookie-policy {
  margin: 0 auto;
  padding: 0px 60px 1px 60px;
  font-family: Arial, sans-serif;
  color: #333;
}
.cookie-policy .title {
  width: 100%;
  font-size: 28px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 15px;
}
.cookie-policy .effective-date {
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
}
.cookie-policy .company_link {
  margin-left: 8px;
}
.cookie-policy .fontBold {
  font-weight: 700 !important;
  color: black;
}
.cookie-policy h2 {
  font-size: 22px;
  margin-top: 20px;
  color: #222;
  font-weight: 700;
  margin-bottom: 10px;
}
.cookie-policy p,
.cookie-policy ul {
  font-size: 16px;
  line-height: 1.6;
  margin-bottom: 6px;
  color: #222;
}
.cookie-policy ul {
  margin-left: 20px;
  list-style-type: disc;
  color: #222;
}
.cookie-policy a {
  color: #007bff;
  text-decoration: none;
}
.cookie-policy a:hover {
  text-decoration: underline;
}
.cookie-policy .title-1 {
  text-decoration: underline;
  margin-bottom: 12px;
  font-weight: 700;
  color: #222;
  font-size: 18px;
}
.cookie-policy .description-1 {
  margin: 0px;
}
.cookie-policy .info-card-1 {
  display: flex;
  justify-content: center;
}
.cookie-policy .info-card-1 .info-card-1-message {
  font-size: 16px;
  margin-top: 10px;
  font-weight: 700 !important;
  margin-bottom: 6px;
  color: #222;
}
.cookie-policy .table-container-1 {
  width: 100%;
  overflow-x: auto;
  margin-top: 10px;
}
.cookie-policy .table-container-1 table {
  border: 0px;
  width: 100%;
  border-collapse: collapse;
  font-family: Arial, sans-serif;
}
.cookie-policy .table-container-1 thead {
  background: white;
  position: sticky;
  top: 0;
  z-index: 10;
}
.cookie-policy .table-container-1 th {
  border: 0px;
  text-align: left;
  font-weight: bold;
  padding: 12px;
  color: #222;
  border-bottom: 2px solid #0056b3;
}
.cookie-policy .table-container-1 td {
  padding: 12px;
  border-left: 0px;
  border-right: 0px;
  border-bottom: 1px solid #ddd;
  color: #222;
}
.cookie-policy .table-container-1 tr:last-child td {
  border-bottom: none;
}
.btnT1, .textBtnT1, .outlineBtnT1, .iconBtnT1 {
  all: unset;
  color: #627FE9;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  padding: 5px 10px;
  border-radius: 4px;
}
.btnT1:hover, .textBtnT1:hover, .outlineBtnT1:hover, .iconBtnT1:hover {
  cursor: pointer;
}
.btnT1:active, .textBtnT1:active, .outlineBtnT1:active, .iconBtnT1:active {
  background-color: #cfd9ff;
}

.outlineBtnT1 {
  border-radius: 2px;
  border: 1px solid #627FE9;
}

.iconBtnT1 {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid #627FE9;
  padding: 4px;
  width: 18px;
  height: 18px;
}

.tableT1Wrapper {
  overflow: auto;
}
.tableT1Wrapper .tableT1 {
  width: 100%;
  border: 0px;
  background-color: #FFF;
  border-radius: 6px 6px;
  position: relative;
  margin: 0px;
}
.tableT1Wrapper .theadT1 {
  position: sticky;
  top: 0px;
}
.tableT1Wrapper .theadT1 .trT1 {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.32) 0%, rgba(0, 0, 0, 0.32) 100%), #627FE9;
}
.tableT1Wrapper .theadT1 .trT1:first-of-type .thT1:first-of-type {
  border-top-left-radius: 6px;
}
.tableT1Wrapper .theadT1 .trT1:first-of-type .thT1:last-of-type {
  border-top-right-radius: 6px;
}
.tableT1Wrapper .theadT1 .trT1 .thT1 {
  color: #FFF;
  padding: 14px 10px;
  border: 0px;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
}
.tableT1Wrapper .tbodyT1 .trT1:last-of-type .tdT1:first-of-type {
  border-bottom-left-radius: 6px;
}
.tableT1Wrapper .tbodyT1 .trT1:last-of-type .tdT1:last-of-type {
  border-bottom-right-radius: 6px;
}
.tableT1Wrapper .tbodyT1 .trT1 .tdT1 {
  border: 0px;
  padding: 10px;
  color: #191919;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  vertical-align: top;
}

.flex {
  display: flex;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.items-baseline {
  align-items: baseline;
}

.items-stretch {
  align-items: stretch;
}

.justify-normal {
  justify-content: normal;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.justify-evenly {
  justify-content: space-evenly;
}

.justify-stretch {
  justify-content: stretch;
}

.gap-5 {
  grid-gap: 5px;
  gap: 5px;
}

.gap-10 {
  grid-gap: 10px;
  gap: 10px;
}

.gap-15 {
  grid-gap: 15px;
  gap: 15px;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-wrap-stretch {
  align-content: stretch;
}

.flex-wrap-top {
  align-content: flex-start;
}

.flex-wrap-middle {
  align-content: center;
}

.flex-wrap-bottom {
  align-content: flex-end;
}

.flex-wrap-between {
  align-content: space-between;
}

.flex-wrap-around {
  align-content: space-around;
}

.flex-items-middle {
  align-items: center;
}

.p-12 {
  padding: 12px;
}

.m-0 {
  margin: 0px;
}

.mb-16 {
  margin-bottom: 16px;
}

.rounded {
  border-radius: 0.25rem; /* 4px */
}

.rounded-md {
  border-radius: 0.375rem; /* 6px */
}

.text-lg {
  font-size: 1.125rem;
  /* 18px */
  line-height: 1.75rem;
  /* 28px */
}

.typography,
.typographyT1 {
  color: #000;
  font-family: "Poppins", sans-serif;
  font-style: normal;
}

.typographyT1 {
  font-size: 24px;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0.24px;
}

.typographyT2 {
  color: #FFF;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
}

.typographyT3 {
  color: #191919;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}

.typographyT4 {
  color: #627FE9;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 114.286% */
  letter-spacing: 0.42px;
}

.cursor-pointer {
  cursor: pointer;
}

.w-fit {
  width: -moz-fit-content;
  width: fit-content;
}

.w-max {
  width: max-content;
}

.pageWrapperT1 {
  padding: 24px;
  position: relative;
  background-color: #E3E9FF;
  height: calc(100vh - 80px);
  overflow-y: auto;
}

.bg-white {
  background-color: #FFFFFF;
}

.bg-627FE9 {
  background-color: #627FE9;
}

.bg-EFF2FD {
  background-color: #EFF2FD;
}

.text-white {
  color: #FFFFFF;
}

.mb-6 {
  margin-bottom: 6px;
}

.labelT1 {
  color: #FFF;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

.react-select-container-T1 {
  min-width: 200px;
}
.react-select-container-T1 .css-1hwfws3 {
  padding: 0px 4px;
  max-height: 32px;
}
.react-select-container-T1 .css-151xaom-placeholder {
  top: 58%;
}
.react-select-container-T1 .css-16pqwjk-indicatorContainer {
  padding: 0px 4px;
}
