.route_code_detail {
    .overlay{
        position: fixed;
        z-index: 10;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: rgba(27, 33, 67, 0.9);
    }
    .modal-popup .pop-heading {
        background-color: #f4f4f4;
        padding: 18px;
        font-family: 'avenir',sans-serif;
        font-size: 16px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #528aea;
        text-align: center;
    }
    .modal-popup {
        max-height: 670px;
        overflow: scroll;
        position: fixed;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        border-radius: 5px;
        width: 1000px;
        min-height: 550px;
        background-color: white;
        z-index: 11;
    }
    .route-detail {
        margin: 55px;
    }
    .scheduler-detail {
        margin: 10px;
    }
    .button_route {
        margin-right: 10px;
        float: right;
    }
    .error-message {
        margin-right: 10px;
        float: right;
        font-size: 12px;
        color: red;
    }
    .select-wrap-depot{
        width: 33%;
        display: inline-block;
        vertical-align: baseline;
        .label{
            font-size: 10px;
            color: #868e96;
           //  text-transform: uppercase;
            margin-bottom: 10px;
        }
    }
    .service_mode {
        width: 277px;
        margin-right: 15px;
    }
    &.disposal-product {
        .select {
            width: 275px;
            margin-bottom: 5px;
        }
        .input-text-wrap {
            width: 100%;
            color: #868e96;
        }
    }
}
.download-icon {
    background-repeat: no-repeat;
    height: 30px;
    width: 30px;
    background-size: contain;
    // margin: 0 5px;
    cursor: pointer;
    display: inline-block;
    padding: 5px;
    margin: 5px;
}
.selectpack {
    width: 250px;
    margin-bottom: 5px;
}
.RouteStages{
    margin-top: 10px;
    display: flex;
}
.ml-20{
    margin-left: 20px;
}
.mt--30{
    margin-top: -30px;
}
.heading-section{
    font-size: 24px;
    margin-top: 20px;
}
.sub-heading-section{
    font-size: 18px;
    font-weight: bold;
    margin-top: -50px;
}
.no-routestage-msg{
    display: flex;
    justify-content: center;
    margin-top: 50;
    color: red;
}

.routestages-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
    border-radius: 10px;
  }
  
  .routestages-table th {
    background-color: #44569e;
    color: white;
    text-align: left;
  }
  
  .routestages-table tbody tr:nth-child(even) {
    background-color: #eff1fd;
  }

.routestg-input{
    width: 120px;
    padding-left: 10px;
    margin-left: 10px;
}
.rs-item{
    display: flex;
    justify-content:space-between;
    align-items: center;
    margin: 5px 0px;
}
.rs-delete-item{
    display: flex;
    justify-content: flex-end;
    margin-top: 70px;
    margin-left: 50px;
}
.disabled {
    pointer-events: none;
    background:  #e6e6e6
}
.NotSelectablediv{
    pointer-events: none;
    background: white
}
