.pod-cycle-form {
    .serviceTypes .section-item {
        display: inline-block;
        margin-right: 30px;
        margin-top: 20px;
        .title {
            padding-left: 10px;
        }
    }
    .modal-popup {
        padding: 10px !important;
    }
    .checkbox {
        margin-top: 10px !important;
    }
    .cycle-row {
        display: flex;
    }
    .title {
        width: unset;
        display: inline-block;
        vertical-align: top;
    }
    .w-p-40 {
        width: 40%;
    }
    
    .w-p-20 {
        width: 20%;
    }
    
    .w-p-60 {
        width: 60%;
    }
    .w-p-100 {
        width: 100%;
    }
    .m-b-10 {
        margin-bottom: 10px;
    }
    .f-s-16 {
        font-size: 16px !important;
    }
    .table-header {
        color: white !important;
    }
    .inline-flex {
        display: inline-flex !important;
    }
    .input-text {
        padding: 5px;
        border: 1px solid grey;
        height: 30px;
        border-radius: 5px;
        font-size: 18px;
        width: 100%;
    }
    .align-right {
        margin-left:auto; 
        margin-right:10px;
    }
    .align-left {
        margin-left:10px; 
        margin-right:auto;
        text-align: left;
    }
    .divTableCell {
        border: 1px solid #5289ea;
        border-top: 0px !important;
    }
    .list-section {
        border-bottom: 0px !important;
    }
    .m-l-20 {
        margin-left: 20px;
    }
    .m-t-10 {
        margin-top: 10px;
    }
    .info-cell {
        position: absolute;
        margin-top: 10px;
    }
    
    .input-textarea {
        padding: 5px;
        border: 1px solid grey;
        border-radius: 5px;
        font-size: 18px;
        width: 100%;
    }
    .divTable {
        margin-top: 0px !important;
    }
}