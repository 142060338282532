@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');


.login-as-container {
    background: linear-gradient(87deg, #597EF7 15.86%, #C4BEFE 124.08%);
    // background-image: url('../../icon/bg.svg');
    background-size: cover;
    background-repeat: no-repeat;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px;
    color: #30343C;
    overflow: hidden;

    & * {
        font-family: Inter;
        color: #30343C;
    }

    .bg-design-wrapper {
        position: relative;
        z-index: 1;

        .design01 {
            position: absolute;
            top: 0px;
            left: 0px;
            transform: translate(-30%, -45%);
            z-index: -1;
        }

        .design02 {
            position: absolute;
            bottom: 0px;
            right: 0px;
            transform: translate(50%, 50%);
            z-index: -1;
        }
    }

    .card-wrapper {
        background-color: #fff;
        max-width: 410px;
        padding: 20px;
        border-radius: 10px;
        box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.12);

        .card-title {
            text-align: center;
            font-size: 22px;
            font-style: normal;
            font-weight: 600;
            line-height: 33px;
            color: #30343C;
            margin-bottom: 24px;
        }

        .form-wrapper {
            display: flex;
            flex-direction: column;
            gap: 10px;

            .btn-group {
                display: flex;
                border-radius: 5px;
                border: 1px solid #597EF7;
                background: #FFF;
                margin-bottom: 8px;
                align-items: stretch;

                button {
                    all: unset;
                    color: #597EF7;
                    text-align: center;
                    font-family: Inter;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 16px;
                    flex: 1;
                    padding: 10px 12px;

                    &:hover,
                    &:focus {
                        cursor: pointer;
                        background-color: #597ef719;
                    }



                    &:first-of-type {
                        border-radius: 4px 0px 0px 4px;
                    }

                    &:last-of-type {
                        border-radius: 0px 4px 4px 0px;
                    }
                }

                button.active {
                    background-color: #597EF7;
                    color: #FFF;

                    &:hover,
                    :focus {
                        cursor: pointer;
                        background-color: #597ef7d4;
                    }
                }

            }


            .label {
                font-family: Inter;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 18px;
                color: #30343C;
                display: inline-block;
                margin-bottom: 2px;
                display: inline-block;
            }


            .chip-btn-group-wrapper {
                display: flex;
                align-items: center;
                gap: 8px;
                flex-wrap: wrap;

                .chip-btn {
                    all: unset;
                    text-align: center;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 21px;
                    border-radius: 20px;
                    background: rgba(89, 126, 247, 0.12);
                    padding: 2px 8px;
                    display: flex;
                    align-items: center;
                    gap: 4px;
                    border: 1px solid transparent;

                    &:focus {
                        box-shadow: 0 0 0 1px #597EF7;
                    }

                    &:hover {
                        border: 1px solid #597EF7;
                    }
                }

                &:hover {
                    cursor: pointer;
                }

                .active {
                    background-color: #597EF7;
                    color: #FFF;
                }

            }

            .remarks {
                width: 100%;
                padding: 6px 12px;
                border-radius: 4px;
                border: 1px solid var(--Textfield-Border-Colour, #DAE0F5);
                background: #FFF;
                resize: none;
                outline: none;

                &:hover {
                    border: 1px solid #597EF7;
                }

                &:focus {
                    box-shadow: 0 0 0 1px #597EF7;
                }

                &::placeholder {
                    color: '#83858A';
                    font-size: '14px';
                }
            }


            .form-submit-btn {
                background-color: #597EF7;
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: 21px;
                color: #fff;
                border: 0px;
                padding: 8.5px 20px;
                border-radius: 4px;

                &:not(:disabled):hover {
                    cursor: pointer;
                    box-shadow: 0px 0px 2px #0d44f6;
                }

                &:disabled {
                    cursor: not-allowed;
                    opacity: 0.6;
                }

                &:not(:disabled):active {
                    outline: 1px solid #597EF7;
                }
            }


        }


    }

























}