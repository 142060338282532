.add-item-details .item-select {
  display: inline-block;
  width: 277px;
}
.add-item-details .select-wrap {
  width: 33%;
  display: inline-block;
  vertical-align: baseline;
}
.add-item-details .label {
  font-size: 10px;
  color: #868e96;
  margin-bottom: 10px;
}
.add-item-details .label1 {
  font-size: 10px;
  color: #868e96;
  margin-bottom: 10px;
  margin-top: 30px;
}
.item-table .item-header .item-label {
  width: 98px;
}

.add-user-wrap .modal-popup .pop-heading {
  background-color: #f4f4f4;
  padding: 18px;
  font-family: 'avenir',sans-serif;
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #528aea;
  text-align: center;
  margin-bottom: 30px;
  margin-top: 30px;
}
.add-user-wrap .modal-popup .pop-heading.select-wrap.data-picke.label {
  margin-top: 30px;
}

.text-orange {
  color: orange;
}
.text-red {
  color: red
}
.theme-color {
  color: #528aea;
}

