.vendor_detail{
    .overlay{
        position: fixed;
        z-index: 10;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: rgba(27, 33, 67, 0.9);
    }
    .modal-popup .pop-heading {
        background-color: #f4f4f4;
        padding: 18px;
        font-family: 'avenir',sans-serif;
        font-size: 16px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #528aea;
        text-align: center;
    }
    .modal-popup {
        max-height: 670px;
        overflow: scroll;
        position: fixed;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        border-radius: 5px;
        width: 1000px;
        min-height: 270px;
        background-color: white;
        z-index: 11;
    }
    .col-3{
        width: 33.33%;
    }
    .col-4{
        width: 25%;
        box-shadow: none;
    }
    .p-10{
        padding: 10px;
    }
    .mt-10{
        margin-top: 10px;
    }
    .ml-20{
        margin-left: 20px;
    }
    .mr-20{
        margin-right: 20px;
    }
    .bg-white{
        background-color: white;
    }
    .bg-theme{
        background-color: #5289ea;
    }
    .sub-heading{
        background-color: #f4f4f4;
        font-size: 16px;
        font-weight: 600;
    }
    .vendor-header{
        font-size: 14px;
        font-weight: 600;
        color: black;
    }
    .vendor-data{
        color: #528AEA;
        font-size: 14px;
        margin-top: 2px;
    }
}