@import "../../variables.less";
.transporter-list {
    width: 100%;
    display: flex;
    .search-text-wrap {
        .text-search{
            margin-bottom: 10px;
            margin-left: 10px;
        }
    }
    .col-2 {
        width: 48%;
        box-shadow: 0 0 27px 4px rgba(69,68,68,.07);
        max-height: 720px;
        overflow: scroll;
    }
    .header {
        padding: 10px;
        text-align: center;
        // background-color: #528aea;
        // color: white;
        display: flex;
        color: #528aea;
        justify-content: space-between;
        height: 40px;
        .add-button {
            padding: 2px 18px;
            height: 20px;
        }
    }
    .lists {
        padding: 5px 10px;
        display: flex;
        border-top: 1px solid hsla(0,0%,59.2%,.2);
    }
    .ml-10 {
        margin-left: 10px;
    }
    .mr-10 {
        margin-right: 10px;
    }
    .space-btw {
        justify-content: space-between
    }
}
.transporter-mapping {
    text-align: center;
    .section-item-company{
        display: inline-block;
        vertical-align: super;
        color: #535356;
        font-size: 16px;
        text-transform: capitalize;
        width: 20%;
        &.ftl {
            width: 11%
        }
        .title{
            display: inline-block;
            vertical-align: top;
            margin: 3px 10px;
        }
    }
    .custome-checkbox-wrap{
        width: 20px;
        height: 20px;
        border-radius: 5px;
        border: 1px solid #d9d9d9;
        display: inline-block;
        position: relative;
        cursor: pointer;
        &.selected{
            border: 1px solid @tms-admin-color;
        }
        .inner-circle{
            width: 10px;
            height: 10px;
            border-radius: 15%;
            background: @tms-admin-color;
            position: absolute;
            top: 4px;
            left: 4px;
        }
    }
}