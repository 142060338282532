.bill-series{
    .modal-popup{
        .content{
            width: 1065px;
            margin: 0px 10px 20px 30px;
            padding: 0px 20px 20px 30px;
            border-radius: 6px;
            box-shadow: 0 0 27px 4px #dbdbe4;
        }
    }
    
    .header{
        font-size: 20px;
        font-weight: 600;
        color: #528aea;
    }
    .sub-header{
        font-size: 14px;
        font-weight: 600;
        color: #528aea;
    }
    .contact-btn{
        .button-classic{
            margin: 10px;
            padding: 5px 20px;
        }
        .wt-40p{
            width: 40%;
        }
        .wt-60p{
            width: 60%;
        }
    }
    .table{
        width: 100%;
        border-radius: 8px;
        margin-left: -30px;
        .table-tr{
            background-color: #3261b1;
            color: white;
            font-size: 13px;
        }
        .table-content{
            background-color: white;
            font-size: 13px;
            color: black;
            padding: 5px;
            text-align: center;
            .acCenter{
                align-content: center;
            }
            :hover{
                background-color: #f4f4f4;
            }
        }
    }
    .branchdrop {
        width: 80%;
    }
    .pop-heading{
        background-color: #f4f4f4;
        padding: 18px;
        font-family: 'avenir',sans-serif;
        font-size: 20px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #3261b1;
        text-align: center;
        .button-footer{
            margin: 10px;
            padding: 5px 20px;
            background-color: #3261b1;
            color: white;
            border-radius: 5px;
            cursor: pointer;
        }
    }
    .noteList{
        margin-top: 0px;
    }
    .wt-50p{
        width: 50%;
    }
    .col-3{
        width: 33.33%;
    }
    .footer{
        background-color: #3261b1;
        float: right;
    }
    .action-icon{
        background-repeat: no-repeat;
        height: 15px;
        width: 15px;
        background-size: contain;
        margin: 0 5px;
        cursor: pointer;
        display: inline-block;
        // padding: 5px;
        // margin: 5px;
    }
    .tooltip {
        position: absolute;
        display: inline-block;
      }
      
      .tooltip .tooltiptext {
        visibility: hidden;
        width: 250px;
        background-color: #b9b6b6;
        color: black;
        text-align: left;
        border-radius: 4px;
        padding: 10px;
        position: absolute;
        z-index: 1;
        bottom: 100%;
        left: 30%;
        opacity: 0;
        transition: opacity 0.3s;
        font-size: 14px;
      }
      
      .tooltip:hover .tooltiptext {
        visibility: visible;
        opacity: 1;
      }
    .series{
        text-align: left;
        padding: 10px;
    }
}