.UL {
  width: max-content;
  margin-top: 10px;

  .border {
    border: 1px solid #528aea;
    padding: 20px 25px;
    border-radius: 8px;
    background-color: white;

    .flex-item{
    display: flex;
    gap: 20px;
    align-items: center;

        .plantName {
      width: 150px;
    }

    .plantName::placeholder {
      color: #528aea;
    }

            .loc {
      width: 150px;
    }

    .loc::placeholder {
      color: #528aea;
    }

            .locCode {
      width: 200px;
    }

    .locCode::placeholder {
      color: #528aea;
    }

            .zone {
      width: 150px;
    }

    .zone::placeholder {
      color: #528aea;
    }

            .state {
      width: 150px;
    }

    .state::placeholder {
      color: #528aea;
    }

                .clusterName {
      width: 170px;
    }

    .clusterName::placeholder {
      color: #528aea;
    }

                .locCodeParent {
      width: 200px;
    }

    .locCodeParent::placeholder {
      color: #528aea;
    }

    .upload-button{
      border: 1px solid #528aea;
      padding: 8px 25px;
      font-size: 13px;
      background-color: #528aea;
      color: white;
      border-radius: 3px;
      cursor: pointer;
  }
    }
  }

  .branchdrop {
    width: 25%;
  }

  .del-check {
    padding-top: 20px;
    display: flex;
    gap: 20px;
    align-items: center;
    margin-left: 10px;
  }

  .table {
    width: max-content;
    margin-left: 0px;
    th {
      border: 1px solid white;
    }
    
    td {
      border: 1px solid white;
      border-right: 1px solid #c4d4f0;
    }
    
    .table-tr {
      background-color: #3261b1;
      color: white;
      font-size: 13px;
      
      .tCenter {
        padding: 10px 15px;
      }
    }
    .tr-1 {
      background-color: #d6e0ef;
    }
  }
    .template {
    border: 1px solid #528aea;
    display: flex;
    align-items: center;
    padding: 2px 15px 2px 8px;
    gap: 0px;
    border-radius: 5px;
    background-color: white;
    cursor: pointer;

    .template-text {
      color: #528aea;
    }
  }

      .file-holder{
      width: 300px;
      height: 36.5px;
      border-radius: 2px;
      background-color: #f9f9f9;
      border: 1px solid #cccccc;
      cursor: pointer;
      position: relative;
      input{
          width: 300px;
          height: 35px;
          opacity: 0;
          cursor: pointer;
      }
      .file-choose{
          position: absolute;
          right: 9px;
          top: 9px;
          font-family: 'avenir',sans-serif;
          font-size: 12px;
          letter-spacing: 0.3px;
          cursor: pointer;
          color: #528aea;
          border-left: 1px solid black;
          padding-left: 8px;
        }
      .file-name{
          font-family: 'avenir',sans-serif;
          font-size: 12px;
          position: absolute;
          top: 9px;
          left: 12px;
          color: #86868a;
      }
    }
}

.search-filter-section{
    clear: both;
    margin-top: 10px;
}

.flex-between {
  justify-content: space-between;
}

.link {
  color: #528aea;
  text-decoration: underline;
}

.order-daterange {
    border: none;
    height: 30px;
    #startDate{
        padding: 8px 10px 8px 32px;
        height: 30px;
        background-image: url('../calendar.svg') !important;
        background-repeat: no-repeat;
        font-size: 12.8px;
        font-family:'SFPro-Display-Medium',sans-serif;
        background-position-x: 8px;
        background-position-y: 10px;
        border: 0.9px solid #d8dbf0;
        border-right: 0px solid white;
        border-bottom-right-radius: 0px;
        border-top-right-radius: 0px;
    } 
    #endDate {
        padding: 8px 10px 8px 10px;
        background-image: none !important;
        background-repeat: no-repeat;
        font-size: 12.8px;
        font-family:'SFPro-Display-Medium',sans-serif;
        background-position-x: 100px;
        background-position-y: 7px;
        height: 30px;
        border: 0.9px solid #d8dbf0;
        border-left: 0px solid white;
        border-bottom-left-radius: 0px;
        border-top-left-radius: 0px;
    }
    .DateRangePickerInput{
        width: max-content;
        margin-left: 0px !important;
    }
}

.button-classic { 
            height: 30px;
            font-size: 14px;
            padding: 0 20px;
            border-radius: 5px;
            margin-right: 10px;
        }