@import "../../../variables.less";

.search-text-wrap{
    display: inline-block;
    margin-right: 10px;
    position: relative;

    .text-search{
        width: 300px;
        height: 40px;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        background-color: #ffffff;
        outline: none;
        text-decoration: none;
        border: none;
        border: 1px solid #d4d4d4;
        padding-left: 12px;
        font-size: 14px;
    }
    .text-search::-moz-placeholder{
        color: #d4d4d4;
    }
    
    .text-search:-ms-input-placeholder{
        color: #d4d4d4;
    }
    
    .text-search::-webkit-input-placeholder{
        color: #d4d4d4;
    }
    .button-search{
        width: 50px;
        height: 40px;
        background: @tms-admin-color;
        color: white;
        font-size: 16px;
        border: none;
        outline: none;
        cursor: pointer;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
    }

    .cross-img {
        position: absolute;
        right: 25%;
        top: 30%;
        width: 15px;
        height: 15px;
        opacity: 0.4;
        cursor: pointer;
        background-size: contain;
        background-repeat: no-repeat;
    }
}