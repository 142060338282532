.company-details-wrap{
    .wrapper{
        width: 1280px;
        margin: 0 auto;
    }
    .go-back-prev-screen {
    	width: 20px;
        height: 20px;
        background-repeat: no-repeat;
        background-size: contain;
        margin-bottom: 20px;
	    cursor: pointer;
    }
}