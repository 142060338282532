@import "../../variables.less";
.generate-button {
  display: inline;
  cursor: pointer;
  // border-radius: 2px;
  border: 1px solid #528aea;
  background: #528aea;
  margin: 0px 23px;
  color: white;
  padding: 10px;
  font-size: 14px;
  border-radius: 20px;
  text-align: center;
  max-width: 250px;
}
.generate-button-default {
  display: inline;
  cursor: pointer;
  // border-radius: 2px;
  border: 1px solid #528aea;
  background: #528aea;
  margin: 0px 23px;
  color: white;
  padding: 10px;
  font-size: 14px;
  border-radius: 20px;
  text-align: center;
  max-width: 250px;
}

.generate-button-default-clicked {
  display: inline;
  cursor: not-allowed;
  // border-radius: 2px;
  border: 1px solid lightcoral;
  background: lightcoral;
  margin: 0px 23px;
  color: white;
  padding: 10px;
  font-size: 14px;
  border-radius: 20px;
  text-align: center;
  max-width: 250px;
}

.main {
  padding: 10px;
  padding-right: 0px;
  margin-left: 20%;
  display: block;
}
.btn-cls {
  padding: 5px 0;
  padding-left: 9px;
  background: none;
  border: none;
  cursor: pointer;
  margin-left: 20px;
  font-size: 16px;
  border-left: 1px solid lightgray;
}

.element {
  margin-bottom: 15px;
  margin-top: 15px;
}

.text-input {
  border: none;
  //border-bottom: 1px solid #d8dbf0;
  padding-bottom: 13px;
  font-size: 16px;
  //color: #212224;
  outline: none;
  width: 277px;
  border-bottom: 1px solid lightgray;
}

.text-input-content {
  border: 1px solid #d8dbf0;
  //border-bottom: 1px solid #d8dbf0;
  // padding-bottom: 13px;
  font-size: 16px;
  color: #212224;
  outline: black;
  width: 350px;
  padding: 10px;
}

.list {
  background-color: white;
  // display: inline-block;
  font-size: 14px;
  border-radius: 5px;
  height: 30px;
  padding: 10px 10px;
  display: inline-flex;
  align-items: center;
  margin: 0 0.3rem 0.3rem 0;
  border: 1px solid lightgray;
}

.list-div {
  background-color: white;
  width: 370px;
  padding: 0.5rem;
  border-radius: 0.3rem;
  border: 1px solid lightgray;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  scroll-behavior: initial;
  height: 192px;
  scrollbar-arrow-color: #212224;
}
.count-box {
  //   border: 1px solid lightgray;
  font-size: 12px;
  //   float: center;
  font-family: inherit;
  max-width: 45px;
  //   border-radius: 0.3rem;
  display: inline-block;
}
.inline-button {
  display: inline-block;
}
 div[data-placeholder]:not(:focus):not([data-div-placeholder-content]):before {
    content: attr(data-placeholder);
    float: left;
    margin-left: 18%;
    margin-right: 2px;
    color: inherit;
    margin-top:9%;

}

#total{
   margin-bottom: 15px;
   margin-left:40%;
  margin-top: 2px;

}

.to{
   float: initial;
}

.type-label {
  margin: 2px 33px 0px 5px;
}
.flex {
  display: flex;
}

.element-attach {
  margin-top: 34px;
  margin-bottom: 35px;
}