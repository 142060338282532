@import "../../variables.less";

.user-provider-root{
    margin: 60px 0px;
    .details-head{
        font-family: 'Avenir',sans-serif;
        font-size: 24px;
        font-weight: 900;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color : #1a2734;
        margin: 15px 5px;
    }
    .button-holder{
        .add-button{
            // background-color: #fff;
            border: 1px solid @tms-admin-color;
            margin: 10px 0px;
            text-align: center;
            background-color: #fff;
        }
    }
    .select_service_type {
        width: 270px;
        margin: 10px;
    }
}