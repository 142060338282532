.vendor-mapping {
    .vendor_btn {
        margin: 10px 0px;
        .search-text-wrap .text-search  {
            width: 250px;
        }
    }
    .button-classic {
        margin-left: 10px;
    }
    .vendor-detail {
        margin: 20px 40px;
        .list-section {
            padding: 10px 0px;
            border-bottom: 1px solid lightgrey;
            justify-content: space-between;
        }
        .select-btn {
            display: flex;
            justify-content: space-between;
            padding-top: 10px;
            .select {
                width: 300px;
            }
        }
    }
    .button_route {
        margin-top: 10px;
        margin-bottom: 10px;
    }
    .mapping-details-section {
        margin-top: 30px;
        
        .list-section {
            border-bottom: 1px solid rgba(212, 212, 212, 0.5);
            padding-bottom: 10px;
            padding-top: 10px;
            display: flex;
            &.content{
                .tabel-header {
                    margin-left: 20px;
                }   
                &:hover {
                border: 1px solid rgba(0, 89, 251, 0.38);
                box-shadow: 0px 10px 20px 0px rgba(129, 172, 212, 0.45);
                }
            }
            &.header{
                font-weight: 600;
                padding-top: 0;
                color: #1a2734;
                font-size: 14px;
                display: flex;
                .tabel-header {
                    margin-left: 20px;
                }
                
            }
            .table-header {
                width: 320px;
                margin-left: 20px;
                &.weight {
                    .label {
                    text-align: center;
                    border-bottom: 1px solid rgba(212, 212, 212, 0.5);
                    margin-bottom: 7px;
                    }
                    .slab-weight {
                        display: flex;
                        justify-content: space-between;
                    }
                }
            }
            .table-row {
                &.slab-weight {
                    width: 35%;
                    margin-left: 5px;
                    &.slab-weight-detention {
                        width: 100%;
                    }
                    .slab-weight-data {
                        display: flex;
                        justify-content: space-between;
                        &.row-data {
                            margin-bottom: 5px;
                            border-bottom: 1px solid rgba(212, 212, 212, 0.5);
                        }
                        div{
                            &.vendor {
                                width: 70%;
                            }
                            width: 25%;
                        }
                    }
                }
            }
        }
        .tabel-header {
            width: 170px;
            display: inline-block;
            vertical-align: middle;
            &.address {
                width: 300px;
            }
        }
    }

}