@import "../../../variables.less";

.shipment-root{
    max-width: 920px;
    .banner{
        background: @tms-admin-color;
        color: #fff;
        font-family: 'Avenir',sans-serif;
        font-size: 20px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        padding: 13px 24px;
    }
    .header{
        color: #1a2734;
        font-family: 'Avenir',sans-serif;
        font-size: 12px;
        font-weight: 900;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        margin: 25px 0px;
    }
}