.tableT1Wrapper {
    overflow: auto;

    & .tableT1 {
        width: 100%;
        border: 0px;
        background-color: #FFF;
        border-radius: 6px 6px;
        position: relative;
        margin: 0px;
    }

    & .theadT1 {
        position: sticky;
        top: 0px;

        & .trT1 {
            background: linear-gradient(0deg, rgba(0, 0, 0, 0.32) 0%, rgba(0, 0, 0, 0.32) 100%), #627FE9;

            &:first-of-type {
                & .thT1 {
                    &:first-of-type {
                        border-top-left-radius: 6px;
                    }

                    &:last-of-type {
                        border-top-right-radius: 6px;
                    }
                }
            }

            & .thT1 {
                color: #FFF;
                padding: 14px 10px;
                border: 0px;
                font-family: 'Poppins',sans-serif;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 18px;
            }
        }

    }

    & .tbodyT1 {

        & .trT1 {
            &:last-of-type {
                & .tdT1 {
                    &:first-of-type {
                        border-bottom-left-radius: 6px;
                    }

                    &:last-of-type {
                        border-bottom-right-radius: 6px;
                    }
                }
            }

            & .tdT1 {
                border: 0px;
                padding: 10px;

                color: #191919;
                font-family: 'Poppins',sans-serif;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 18px;
                vertical-align: top;
            }

        }

    }

}