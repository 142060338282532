.plants-depots-main-wrap{
    max-width: 944px;
    font-family: 'Avenir',sans-serif;
    .search-holder{
        display: flex;
        justify-content: space-between;
        margin: 35px 4px;
        .select {
            width: 250px;
        }
        .new{
            border-radius: 5px;
            border: none;
        }
    }
    .button-classic{
        margin-right: 0px !important;
    }
    .heading{
        display: flex;
        font-size: 12px;
        font-weight: 900;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: 0.2px;
        color: #ababae;
    }
    .depot-users-list{
        display: flex;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: 0.2px;
        color: #535356;
        margin : 4px 0px;
        padding-bottom: 10px;
        border-bottom: 1px solid #ececee;
        .edit-delete{
            display: flex;
            justify-content: space-between;
            max-height: 23px;
            width: 99px!important;
            .add-button{
                padding: 4px 6px;
                margin: 0px 4px 0px 0px;
            }
        }
    }
    .heading, .depot-users-list{
        .head{
            margin: 10px 15px 10px 5px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        .usn{
            width: 135px
        }
        .email{
            width: 150px;
        }
        .phone{
            width: 100px!important;
        }
        .branch{
            width: 120px!important;
        }
        .role, .actions{
            width: 70px;
        }
        .active{
            width: 100px;
        }
    }
    .depot-users-list{
        .usn{
            font-weight: 900;
        }
    }
}