.indent-reasons {
    width: 95%;
    .header {
        background: #528aea;
        color: #fff;
        padding: 10px 12px;
        font-family: 'avenir',sans-serif;
        font-size: 18px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
    }
    .fuel-escalation-table {
        // margin-top: 20px;
        margin-bottom: 20px;
        .list-section {
            font-weight: 600;
            color: #1a2734;
            font-size: 15px;
            display: flex;
            justify-content:space-between;
            border-bottom: 1px solid rgba(212, 212, 212, 0.5);
            &.no-data{
                padding: 10px 0px;
                text-align: center;
                display: block;
            }
            .table-header {
                width: 50%;
                padding: 10px 0px;
                margin-left: 30px;
            }
            .table-row {
                width: 50%;
                padding: 10px 0px;
                margin-left: 30px;
            }
            
        }
    }
}