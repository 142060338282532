@import "../../variables.less";

.button-classic{
    height: 40px;
    padding: 0 30px;
    border-radius: 25px;
    background-color: white;
    color: @tms-admin-color;
    font-size: 16px;
    font-weight: bold;
    // border: none;
    outline: none;
    cursor: pointer;
    border: 1px solid @tms-admin-color;


    &.incomplete{
        color: white;
    }
}

.button-section .button-classic {
    margin: 0 10px;
}

input.button-classic[disabled] {
    background: #f0f0f0;
    cursor: not-allowed;
    opacity: 0.5;
}