@import "../../variables.less";

.contracts-management-wrap {
  max-width: 900px;
  margin: 0 auto;
  padding: 30px;
  .contracts-holder {
    display: flex;
    max-width: 800px;
    margin: 0 auto;
    margin-bottom: 30px;

    .description {
      width: 419px;
      .description-block {
        margin: 40px 5px 0 5px;
      }
    }
    .action {
      width: 350px;
      .action-block {
        /*margin: 25px 5px 0 5px;*/
        position: relative;
        height: 60px;
        padding-top: 30px;
        .select {
          width: 350px;
        }
        .amount {
          width: 90px;
          padding: 7px;
        }
        .inr,
        .inr-yes-no {
          font-size: 10px;
          position: absolute;
          top: -12px;
          color: #86868a;
          font-family: 'Avenir',sans-serif;
          line-height: normal;
          letter-spacing: normal;
        }
        .inr-yes-no {
          left: 140px;
        }
        .inr {
          left: 0;
        }
        .bidding-rate {
          display: flex;
          width: 372px;
          .select {
            margin-right: 20px;
          }
        }
      }
      .bidding-time {
        display: flex;
        width: 410px;
        .time-holder {
          margin-left: 20px;
          margin-top: 6px;
          .days-hours-holder {
            .dhm-label {
              top: -27px;
            }
            .times {
              top: -16px;
            }
          }
        }
      }
      .dhm {
        display: flex !important;
      }
    }
  }

  .apply-settings {
    display: flex;
    .label {
      font-family: 'avenir',sans-serif;
      font-size: 16px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: 0.2px;
      color: #535356;
      margin-left: 15px;
    }
  }
  .heading {
    background: @tms-admin-color;
    color: #fff;
    padding: 20px 12px;
    font-family: 'avenir',sans-serif;
    font-size: 20px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    display: block;
    &.sub-heading {
      padding: 2px 16px;
      font-size: 18px;
    }
  }
  .button-holder {
    text-align: center;
  }
  .golden-box {
    border: 1px solid @tms-admin-color;
    margin: 30px 0px;
    &.golden-box-sub {
      margin: 10px 5px;
    }
    .heading {
      border-bottom: 1px solid @tms-admin-color;
    }
    .golden-box-content {
      display: flex;
      justify-content: space-between;
      // overflow: scroll;
      .tg  {
        border-collapse:collapse;
        border-color:#9ABAD9;
        border-spacing:0;
      }
      .tg td{
        background-color:#EBF5FF;
        border-color:#9ABAD9;
        border-style:solid;
        border-width:1px;
        color:#444;
        font-family:Arial, sans-serif;
        font-size:14px;
        overflow:hidden;
        padding:10px 5px;
        word-break:normal;
      }
       .tg th{
         background-color:#409cff;
         border-color:#9ABAD9;
         border-style:solid;
         border-width:1px;
         color:#fff;
         font-family:Arial, sans-serif;
         font-size:14px;
         font-weight:normal;
         overflow:hidden;
         padding:10px 5px;
         word-break:normal;
       }
       .tg .tg-0lax{
         text-align:left;
         vertical-align:top;
         height: 30px;
         width:200px;
         
        }

      .input-holder {
        position: relative;
        margin: 50px 20px;
        .input {
          border: none;
          border-bottom: 1px solid #ededef;
          width: 200px;
          padding: 7px;
        }
        .labels {
          position: absolute;
          top: -20px;
          left: 0;
          font-family: 'avenir',sans-serif;
          font-size: 12px;
          font-weight: 900;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #868e96;
        }
      }
      .currecy_converter {
        .input-holder {
          margin: 15px 20px;
        }
      }
      .add-role-section {
        padding: 30px 20px 20px 15px;
        .add-items {
          display: inline-block;
          vertical-align: middle;
          margin-right: 30px;
        }
      }
    }
    .golden-box-display {
      display: flex;
      justify-content: space-around;
      border: none;
      border-bottom: 1px solid #ededef;
      padding: 12px;
      .headers {
        margin: 0px 20px;
        font-family: 'avenir',sans-serif;
        font-size: 12px;
        font-weight: 900;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #868e96;
        width: 150px;
      }
      .details {
        width: 150px;
        margin: 0px 20px;
        .labels {
          margin-top: 6px;
        }
        .add-button {
          margin: 0px;
        }
      }
    }
    .add-button {
      padding: 10px 15px;
    }
    .button-holder {
      padding: 20px;
      text-align: right;
      .add-button {
        font-size: 13px;
      }
    }
  }
}
.drop-open {
  transition: height 0.5s;
  margin: 16px 0px;
  .user-shipment-accessibility-list-wrap {
    padding: 30px 10px 10px 15px;
    .user-name,
    .role-name,
    .accessibility-status,
    .list-action {
      display: inline-block;
      vertical-align: middle;
    }
    .user-name {
      width: 225px;
    }
    .role-name {
      width: 165px;
    }
    .accessibility-status {
      width: 300px;
    }
    &.header {
      font-size: 12px;
      font-weight: 900;
      color: #868e96;
    }
  }
}
.drop-close {
  visibility: hidden;
  height: 0px;
  transition: height 0.2s;
}
.open-arrow,
.closed-arrow {
  display: inline-block;
  transition: all 0.3s;
  cursor: pointer;
  position: relative;
  float: right;
  input {
    position: absolute;
    left: 0;
    opacity: 0;
    cursor: pointer;
  }
}
.open-arrow {
  transform: rotate(180deg);
}
.add-button {
  cursor: pointer;
  height: 40px;
  // padding: 0 20px;
  border-radius: 25px;
  color: #fff;
  background: @tms-admin-color;
  margin: 0px 20px;
  padding: 13px 30px;
  display: inline-block;
  border: 1px solid @tms-admin-color;
}
div.add-button {
  color: @tms-admin-color;
  background: #fff;
  border: 1px solid @tms-admin-color;
  &.add-level  {
    margin: 5px 5px;
  }
}
button.add-button {
  font-size: 14px;
}

.arrow-icon {
  background-image: url(/images/drop-up-arrow.svg);
  background-repeat: no-repeat;
  height: 23px;
  width: 23px;
  background-size: contain;
  margin: 0 5px;
  cursor: pointer;
  float: right;
  transition-delay: 0.2s;
  transition-duration: 0.2s;
  -webkit-transition-duration: 0.5s;
  -webkit-transition-delay: 0.2s;
}

.rotate {
  transform: rotate(180deg);
}

.contracts-management-grid-wrap {
  width: 100%;

  .description-block,
  .action-block {
    display: inline-block;
    vertical-align: middle;
  }

  .description-block {
    width: 51%;
  }

  .action-block {
    width: 48%;
  }

  .contracts-management-row {
    padding: 15px 0;
  }

  .action-block {
    &.indent-time {
      .yes-no-root {
        margin-right: 20px;
      }
    }
    .select {
      width: 350px;
    }
    .select-uom {
      width: 150px;
      margin-left: 5px;
      display: inline-block;
    }
  }

  .action-block .bidding-rate {
    display: flex;
    width: 372px;
  }

  .bidding-time {
    display: flex;
    width: 470px;

    .time-holder {
      margin-left: 15px;
      margin-top: 4px;

      .days-hours-holder {
        .dhm-label {
          //top: -28px;
          text-align: left;
          width: 93px;
        }
        .dhm-input {
          width: 70px;
          padding: 7px;
        }
      }
    }
  }

  .amount {
    width: 90px;
    padding: 7px;
  }
}

.contract-error {
  text-align: center;
  color: red;
  font-size: 14px;
  margin-top: 6px;
}

.contracts-management-row {
  &.currecy_converter {
    display: flex;
    .input-holder {
      margin: 15px 20px;
    }
  }
  .css-10nd86i.select {
    width: 338px;
  }
}

.approval-row {
  .select {
    width: 300px;
    margin: 5px;
    display: inline-block;
  }
}

.level-header {
  margin: 5px 0px;
  border-bottom: 1px solid grey;
  padding: 5px;
  color: #528aea;
  font-size: 16px;
  font-weight: 900;
}
.dInBlock {
  display: inline-block; 
}
.select-approver {
  margin-left: 20px;
  width: 300px;
}

.input_currency {
  width: 150px;
  margin: 5px;
  height:30px;
  display: inline-block;
  text-align: center;
}
.currencey_name{
  margin-left: 20px;
  .currency_label {
    text-align: center;
  }
}

.product-list {
  width: 100%;
  display: flex;
  .search-text-wrap {
      .text-search{
          margin-bottom: 10px;
          margin-left: 10px;
      }
  }
  .col-2 {
      width: 48%;
      box-shadow: 0 0 27px 4px rgba(69,68,68,.07);
      max-height: 720px;
      overflow: scroll;
  }
  .header {
      padding: 10px;
      text-align: center;
      // background-color: #528aea;
      // color: white;
      display: flex;
      color: #528aea;
      justify-content: space-between;
      height: 40px;
      .add-button {
          padding: 2px 18px;
          height: 20px;
      }
  }
  .lists {
      padding: 5px 10px;
      display: flex;
      border-top: 1px solid hsla(0,0%,59.2%,.2);
  }
  .ml-10 {
      margin-left: 10px;
  }
  .mr-10 {
      margin-right: 10px;
  }
  .space-btw {
      justify-content: space-between
  }
}

.intial-product-list{
  border-bottom: 1px solid #ececee;
  margin-left: 49px;
  user-select: all;
  cursor: pointer;
}

.add-product-button {
  cursor: pointer;
  //padding: 0 20px;
  border-radius: 25px;
  color: #1362e9;
  //background: @tms-admin-color;
  margin: 0px 20px;
  //padding: 13px 30px;
  display: inline-block;
  //border: 1px solid @tms-admin-color;
}

.select-all-product-list{
  padding-left: 40%
}
.product-header-name{
  color: #528aea;
  /* border-bottom: 4px solid; */
  margin-bottom: 4px;
  margin-left: 22px;
}

.col-4 {
  width: 25%;
  box-shadow: 0 0 27px 4px rgba(69,68,68,.07);
  max-height: 720px;
  // overflow: scroll;
  text-align: center;
}

.seleted-product-list{
  border-bottom: 1px solid #ececee;
  margin-left: 49px;
  cursor: pointer;
  user-select: all;
}

.product-details-list{
  border-bottom: 1px solid #ececee;
  cursor: pointer;
  min-height: 45px;
  color: black;
  padding-top: 10px;
}

.product-fields{
  width: 80%;
  height: 30px;
  margin-left: 20px;
  padding: 0px 10px;
}

.product-error-message{
  padding-left: 4%;
    color: red;
}
.user-select-product{
  border-bottom: 1 px solid #ececee;
	margin-left: 49 px;
	cursor: pointer;
	background-color: #a1a1a185;
}
.right-single-arrow{
  padding-top: 120px;
}
.right-double-arrow{
  padding-top: 8px;
}
.left-single-arrow{
  padding-top: 10px;
}

.equipment-option {
  .equipment_select__control {
    border: none;
  }
  input {
    height: 30px;
  }
}


