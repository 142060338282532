@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import 'stylekit/button';
@import 'stylekit/table';
@import 'stylekit/flexbox';
@import 'stylekit/spacing';
@import 'stylekit/borders';
@import 'stylekit/typography';
@import 'stylekit/interactivity';
@import 'stylekit/sizing';



.pageWrapperT1 {
	padding: 24px;
	position: relative;
	background-color: #E3E9FF;
	height: calc(100vh - 80px);
	overflow-y: auto;
}


// background-color SH

.bg-white {
	background-color: #FFFFFF;
}

.bg-627FE9 {
	background-color: #627FE9;
}

.bg-EFF2FD {
	background-color: #EFF2FD;
}

// background-color EH



// color SH

.text-white {
	color: #FFFFFF;
}

// color EH


// margin SH 

.mb-6 {
	margin-bottom: 6px;
}

// margin EH


// label SH

.labelT1 {
	color: #FFF;
	font-family: 'Poppins',sans-serif;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 16px;
}

// label EH



.react-select-container-T1 {
	min-width: 200px;

	& .css-1hwfws3 {
		padding: 0px 4px;
		max-height: 32px;
	}

	& .css-151xaom-placeholder {
		top: 58%;
	}
	
	
	& .css-16pqwjk-indicatorContainer {
		padding: 0px 4px;
	}
}