// NavBarSection SH

.navBarT1>.header-panel {
    width: 100%
}

// NavBarSection EH

.auditDateWrapper {
    td {
        border: 1px solid #e4e7e7;
    }

    display: flex;
    align-items: center;
    gap: 8px;
    border-radius: 2px;
    border: 1px solid #627FE9;
    background: #FFF;
    padding: 2px 5px;

    .SingleDatePickerInput {
        border: 0px;
    }
    .DateInput_1{
        width: 110px;
    }

    #auditLogToDate,
    #auditLogFromDate {
     max-height: 31px;
    //  width: 100px;
     font-size: 16px;
        &::placeholder {
            font-size: 14px;
        }
    }
}
.audit-text-wrap{
    word-wrap: break-word;
}