.location-network {
    .loaction-btn {
        margin: 10px 0px;
    }
    .button-classic {
        margin-left: 10px;
    }
    .select {
        width: 130px;
        margin-right: 20px;
    }
    table{
        border: white;
    }
    .chain-details-section {
        margin-top: 30px;
        .list-section {
            border-bottom: 1px solid rgba(212, 212, 212, 0.5);
            padding-bottom: 25px;
            padding-top: 20px;
            display: flex;
            &.content{
                // margin-top: -20px;
                &:hover {
                border: 1px solid rgba(0, 89, 251, 0.38);
                box-shadow: 0px 10px 20px 0px rgba(129, 172, 212, 0.45);
                }
            }
            &.header{
                font-weight: 600;
                padding-top: 0;
                color: #1a2734;
                font-size: 14px;
            }
        }
        .tabel-header {
            width: 170px;
            display: inline-block;
            vertical-align: middle;
            // padding: 5px;
            white-space: nowrap; /* Prevent line wrapping */
            // overflow: hidden; /* Hide overflowing content */
            // text-overflow: ellipsis; /* Add ellipsis (...) for overflow */
            font-size: 14px;
            padding: 5px;
            &.address {
                width: 300px;
            }
        }
        .vol-th{
            background-color: rgb(100, 100, 235);
            color: white;
            border: rgb(100, 100, 235);
            height: 70px;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            
        }
        .vol-td{
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .vol-description-td{
            white-space: normal;
            width: 150px;
        }
        .vol-branch-td{
                text-align: center;
                margin-left: 1rem;
                white-space: normal;
        }
    }
    .route-chain-details-section {
        margin-top: 30px;
        overflow: auto;
        width: 110%;
        .list-section {
            width: max-content;
            border-bottom: 1px solid rgba(212, 212, 212, 0.5);
            padding-bottom: 25px;
            padding-top: 20px;
            &.content{
                &:hover {
                border: 1px solid rgba(0, 89, 251, 0.38);
                box-shadow: 0px 10px 20px 0px rgba(129, 172, 212, 0.45);
                }
            }
            &.header{
                font-weight: 600;
                padding-top: 0;
                color: #1a2734;
                font-size: 14px;
            }
        }
        .tabel-header {
            width: 170px;
            display: inline-block;
            vertical-align: middle;
            &.address {
                width: 300px;
            }
        }
    }
    .refresh {
        display: inline-block;
        width: 33%;
        .long {
            margin: 0px 65px;
        }
    }
    .refresh-icon {
        background-image: url(/images/refresh-button.svg);
        background-repeat: no-repeat;
        height: 15px;
        width: 15px;
        background-size: contain;
        margin: 0px 58px;
        cursor: pointer;
        display: inline-block;
        position: absolute;
    }

    .action-icon {
        background-repeat: no-repeat;
        height: 18px;
        width: 18px;
        background-size: contain;
        // margin: 0 5px;
        cursor: pointer;
        display: inline-block;
        padding: 5px;
        margin: 5px;
    }
    .sync-button {
        border: 1px solid;
        border-radius: 5px;
        width: 80px;
        padding: 2px;
    }
    .error-color {
        color: #d9042a;
    }
}
.vol-act{

    margin-left: 70px;

}
.cdsTable{
    th{
        width: 1200px; 
    }
    tr{
        width: 1200px;
    }
    td{
        border: none;
        white-space: nowrap;
        word-wrap: break-word;
    }
}
.depobtn-div{
    margin-left: 750px;
}
.depots-btn{
    border: white;
    
}
.location-network .chain-details-section .tabel-header{
    text-wrap: wrap;
}