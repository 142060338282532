.Lr-summary {
    width: 100%;
    .summary{
      .LSP-threshold {
        display: flex;
        justify-content: space-between;
        align-items: center;
  
        .dropLSP{
          width: 20%;
        }
  
        .change-thresold {
          color: #528aea;
          cursor: pointer;
        }
      }
  
      .table {
        width: 100%;
        border-radius: 8px;
        display: flex;
    
        th {
          border: 1px solid white;
        }
    
        td {
          border: 1px solid white;
          border-right: 1px solid #c4d4f0;
          padding: 8px 8px;
        }
    
        .table-tr {
          background-color: #3261b1;
          color: white;
          font-size: 13px;
    
          .tCenter {
            padding: 5px 10px;
          }
        }
  
    
        .tr-2 {
          background-color: #d6e0ef;
        }
  
        .table-view2 {
          margin-left: 20px;
        }
      }
    }
  }