@import "../../variables.less";

.navigation-panel-root{
    font-size: 16px;
    .navigation-holder{
        display: flex;
        justify-content: left;
        border-bottom: 4px solid #f9f9f9;
        max-width: 885px;
        margin: 30px 0px;
        .options{
            border-bottom: 4px solid #f9f9f9;
            padding: 5px 10px;
            cursor: pointer;
            margin-bottom: -4px;
        }
        .selected-option{
            color: @tms-admin-color;
            border-bottom: 4px solid;
        }
    }
}
body{
    -webkit-box-sizing: border-box;
}