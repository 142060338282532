.cookie-policy {
  // max-width: 1200px;
  margin: 0 auto;
  padding: 0px 60px 1px 60px;
  font-family: Arial, sans-serif;
  color: #333;
  // background-color: #f9f9f9;
  // border-radius: 8px;
  // box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  .title {
    width: 100%;
    font-size: 28px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 15px;
  }

  .effective-date {
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 20px;
  }

  .company_link {
    margin-left: 8px;
  }

  .fontBold {
    font-weight: 700 !important;
    color: black;
  }

  h2 {
    font-size: 22px;
    margin-top: 20px;
    color: #222;
    font-weight: 700;
    margin-bottom: 10px;
  }

  p,
  ul {
    font-size: 16px;
    line-height: 1.6;
    margin-bottom: 6px;
    color: #222;
  }

  ul {
    margin-left: 20px;
    list-style-type: disc;
    color: #222;
  }

  a {
    color: #007bff;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }


  .title-1 {
    text-decoration: underline;
    margin-bottom: 12px;
    font-weight: 700;
    color: #222;
    font-size: 18px;
  }

  .description-1 {
    margin: 0px;
  }

  .info-card-1 {
    display: flex;
    justify-content: center;

    .info-card-1-message {
      font-size: 16px;
      margin-top: 10px;
      font-weight: 700 !important;
      margin-bottom: 6px;
      color: #222;
    }
  }

 .table-container-1 {

    width: 100%;
    overflow-x: auto;
    margin-top: 10px;

    table {
      border: 0px;
      width: 100%;
      border-collapse: collapse;
      font-family: Arial, sans-serif;
    }

    thead {
      background: white;
      position: sticky;
      top: 0;
      z-index: 10;
    }

    th {
      border: 0px;
      text-align: left;
      font-weight: bold;
      padding: 12px;
      color: #222;
      border-bottom: 2px solid #0056b3;
    }

    td {
      padding: 12px;
      border-left: 0px;
      border-right: 0px;
      border-bottom: 1px solid #ddd;
      color: #222;
    }

    tr:last-child td {
      border-bottom: none;
    }
  }
}