.reconciliation {
 
    .list-section {
        font-weight: 600;
        color: #1a2734;
        font-size: 15px;
        display: flex;
        justify-content:space-between;
        border-bottom: 1px solid rgba(212, 212, 212, 0.5);
        .table-header {
            width: 50%;
            padding: 10px 0px;
            margin-left: 30px;
        }
        .table-row {
            width: 50%;
            margin-left: 30px;
            padding: 10px 0px;
        }
        .event-id {
            width: 20%;
        }
        .dropdownInput {
            width: 20%;
            height:40px;
            border: 1px solid lightgray;
            margin-top: 2px;
            outline: none;
            border-radius: 4px;
            padding: 5px;
        }
        .event {
            width: 60%;
        }
        .action {
            width: 20%;
            cursor: pointer;
        }
        .rfq-input {
            width: 100%;
            height: 3;
            min-height: 33px;
            font-size: 16px;
            border: 1px solid #cccccc;
            border-radius: 6px 5px 5px 5px;
            padding: 7px 9px;
        }
        .delete-icon {
            margin-top: 10px;
        }
    }
    .approval-btn {
        text-align:right;
        margin-right: 50px;
        margin-bottom: 20px;
        .button-classic {
            margin-right: 10px;
        }
    }
   
    
    
    .debit-master {
        .late-delivery {
            margin-top: 10px;
            padding: 10px;
            .grace {
                display: inline-block;
                width:33%;
                .input-text-wrap {
                    width: 30%;
                    .text-input {
                        width: 92px;
                    }
                }
            }
            .amount {
                display: inline-block;
                width:23%;
                .input-text-wrap {
                    width: 30%;
                }
                .text-input {
                    width:180px;
                }
            }
            .select-wrap {
                display: inline-block;
                vertical-align: middle;
                width: 33%;
                margin-bottom: 20px;
                .label{
                    font-size: 10px;
                    color: #868e96;
                    //text-transform: uppercase;
                    margin-bottom: 10px;
                }
                .select {
                    width: 277px;
                }
            }
        }
        .non-register-vendor {
            .list-section {
                border: none;
                .table-row {
                    padding: 10px 0px;
                }
            }

        }
    }
    
}
