.branch-list-root{
    .modal-popup{
        .branch-detail{
            display: flex;
            margin: 20px;
            .branch{
                margin: 2px 0px 0px 15px;
                &.selectAll {
                	font-family: 'avenir',sans-serif;
    				font-weight: 800;
    				margin-top: 0;
                }
            }
        }
    }
}