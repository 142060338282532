.tatForm{
    overflow: scroll;
    height: 100%;
    width: 100%;
    .tat-header{
        font-size: 20px;
        color: #627FE9;
        text-align: center;
        margin: 10px;
    }
    .tat-table{
        background-color: #627FE9;
        color: white;
        font-size: 14px;
        font-weight: bolder;
        text-align: center;
        border: 1px solid #F6F9FF;
    }
    .tat-body{
        font-size: 14px;
        font-weight: bolder;
        text-align: center;
        border: 1px solid #9f9e9e63;
        background-color: #F6F9FF;
        .m-5{
            margin: 5px;
        }
        .laneInput .input-text-wrap {
            width: 90%;
            margin-bottom: 0px !important;
            .label{
                margin-bottom: 0px !important;
            }
            .text-input {
                width: 100%;
                font-size: 14px;
                padding-bottom: 2px;
                height: 30px;
            }
        }
    
    }
    .wt-5p{
        width: 5%;
    }
    .wt-10p{
        width: 10%;
    }
    .wt-15p{
        width: 15%;
    }
    .approveButton{
        .button-classic{
            color: green;
            border: 1px solid green;
        }
    }
    .rejectButton{
        .button-classic{
            color: red;
            border: 1px solid red;
        }
    }
}