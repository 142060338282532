.add-freight-rate {
    .w-p-24 {
        width: 24% !important;
    }
    .w-p-25 {
        width: 25% !important;
    }
    .w-200 {
        width: 200px !important;
    }
    .w-225 {
        width: 225px !important;
    }
    .w-p-16 {
        width: 16% !important;
    }
    .w-p-18 {
        width: 18% !important;
    }
    .w-150 {
        width: 150px !important;
    }
    .w-p-50 {
        width: 50% !important;
    }
    .m-t-15 {
        margin-top: 15px;
    }
    .m-t-10 {
        margin-top: 10px;
    }
    .inline-flex {
        display: inline-flex !important;
    }
    .label {
        font-size: 10px;
        color: #868e96;
      //  text-transform: uppercase;
        margin-bottom: 5px !important;
    }
}