.newVendor {
    margin: 30px;
    padding: 10px;
    box-sizing: border-box;
    font-family: 'Poppins',sans-serif;
    letter-spacing: 0.01em;
    background: #E3E9FF;
    .overlay {
        position: fixed;
        z-index: 10;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: rgba(27, 33, 67, 0.9);
    }
    .modal-popup {
        position: fixed;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        border-radius: 5px;
        width: 1065px;
        min-height: 500px;
        height: 700px;
        overflow-y: scroll;
        background-color: #E3E9FF;
        z-index: 11;
        padding: 40px 47px 15px 47px;
        .mainHeading {
            font-family: 'Poppins',sans-serif;
            font-size: 30px;
            font-weight: 800;
            line-height: 26px;
            letter-spacing: 0.01em;
            text-align: left;
            margin-bottom: 20px;
        }
        .inputBlock {
            min-width: 250px;
            height: 40px;
            padding-left: 5px;
            border: 1px solid #627FE9;
        }
        .inputBlock:focus {
            border: 2px solid #627FE9;
            outline-offset: 0px;
            outline: none !important;
        }

        .labels {
            margin-bottom: 10px;
        }

        .vendorDetails {
            padding: 20px;
            width: 908px;
            height: 240px;
            margin: 0 20px 20px 20px;
            background-color: #FFFFFF;
            border-radius: 7px;
        }
        .BlockDetails {
            padding: 20px;
            width: 908px;
            height: 180px;
            margin: 0 20px 20px 20px;
            background-color: #FFFFFF;
            border-radius: 7px;

        }

        .emailDetails {
            padding: 20px;
            width: 945px;
            min-height: 210px;
            background-color: #E3E9FF;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            .Bclr {
                background-color: #FFFFFF;
                width: 310px;
                min-height: 170px;
                margin: 0px 0px;
                border-radius: 7px;
                .delete-icon {
                    margin: 0px;
                    margin-top: 10px;
                }

            }

            .Bclr1 {
                background-color: #FFFFFF;
                width: 582px;
                height: 170px;
                margin: 0px 0px;
                border-radius: 7px;

                .select {
                    width: 270px;
                }

                .source {
                    display: flex;
                    height: 40px;

                }

            }

        }

        .cecBlock {
            display: flex;
            justify-content: space-between;
            flex-direction: row;

        }

        .select {
            width: 420px;
            border: 1px solid #627FE9;
            border-radius: 4px;
            // outline-offset: 0px;
            //  outline: none !important;
        }
        .select:focus {
            border: 2px solid #627FE9;
            border-radius: 4px;
            outline-offset: 0px;
            outline: none !important;
        }

        span {
            color: red;
        }

    }

    .submit-section {
        display: flex;
        justify-content: flex-end;

        .button-section1 {
            border: 1px solid #627FE9;
            width: 120px;
            border-radius: 6px;
            background-color: #FFFFFF;
            color: #627FE9;
            padding: 10px;
        }
        .button-section1:hover {
             cursor:pointer;
        }
        .sections {
            background-color: #627FE9;
            color: #FFFFFF;
        }
    }
}