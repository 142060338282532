.vendor_mapping_detail {
    .overlay{
        position: fixed;
        z-index: 10;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: rgba(27, 33, 67, 0.9);
    }
    .modal-popup .pop-heading {
        background-color: #f4f4f4;
        padding: 18px;
        font-family: 'avenir',sans-serif;
        font-size: 16px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #528aea;
        text-align: center;
    }
    .modal-popup {
        max-height: 670px;
        overflow: scroll;
        position: fixed;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        border-radius: 5px;
        width: 1000px;
        min-height: 270px;
        background-color: white;
        z-index: 11;
    }
    .vendor-detail {
        margin: 55px;  
    }
    
    .scheduler-detail {
        margin: 10px;
    }
    .button_route {
        margin-right: 10px;
        float: right;
    }
    .error-message {
        margin-right: 10px;
        float: right;
        font-size: 12px;
        color: red;
    }
    .select-wrap-depot{
        width: 33%;
        display: inline-block;
        vertical-align: baseline;
        .label{
            font-size: 10px;
            color: #868e96;
            margin-bottom: 10px;
        }
    }
    &.lane-mapping {
        .select-wrap-depot{
            width: 32%;
            margin-right: 10px;
        }
    }
}
// .list-section{
//     display: flex;
// }
// .list-section {
//     font-weight: 600;
//     color: #1a2734;
//     font-size: 15px;
//     display: flex;
//     justify-content:space-between;
//     border-bottom: 1px solid rgba(212, 212, 212, 0.5);
//     .table-header {
//         color: #868e96;
//         font-size: 16px;
//         display: flex;
//      justify-content: space-between;
//         }
//     }
//     .select-btn{
    //     display: flex;
    //     justify-content: space-between;
    //     margin-left: 0px;
    //     margin-top: 10px;
    //      .select {
    //         width: 220px;
    //     }
    // }