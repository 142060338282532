
// Flex Style SH

.flex{
	display: flex;
}


// align-items

.items-start{
	align-items: flex-start;
}

.items-end{
	align-items: flex-end;
}

.items-center{
	align-items: center;
}

.items-baseline{
	align-items: baseline;
}

.items-stretch{
	align-items: stretch;
}

// justify-content

.justify-normal{
	justify-content: normal;
}
.justify-start{
	justify-content: flex-start;
}
.justify-end{
	justify-content: flex-end;
}
.justify-center{
	justify-content: center;
}
.justify-between{
	justify-content: space-between;
}
.justify-around{
	justify-content: space-around;
}
.justify-evenly{
	justify-content: space-evenly;
}
.justify-stretch{
	justify-content: stretch;
}

// gap

.gap-5{
	gap: 5px;
}
.gap-10{
	gap: 10px;
}
.gap-15{
	gap: 15px;
}

// flex-wrap

.flex-wrap{
  flex-wrap: wrap;
}
.flex-wrap-stretch { align-content: stretch; }
.flex-wrap-top { align-content: flex-start; }
.flex-wrap-middle { align-content: center; }
.flex-wrap-bottom { align-content: flex-end; }
.flex-wrap-between { align-content: space-between; }
.flex-wrap-around { align-content: space-around; }

.flex-items-middle { align-items: center; }
// Flex Style EH
