.button-default {
    cursor: pointer;
    padding: 0 15px;
    border-radius: 20px;
    border: 1px solid #528aea;
    background: #528aea;
    color: #fff;
    min-width: 76px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    display: inline-block;
    vertical-align: bottom;
    margin-bottom: 10px;
}
.wt-33p{
    width: 33%;
}
.m-10{
    margin: 10px;
}
.p-10{
    padding: 10px;
}
.tCenter{
    text-align: center;
}