.Lr-upload {
    width: 105%;
  
    .border {
      border: 1px solid #528aea;
      padding: 20px 25px;
      border-radius: 8px;
      background-color: white;
  
      .flex-item{
      display: flex;
      gap: 20px;
      align-items: center;
  
      .dropLSP {
        width: 20%;
      }
  
      .boundType {
        width: 20%;
      }
  
      .dropLSP::placeholder {
        color: #528aea;
      }
  
      .file-holder{
        width: 300px;
        height: 36.5px;
        border-radius: 2px;
        background-color: #f9f9f9;
        border: 1px solid #cccccc;
        cursor: pointer;
        position: relative;
        input{
            width: 300px;
            height: 35px;
            opacity: 0;
            cursor: pointer;
        }
        .file-choose{
            position: absolute;
            right: 9px;
            top: 9px;
            font-family: 'avenir',sans-serif;
            font-size: 12px;
            letter-spacing: 0.3px;
            cursor: pointer;
            color: #528aea;
            border-left: 1px solid black;
            padding-left: 8px;
          }
        .file-name{
            font-family: 'avenir',sans-serif;
            font-size: 12px;
            position: absolute;
            top: 9px;
            left: 12px;
            color: #86868a;
        }
      }
  
      .upload-button{
        border: 1px solid #528aea;
        padding: 8px 25px;
        font-size: 13px;
        background-color: #528aea;
        color: white;
        border-radius: 3px;
        cursor: pointer;
    }
  
    .template {
      border: 1px solid #528aea;
      display: flex;
      align-items: center;
      padding: 2px 8px;
      gap: 5px;
      border-radius: 5px;
      background-color: white;
      cursor: pointer;
  
      .template-text {
        color: #528aea;
      }
    }
      }
    }
  
    .all {
      padding-top: 20px;
      display: flex;
      gap: 20px;
      align-items: center;
  
      .BdropLSP {
        width: 17%;
      }
  
      .BboundType {
        width: 17%;
      }
    }
  
  
    .branchdrop {
      width: 25%;
    }
  
    .lr-range{
      border: 1px solid #528aea;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 2px 8px;
      border-radius: 5px;
      width: 300px;
      height: 36px;
      color:#729FEB;
  
      .lr-rangeleft{
        width: 80%;
        display: flex;
        align-items: center;
        // border: 1px solid red;
        border-right: 1px solid #729FEB;
        
        .lr-range-to{
          width:10%;
          font-size: 12px;
          // margin-left: 30px;
          // border: 1px solid red;
        }
        .lr-range1{
          width: 45%;
          display: flex;
          align-items: center;
          // border: 1px solid red;
          input{
            width: 100px;
            height: 33px;
            margin-top: -1px;
            cursor: pointer;
            border:none;
            outline: none;
            margin-left: 5px;
            background-color: transparent;
            color:#729FEB;
          }
      }      
      }
  
      .lr-rangeright{
        width: 20%;
        display: flex;
        justify-content: center;
        cursor: pointer;
  
        &:hover{
          color:#3261b1;
        }
      }
  
    }
  
    .del-check {
      padding-top: 20px;
      display: flex;
      gap: 20px;
      align-items: center;
      margin-left: 10px;
    }
  
    .table {
      width: 100%;
      border-radius: 8px;
      margin-left: -30px;
      th {
        border: 1px solid white;
      }
  
      td {
        border: 1px solid white;
        border-right: 1px solid #c4d4f0;
      }
  
      .table-tr {
        background-color: #3261b1;
        color: white;
        font-size: 13px;
  
        .tCenter {
          padding: 10px 15px;
        }
      }
      .tr-1 {
        background-color: #d6e0ef;
      }
    }
  }