.tax-code-details {
  background-color: white;
  border: 1px solid grey;
  border-radius: 10px;
  width: 98%;
  margin-top: 30px;
  margin-left: 13px;
  
  label{
    margin-bottom: 1rem;
  }
  h3 {
    margin-left: 13px;
    font-size: 20px;
    font-weight: 500;
    margin-top: 15px;
  }
  .planning-component {
    padding: 20px;
    margin: 0 auto;
  }

  .row-container {
    margin-bottom: 20px;
  }

  .row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    width: 80%;
  }

  .column {
    flex: 1;
    margin: 0 5px;
  }

  .transhColumn{
    flex: 1;
    margin: 0 5px;
    margin-top: 1rem;
  }

  input {
    width: 100%;
    padding: 8px;
    border-radius: 4px;
    height: 29px;
    border: 1px solid #ccc;
    font-size: 16px;
  }
  .row-input {
    height: 43px;
  }
  .add-btn,
  .delete-btn {
    background-color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
    font-size: 16px;
    border-radius: 4px;
  }

  .add-btn {
    display: flex;
    align-items: center;
    margin-top: 20px;
    background-color: white;
    border: 1px solid hsl(0, 0%, 80%);
    color: #000000b8;
  }

  .delete-btn {
    background-color: white;
    color: #ff0000d4;
    padding-left: 14px;
    padding-top: 11px;
    padding-bottom: 7px;
  }

  .add-btn:hover {
    opacity: 0.8;
    color: white;
    background-color: cornflowerblue;
  }

  .delete-btn:hover {
    opacity: 0.8;
    color: white;
    background-color: #e41313d4;
  }

  .add-btn svg,
  .delete-btn svg {
    margin-right: 5px;
  }
  .tax-code-btns {
    display: flex;
    button {
      margin-right: 1rem;
    }
  }
}
